import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { accountService } from './Services';

window.dataLayer = window.dataLayer || [];

const root = ReactDOM.createRoot(document.getElementById('root'));
accountService.refreshToken(init).finally();

function init() {
  return root.render(
    <HashRouter>
      <App />
    </HashRouter>
  );
}
