import React, { useState } from "react";
import { Modal } from '../../Components';
import { AnimatePresence } from "framer-motion";
import { utils } from '../../Helpers/utils';
import { ModalOpportunityTerms, ModalConfirmDecline } from "../../Pages";
import {
  accountService,
  influencerService
} from '../../Services';
export default function ModalOpportunity(props) {
  const [modalDataTerms, setModalDataTerms] = useState(null);
  const [modalTerms, setModalTerms] = useState(null);
  const [email, setEmail] = useState(props.opportunity.email);
  const [error, setError] = useState(false);

  const fetchTerms = async () => {
    try {
      const res = await accountService.getUserTerms();
      if (res['note'] && res['note'] !== '') {
        setModalTerms("opportunityTerms");
        setModalDataTerms({ termsDetails: res, showPlatformTerms: true, opportunity: props.opportunity });
      } else {
        setModalTerms("opportunityTerms");
        setModalDataTerms({ ...props.opportunity, showPlatformTerms: false });
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        date: `${Date.now()}-${props.toastList.length}`
      });
    }
  };

  const onCollaborate = async () => {
    let opportunity = { ...props.opportunity, type: 'COLLABORATE', email: email };
    //document.getElementById("email").parentElement.className = 'input error';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email == "" || emailRegex.test(email) === false) {
      setError(true);
    } else {
      setError(false);
      confirm(opportunity);
    }
  };
  const confirmDecline = () => {
    setModalTerms("confirm");
    setModalDataTerms();
  };
  const onReject = () => {
    let opportunity = { ...props.opportunity, type: 'REJECT' };
    confirm(opportunity);
  };

  const confirm = async (opportunity) => {
    try {
      let payload = {};
      if (opportunity['type'] === 'COLLABORATE') {
        payload = { email: opportunity.email, status: 'accepted', terms: 1, influencer_id: props.opportunity.influencer_id, t_and_c_id: opportunity.t_and_c_id };
      } else if (opportunity['type'] === 'REJECT') {
        payload = { status: 'reject', influencer_id: props.opportunity.influencer_id, t_and_c_id: opportunity.t_and_c_id };
      }
      const res = await influencerService.collaborate(
        opportunity.id,
        payload
      );
      if (res['status'] === 'success') {
        addToast({
          error: false,
          msg: res['message'],
          date: new Date()
        });
        props.setModal(null);
        props.setModalData(null);
        props.updateOnCollaborate();
      }
    } catch (err) {
      //props.setModal(null);
      // props.setModalData(null);
      addToast({
        error: true,
        msg: "Please enter valid email.",
        date: new Date()
      });
    }
  };
  const addToast = data => {
    const newToastList = [...props.toastList];
    newToastList.push({ ...data, id: `${Date.now()} - ${props.toastList.length}` });
    props.setToastList(newToastList);
  };
  return (
    <>
      <Modal active={props.active} close={props.close}>
        {modalTerms !== "opportunityTerms" && (

          <div
            className={
              "modal__opportunity " +
              (props.accountCount > 1 ? props.opportunity.handle.handle != '' ? "moreAcc " : "" : "")
            }
          >
            {(props.accountCount > 1 && props.opportunity.handle) && (
              <div className="modal__opportunity-account">
                {props.opportunity.handle.icon && utils.getIconFilled(props.opportunity.handle.icon[0])}
                {props.opportunity.handle && <span>{props.opportunity.handle.handle}</span>}
              </div>
            )}
            <div className='modal__opportunity-title'>
              <div className='modal__opportunity-title-image'>
                <img
                  src={
                    props.opportunity.brand_logo
                      ? props.opportunity.brand_logo
                      : `${process.env.PUBLIC_URL}/images/bulls-eye.png`
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = `${process.env.PUBLIC_URL}/images/bulls-eye.png`;
                  }}
                  alt='bulls-eye'
                />
              </div>
              <div className='modal__opportunity-title-content'>
                <h3>{props.opportunity.brand_name}</h3>
                <div className='modal__opportunity-title-content-row'>
                  <h6>{props.opportunity.title}</h6>
                  {props.opportunity.products && props.opportunity.products.map((product, idx) => (
                    <a href='#' key={idx}>
                      {utils.getIcon(product)}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className='modal__opportunity-content'>
              <p><strong>{props.opportunity.social_product}</strong></p>
              <h3>Overview</h3>
              <p className="hideToolbar">
                <span>
                  {props.opportunity.description
                    ? utils.draftJsFormatingForDetails({ 'data': props.opportunity.description, 'view': 'fullview' })
                    : 'NA'}
                </span>
              </p>
              {props.opportunity.revisionWindow ?
                <>
                  <h3>Revision Submission Deadline</h3>
                  <p>
                    {props.opportunity.revisionWindow}
                  </p>  </> : ''}
              <h3>Required Hashtags</h3>
              <p>
                {props.opportunity.hashtag}
              </p>
              <h3>Deliverables</h3>
              <p className="hideToolbar">
                {props.opportunity.deliverable ?
                  <span>
                    {props.opportunity.deliverable
                      ? utils.draftJsFormatingForDetails({ 'data': props.opportunity.deliverable, 'view': 'fullview' })
                      : 'NA'}
                  </span>
                  :
                  'NA'}
              </p>
              {props.opportunity.compensationDetails && (
                <>
                  {
                    props.opportunity.compensationDetails.product != '' && (
                      <>
                        <h3>Product</h3>
                        <p>
                          {props.opportunity.compensationDetails.product}
                        </p>
                      </>
                    )
                  }
                </>
              )}
            </div>
            <div className='modal__opportunity-footer'>
              <div className='modal__opportunity-footer-row'>
                <div className='modal__opportunity-footer-col'>
                  <h3>Compensation</h3>
                  <div className='modal__opportunity-footer-col-inner'>
                    <h6>
                      Amount: {''}
                      <span>
                        {props.opportunity.compensationDetails
                          ? props.opportunity.compensationDetails.amount

                          : 'NA'}
                        {(props.opportunity.rate_overwritten && parseInt(props.opportunity.rate_overwritten) == 1)
                          && <img className="redFlag"
                            src={`${process.env.PUBLIC_URL}/images/red-flag.png`} alt="red-flag" />
                        }
                      </span>
                    </h6>
                    {props.opportunity.compensationDetails && props.opportunity.compensationDetails.product != '' &&
                      <h6>
                        Product:
                        <span>
                          {props.opportunity.compensationDetails
                            ? props.opportunity.compensationDetails.product != '' ? 'Yes'
                              : 'No' : 'NA'}
                        </span>
                      </h6>
                    }
                  </div>

                </div>
                <div className='modal__opportunity-footer-col'>
                  <h3>Post Window</h3>
                  <div className='modal__opportunity-footer-col-inner'>
                    <h6>
                      <span>{props.opportunity.start_date}{' -> '}{props.opportunity.end_date}</span>
                    </h6>
                    {/*<h6>
                      Opt in by: <span>{props.opportunity.end_date}</span>
                        </h6>*/}
                  </div>
                </div>
                <div className='modal__opportunity-footer-col'>
                  <h3> Opt in by</h3>
                  <div className='modal__opportunity-footer-col-inner'>
                    <h6>
                      <span>{props.opportunity.deadline_date != "" && props.opportunity.deadline_date !== null ? props.opportunity.deadline_date : 'NA'}</span>
                    </h6>
                    {/*<h6>
                      Opt in by: <span>{props.opportunity.end_date}</span>
                        </h6>*/}
                  </div>
                </div>
              </div>
              <div className='modal__opportunity-footer-btns'>
                <button
                  className='button secondary'
                  onClick={fetchTerms}
                >
                  I’m interested in this opportunity
                </button>

                <button
                  className='button secondary red'
                  onClick={confirmDecline}
                >
                  Decline
                </button>
              </div>
            </div>
          </div>
        )}
        {modalTerms === "opportunityTerms" && (
          <ModalOpportunityTerms
            setToastList={props.setToastList}
            accountCount={props.accountCount}
            toastList={props.toastList}
            {...modalDataTerms}
            setModalData={setModalDataTerms}
            setModal={setModalTerms}
            close={() => props.setModal(null)}
            updateOnCollaborate={props.updateOnCollaborate}
            onReject={onReject}
            email={email}
            setEmail={setEmail}
            onCollaborate={onCollaborate}
            setError={setError}
            error={error} />
        )}
        {modalTerms === "confirm" && (
          <ModalConfirmDecline
            close={() => setModalTerms(null)}
            onReject={onReject}
          />
        )}
      </Modal >
    </>

  );
}
