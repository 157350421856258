import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Modal, SelectInput } from '../../Components';

const selectList = [
  { id: '3', value: "I'm a brand" },
  { id: '1', value: "I'm a influencer" }
];

export default function ModalPlatformCreate(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState(false);
  // let roleType = searchParams.get('type') ? searchParams.get('type') : null;
  // roleType =
  //   roleType !== null && roleType == 1 ? "I'm a influencer" : "I'm a brand";
  let refId = (props.referId === null || props.referId === undefined) ? 0 : props.referId;
  const [selectDesc, setselectDesc] = useState(props.roleId);


  const handleLoginWithFacebook = () => {
    if (!isChecked) {
      setError(true);
    }
    else {
      let roleID = selectDesc === "I'm a brand" ? 3 : 1;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/facebook/action/signup/role/${roleID}/${refId}/referalPath`;
    }
  };

  const handleLoginWithGoogle = () => {
    if (!isChecked) {
      setError(true);
    }
    else {
      let roleID = selectDesc === "I'm a brand" ? 3 : 1;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/google/action/signup/role/${roleID}/${refId}/referalPath`;
    }
  };
  const handleLoginWithTikTok = () => {
    if (!isChecked) {
      setError(true);
    }
    else {
      let roleID = selectDesc === "I'm a brand" ? 3 : 1;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/tiktok/action/signup/role/${roleID}/${refId}/referalPath`;
    }
  };
  const handleLoginWithInsta = () => {
    if (!isChecked) {
      setError(true);
    }
    else {
      let roleID = selectDesc === "I'm a brand" ? 3 : 1;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/instagram/action/signup/role/${roleID}/${refId}/referalPath`;
    }
  };
  return (
    <Modal addClass='platform' close={props.close}>
      <div className='modal__platform'>
        <h3 className="accountType">{selectDesc}</h3>
        <div className='checkbox'>
          <input type='checkbox' checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          <label htmlFor=''></label>
          <p>
            Accept{' '}
            <span onClick={() => props.setModal('termsCreate')}>
              Terms & Conditions
            </span>
          </p>
          {error && !isChecked && (
            <div className="checkbox__error">You have to accept terms</div>
          )}
        </div>
        <h4>Which platform would you like to sign up with?</h4>
        <button
          className='button'
          onClick={handleLoginWithFacebook}
        >
          <img
            src={process.env.PUBLIC_URL + '/images/facebookSign.png'}
            alt=''
          />
          Sign up using Facebook
        </button>
        <button
          className='button'
          onClick={handleLoginWithInsta}
        >
          <img
            src={process.env.PUBLIC_URL + '/images/instagramSign.png'}
            alt=''
          />
          Sign up using Instagram
        </button>
        <button
          className='button'
          onClick={handleLoginWithTikTok}
        >
          <img
            src={process.env.PUBLIC_URL + '/images/tiktokSign.png'}
            alt=''
          />
          Sign up using TikTok
        </button>
        <button
          className='button'
          onClick={handleLoginWithGoogle}
        >
          <img src={process.env.PUBLIC_URL + '/images/googleSign.png'} alt='' />
          Sign up using Google
        </button>

      </div>
    </Modal>
  );
}