import React, { useEffect, useRef, useState } from "react";
import AssetVideo from "./AssetVideo";

export default function AssetsTableItem(props) {
  const [checked, setChecked] = useState(false);
  const videoWrapperRef = useRef(null);
  const checkboxRef = useRef(null);
  const clickFunc = (e) => {
    if (
      !videoWrapperRef.current.contains(e.target) &&
      !checkboxRef.current.contains(e.target)
    ) {
      props.onClick();
    }
  };

  useEffect(() => {
    setChecked(props.allChecked);
  }, [props.allChecked]);

  return (
    <tr
      onClick={clickFunc}
      className={
        props.modal === "assetGood"
          ? props.checkedSolo === props.index
            ? "active"
            : ""
          : checked
            ? "active"
            : ""
      }
    >
      <td ref={checkboxRef}>
        <div className="checkbox">
          <input
            checked={
              props.modal === "assetGood"
                ? props.checkedSolo === props.index
                  ? true
                  : false
                : checked
            }
            onChange={(e) => {
              if (props.modal === "assetGood") {
                if (e.target.checked) {
                  props.setCheckedSolo(props.index);
                  props.setItems([props.itemData]);
                } else {
                  props.setCheckedSolo(false);
                  props.setItems(
                    props.items.filter(
                      (filterItem) => filterItem.id !== props.id
                    )
                  );
                }
              } else {
                setChecked(e.target.checked);
                if (e.target.checked) {
                  props.setItems([...props.items, props.itemData]);
                } else {
                  props.setItems(
                    props.items.filter(
                      (filterItem) => filterItem.id !== props.id
                    )
                  );
                }
              }
            }}
            type="checkbox"
          />
          <label htmlFor=""></label>
        </div>
      </td>
      <td ref={videoWrapperRef}>
        <div className="assets__table-media">
          {props.media_type_id === 2 && (
            <div className="assets__media">
              <img src={process.env.PUBLIC_URL + props.thumbnail} alt="" />
            </div>
          )}
          {props.media_type_id === 1 && <AssetVideo {...props} />}
        </div>
      </td>
      <td>
        <p>{props.title}</p>
        {props.notif && (
          <div className="assets__table-notif">
            <span className="pc">
              This asset will be permanently removed in 5 days
            </span>
            <span className="mob">Permanent remove in 5 days</span>
          </div>
        )}
      </td>
      <td>
        <p>{props.description}</p>
      </td>
      <td>{props.created_at}</td>
      <td className={props.timesUsed && "bold"}>
        {props.campaigns && props.campaigns.length}
        {props.deletedOn && props.deletedOn}
      </td>
    </tr>
  );
}
