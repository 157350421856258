import React from "react";
import { Modal } from "../../../../Components";
import AssetsAdd from "./AssetsAdd";
import AssetsMain from "./AssetsMain";

export default function ModalCampAsset(props) {
  return (
    <Modal addClass="campAsset" close={props.close}>
      <div className="modal__campaignAsset">
        {(props.modal === "asset" ||
          props.modal === "assetGrid" ||
          props.modal === "assetGridGood" ||
          props.modal === "assetGood") && (
            <AssetsMain
              modal={props.modal}
              setModal={props.setModal}
              setModalData={props.setModalData}
              form={props.form}
              updateForm={props.updateForm}
              goodIndex={props.goodIndex}
              paginationData={props.paginationData}
              onPaginate={props.onPaginate}
              assetList={props.assetList}
              setSelectedItems={props.setSelectedItems}
              selectedItems={props.selectedItems}
            />
          )}
        {(props.modal === "addAsset" || props.modal === "addAssetCamp") && (
          <AssetsAdd user={props.user} modal={props.modal}   updateFormMain={props.updateForm} isAssetUploaded={props.isAssetUploaded} setIsAssetUploaded={props.setIsAssetUploaded} setModal={props.setModal}  addToast={props.addToast}  formAsset={props.form}/>
        )}
      </div>
    </Modal>
  );
}
