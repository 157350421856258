import React, { useState } from "react";

import {
  viewGrid,
  viewList,
  Pagination,
} from "../../Components";
import AssetsTableItem from "./AssetsTableItem";
import AssetsGridItem from "./AssetsGridItem";

const selectDescList = [
  { id: "1", value: "All assets" },
  { id: "2", value: "Title" },
  { id: "3", value: "Description" },
  { id: "4", value: "Upload date" },
  { id: "5", value: "Times used" },
];
export default function AssetsDeleted({ isLoaded, setModalAsset, setModalDataAsset, assestsList, paginationData, onPaginate }) {
  const [view, setView] = useState(true);
  const [selectDesc, setselectDesc] = useState(selectDescList[0].value);
  const changeMediumDesc = (item) => {
    setselectDesc(item.value);
  };
  return (
    <div className="assets">
      <div className="assets__table-title del">
        <h4 className="mob">Details</h4>
        <div className="assets__table-title-info">
          <h4 className="pc">Details</h4>
          <p className="sm">
            Assets that have been deleted will be automatically removed after 30
            days.
          </p>
        </div>
        <div className="assets__table-title-inner">
          <button
            type="button"
            className="assets__table-title-view"
            onClick={() => setView(!view)}
          >
            {view ? viewGrid : viewList}
          </button>
          {/*<SelectInput
            list={selectDescList}
            selected={selectDescList[0]}
            onChange={changeMediumDesc}
          />
          <SearchInput type="text" icon={searchIcon} placeholder="Search" />*/}
        </div>
      </div>

      {view ? (
        <div className="assets__table">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Title</th>
                <th>Description</th>
                <th>Upload date</th>
                <th>Deleted on</th>
              </tr>
            </thead>
            <tbody>
              {isLoaded && (
                <>
                  {assestsList.map((item, index) => {
                    return (
                      <AssetsTableItem
                        list="deletedlist"
                        onClick={() => {
                          setModalAsset("permanent");
                          setModalDataAsset(item);
                        }}
                        key={index}
                        {...item}
                      />
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="assets__items">
          {isLoaded && (
            <>
              {assestsList.map((item, index) => {
                return (
                  <AssetsGridItem
                    onClick={() => {
                      setModalAsset("permanent");
                      setModalDataAsset(item);
                    }}
                    key={index}
                    {...item}
                  />
                );
              })}
            </>
          )}
        </div>
      )}
      <div className="assets__table-footer">
        {isLoaded && (
          <>
            <Pagination
              perPage={paginationData['per_page']}
              total={paginationData['total']}
              currentPage={paginationData['current_page']}
              totalPages={paginationData['total_pages']}
              onPaginate={onPaginate}
            />
          </>
        )}
      </div>
    </div>
  );
}
