import React from "react";

export default function TextArea({
  label,
  onChange,
  value,
  type,
  placeholder,
  error,
  errorText,
  row,
  textAreaRef,
  defaultValue,
}) {
  return (
    <div className="input__outer">
      {label && <label>{label}</label>}
      <div className="input">
        <textarea
          ref={textAreaRef}
          cols="30"
          rows={row ? row : "5"}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          type={type}
          placeholder={placeholder}
        ></textarea>
      </div>
      {error && value === "" && <div className="input__error">{errorText}</div>}
    </div>
  );
}
