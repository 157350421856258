import React, { useState } from "react";
import { utils } from "../../Helpers/utils";
export default function CampaignsTableItem(props) {

  console.log(props.revision);
  return (
    <tr>
      <td onClick={props.onClick}>
        <p>{props.title}</p>
      </td>
      <td onClick={props.onClick}>
        <p>{props.description && utils.draftJsFormatingForDetails({ 'data': props.description, 'view': 'column' })}</p>
      </td>
      <td onClick={props.onClick}>{props.start_date}</td>
      <td onClick={props.onClick}>{props.end_date}</td>
      <td className="bold" onClick={props.onClick}>{props.budget}</td>
      <td className="bold" onClick={props.onClick}>{props.comp}</td>
      <td onClick={props.onClick}>
        <div className={"campaigns__table-status " + props.status}>
          {props.status}
        </div>
      </td>

      {(props.status == 'active' && props.revision != 0) ?
        <td >
          <a onClick={() => {
            props.setCampaignId(props.id); props.setTab("revisiontree")
          }}>
            <img src='images/revision-edit.png' />
          </a>
        </td>
        : <td ><img src='images/revision-ban.png' /></td>
      }

    </tr >
  );
}
