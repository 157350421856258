import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/influencers`;
const tamayouInfluencerbaseUrl = `${process.env.REACT_APP_API_URL}/tamayou_influencers`;

export const influencerService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete,
  getByEncrptedUserId,
  getTamayouInfluencerDetailsById,
  getDashboardData,
  sendPaymentAccountOtp,
  verifyPaymentAccountOtp,
  getBillingHistory,
  getPaymentRate,
  updatePaymentRate,
  getInfluencerDetails,
  getCategoriesDetails,
  saveProfileDetails,
  collaborate,
  getCollaborationsData,
  getSocialAccounts,
  removeSocialAccount,
  getMenuCounts,
  getSocialAccountsToConnect,
  saveSocialAccounts,
  connectPayPal,
  saveSocialAccountProduct,
  getCountForBubble,
  getNotifications,
  updateNotificationstatus,
  updateAllNotification,
  getRecommendInfluencer,
  saveRecommendation,
  getRecommendationById,
  getProfileStatus,
  getPostWithStatus,
  updatePostStatus,
  getReferralUrl,
  getTamaCoinDetails,
  getRevisionWithStatus,
  saveRevisionDetails,
  upadateRevisionDetails
};

function getAll(options) {
  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
  return fetchWrapper.get(url);
}
function getCountForBubble() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/influencer/menu_counts`);
}

function getAllPaging(options, filterString) {
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  }
  if (options.fileType) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[filetype]=${options.fileType}`;
  }
  if (options.search) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `filter[${options.search.key}]=${options.search.value}`;
  }
  if ('range' in options) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `range[month]=${options.range}`;
  }
  if ('postStatus' in options) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `range[month]=${options.range}`;
  }
  return filterString;
}

function getAllDateRange(options) {
  return `range[month]=${options.range}`;
}

function getSortOrder(options) {
  return `sortBy[order]=${options.sortBy}`;
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function getByEncrptedUserId(id) {
  return fetchWrapper.get(`${tamayouInfluencerbaseUrl}/${id}`);
}

function getTamayouInfluencerDetailsById(id) {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/tamayou_influencers_dtls/${id}`
  );
}

function getDashboardData(options) {
  let filterString = '';
  let filterStringRange = '';
  let filterStringSort = '';
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }

  if (options.sortBy !== undefined) {
    filterStringSort = getSortOrder(options);
  }

  const apiURL = `${process.env.REACT_APP_API_URL}/dashboardData`;
  let url = filterString.length > 0 ? `${apiURL}?${filterString}` : `${apiURL}`;
  url += filterStringRange.length > 0 ? `&${filterStringRange}` : '';
  url += filterStringSort.length > 0 ? `&${filterStringSort}` : '';
  return fetchWrapper.get(url);
  // return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/dashboardData`);
}

function sendPaymentAccountOtp(params) {
  return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/billing`, params);
}

function verifyPaymentAccountOtp(params) {
  return fetchWrapper.put(
    `${process.env.REACT_APP_API_URL}/billing/submit-otp`,
    params
  );
}

function getBillingHistory(options) {
  let filterString = '';
  if (
    options.page !== undefined ||
    options.search !== undefined ||
    options.range !== undefined
  ) {
    filterString = getAllPaging(options, filterString);
  }
  const apiURL = `${process.env.REACT_APP_API_URL}/payment`;
  const url =
    filterString.length > 0 ? `${apiURL}?${filterString}` : `${apiURL}`;
  return fetchWrapper.get(url);
}

function getPaymentRate() {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/influencer-product`
  );
}

function updatePaymentRate(payload) {
  return fetchWrapper.post(
    `${process.env.REACT_APP_API_URL}/influencer-product/bulk`,
    payload
  );
}

function getInfluencerDetails(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/influencer/${id}`);
}

function getCategoriesDetails() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/config`);
}

function saveProfileDetails(id, params) {
  return fetchWrapper.put(
    `${process.env.REACT_APP_API_URL}/influencer/${id}`,
    params
  );
}

function collaborate(id, params) {
  return fetchWrapper.put(
    `${process.env.REACT_APP_API_URL}/influencer/engagement/${id}`,
    params
  );
}
function connectPayPal() {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/adv/connectToPayPal`
  );
}

function getCollaborationsData(options) {
  let filterString = '';
  let filterStringId = "";

  if (options.filter) {
    filterStringId += options.filter;
  }
  console.log(filterStringId);
  if (options.page !== undefined || options.search !== undefined) {
    filterString = getAllPaging(options, filterString);
  }

  const apiURL = `${process.env.REACT_APP_API_URL}/collaborationsByInfluencer`;
  let url =
    filterString.length > 0 ? `${apiURL}?${filterString}` : `${apiURL}`;
  url +=
    filterStringId.length > 0 ? `&${filterStringId}` : "";
  console.log(url);
  return fetchWrapper.get(url);
}

function getSocialAccounts() {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/influencer-product`
  );
}

function removeSocialAccount(id) {
  return fetchWrapper.put(
    `${process.env.REACT_APP_API_URL}/changesocialstatus/${id}`
  );
}

function getMenuCounts() {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/influencer/menu_counts`
  );
}

function getSocialAccountsToConnect(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/handles/${id}`);
}

function saveSocialAccounts(payload) {
  return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/handles`, payload);
}

function saveSocialAccountProduct(payload) {
  return fetchWrapper.post(
    `${process.env.REACT_APP_API_URL}/influencers/saveProduct`,
    payload
  );
}

function getNotifications(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/usernotifications/userid/${id}`);
}
function updateNotificationstatus(id, params) {
  return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/usernotifications/${id}`, params);
}

function updateAllNotification(id, params) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/usernotifications/status`);
}
function getRecommendInfluencer(filterString) {
  const apiURL = `${process.env.REACT_APP_API_URL}/recommended-influencer`;
  const url =
    filterString.length > 0 ? `${apiURL}?${filterString}` : `${apiURL}`;

  return fetchWrapper.get(url);
}
function saveRecommendation(payload) {
  return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/saveRecommendation`, payload);
}
function getRecommendationById(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/getRecommendationById/${id}`);
}
function getProfileStatus() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/getProfileStatus/1`);
}
function getPostWithStatus(options, status) {

  let filterString = '';
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${process.env.REACT_APP_API_URL}/getEngInfluencer?status=${status}&${filterString}` : `${process.env.REACT_APP_API_URL}/getEngInfluencer?status=${status}`;

  return fetchWrapper.get(url);
  //return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/getEngInfluencer?status=${status}`);
}

function updatePostStatus(payload, infId) {
  return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/saveInfStatus/${infId}`, payload);
}
function getReferralUrl() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/referer`);
}
function getTamaCoinDetails() {
  console.log("ds")
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/getTamaCoinDetails`);
}

function getRevisionWithStatus(options, status, filterString) {

  // let filterString = '';
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${process.env.REACT_APP_API_URL}/revision?${filterString}` : `${process.env.REACT_APP_API_URL}/revision`;//?status=${status}

  return fetchWrapper.get(url);
  //return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/getEngInfluencer?status=${status}`);
}

function saveRevisionDetails(params) {
  console.log("kkk");
  return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/revision`, params);
}

function upadateRevisionDetails(id, params) {
  return fetchWrapper.put(
    `${process.env.REACT_APP_API_URL}/revision/${id}`, params);
}