import React from 'react';
import Modal from '..//Modal';
import { closeIcon } from '../SVG';
export default function ModalNotifDelete(props) {
  return (
    <Modal addClass='notif' close={props.close}>
      <div className='modal__notification'>
        <div className='modal__notification-inner'>
          <div className='modal__notification-title solo'>
            <h4>Notifications</h4>
            <button
              className='button tertiary big grey'
              onClick={props.cancelBtnHandler}
            >
              {closeIcon} Cancel
            </button>
          </div>
          <div className='modal__notification-delete'>
            <h4>Delete notification?</h4>
            <p className='sm'>
              Please confirm you would like to delete this notification.
            </p>
            <button className='button primary' onClick={props.deleteNotif}>
              Yes, delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
