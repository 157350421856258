import React, { useState, useEffect, useRef } from 'react';
import { Profile, Toast } from '../../Components';
import MyProfileAbout from './MyProfileAbout';
import MyProfileInfo from './MyProfileInfo';
import MyProfileShipAdd from './MyProfileShipAdd';
import MyProfilePass from './MyProfilePass';
import { utils } from '../../Helpers/utils';
import { convertToRaw } from 'draft-js';
import {
  influencerService,
  accountService,
  alertService
} from '../../Services';

// const initForm = {
//   profile_name: '',
//   discription: 'test2',
//   category: [],
//   bio: 'asdbasiud',
//   firstName: 'Jeremy',
//   lastName: 'Chapline',
//   email: 'jeremy@thecharredcask.com',
//   address: {
//     address: '1234 Richmond Road.',
//     apartment: '1234',
//     city: 'Richmond',
//     region: '',
//     country: 'Afghanistan',
//     zipCode: '23236',
//     state: 'Virginia',
//   },

//   currentPassword: '',
//   newPassword: '',
//   confirmNewPassword: '',

//   // {
//   //   id: 2,
//   //   title: null,
//   //   photo: null,
//   //   first_login: 0,
//   //   profile_name: "",
//   //   link: "",
//   reset_password: 1,
// };

export default function MyProfile(props) {
  const user = accountService.userValue;
  const userId = user.data.id;
  const [form, setForm] = useState({});
  const [toastList, setToastList] = useState([]);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [categoriesData, setCategoriesData] = useState({});
  const [isSelectBoxIsSelected, setIsSelectBoxIsSelected] = useState(false);
  let refAbout = useRef(null);
  let refInfo = useRef(null);
  let refShip = useRef(null);
  let refPass = useRef(null);
  const updateForm = data => {
    setForm({ ...form, ...data });
  };

  useEffect(() => {
    async function fetchInfluencerData() {
      try {
        const res = await influencerService.getInfluencerDetails(userId);
        res.address = Object.assign({}, res.addresses);
        // delete res.reset_password;
        delete res.addresses;
        setForm(res);
        setIsFormLoaded(true);
      } catch (err) {
        alertService.error(err);
      }
    }

    async function fetchCategoriesData() {
      try {
        const res = await influencerService.getCategoriesDetails();
        setCategoriesData(res);
      } catch (err) {
        alertService.error(err);
      }
    }
    fetchInfluencerData();
    fetchCategoriesData();
  }, []);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterCalled();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);

  useEffect(() => {
    const windowClick = ({ target }) => {

      if (target.classList.contains('select__selected') == true || target.classList.contains('select__option') == true) {
        setIsSelectBoxIsSelected(true);
      }
    };
    window.addEventListener("click", windowClick);


    return () => window.removeEventListener("click", windowClick);
  }, [form]);

  const setInput = key => event => {
    updateForm({ [key]: event.target.value });
  };

  const setBio = key => event => {
    updateForm({ ['bio']: JSON.stringify(event) });
  };

  const addToast = data => {
    const newToastList = [...toastList];
    if (data.msg) {
      if (data.msg[data.msg.length - 1] === '.')
        data.msg = data.msg.slice(0, -1);
    }
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  const saveInfluencerProfileChanges = async (type, reqBody) => {
    try {
      reqBody.request_type = type;
      await influencerService.saveProfileDetails(userId, reqBody);
      props.setIsBasicStepsDone(!props.isBasicStepsDone);
      addToast({ error: false, date: new Date() });
      setIsSelectBoxIsSelected(false);
    } catch (err) {
      addToast({
        error: true,
        date: new Date(),
        msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '')
      });
    }
  };

  const enterCalled = () => {
    let about = document.activeElement.parentElement.closest('.form__about');
    let info = document.activeElement.parentElement.closest('.form__personal');
    let shipping = document.activeElement.parentElement.closest('.form__shipping');
    let passwordEntered = document.activeElement.parentElement.closest('.form__password');
    if (info) {
      refInfo.current.click();
    }
    if (about) {
      refAbout.current.click();
    }
    if (shipping || isSelectBoxIsSelected) {
      refShip.current.click();
    }
    if (passwordEntered) {
      refPass.current.click();
    }
  }
  const chkEditorVal = (item) => {
    let editorError = false;
    if (item === "") {
      return editorError = true;
    } else {

      let isValidJson = utils.testJSON(item);
      if (isValidJson) {
        if (JSON.parse(item).blocks[0].text === "") {
          return editorError = true;
        } else {
          return false;
        }
      }
    }
  }
  const saveChanges = type => {
    if (type === 'info') {
      if (form.firstName === '' || form.lastName === '' || form.email === '') {
        addToast({ error: true, date: new Date(), msg: 'Enter details to save.' });
      } else {
        saveInfluencerProfileChanges('personalinfo', {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email
        });
        //saveInfluencerProfileChanges('updateEmail', { email: form.email });
        accountService.refreshToken(function () { });

      }
    } else if (type === 'about') {
      if (form.profile_name === '' || form.category.length === 0) {
        addToast({ error: true, date: new Date(), msg: 'Enter details to save.' });
      } else {
        if (form.bio === "" || chkEditorVal(form.bio) === true) {
          addToast({ error: true, date: new Date(), msg: 'Bio is required.' });
        } else {
          saveInfluencerProfileChanges('updateProfile', form);
        }
      }
    } else if (type === 'shipAdd') {
      if (
        form.address.country === '' ||
        form.address.address === '' ||
        form.address.city === '' ||
        form.address.state === '' ||
        form.address.zipCode === ''
      ) {
        // addToast({ error: true, date: new Date(),msg: '' });
      } else {
        //delete form.address.apartment;
        //delete form.address.zipCode;
        saveInfluencerProfileChanges('shippingaddress', form);
      }
    } else if (type === 'pass') {
      if (
        form.currentPassword === undefined ||
        form.newPassword === undefined ||
        form.confirmNewPassword === undefined ||
        form.currentPassword.length < 8 ||
        form.newPassword.length < 8 ||
        form.confirmNewPassword.length < 8
      ) {
        addToast({
          error: true,
          date: new Date(),
          msg: 'Password details required.'
        });
      } else {
        var passwordFormat = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (form.newPassword.match(passwordFormat)) {
          if (form.newPassword !== form.confirmNewPassword) {
            addToast({
              error: true,
              date: new Date(),
              msg: 'New Password and Confirm Password is not same'
            });
          } else {
            saveInfluencerProfileChanges('updatePassword', {
              current_password: form.currentPassword,
              password: form.newPassword,
              confirm_password: form.confirmNewPassword
            });
          }
        } else {
          addToast({
            error: true,
            msg: "Password should have atleast one uppercase, one lowercase letter, one number and one special character",
            successText: '',
            date: new Date()
          });
        }
      }
    }
  };
  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title'>
            <h2>My profile</h2>
            <Profile
              user={props.user}
              setModal={props.setModal}
              profiles={props.profiles}
              NotifModul={props.NotifModul}
              setNotifModul={props.setModalNotification}
            />
          </div>
          {(form && isFormLoaded) && (
            <div className='myprofile'>
              {categoriesData.categories && (
                <MyProfileAbout
                  form={form}
                  setInput={setInput}
                  updateForm={updateForm}
                  saveChanges={saveChanges}
                  categories={categoriesData.categories.map(
                    cat => cat.category
                  )}
                  categoriesList={categoriesData.categories}
                  setBio={setBio}
                  enterCalled={enterCalled}
                  refAbout={refAbout}
                />
              )}
              {categoriesData.categories && (
                <MyProfileInfo
                  form={form}
                  setInput={setInput}
                  addToast={addToast}
                  saveChanges={saveChanges}
                  enterCalled={enterCalled}
                  refInfo={refInfo}
                />
              )}
              {categoriesData.countries && (
                <MyProfileShipAdd
                  form={form}
                  updateForm={updateForm}
                  saveChanges={saveChanges}
                  countries={categoriesData.countries}
                  states={categoriesData.state}
                  enterCalled={enterCalled}
                  refShip={refShip}

                />
              )}
              {categoriesData.countries && form.reset_password === 1 && (
                <MyProfilePass
                  setInput={setInput}
                  form={form}
                  saveChanges={saveChanges}
                  enterCalled={enterCalled}
                  refPass={refPass}

                />
              )}
            </div>
          )}
        </div>
      </div>

      {toastList && <Toast list={toastList} setList={setToastList} />}
    </>
  );
}
