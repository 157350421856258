import React, { useState, useEffect } from "react";
import { SearchInput } from "../../Components";
import { SelectInput } from "../../Components";
import {
  download,
  plusIcon,
  searchIcon,
  systemIcon,
} from "../../Components/Base/SVG";
import { Pagination } from "../../Components";
import TamaCoinMainItem from "./TamaCoinMainItem";

const selectDescList = [
  { id: "coin", value: "Coin" },
  { id: "activity", value: "Activity" },
];
export default function TamaCoinMain({ searchText, search, changeMediumDesc, setTab, setTabData, tabData, paginationData, onPaginate, downloadCsv, setSearchTextOnChange }) {
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchText]);
  return (
    <div className="admnotif">
      <div className="admnotif__table-title">
        <div className="admnotif__table-title-info">
          <button className="button tertiary big" onClick={() => setTab("add")}>
            Create Tama Coin {plusIcon}
          </button>

        </div>
        <div className="admnotif__table-title-inner">
          <SelectInput
            list={selectDescList}
            selected={selectDescList[0]}
            onChange={changeMediumDesc}
          />

          <SearchInput
            value={searchText}
            onClick={search}
            type="text"
            icon={searchIcon}
            placeholder="Search"
            onChange={setSearchTextOnChange} />
        </div>
      </div>

      <div className="admnotif__table">
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Activity</th>
              <th>Notes</th>
              <th>Coin</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {tabData.length > 0 && tabData.map((item, index) => {
              return (
                <TamaCoinMainItem
                  onClick={() => {
                    setTab("edit");
                    setTabData(item);
                  }}
                  tab="main"
                  key={index}
                  {...item}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="admnotif__table-footer">
        <button className="button tertiary download" onClick={downloadCsv}>
          Download CSV {download}
        </button>
        <Pagination
          perPage={paginationData['per_page']}
          total={paginationData['total']}
          currentPage={paginationData['current_page']}
          totalPages={paginationData['total_pages']}
          onPaginate={onPaginate}
        />
      </div>
    </div>
  );
}
