import React, { useEffect, useState } from "react";
import {
  commentsIcon,
  download,
  facebook,
  heartIcon,
  instagram,
  shareIcon,
  LineChart,
  BarChart,
  MultipleLineChart,
  AgeChart,
  arrowRight,
  tickIconGreen,
  closeIconRed,
  questionIcon

} from "../../Components";
import {
  engagementService
} from '../../Services';

import { utils } from "../../Helpers/utils";
export default function EngagementInsight(props) {
  const [engagementId, setEngagementId] = useState(props.engagementId);
  const [toastList, setToastList] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isGrpahDataLoaded, setIsGraphDataLoaded] = useState(false);
  const [influencerData, setInfluencerData] = useState([]);
  const [productData, setProductData] = useState("");
  const [a, setA] = useState([]);
  const [isLoaded, setIsLoaded] = useState("");
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    async function getEngagementStatsData() {
      try {
        const res = await engagementService.getEngagementStats(engagementId);
        setGraphData(res.data);
        setIsGraphDataLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }

    let prd = "";
    let inf = "";

    props.engagementOverview.productsName.map(item => {
      prd += item + "\n";
    });

    setProductData(prd);
    console.log(props.engagementOverview.showinfluencers);
    console.log(props.engagementOverview);
    setInfluencerData(props.engagementOverview.showinfluencers);
    getEngagementStatsData();
    setIsLoaded(true);
  }, []);

  return (
    <div className="campaigns">
      {isLoaded &&
        <div className="campaignsInsight">
          <div className="campaignsInsight__info">
            <div className="campaignsInsight__info-overview">
              <div className="campaignsInsight__info-overview-title">
                <h3>Influencers </h3>
              </div>
              <div >
                <div>
                  <h6>
                    <span>
                      {(influencerData && influencerData.length > 0) && (
                        influencerData.map((item, index) => {
                          return (
                            <div key={index} className="influencerDetails">
                              {(item.handle && item.handle.icon.length > 0) && utils.getIconFilled(item.handle.icon[0])} {'  '}
                              {item.handle && item.handle.handle} {' '}
                              {(item.influencer_rate && item.handle) && "$" + item.influencer_rate} {'  '}
                              {(item.rate_overwritten && parseInt(item.rate_overwritten) == 1) && <img
                                src={`${process.env.PUBLIC_URL}/images/red-flag.png`} alt="red-flag" />} {' '}
                              {'  '}
                              {(item.handle && item.status === 'pending') ?
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/question.png`} alt="Pending" /> :
                                (item.handle && item.status === 'reject') ?
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/negative.png`} alt="Reject" /> :
                                  (item.handle && item.status === 'accepted') ? <img
                                    src={`${process.env.PUBLIC_URL}/images/positive.png`} alt="Accept" /> :
                                    (item.handle && item.status === 'live') ? <img
                                      src={`${process.env.PUBLIC_URL}/images/live.png`} alt="Live" /> :
                                      (item.handle && item.status === 'verified') ? <img
                                        src={`${process.env.PUBLIC_URL}/images/verified.png`} alt="Verified" /> :
                                        (item.handle && item.status === 'paid') ? <img
                                          src={`${process.env.PUBLIC_URL}/images/paid.png`} alt="Paid" /> : ''}
                              {' '}

                            </div>
                          );

                        })
                      )}
                    </span>
                  </h6>
                </div>
                <p className="hideToolbar bottomOfDiv" >
                  {<button
                    className="button tertiary viewEngagement"
                    onClick={() => props.setTab("viewEngagement")}
                  >
                    View Engagement {arrowRight}
                  </button>}
                </p>
              </div>
            </div>
            <div className="campaignsInsight__info-overview">
              <div className="campaignsInsight__info-details-row">
                <h3>Deliverables</h3>
                <div className="campaignsInsight__info-details-row-inner">
                  <p className="hideToolbar">
                    {props.engagementOverview.deliverable
                      ? utils.draftJsFormatingForDetails({ 'data': props.engagementOverview.deliverable, 'view': 'fullview' })
                      : ''}
                  </p>
                </div>
              </div>
              <div className="campaignsInsight__info-details-row">
                <div className="campaignsInsight__info-details-row-inner reachInsight">
                  <p className="hideToolbar">Desired Reach: {props.engagementOverview.eng_reach
                    ? props.engagementOverview.eng_reach != 0 ? props.engagementOverview.eng_reach : 'NA' : 'NA'}</p>
                  <p className="hideToolbar">Desired Interactions: {props.engagementOverview.interaction
                    ? props.engagementOverview.interaction !== 0 ? props.engagementOverview.interaction : 'NA' : 'NA'}</p>
                </div>
              </div>
            </div>
            <div className="campaignsInsight__info-details">
              <div className="campaignsInsight__info-details-row">
                <h3>Product</h3>
                <div className="campaignsInsight__info-details-row-inner">
                  <h6>
                    <span>
                      < NewlineText text={productData} />
                    </span>
                  </h6>
                </div>
              </div>
              <div className="campaignsInsight__info-details-row">
                <h3>Timeline</h3>
                <div className="campaignsInsight__info-details-row-inner">
                  <h6>
                    {props.engagementOverview.start_date + " -> " + props.engagementOverview.end_date}
                  </h6>
                </div>
              </div>
              <div className="campaignsInsight__info-details-row">
                <h3>HashTags</h3>
                <div className="campaignsInsight__info-details-row-inner">
                  <p className="hideToolbar">
                    {props.engagementOverview.hashtag &&
                      props.engagementOverview.hashtag.split(',').map((item, index) => {
                        return (
                          <div
                            className="formCampaigns__detail-hashtags-item-report"
                            key={index}
                          >
                            {item}
                          </div>
                        );
                      })}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isGrpahDataLoaded && (
            <>
              <div className="campaignsInsight__insight">
                <h3>Engagement insights</h3>
                <div className="campaignsInsight__insight-row">
                  <h5>Engagement at a glance</h5>
                  <div className="campaignsInsight__insight-row-inner">
                    <div className="campaignsInsight__insight-row-item">
                      {heartIcon}
                      <h5>{graphData.summary ? graphData.summary.like : 0} Total likes</h5>
                    </div>
                    <div className="campaignsInsight__insight-row-item">
                      {commentsIcon}
                      <h5>{graphData.summary ? graphData.summary.comment : 0} Total Comments</h5>
                    </div>
                    <div className="campaignsInsight__insight-row-item">
                      {shareIcon}
                      <h5>{graphData.summary ? graphData.summary.share : 0} Total Shares</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="campaignsChart">
                <div className="campaignsChartTop">
                  <div className="campaignsChartTop__col">
                    <div className="campaignsChartTop__col-row">
                      <div className="campaignsChartTop__reach">
                        <div className="campaignsChartTop__reach-title">
                          <h5>Reach</h5>
                          <h2>{graphData.reach ? graphData.reach.header : 0}</h2>
                        </div>
                        <div className="campaignsChartTop__reach-chart">
                          <LineChart
                            data={graphData.reach ? graphData.reach.data ? graphData.reach.data : [] : []}
                            labels={graphData.label ? graphData.label : []}
                          />
                        </div>
                      </div>
                      <div className="campaignsChartTop__reach">
                        <div className="campaignsChartTop__reach-title">
                          <h5>Impressions</h5>
                          <h2>{graphData.impression ? graphData.impression.header : 0}</h2>
                        </div>
                        <div className="campaignsChartTop__reach-chart">
                          <BarChart
                            labels={graphData.label ? graphData.label : []}
                            data={graphData.impression ? graphData.impression.data ? graphData.impression.data : [] : []}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="campaignsChartTop__col-total">
                      <div className="campaignsChartTop__col-total-title">
                        <h5>Impressions vs reach</h5>
                        <div className="campaignsChartTop__col-total-title-inner">
                          <div className="campaignsChartTop__col-total-title-item">
                            <span style={{ background: "#08A708" }}></span>
                            <h6 className="sm">Total Impressions - {graphData.impression ? graphData.impression.header : 0}</h6>
                          </div>
                          <div className="campaignsChartTop__col-total-title-item">
                            <span style={{ background: "#1F4BE7" }}></span>
                            <h6 className="sm">Total Reach - {graphData.reach ? graphData.reach.header : 0}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="campaignsChartTop__col-total-chart">
                        <MultipleLineChart
                          data1={graphData.impression ? graphData.impression.data ? graphData.impression.data : [] : []}
                          data2={graphData.reach ? graphData.reach.data ? graphData.reach.data : [] : []}
                          labels={graphData.label ? graphData.label : []}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="campaignsChartTop__col">
                    <div className="campaignsChartTop__age">
                      <div className="campaignsChartTop__age-title">
                        <h5>Average by Age</h5>
                      </div>
                      <AgeChart ageData={graphData.ageData ? graphData.ageData.data ? graphData.ageData.data : [] : []} />
                    </div>
                    <div className="campaignsChartTop__gender">
                      <div className="campaignsChartTop__gender-title">
                        <h5>Audience by Gender</h5>
                        <div className="campaignsChartTop__gender-subtitle">
                          Entire campaign
                        </div>
                      </div>
                      <div className="campaignsChartTop__gender-row">
                        <div className="campaignsChartTop__gender-item">
                          <h6 className="sm">Male</h6>
                          <h2 className="uniq">{graphData.ageData.header ? graphData.ageData.header.gender_m : 0}%</h2>
                        </div>
                        <div className="campaignsChartTop__gender-item">
                          <h6 className="sm">Female</h6>
                          <h2 className="uniq">{graphData.ageData.header ? graphData.ageData.header.gender_f : 0}%</h2>
                        </div>
                        <div className="campaignsChartTop__gender-item">
                          <h6 className="sm">Undisclosed</h6>
                          <h2 className="uniq">{graphData.ageData.header ? graphData.ageData.header.gender_u : 0}%</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="campaignsChartBot">
                  <h3>Engagement</h3>
                  <div className="campaignsChartBot__row">
                    <div className="campaignsChartBot__col">
                      <div className="campaignsChartBot__col-title">
                        <h5>Amplification rate</h5>
                        <h2>{graphData.ampRate ? graphData.ampRate.header : '0%'}</h2>
                      </div>
                      <div className="campaignsChartBot__col-chart">
                        <LineChart
                          percentage={true}
                          data={graphData.ampRate ? graphData.ampRate.data ? graphData.ampRate.data : [] : []}
                          labels={graphData.label ? graphData.label : []}
                        />
                      </div>
                    </div>
                    <div className="campaignsChartBot__col">
                      <div className="campaignsChartBot__col-title">
                        <h5>Virality rate</h5>
                        <h2>{graphData.viralityRate ? graphData.viralityRate.header : '0%'}</h2>
                      </div>
                      <div className="campaignsChartBot__col-chart">
                        <LineChart
                          percentage={true}
                          data={graphData.viralityRate ? graphData.viralityRate.data ? graphData.viralityRate.data : [] : []}
                          labels={graphData.label ? graphData.label : []}
                        />
                      </div>
                    </div>
                    <div className="campaignsChartBot__col">
                      <div className="campaignsChartBot__col-title">
                        <h5>Engagement rate</h5>
                        <h2>{graphData.engagementRate ? graphData.engagementRate.header : '0%'}</h2>
                      </div>
                      <div className="campaignsChartBot__col-chart">
                        <LineChart
                          percentage={true}
                          data={graphData.engagementRate ? graphData.engagementRate.data ? graphData.engagementRate.data : [] : []}
                          labels={graphData.label ? graphData.label : []}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="campaignsChartBot__total">
                    <div className="campaignsChartBot__total-title">
                      <h5>Total Engagement</h5>
                      <div className="campaignsChartBot__total-title-inner">
                        <div className="campaignsChartBot__total-title-item">
                          <span style={{ background: "#08A708" }}></span>
                          <h6 className="sm">Likes</h6>
                        </div>
                        <div className="campaignsChartBot__total-title-item">
                          <span style={{ background: "#1F4BE7" }}></span>
                          <h6 className="sm">Comments</h6>
                        </div>
                        <div className="campaignsChartBot__total-title-item">
                          <span style={{ background: "#FF319A" }}></span>
                          <h6 className="sm">Shares</h6>
                        </div>
                        <div className="campaignsChartBot__total-title-item">
                          <span style={{ background: "#8D0048" }}></span>
                          <h6 className="sm">Interactions</h6>
                        </div>
                      </div>
                    </div>
                    <div className="campaignsChartBot__total-chart">
                      <MultipleLineChart
                        data1={graphData.like ? graphData.like.data ? graphData.like.data : [] : []}
                        data2={graphData.comment ? graphData.comment.data ? graphData.comment.data : [] : []}
                        data3={graphData.share ? graphData.share.data ? graphData.share.data : [] : []}
                        data4={graphData.interaction ? graphData.interaction.data ? graphData.interaction.data : [] : []}
                        labels={graphData.label ? graphData.label : []}
                      />
                    </div>
                  </div>
                </div>


              </div>
            </>)}
        </div>
      }
    </div >
  );
}

function NewlineText(props) {
  console.log(props);
  const text = props.text;
  const newText = text.split('\n').map(str => <p>{str}</p>);
  return newText;
}

function NewlineTextInf(props) {
  let newText = "";
  //const text = props.text;
  if (props.handle) {
    newText = utils.getIconFilled(props.handle.icon[0]) + " " + props.handle.handle + "";
  }

  return newText;
}