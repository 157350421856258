import React, { useEffect, useState } from "react";
import { SelectInput, Pagination } from "../../Components";


import PostVerificationTableItem from "./PostVerificationTableItem";



export default function PostVerificationMain({ loadList,loadData, newStatus, setData, postStatusList, postStatusListColumn, OnChangeDropdown, setLoadData, infEngList, paginationData, onPaginate, changePostStatus, postStatus }) {

  let [status, setStatus] = useState("")
  useEffect(() => {
    setStatus(postStatus);
  }, [infEngList]);
  return (
    <>
      <div className="campaigns">
        <div className="campaigns__table-title">
          <div className="campaigns__table-title-info">
            <h4>Post Verification List</h4>
          </div>
          <div className="campaigns__table-title-inner poststatus">
            <SelectInput
              list={postStatusList}
              value={status}
              selected={postStatusList.filter(item => item.id === postStatus)[0]}
              onChange={changePostStatus}
            />
          </div>
        </div>
      
        <>
          <div className="campaigns__table postStatus">
            <table className="post_verification_table">
              <thead>
                <tr>
                  <th>Asgmt Id</th>
                  <th>Engmt Id</th>
                  <th>
                  Product
                      
                  
                  </th>
                  <th>handle</th>
                  <th>Rate</th>
                  <th>Hashtag</th>

                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loadList ? 
                   (infEngList.length > 0 && loadList)?

                  infEngList.map((item, index) => {
                    return (
                      <PostVerificationTableItem
                        postStatusList={postStatusList}
                        changePostStatus={changePostStatus}
                        key={index}
                        status={postStatus}
                        item={item}
                        OnChangeDropdown={OnChangeDropdown}
                        postStatusListColumn={postStatusListColumn}
                        setData={setData}
                        newStatus={newStatus}
                        rowIndex={index}
                        loadData={loadData}
                      />
                    );
                  })
                  : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: 'center' }}>
                        No Post Found
                    </td>
                    </tr>
                  ): (
                    <tr>
                      <td colSpan="7" style={{ textAlign: 'center' }}>
                       Loading...
                    </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </>
        <div className="campaigns__table-footer">
          <button className="button tertiary download" >
          </button>
          <Pagination
            perPage={paginationData['per_page']}
            total={paginationData['total']}
            currentPage={paginationData['current_page']}
            totalPages={paginationData['total_pages']}
            onPaginate={onPaginate}
          />
        </div>
      </div>

    </>
  );
}
