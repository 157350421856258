import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
    HeaderAcc,

} from '../../Components';
import { motion } from 'framer-motion';
import { accountService } from '../../Services';




export default function ValidateEmail() {
    const location = useLocation();

    const navigate = useNavigate();
    const [toastList, setToastList] = useState([]);
    const [email, setEmail] = useState(location.state ? location.state.email : "");
    const [reason, setReason] = useState(location.state ? location.state.reason : "");
    const [userId, setUserId] = useState(location.state ? location.state.userId : "");
    const [message, setMessage] = useState("");
    //window.history.replaceState({}, document.title)
    const resendValidateEmailLink = async () => {
        try {

            let res = await accountService.resendValidateEmail(userId);
            if (res.message) {
                setMessage(res.message);
            }

        } catch (err) {
            addToast({
                error: true,
                date: new Date(),
                msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '')
            });
        }
    }
    const addToast = data => {
        const newToastList = [...toastList];
        if (data.msg) {
            if (data.msg[data.msg.length - 1] === '.')
                data.msg = data.msg.slice(0, -1);
        }
        newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
        setToastList(newToastList);
    };

    return (
        <>
            <div className='account log'>
                <div className='account__inner'>
                    <HeaderAcc />
                    <div className='account__inner-col'>
                        <div className='account__inner-col-image'>
                            <img
                                src={process.env.PUBLIC_URL + '/images/accountBg.jpg'}
                                alt='accountBg'
                            />
                        </div>
                    </div>
                    <div className="account__footer">
                        <div className="account__footer-inner">
                            <div className="account__footer-copy">Copyright {new Date().getFullYear()} © TamaYou</div>
                            <Link target="_blank" to="https://tamayou.com/tama-privacy-policy/" className="account__footer-link">
                                Privacy
              </Link>
                            <Link target="_blank" to="https://tamayou.com/terms/" className="account__footer-link linkTerms">
                                Terms
              </Link>
                        </div>
                    </div>
                    <div className='account__inner-col big validateEmail'>
                        <motion.div
                            initial={{ opacity: 0, y: 0 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.4 }}
                            exit={{ opacity: 0, y: 0 }}
                            className='account__create'
                        >
                            <div className='account__create-inner'>


                                {((email !== "" && email !== undefined) || reason === 'resendverification') ?
                                    <div className='account__create-type'>
                                        <h2 className='uniq validateEmailText'>Validate Email</h2>
                                        <p className='validateEmailText'>Please verify your email.</p>
                                        <p className='validateEmailText'>We sent an email to {email}</p>
                                        <p className='validateEmailText'>Just click on the link in that email to complete your signup.
                                            If you don't see it, you may need to check your spam folder.</p>
                                        <p className='validateEmailText'>Still can't find the email?</p>
                                        <button className='button primary resendValidateEmailLink' onClick={resendValidateEmailLink}>
                                            Resend
                                            </button><p className='validateEmailText messageText'><strong>{message}</strong></p>
                                        <p className='validateEmailText'>Return to <a href="#/account/login">login page → </a></p>
                                    </div> :
                                    reason === "expired" ?
                                        <div className='account__create-type validateEmailText'>
                                            <h2 className='uniq validateEmailText'>Your verification link has expired</h2>

                                            <p className='validateEmailText'>
                                                Looks like the verification link has expired. Not to worry, we can send the link again.</p>
                                            <button className='button primary resendValidateEmailLink' onClick={resendValidateEmailLink}>
                                                Resend
                                        </button><p className='validateEmailText messageText'><strong>{message}</strong></p>
                                            <p>Return to <a href="#/account/login">login page → </a></p>
                                        </div> : reason === "unverified" ?
                                            <div className='account__create-type validateEmailText'>
                                                <h2 className='uniq validateEmailText'>We couldn’t verify your email</h2>
                                                <p className='validateEmailText'>
                                                    <img className="dangerImg" src={process.env.PUBLIC_URL + '/images/danger.png'} /> The email verification link you clicked is invalid.</p>
                                                <p className='validateEmailText'>Try copying the entire link from your email and pasting it into your browser, or log in and resend confirmation link.</p>
                                                <p>Return to <a href="#/account/login">login page → </a></p>
                                            </div> : ''
                                }
                            </div>
                        </motion.div>
                    </div>
                </div >

            </div >

        </>
    );
}