import React from "react";
import { utils } from '../../Helpers/utils';
export default function NotifMainItem(props) {
  console.log(props);
  return (
    <tr onClick={props.onClick}>
      <td>
        {props.id}
      </td>
      <td>
        <p>{props.activity}</p>
      </td>
      <td>
        <p>{props.notes
          ? utils.convertJsontoString(props.notes)
          : ''}</p>
      </td>

      <td>{props.coin}</td>
      <td>
        <div >
          {props.date}
        </div>
      </td>
    </tr>
  );
}
