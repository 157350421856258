import React, { useState, useEffect } from "react";
export default function GoodsBlock({ tabData, title }) {
    const [countOfGoods, setCountOfGoods] = useState(tabData.goodsWallet.length);
    const [selectedGoods, setSelectedGoods] = useState(tabData.goodsWallet[0]);
    const getGoodsWalletData = (itemIndex) => {
        setSelectedGoods(tabData.goodsWallet[itemIndex])
    }
    return (
        <>
            <h3 className="big">{title}
                {countOfGoods > 1 &&
                    <button className="reportButton">
                        {Array.from(Array(countOfGoods), (e, i) => {
                            return <span onClick={() => getGoodsWalletData(i)}> {i + 1}</span>
                        })}
                    </button>
                }
            </h3>
            {countOfGoods > 0 &&
                <div className="engagement__col-inner">
                    <div className="engagement__col-row">
                        <h6 className="semi">Date</h6>
                        <p>{tabData.start_date + '->' + tabData.end_date}</p>
                    </div>
                    <div className="engagement__col-row">
                        <h6 className="semi">Name</h6>
                        <p>{selectedGoods.name}</p>
                    </div>
                    <div className="engagement__col-row">
                        <h6 className="semi">Total goods</h6>
                        <p>{selectedGoods.total}</p>
                    </div>
                    <div className="engagement__col-row">
                        <h6 className="semi">Commited goods</h6>
                        <p>{selectedGoods.committed}</p>
                    </div>
                    <div className="engagement__col-row">
                        <h6 className="semi">Locked goods</h6>
                        <p>{selectedGoods.locked}</p>
                    </div>
                    <div className="engagement__col-row">
                        <h6 className="semi">Available goods</h6>
                        <p>{selectedGoods.available}</p>
                    </div>
                </div>
            }
        </>
    )
}