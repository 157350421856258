import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Header,
  Sidebar,
  ModalSwitch
} from '../Components';
import { accountService, influencerService } from '../Services';
import { PrivateRoute } from '../Helpers';
import {
  Opportunities,
  MyProfile,
  Collaboration,
  Payment,
  Rates,
  Dashboard,
  ReferFriend,
  UserTamaCoin
} from './index';

export default function Influencer({
  user,
  setChangePage,
  changePage,
  setUser,
  allNotifications,
  setModalNotification,
  setModalNotificationData,
  updateNotificationStatus,
  modalNotification,
  accountCount,
  impersonationBar,
  locationData
}) {
  const [NotifModul, setNotifModul] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modal, setModal] = useState(null);
  const [menu, setMenu] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [toastList, setToastList] = useState([]);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [newNavigation, setNewNavigation] = useState({});

  const [isBasicStepsDone, setIsBasicStepsDone] = useState(false);
  useEffect(() => {
    if (user) {
      const roleId =
        user['data']['roles'].length > 0
          ? user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['id']
          : '';
      if (roleId === 3) {
        //navigate('/brand/dashboard');
      } else if (roleId === 2) {
        //navigate('/admin/dashboard');
      }
    }
  }, []);

  useEffect(() => {
    if (modal) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [modal]);

  useEffect(() => {
    if (menu) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove('active');
  }, [location]);
  const close = e => {
    if (e.target === e.currentTarget) setMenu(false);
  };

  useEffect(() => {
    getProfiles();
    getOpporCollabCount();
  }, []);
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  async function getProfiles() {
    try {
      const res = await accountService.getProfiles({});
      if (res && res['data']) {
        setProfiles(res['data']['profiles']);
      }
      if (searchParams.get('sociallogin') !== null && searchParams.get('sociallogin') === 'true') {

        await accountService.refreshToken(function () { });
      }
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err });
    }
  }
  async function getOpporCollabCount() {
    try {
      const res = await influencerService.getCountForBubble({});
      setNewNavigation({ 'campaigns_count': res.opportunity_count, 'collaboration_count': res.collaboration_count });
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err });
    }
  }
  return (
    <>
      <div className={impersonationBar ? "impersonationDivMargin" : ''}>
        <Header setModal={setModal} menu={menu} setMenu={setMenu} profiles={profiles} />
        <Sidebar menu={menu} close={close} user={user} type={'influencer'} newNavigation={newNavigation} isBasicStepsDone={isBasicStepsDone} impersonationBar={impersonationBar} />
        <main>
          <Routes>
            <Route
              exact
              path='/opportunities'
              element={
                <PrivateRoute>
                  <Opportunities
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/profile'
              element={
                <PrivateRoute>
                  <MyProfile
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    setIsBasicStepsDone={setIsBasicStepsDone}
                    isBasicStepsDone={isBasicStepsDone}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/collaborations'
              element={
                <PrivateRoute>
                  <Collaboration
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                  />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path='/payment'
              element={
                <PrivateRoute>
                  <Payment
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/social_accounts'
              element={
                <PrivateRoute>
                  <Rates
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    setIsBasicStepsDone={setIsBasicStepsDone}
                    isBasicStepsDone={isBasicStepsDone}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/refer_friend'
              element={
                <PrivateRoute>
                  <ReferFriend
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/tamacoin'
              element={
                <PrivateRoute>
                  <UserTamaCoin
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                  />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path='/dashboard'
              element={
                <PrivateRoute>
                  <Dashboard
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setNotifModul={setNotifModul}
                    setModalNotification={setModalNotification}
                    setModalNotificationData={setModalNotificationData}
                    updateNotificationStatus={updateNotificationStatus}
                    accountCountNotification={accountCount}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/'
              element={
                <PrivateRoute>
                  <Dashboard
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setNotifModul={setNotifModul}
                    setModalNotification={setModalNotification}
                    setModalNotificationData={setModalNotificationData}
                    updateNotificationStatus={updateNotificationStatus}
                    accountCountNotification={accountCount}
                  />
                </PrivateRoute>
              }
            />
            <Route path="*" element={
              <PrivateRoute>
                <Dashboard
                  user={user}
                  setModalSwitch={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setNotifModul={setNotifModul}
                  setModalNotification={setModalNotification}
                  setModalNotificationData={setModalNotificationData}
                  updateNotificationStatus={updateNotificationStatus}
                  accountCountNotification={accountCount}
                />
              </PrivateRoute>
            } />

          </Routes>
        </main>
      </div>
      <AnimatePresence>
        {modal === 'switch' && (
          <ModalSwitch
            changePage={changePage}
            setChangePage={setChangePage}
            close={() => setModal(null)}
            profiles={profiles}
          />
        )}
      </AnimatePresence>
    </>
  );
}