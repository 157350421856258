import React from "react";

export default function BillingItem(props) {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <tr onClick={props.onClick}>
      <td>
        <p>{props.transaction}</p>
      </td>
      <td>
        <p>{props.description}</p>
      </td>
      <td>{props.date}</td>
      <td>{capitalizeFirstLetter(props.transaction_type)}</td>
      <td>{props.amount}</td>
    </tr>
  );
}
