import React from "react";

export default function PrevSentItem(props) {
  return (
    <tr>
      <td>
        <p>{props.firstName}</p>
      </td>
      <td>{props.handle}</td>
      <td>
        <p>{props.email}</p>
      </td>
      <td>{props.name}</td>
      <td>{props.created_at}</td>
      <td>
        <div className={"prevsent__table-status " + props.status}>
          {props.status}
        </div>
      </td>
    </tr>
  );
}
