import React from "react";
import { Modal } from "../../Components";
import { TextInput } from "../../Components";
export default function ModalConfirmModalForPaid({ setRefrenceNumber, refrenceNumber, close, changeStatusOfPost, error, newStatus, loadData }) {

  return (
    <Modal addClass="modalCamp" close={close}>
      <div className="modal__camp">
        <div className="modal__assetDetail-delete perm long referenceNumber">
          <h4>Are you sure?</h4>
          <p className="sm">
            This operation is irreversible. Set to Paid?
          </p>
          <div className="referenceNumber">
            <div ><p>Enter Refrence/payment number.</p></div>
            <div className="refrenceInput">
              <TextInput
                type="text"
                label=""
                error={error}
                errorText={'Enter Refrence/payment number'}
                value={refrenceNumber}
                onChange={(e) =>
                  setRefrenceNumber((e.target.value))
                }

              />
            </div>
          </div>
          <div className="modal__assetDetail-delete-btns">
            <button className="button secondary" onClick={close}>
              Cancel
            </button>
            <button className="button primary pink" onClick={() => changeStatusOfPost(loadData, newStatus.status)}>
              Update Status Paid
            </button>
          </div>
        </div>
      </div>
    </Modal >
  );
}
