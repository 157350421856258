import React from 'react';
import { Modal } from '../../../Components';
import { arrowLeft, trashIcon } from '../../../Components';
export default function ModalNotifExpired(props) {
  return (
    <Modal addClass='notif' close={props.close}>
      <div className='modal__notification'>
        <div className='modal__notification-inner'>
          <div className='modal__notification-title solo'>
            <h4>Notifications</h4>
            <button
              className='button tertiary big grey'
              onClick={() => {
                props.setModalData(props);
                props.setModal('notifDeleteDash');
              }}
            >
              {trashIcon} Delete
            </button>
          </div>
          <div className='modal__notification-item'>
            <div className='modal__notification-item-image'>
              <img
                src={process.env.PUBLIC_URL + '/images/notification/5.svg'}
                alt={props.id}
              />
            </div>
            <h3>Sorry, this opportunity is expired.</h3>
            <p className='sm'>
              Unfortunately this opportunity expired on {props.expired}.
              Keep an eye out for future opportunities.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
