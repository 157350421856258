import React, { useEffect, useState } from "react";
import { useRef } from "react";
import AssetVideo from "./AssetVideo";

export default function AssetsGridItem(props) {
  const [checked, setChecked] = useState(false);
  const videoWrapperRef = useRef(null);
  const checkboxRef = useRef(null);
  const clickFunc = (e) => {
    if (
      !videoWrapperRef.current.contains(e.target) &&
      !checkboxRef.current.contains(e.target)
    ) {
      props.onClick();
    }
  };

  useEffect(() => {
    setChecked(props.allChecked);
  }, [props.allChecked]);
  return (
    <div
      className={
        "assetsItem " +
        (props.modal === "assetGood" || props.modal === "assetGridGood"
          ? props.checkedSolo === props.index
            ? "active"
            : ""
          : checked
            ? "active"
            : "")
      }
      onClick={clickFunc}
    >
      <div className="checkbox" ref={checkboxRef}>
        <input
          checked={
            props.modal === "assetGood" || props.modal === "assetGridGood"
              ? props.checkedSolo === props.index
                ? true
                : false
              : checked
          }
          onChange={(e) => {
            if (
              props.modal === "assetGood" ||
              props.modal === "assetGridGood"
            ) {
              if (e.target.checked) {
                props.setCheckedSolo(props.index);
                props.setItems([props.itemData]);
              } else {
                props.setCheckedSolo(false);
                props.setItems(
                  props.items.filter((filterItem) => filterItem.id !== props.id)
                );
              }
            } else {
              setChecked(e.target.checked);
              if (e.target.checked) {
                props.setItems([...props.items, props.itemData]);
              } else {
                props.setItems(
                  props.items.filter((filterItem) => filterItem.id !== props.id)
                );
              }
            }
          }}
          type="checkbox"
        />
        <label htmlFor=""></label>
      </div>
      <div className="assetsItem__inner">
        <div className="assetsItem__header">
          <div className="assetsItem__header-media" ref={videoWrapperRef}>
            {props.media_type_id === 2 && (
              <div className="assets__media">
                <img src={process.env.PUBLIC_URL + props.thumbnail} alt="" />
              </div>
            )}
            {props.media_type_id === 1 && <AssetVideo {...props} />}
          </div>
          <h6>{props.title}</h6>
          <p className="sm desc">{props.description}</p>
        </div>
        <div className="assetsItem__row">
          <div className="assetsItem__col">
            <h6 className="sm">Upload date</h6>
            <p className="sm">{props.created_at}</p>
          </div>
          <div className="assetsItem__col">
            {props.campaigns && (
              <>
                <h6 className="sm">Times used</h6>
                <p className="sm bold">{props.campaigns && props.campaigns.length}</p>
              </>
            )}
            {props.deletedOn && (
              <>
                <h6 className="sm">Deleted on</h6>
                <p className="sm">{props.deletedOn}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
