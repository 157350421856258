import React, { useState, useEffect } from "react";
import {
  plusIcon,
  trashIcon,
  viewGrid,
  viewList,
  Pagination,
  SelectInput,
  SearchInput,
  searchIcon
} from "../../Components";
import AssetsTableItem from "./AssetsTableItem";
import { AssetsModul } from "./AssetsModul";
import AssetsGridItem from "./AssetsGridItem";

const selectDescList = [
  { id: "", value: "All assets" },
  { id: "asset.title", value: "Title" },
  { id: "asset.description", value: "Description" },
  { id: "created_at", value: "Upload date" },
  { id: "length", value: "Times used" },
];
export default function AssetsMain({
  setTab,
  setModalAsset,
  setModalDataAsset,
  paginationData,
  onPaginate,
  assestsList,
  isLoaded,
  setAssestsList,
  changeMediumDesc,
  setSearchTextOnChange,
  search,
  searchText,
  selectDesc
}) {
  const [view, setView] = useState(true);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        if (searchText != "") {
          search();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchText]);
  return (
    <div className="assets">
      <div className="assets__table-title">
        <h4 className="mob">Details</h4>
        <div className="assets__table-title-info">
          <h4 className="pc">Details</h4>
          <button className="button tertiary big" onClick={() => setTab("add")}>
            Add asset {plusIcon}
          </button>
          <button
            className="button tertiary big"
            onClick={() => { setTab("deleted"); onPaginate(1); setAssestsList([]); }}
          >
            View deleted assets {trashIcon}
          </button>
        </div>
        <div className="assets__table-title-inner">
          <button
            type="button"
            className="assets__table-title-view"
            onClick={() => setView(!view)}
          >
            {view ? viewGrid : viewList}
          </button>
          <SelectInput
            list={selectDescList}
            selected={selectDescList.filter(item => item.id === selectDesc)[0]}
            onChange={changeMediumDesc}
            value={selectDesc}
          />
          <SearchInput
            type="text"
            value={searchText}
            icon={searchIcon}
            onClick={search}
            onChange={setSearchTextOnChange}
            placeholder="Search"
          />
        </div>
      </div>
      {view ? (
        <>
          <div className="assets__table">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Upload date</th>
                  <th>Times used</th>
                </tr>
              </thead>
              <tbody>
                {(isLoaded && assestsList.length > 0) ? (
                  <>
                    {assestsList.map((item, index) => {
                      return (
                        <AssetsTableItem
                          list="activelist"
                          onClick={() => {
                            setModalAsset("detail");
                            setModalDataAsset(item);
                          }}
                          key={index}
                          {...item}
                        />
                      );
                    })}
                  </>
                ) : ((isLoaded &&
                  <tr>
                    <td colSpan='5' style={{ textAlign: 'center', paddingTop: '16px', paddingBottom: '16px' }}>
                      No records
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="assets__items">
          {(isLoaded && assestsList.length > 0) ? (
            <>
              {assestsList.map((item, index) => {
                return (
                  <AssetsGridItem
                    onClick={() => {
                      setModalAsset("detail");
                      setModalDataAsset(item);
                    }}
                    key={index}
                    {...item}
                  />
                );
              })}
            </>
          ) : (
            (isLoaded &&
              <table style={{ textAlign: 'center', width: "100%" }}><tr>
                <td colSpan='5' style={{ textAlign: 'center', paddingTop: '16px', paddingBottom: '16px' }}>
                  No records
                </td>
              </tr></table>))}
        </div>
      )}
      <div className="assets__table-footer">
        {(isLoaded && assestsList.length > 0) && (
          <>
            <Pagination
              perPage={paginationData['per_page']}
              total={paginationData['total']}
              currentPage={paginationData['current_page']}
              totalPages={paginationData['total_pages']}
              onPaginate={onPaginate}
            />
          </>
        )}
      </div>
    </div>
  );
}
