import React from "react";
import { utils } from '../../Helpers/utils';
export default function PaymentItem(props) {
  return (
    <tr>
      <td>
        <p>{props.brand_name}</p>
      </td>
      <td>{props.payment_date}</td>
      {props.accountCount > 1 && (
        <td>
          <div className="payment__table-account">
            {utils.getIconFilled(props.handle.icon[0])} <p>{props.handle.handle}</p>
          </div>
        </td>
      )}
      <td>{props.payment_processor_name}</td>
      <td>{props.budget}</td>
    </tr>
  );
}
