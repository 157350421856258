import React, { useState } from "react";
import { Modal, trashIcon } from "../../Components";

export default function ModalAssetPermanent(props) {
  const [tabModal, setTabModal] = useState("main");
  const [showMore, setShowMore] = useState(false);

  return (
    <Modal addClass="modalAsset" close={props.close}>
      <div className="modal__asset">
        <div className="modal__asset-inner">
          {tabModal === "main" && (
            <div className="modal__assetDetail">
              <div
                className="modal__assetDetail-media"
                onClick={props.mediaClick}
              >
                <div className="modal__assetDetail-media-outer">
                  <div className="modal__assetDetail-media-inner">
                    {props.media_type_id === 2 && (
                      <img src={process.env.PUBLIC_URL + props.thumbnail} alt={props.title} />
                    )}
                    {props.media_type_id === 1 && (
                      <video src={process.env.PUBLIC_URL + props.mediafile}></video>
                    )}
                  </div>
                </div>
                <div className="modal__assetDetail-media-content">
                  <div className="modal__assetDetail-media-content-item">
                    <h6>File type</h6>
                    <p>{props.file_type && props.file_type.toUpperCase()}</p>
                  </div>
                  <div className="modal__assetDetail-media-content-item">
                    <h6>File Size</h6>
                    <p>{props.file_size && (props.file_size / 1048576).toFixed(2)} MB</p></div>
                  {props.assetType === "video" && (
                    <div className="modal__assetDetail-media-content-item">
                      <h6>Resolution:</h6>
                      <p>150x150</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal__assetDetail-content">
                <h5>Title</h5>
                <h3>{props.title}</h3>
                <div className="modal__assetDetail-content-desc">
                  <h5>Description</h5>
                  <p>
                    {showMore
                      ? props.description
                      : props.description.toString().substring(0, 305) + "..."}
                    {props.description && props.description.length > 305 && (
                      <button
                        type="button"
                        className="button tertiary"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </button>
                    )}
                  </p>
                </div>
                <div className="modal__assetDetail-content-row">
                  <div className="modal__assetDetail-content-date">
                    <h6>Upload date:</h6>
                    <p>{props.created_at}</p>
                  </div>
                  <div className="modal__assetDetail-content-deleted">
                    <h6>Deleted on:</h6>
                    <p>{props.updated_at}</p>
                  </div>
                </div>
                <div className="modal__assetDetail-content-btns">
                  <button
                    className="button secondary"
                    onClick={() => setTabModal("recover")}
                  >
                    Recover asset
                  </button>
                  <button
                    className="button secondary pink"
                    onClick={() => setTabModal("remove")}
                  >
                    Permanently remove
                  </button>
                </div>
              </div>
            </div>
          )}
          {tabModal === "remove" && (
            <div className="modal__assetDetail-delete perm">
              <h4>Are you sure?</h4>
              <p className="sm">
                Deleting this asset will remove it from your library for good.
              </p>
              <div className="modal__assetDetail-delete-btns">
                <button
                  className="button secondary"
                  onClick={() => setTabModal("main")}
                >
                  Cancel
                </button>
                <button className="button primary pink" onClick={props.deleteAssetPermanently}>
                  Delete asset
                </button>
              </div>
            </div>
          )}
          {tabModal === "recover" && (
            <div className="modal__assetDetail-delete perm">
              <h4>Are you sure?</h4>
              <p className="sm">
                Recovering this asset will place it back into your active asset
                library.
              </p>
              <div className="modal__assetDetail-delete-btns">
                <button
                  className="button secondary"
                  onClick={() => setTabModal("main")}
                >
                  Cancel
                </button>
                <button className="button primary" onClick={props.recoverAsset}>
                  Recover asset
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
