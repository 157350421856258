import React, { useState, useEffect } from "react";
import { Modal } from "../../Components";
import {
  commentsIcon,
  graphIcon,
  heartIcon,
  imageIcon,
  searchIcon,
} from "../../Components";
import { YearToDate } from "../../Components";

export default function ModalInfluencerProfile(props) {
  console.log(props);

  return (
    <Modal addClass="modalFunds" close={props.close}>
      <div className="modal__url">
        <h3 className="big">{props.ModalData.profile_name}</h3>
        <div className="modal__users-col">
          <h5>Email</h5>
          <p>{props.ModalData.email}</p>
        </div>
        <div className="modal__users-row">
          <div className="modal__users-col">
            <h5>Interest</h5>
            {props.ModalData.category_name.length > 0 ?
              (props.ModalData.category_name.map((item, index) => item + ((index + 1 !== props.ModalData.category_name.length) ? ', ' : ''))) : 'NA'}
          </div>
        </div>
        <div className="modal__users-row">
          <div className="modal__users-col">
            <h5>Shipping Address</h5>
            {(props.ModalData.firstName !== null ? props.ModalData.firstName : '') + " " + (props.ModalData.lastName !== null ? props.ModalData.lastName : '')}
            <br></br>
            {props.ModalData.addresses.address !== "" ? props.ModalData.addresses.address : ''}
            {props.ModalData.addresses.apartment !== "" ? ",#" + props.ModalData.addresses.apartment : ''}
            <br></br>
            {props.ModalData.addresses.city != "" ? props.ModalData.addresses.city + ", " : ''}
            {props.ModalData.addresses.state != "" ? props.ModalData.addresses.state + " " : ''}
            {props.ModalData.addresses.zipCode !== "" ? props.ModalData.addresses.zipCode : ''}
            <br></br>
            {props.ModalData.addresses.country !== "" ? props.ModalData.addresses.country + "" : ''}

          </div>
        </div>



      </div >

    </Modal >


  );
}

const ProductItem = (props) => {
  return (
    <div className="campaignsInfluItem__product-item modalProduct">
      <h6>{props.name}</h6>
      <p>${props.rate}</p>
    </div>
  );
};
