import React from "react";

export default function TagInput(props) {
  return (
    <div className="input__outer">
      {props.label && <label>{props.label}</label>}
      <div
        className={
          "input " +
          (props.error && props.list.length === 0 ? "error " : "") +
          (props.icon ? "wIcon" : "")
        }
      >
        <input
          ref={props.inpRef}
          onChange={props.onChange}
          value={props.value}
          defaultValue={props.defaultValue}
          type={props.type}
          placeholder={props.placeholder}
          onKeyDown={props.onKeydown}
          onFocus={props.onFocus}
          readOnly={props.readOnly}
        />
        {props.icon && (
          <div
            className="input__icon"
            onClick={() => props.btnClickHandler(props.inpRef.current)}
          >
            {props.icon}
          </div>
        )}
      </div>
      {props.showList && (
        <div className="input__options">
          {props.autoCompleteList.map((item, index) => (
            <div
              className="input__option"
              key={index}
              onClick={() => props.optionClickHandler(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
      {props.error && props.value === "" && (
        <div className="input__error">{props.errorText}</div>
      )}
    </div>
  );
}
