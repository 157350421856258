import React, { useState } from 'react';
import { TextInput, EmailInput } from '../../Components';
import { motion } from 'framer-motion';

export default function MyProfileInfo({ form, setInput, saveChanges, refInfo }) {
  const [error, setError] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 * 2 }}
      exit={{ opacity: 0, y: 30 }}
      className='form'
    >
      <div className='form__title'>
        <h3 className='big'>Personal info</h3>
      </div>
      <button
        ref={refInfo}
        className='button secondary'
        onClick={() => {
          saveChanges('info');
          setError(true);
        }}
      >
        Save changes
      </button>
      <div className='form__personal'>
        <div className='form__personal-row'>
          <EmailInput
            value={form.email}
            type='email'
            onChange={setInput('email')}
            label='E-mail'
            error={error}
            errorText='Please enter E-mail'
          />
        </div>
        <div className='form__personal-row'>
          <TextInput
            value={form.firstName}
            type='text'
            onChange={setInput('firstName')}
            label='First name'
            error={error}
            errorText='Please enter First name'
            autoComplete="firstname"
          />
          <TextInput
            value={form.lastName}
            type='text'
            onChange={setInput('lastName')}
            label='Last name'
            error={error}
            errorText='Please enter Last name'
            autoComplete="lastname"
          />

        </div>
      </div>
    </motion.div>
  );
}
