import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import {
  HeaderAcc,
  Toast,
  EmailInput,
  PasswordInput,
  SelectInput,
  TextInput
} from '../../Components';
import { motion } from 'framer-motion';
import { accountService } from '../../Services';
import ReCAPTCHA from 'react-google-recaptcha';
const initForm = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  accountType: "I'm a brand"
};

const selectList = [
  { id: '3', value: "I'm a brand" },
  { id: '1', value: "I'm a influencer" }
];
export default function CreateAccount({ setModal, setRoleId, setReferId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const roleType = searchParams.get('type') ? searchParams.get('type') : null;
  let referId = searchParams.get('refId') ? searchParams.get('refId') : null;
  const [error, setError] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isBrandSelected, setIsBrandSelected] = useState(roleType !== null && roleType == 1 ? false : true);
  const [toastList, setToastList] = useState([]);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [captch, setCaptch] = useState('');
  const navigate = useNavigate();
  initForm['accountType'] =
    roleType !== null && roleType == 1 ? "I'm a influencer" : "I'm a brand";
  const [form, setForm] = useState(initForm);
  if (referId !== null) {
    sessionStorage.setItem("referId", referId);
  } else {
    let sessionRefId = sessionStorage.getItem("referId");
    if (sessionRefId !== null || sessionRefId !== undefined) {
      referId = sessionRefId;
    }
  }
  const updateForm = data => {
    setForm({ ...form, ...data });
  };
  const setInput = key => event => updateForm({ [key]: event.target.value });
  useEffect(() => {
    const isError = searchParams.get('error') !== null ? searchParams.get('error') == 1 ? Parser("Account already exists <a className='paidLink' href='/#/account/login'> click here </a> to login") : searchParams.get('error') == 2 ? Parser("Not have enough permission to signup") : '' : '';
    if (isError !== '') {
      addToast({
        error: true,
        msg: isError,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }, [])
  const changeMedium = key => item => {
    updateForm({ [key]: item.value });
    setRoleId(item.value);
    setIsTermsChecked(false);
    setIsChecked(false);
    setErrorTerms(false);
    setError(false);
    if (item.value === "I'm a brand") {
      setIsBrandSelected(true);
    } else {
      setIsBrandSelected(false)
    }
  };
  const selectListIndex = React.useMemo(() => {
    if (initForm.accountType !== '') {
      return selectList.findIndex(item => item.value === initForm.accountType);
    }
    return null;
    // eslint-disable-next-line
  }, []);

  const createValidation = () => {
    if (
      form.email === '' ||
      form.password === '' ||
      form.password.length < 8 ||
      form.confirmPassword === '' ||
      form.confirmPassword < 8 ||
      form.accountType === '' ||
      form.lastName === '' ||
      form.firstName === '' ||
      captch === '' ||
      !isChecked
    ) {
      setError(true);
    } else {
      var passwordFormat = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (form.password.match(passwordFormat)) {
        if (form.password !== form.confirmPassword) {
          setError(true);
          addToast({
            error: true,
            msg: "Password and Confirm Password should be same",
            successText: '',
            date: new Date()
          });
        } else {
          const payload = {
            title: '',
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            password: form.password,
            confirmPassword: form.confirmPassword,
            refId: referId,
            role:
              form.accountType === selectList[0]['value']
                ? 'advertiser'
                : 'influencer',
            acceptTerms: isChecked,
            captcha: captch
          };
          accountService
            .register(payload)
            .then(res => {
              navigate('/account/validateEmail', { state: { 'email': res.data.email, 'userId': res.data.id } });
            })
            .catch(err => {
              addToast({
                error: true,
                msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
                successText: '',
                date: new Date()
              });
            });
        }
      }
      else {
        setError(true);
        addToast({
          error: true,
          msg: "Password should have atleast one uppercase, one lowercase letter, one number and one special character",
          successText: '',
          date: new Date()
        });
      }

    }
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  const onChange = value => {
    setCaptch(value);
  };
  const handleLoginWithFacebook = () => {
    if (!isTermsChecked) {
      setErrorTerms(true);
    }
    else {
      let roleID = form.accountType === "I'm a brand" ? 3 : 1;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/facebook/action/signup/role/${roleID}/${referId}/referalPath`;
    }
  };

  const handleLoginWithGoogle = () => {
    if (!isTermsChecked) {
      setErrorTerms(true);
    }
    else {
      let roleID = form.accountType === "I'm a brand" ? 3 : 1;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/google/action/signup/role/${roleID}/${referId}/referalPath`;
    }
  };
  const handleLoginWithTikTok = () => {
    if (!isTermsChecked) {
      setErrorTerms(true);
    }
    else {
      let roleID = form.accountType === "I'm a brand" ? 3 : 1;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/tiktok/action/signup/role/${roleID}/${referId}/referalPath`;
    }
  };
  const handleLoginWithInsta = () => {
    if (!isTermsChecked) {
      setErrorTerms(true);
    }
    else {
      let roleID = form.accountType === "I'm a brand" ? 3 : 1;
      window.location.href = `${process.env.REACT_APP_API_URL}/login/instagram/action/signup/role/${roleID}/${referId}/referalPath`;
    }
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        createValidation();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);
  return (
    <>
      <div className='account log'>
        <div className='account__inner'>
          <HeaderAcc />
          <div className='account__inner-col'>
            <div className='account__inner-col-image'>
              <img
                src={process.env.PUBLIC_URL + '/images/accountBg.jpg'}
                alt='accountBg'
              />
            </div>
          </div>
          <div className="account__footer">
            <div className="account__footer-inner">
              <div className="account__footer-copy">Copyright {new Date().getFullYear()} © TamaYou</div>
              <Link target="_blank" to="https://tamayou.com/tama-privacy-policy/" className="account__footer-link">
                Privacy
              </Link>
              <Link target="_blank" to="https://tamayou.com/terms/" className="account__footer-link linkTerms">
                Terms
              </Link>
            </div>
          </div>
          <div className='account__inner-col big'>
            <motion.div
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ opacity: 0, y: 0 }}
              className='account__create'
            >
              <div className='account__create-inner'>
                <h2 className='uniq'>Sign up</h2>
                <div className='account__create-type'>
                  <SelectInput

                    list={selectList}
                    selected={selectList[selectListIndex]}
                    onChange={changeMedium('accountType')}
                  />
                </div>
                {(isBrandSelected || isBrandSelected == null) &&
                  <>
                    < button
                      className='button login'

                      // onClick={() => { setModal('platformCreate'); setRoleId(form.accountType) }}


                      onClick={() => { setRoleId(form.accountType); setReferId(referId); form.accountType === "I'm a brand" && setModal('platformCreate'); }}
                    >
                      Sign up using
                      <img
                        src={process.env.PUBLIC_URL + '/images/googleSign.png'}
                        alt=''
                      />
                      <img
                        src={process.env.PUBLIC_URL + '/images/facebookSign.png'}
                        alt=''
                      />
                      <img
                        src={process.env.PUBLIC_URL + '/images/tiktokSign.png'}
                        alt=''
                      />
                      <img
                        src={process.env.PUBLIC_URL + '/images/instagramSign.png'}
                        alt=''
                      />
                    </button>



                    <h5 className='reg'>or</h5>

                    <div className='account__create-row'>
                      <TextInput
                        value={form.firstName}
                        type='text'
                        onChange={setInput('firstName')}
                        label='First name'
                        error={error}
                        errorText='Please enter first name'
                        autoFocus
                      />
                      <TextInput
                        value={form.lastName}
                        type='text'
                        onChange={setInput('lastName')}
                        label='Last name'
                        error={error}
                        errorText='Please enter last name'
                      />
                    </div>
                    <div className='account__create-email'>
                      <EmailInput
                        value={form.email}
                        type='email'
                        onChange={setInput('email')}
                        label='E-mail'
                        error={error}
                        errorText='Please enter Email'
                      />
                    </div>
                    <div className='account__create-row mb24'>
                      <PasswordInput
                        value={form.password}
                        type='password'
                        onChange={setInput('password')}
                        label='Password'
                        error={error}
                        errorText={form.password === "" ? 'Please enter Password' : "Please enter 8 digit password"}
                      />
                      <PasswordInput
                        value={form.confirmPassword}
                        type='password'
                        onChange={setInput('confirmPassword')}
                        label='Confirm password'
                        error={error}
                        errorText={form.confirmPassword === "" ? 'Please enter Confirmation' : "Please enter 8 digit confirm Password"}
                      />
                    </div>
                    <div className='account__create-rect'>
                      <ReCAPTCHA
                        sitekey='6Ledv1skAAAAAF3Zb07lBukAj9N-sfq5wA_C7872'
                        onChange={onChange}
                      />
                    </div>
                    {error && captch == "" &&
                      <div className="input__error captchaerror">Please select captcha</div>}
                    <div className='checkbox'>
                      <input
                        type='checkbox'
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                      <label htmlFor=''></label>
                      <p>
                        Accept{' '}
                        <span onClick={() => setModal('terms')}>
                          Terms & Conditions
                        </span>
                      </p>
                      {error && !isChecked && (
                        <div className="checkbox__error">
                          You have to accept terms
                        </div>
                      )}
                    </div>
                    <button className='button primary' onClick={createValidation}>
                      Create account
                    </button>

                    <p className='sm'>
                      Already have an account?{' '}
                      <Link to='/account/login'>Sign in</Link>
                    </p>
                  </>}
                {(isBrandSelected == false) &&
                  <>
                    <div className='checkbox'>
                      <input type='checkbox' checked={isTermsChecked} onChange={() => setIsTermsChecked(!isTermsChecked)} />
                      <label htmlFor=''></label>
                      <p>
                        Accept{' '}
                        <span onClick={() => setModal('termsCreate')}>
                          Terms & Conditions
                        </span>
                      </p>
                      {errorTerms && !isTermsChecked && (
                        <div className="checkbox__error">You have to accept terms</div>
                      )}
                    </div>
                    <h4>Which platform would you like to sign up with?</h4>

                    <div className='modal__platform influencerSignup'>
                      <p className='sm faqlink'>
                        * Facebook/Instagram business account required. <Link target="_blank" to='https://tamayou.com/faq/'>Click here</Link> to learn how to convert your account.

                      </p>


                      <button
                        className='button'
                        onClick={handleLoginWithFacebook}

                      >
                        <img
                          src={process.env.PUBLIC_URL + '/images/facebookSign.png'}
                          alt=''
                        />
                        Sign up using Facebook
                      </button>
                      <button
                        className='button'
                        onClick={handleLoginWithInsta}

                      >
                        <img
                          src={process.env.PUBLIC_URL + '/images/instagramSign.png'}
                          alt=''
                        />
                        Sign up using Instagram
                      </button>
                      <button
                        className='button'
                        onClick={handleLoginWithTikTok}

                      >
                        <img
                          src={process.env.PUBLIC_URL + '/images/tiktokSign.png'}
                          alt=''
                        />
                        Sign up using TikTok
                      </button>

                      <button
                        className='button'
                        onClick={handleLoginWithGoogle}

                      >
                        <img src={process.env.PUBLIC_URL + '/images/googleSign.png'} alt='' />
                        Sign up using Google
                      </button>
                      <p className='sm'>
                        Already have an account?{' '}
                        <Link to='/account/login'>Sign in</Link>
                      </p>
                    </div>
                  </>
                }
              </div>
            </motion.div>
          </div>
        </div >

      </div >
      {
        toastList && <Toast list={toastList} setList={setToastList} />
      }
    </>
  );
}