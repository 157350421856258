import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/campaigns`;

export const engagementService = {
  getAll,
  getById,
  create,
  update,
  getByCampaignId,
  delete: _delete,
  getCampaignsByInfluencer,
  getConfigData,
  getInfluencers,
  getProductsByProposal,
  getContentByProposal,
  getEngagementStats
};

function getAll(options) {
  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
  return fetchWrapper.get(url);
}

function getByCampaignId(options, id) {
  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const apiURL = `${process.env.REACT_APP_API_URL}/campaignByProposalId/${id}`;
  const url =
    filterString.length > 0 ? `${apiURL}?${filterString}` : `${apiURL}`;

  return fetchWrapper.get(url);
}

function getAllPaging(options, filterString) {
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  } if (options.fileType) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[filetype]=${options.fileType}`;
  }
  return filterString;
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}
function getEngagementStats(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/engagementStats/${id}`);
}
function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function getCampaignsByInfluencer(options, id) {
  let filterString = '';
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const apiURL = `${process.env.REACT_APP_API_URL}/campaignsByInfluencer`;
  const url =
    filterString.length > 0 ? `${apiURL}?${filterString}` : `${apiURL}`;
  return fetchWrapper.get(url);
}

function getConfigData() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/config`);
}

function getInfluencers(params) {
  return fetchWrapper.post(
    `${process.env.REACT_APP_API_URL}/influencers/product-rates`,
    params
  );
}

function getProductsByProposal(id) {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/productsByProposalId/${id}`
  );
}

function getContentByProposal(id) {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/contentByProposalId/${id}`
  );
}
