export default function InsigntBlock({tabData,setTab}) {
    return (
     
        <>
        <h3 className="big">Insights
            {(tabData.status === 'active' || tabData.status === 'complete') &&
              <button className="reportButton" onClick={() => {
                setTab("insights")
              }}>
                Report
              </button>}
          </h3>
        <div className="engagement__col-inner">
            <div className="engagement__col-row">
              <h6 className="semi">Total views</h6>
              <p>{tabData.campaign_statistics ? tabData.campaign_statistics.impression : ''}</p>
            </div>
            <div className="engagement__col-row">
              <h6 className="semi">Total reach</h6>
              <p>{tabData.campaign_statistics ? tabData.campaign_statistics.reach : ''}</p>
            </div>
            <div className="engagement__col-row">
              <h6 className="semi">Total likes</h6>
              <p>{tabData.campaign_statistics ? tabData.campaign_statistics.like : ''}</p>
            </div>
            <div className="engagement__col-row">
              <h6 className="semi">Total shares</h6>
              <p>{tabData.campaign_statistics ? tabData.campaign_statistics.share : ''}</p>
            </div>
            <div className="engagement__col-row">
              <h6 className="semi">Avg engagement(%) </h6>
              <p>{tabData.campaign_statistics ? parseFloat(tabData.campaign_statistics.engagement_rate).toFixed(3) : ''}</p>
            </div>
          </div></>)
}