import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderAcc, EmailInput } from '../../Components';
import { motion } from 'framer-motion';

const initForm = {
  instagramLink: ''
};
export default function SignUp({ setModal }) {
  const [form, setForm] = useState(initForm);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const updateForm = data => {
    setForm({ ...form, ...data });
  };
  const setInput = key => event => updateForm({ [key]: event.target.value });
  const instValidation = () => {
    if (form.instagramLink === '') {
      setError(true);
    } else {
      navigate('/login');
    }
  };
  return (
    <>
      <div className='account log'>
        <div className='account__inner'>
          <HeaderAcc />
          <div className='account__inner-col'>
            <div className='account__inner-col-image'>
              <img
                src={process.env.PUBLIC_URL + '/images/accountBg.jpg'}
                alt='accountBg'
              />
            </div>
          </div>
          <div className='account__inner-col'>
            <motion.div
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ opacity: 0, y: 0 }}
              className='account__login signup'
            >
              <div className='account__login-inner'>
                <h2 className='uniq'>Sign up</h2>
                <EmailInput
                  value={form.instagramLink}
                  type='email'
                  onChange={setInput('instagramLink')}
                  label='Instagram'
                  error={error}
                  errorText='Please enter Instagram'
                />
                <button className='button primary' onClick={instValidation}>
                  Join
                </button>
                <h5 className='reg'>or</h5>
                <button
                  className='button login'
                  onClick={() => setModal('platform')}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/images/login.png'}
                    alt='login'
                  />
                  Log in with Google or Facebook
                </button>
                <p className='sm'>
                  Don’t have an account? <Link to='/sign-up'>Sign Up</Link>
                </p>
              </div>
            </motion.div>
          </div>
          <div className="footerdiv">
            <p>Copyright 2023 All rights Reserved.</p>
            <p><a href="https://tamayou.com/tama-privacy-policy/">Privacy</a></p>
            <p><a href="https://tamayou.com/terms/">Terms</a></p>
          </div>
        </div>
      </div>
    </>
  );
}
