import React from 'react';
import { arrowRight } from '../../../Components';
import { AnimatePresence, motion } from 'framer-motion';
import { utils } from '../../../Helpers/utils';
export default function CardOpportunity(props) {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 * props.index }}
        exit={{ opacity: 0, y: 30 }}
        className={
          "card " +
          (props.accountCount > 1 ? "moreAcc " : "") +
          (props.type ? props.type : "")
        }
      >
        {/* {!props.last && (
          <div className='card__close' onClick={props.deleteCard}>
            {closeIcon}
          </div>
        )} */}
        {(props.accountCount > 1 && props.opportunity.handle) && (
          <div className="card__account">
            {props.opportunity.handle.icon && utils.getIconFilled(props.opportunity.handle.icon[0])}
            {props.opportunity.handle && <span>{props.opportunity.handle.handle}</span>}
          </div>
        )}
        <div className='card__title'>
          <div className='card__title-image'>
            <img
              src={
                props.opportunity.brandLogo
                  ? props.opportunity.brandLogo
                  : `${process.env.PUBLIC_URL}/images/bulls-eye.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.PUBLIC_URL}/images/bulls-eye.png`;
              }}
              alt='bulls-eye'
            />
          </div>
          <div className='card__ttile-content'>
            <h3>{props.opportunity.brand_name}</h3>
            <div className='card__title-content-row'>
              {<h6>{props.opportunity.title}</h6>}
              {props.opportunity.products &&
                props.opportunity.products.map((product, idx) => (
                  <a href='#' key={idx}>
                    {utils.getIcon(product)}
                  </a>
                ))}
            </div>
          </div>
        </div>
        <h6>Deliverables</h6>
        <p>
          {props.opportunity.deliverable ?
            <span>
              {props.opportunity.deliverable
                ? utils.draftJsFormatingForDetails({ 'data': props.opportunity.deliverable, 'view': 'lessview' })
                : 'NA'}
            </span>
            :
            ''}
          <button
            type='button'
            onClick={props.openModal}
          >
            Read
          </button>
        </p>
        <div className='card__comp opportunityCard_optinby'>
          <h6 >Post window:</h6> <span>{props.opportunity.start_date}{' -> '}{props.opportunity.end_date}</span>
        </div>
        <h6>Compensation</h6>
        <div className='card__comp'>
          <h6>
            Amount:
            <span>
              {props.opportunity.compensationDetails
                ? ` ${props.opportunity.compensationDetails.amount}`

                : ' NA'}
              {
                (props.opportunity.rate_overwritten && parseInt(props.opportunity.rate_overwritten) == 1)
                && <img className="redFlag" src={`${process.env.PUBLIC_URL}/images/red-flag.png`} alt="red-flag" />
              }
            </span>
          </h6>
          {props.opportunity.compensationDetails && props.opportunity.compensationDetails.product != '' &&
            <h6>
              Product :
            <span>
                {props.opportunity.compensationDetails
                  ? props.opportunity.compensationDetails.product != '' ? ' Yes'
                    : ' No' : ' NA'}
              </span>
            </h6>
          }
        </div>
        {/*<h6>Content timeline</h6>*/}
        <div className='card__timeline'>
          <h6>
            Opt in by: <span>{props.opportunity.deadline_date != "" && props.opportunity.deadline_date !== null ? props.opportunity.deadline_date : 'NA'}</span>
          </h6>
          {/*<h6>
            Analytics by: <span>{props.opportunity.end_date}</span>
              </h6>*/}
        </div>
        <button
          className='button tertiary'
          onClick={props.openModal}
        >
          View full details {arrowRight}
        </button>
      </motion.div>
      <AnimatePresence>
        {props.modal !== null && props.children}
      </AnimatePresence>
    </>
  );
}
