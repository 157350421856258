import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import {
  TextInput,
  TextArea,
  arrowRight,
  closeIcon,
  plusIcon,
  Toast
} from "../../Components";
import TagInput from "../../Components/Base/Form/TagInput";
import AssetVideo from "./AssetVideo";
import {
  contentService,
  uploadService
} from '../../Services';


export default function AssetsEdit(props) {
  const tagsWrapper = useRef(null);
  const inpRef = useRef(null);
  const fileRef = useRef(null);
  const editAsset = useRef(null);
  const [form, setForm] = useState({
    title: props.title,
    tags: props.tags.split(","),
    description: props.description,
    mediafile: props.mediafile,
    media_type_id: props.media_type_id,
    file_type: props.file_type,
    file_size: props.file_size,
    thumbnail: props.thumbnail,
  });
  const [error, setError] = useState(false);
  const [assetFile, setAssetFile] = useState(form.mediafile);
  const [disableUpdateButton, setDisableUpdateButton] = useState(false);
  const [assetFileDetail, setAssetFileDetail] = useState({
    type: props.media_type_id,
    name: props.thumbnail
  });
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });

  const addToast = data => {
    const newToastList = [...props.toastList];
    if (data.msg) {
      if (data.msg[data.msg.length - 1] === ".")
        data.msg = data.msg.slice(0, -1);
    }
    newToastList.push({ ...data, id: `${Date.now()}-${props.toastList.length}` });
    props.setToastList(newToastList);
  };
  const readFile = ({ file, onLoad }) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = ({ target }) => onLoad(target.result);
      reader.readAsDataURL(file);
    }
  };
  const previewImage = () => {
    if (fileRef.current.files && fileRef.current.files[0]) {
      const fileType = fileRef.current.files[0].type;
      if ((fileType.startsWith('image') === false && fileType.startsWith('video') === false) || fileRef.current.files[0].size > 10000000) {
        setDisableUpdateButton(false);
        addToast({ error: true, date: new Date(), msg: " .GIF, .PNG, .JPG, .MP4, .MOV only with a maximum file size of 10MB" });
      } else {
        if (fileType.startsWith('image')) {
          updateForm({ ["media_type_id"]: 2 })
        }
        if (fileType.startsWith('video')) {
          updateForm({ ["media_type_id"]: 1 })
        }
        setDisableUpdateButton(true);
        readFile({
          file: fileRef.current.files[0],
          onLoad: (result) => {
            //setAssetFileDetail(fileRef.current.files[0]);
            upload();
          }
        });
      }
    }
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        editAsset.current.click();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);
  const upload = () => {
    uploadService
      .upload(
        fileRef.current.files[0],
        true,
        true
      )
      .then(response => {
        if (response.message === 'success') {
          const fileType = fileRef.current.files[0].type;

          if (fileType.startsWith('image')) {
            setAssetFile(response.path);
            setAssetFileDetail({ "name": response.thumbail_path, "type": 2 });
            updateForm({ ["mediafile"]: response.path, ["media_type_id"]: 2, ["file_type"]: response.file_type, ["file_size"]: response.file_size })
          }
          if (fileType.startsWith('video')) {
            setAssetFile(response.path);
            setAssetFileDetail({ "name": response.path, "type": 1 });
            updateForm({ ["mediafile"]: response.path, ["media_type_id"]: 1, ["file_type"]: response.file_type, ["file_size"]: response.file_size })
          }
          setDisableUpdateButton(false);
        } else {
          addToast({ error: true, date: new Date(), msg: response.message });
        }
      })
      .catch(err => {
        setAssetFile("");
        setAssetFileDetail({});
        setDisableUpdateButton(false);
        addToast({ error: true, date: new Date(), msg: err.message });
      });
  };
  const delTags = (index) => {
    let newTags = [...form.tags];
    newTags.splice(index, 1);
    updateForm({ tags: newTags });
  };
  const setTags = (obj) => {
    let newTags = [...form.tags];
    newTags.push(...obj.value.split(','));
    updateForm({ tags: newTags });
    obj.value = "";
  };
  const handlePress = (event) => {
    const inputItem = event.target;

    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      setTags(inputItem);
      return;
    }
  };
  useEffect(() => {
    updateForm({ mediafile: assetFile });
  }, [assetFile]);

  const submitBtnClick = async () => {
    if (form.title === "" || form.description === "" || form.mediafile === "" || form.tags.length === 0) {
      setError(true);
      addToast({ error: true, date: new Date(), msg: 'Enter all details to edit Asset.' });
    } else {
      try {
        form.tags = form.tags.toString();
        const res = await contentService.update(props.id, form);
        props.setTab("main")
        addToast({ error: false, date: new Date(), msg: res.message });
      } catch (err) {
        props.setTab("main")
        addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, "") });
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="assetsAdd"
    >
      <div className="assetsAdd__form">
        <div className="assetsAdd__form-title">
          <h3 className="big">Edit asset</h3>
        </div>
        <div className="assetsAdd__form-inner">
          <div className="assetsAdd__form-head">
            <TextInput
              value={form.title}
              type="text"
              onChange={setInput("title")}
              label="Title"
              error={error}
              errorText="Please enter title"
              autoFocus
            />
          </div>
          <div className="assetsAdd__form-desc">
            <TextArea
              value={form.description}
              onChange={setInput("description")}
              label="Description"
              error={error}
              errorText="Please enter description"
              placeholder="Enter description"
              row="4"
            />
          </div>
          {form.tags && (
            <div className="assetsAdd__form-tags" ref={tagsWrapper}>
              <TagInput
                btnClickHandler={setTags}
                inpRef={inpRef}
                onKeydown={handlePress}
                type="text"
                label="Tags"
                icon={plusIcon}
                list={form.tags}
                error={error}
              />
              <p className="sm">
                TAMA highly recommends adding tags to your assets to help easily
                locate them when building campaigns.
              </p>
              <div className="assetsAdd__form-tags-items">
                {Array.isArray(form.tags) && form.tags.map((item, index) => {
                  return (
                    <div className="assetsAdd__form-tags-item" key={index}>
                      {item}
                      <div
                        className="assetsAdd__form-tags-item-icon"
                        onClick={() => delTags(index)}
                      >
                        {closeIcon}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>)}
          <div className="assetsAdd__form-file">
            <h6 className="semi">Upload file/logo</h6>
            <div className="assetsAdd__form-file-inner edit">
              {assetFileDetail.name && (
                <div className="assetsAdd__form-preview">
                  {(form.media_type_id === 2) && (
                    <div className="assets__media">
                      <img src={process.env.PUBLIC_URL + assetFileDetail.name} alt="" />
                    </div>
                  )}
                  {(form.media_type_id === 1) && (
                    <AssetVideo mediafile={assetFileDetail.name} />
                  )}
                  <h6 className="sm">{assetFileDetail.name && assetFileDetail.name.substring(assetFileDetail.name.lastIndexOf('/') + 1)}</h6>
                </div>
              )}
              {props.campaigns <= 0 ? (
                <div
                  className={
                    "assetsAdd__form-upload " +
                    (error && form.mediafile === "" ? "error" : "")
                  }
                >
                  <div className="assetsAdd__form-upload-icon">{plusIcon}</div>
                  <h5 className="reg">Upload media</h5>
                  <p className="sm">
                    .GIF, .PNG, .JPG, .MP4, .MOV only with a maximum file size of 10MB
                  </p>
                  <button className="button primary">
                    Upload
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime"
                      ref={fileRef}
                      onChange={previewImage}
                    />
                  </button>
                  {error && form.mediafile === "" && (
                    <div className="assetsAdd__form-upload-error">
                      Please upload file
                    </div>
                  )}
                </div>
              ) : (
                  <div className="assetsAdd__form-text">
                    <p className="sm">
                      This asset is currently being used by {props.campaigns.length} campaigns. The media
                    can not be edited.
                  </p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <button ref={editAsset} className="button primary" onClick={submitBtnClick} disabled={disableUpdateButton}>
        Update asset
      </button>
    </motion.div>
  );
}
