import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

export default function Assets({
  form,
  setModal,
  setModalData,
  setAssetIndex,
  setSelectedItems,
  selectedItems,
  updateForm,
  campaignAssets,
  readOnly
}) {
  const [checkedAll, setCheckedAll] = useState();
  const [items, setItems] = useState(form.assets);

  const { selectedContent, selectedProduct, selectedInfluencer } =
    selectedItems;
  useEffect(() => {
    if ((form.assets).length !== 0) {
      // setCheckedAll()
    }
    let arr = [];
    let selContent = [];
    items.map(item => {
      arr.push(item);
      selContent.push(item.id);
    })
    setSelectedItems({ ...selectedItems, selectedContent: selContent });
  }, [items]);

  const selectAll = () => {

    if (!checkedAll) {
      setItems(campaignAssets);
    } else {
      setItems([]);
      setSelectedItems({ ...selectedItems, selectedContent: [] });
    }
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="form p16"
    >
      <div className="formEngagement__assets">
        <div className="formEngagement__assets-title">
          <h3 className="big">Assets</h3>
        </div>
        <div className="formEngagement__assets-content">
          <div className="formEngagement__assets-content-title">
            {form.assets.length === 0 && (
              <h5 className="reg">
                There are currently no assets selected for this campaign.
              </h5>
            )}
            {form.assets.length > 0 && (
              <h5 className="reg">
                There are currently {form.assets.length} assets assigned to this
                campaign.
              </h5>
            )}
            <div className="formEngagement__assets-items">
              {campaignAssets.map((item, index) => {
                return (
                  <AssetsItem
                    {...item}
                    itemData={item}
                    assets={form.assets}
                    key={item.id}
                    setItems={setItems}
                    items={items}
                    readOnly={readOnly}
                    setCheckedAll={setCheckedAll}
                    checkedAll={checkedAll}
                    onItemClick={() => {
                      setAssetIndex(index);
                      setModalData(item);
                      setModal("assetDetail");
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {!readOnly &&
          <div className="formEngagement__assets-btns">
            <button
              className="button secondary"
              onClick={() => { setCheckedAll(!checkedAll); selectAll(); }}
            >
              {checkedAll ? "Deselect" : "Select"} all assets
          </button>
          </div>
        }
      </div>
    </motion.div>
  );
}
const AssetsItem = (props) => {


  let isAssetPresent = props.assets.filter(item => item.id === props.id);
  const [checked, setChecked] = useState((isAssetPresent.length === 1) ? true : false);

  useEffect(() => {
    if (props.checkedAll !== undefined) {
      if (props.checkedAll) {

        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [props.checkedAll]);

  return (
    <div className={"formEngagement__assetsItem " + (checked ? "active" : "")}>
      <div className="checkbox">
        <input
          checked={checked}
          disabled={props.readOnly}
          onChange={(e) => {
            setChecked(e.target.checked);
            if (e.target.checked) {
              props.setItems([...props.items, props.itemData]);
            } else {
              props.setItems(
                props.items.filter(
                  (filterItem) => filterItem.id !== props.id
                )
              );

            }

          }}
          type="checkbox"
        />
        <label htmlFor=""></label>
      </div>
      <div className="assetsAdd__form-preview" onClick={props.onItemClick}>
        {props.media_type_id === 2 && (
          <div className="assets__media">
            <img src={process.env.PUBLIC_URL + props.thumbnail} alt="" />
          </div>
        )}

        {props.media_type_id === 1 && (
          // <AssetVideo asset={props.asset} />
          <div className="assets__media vid">
            <video src={process.env.PUBLIC_URL + props.mediafile} muted></video>
          </div>
        )}
        <h6 className="sm">{props.title}</h6>
      </div>
    </div>
  );
};
