import React, { useEffect, useState, useRef } from "react";
import { SearchInput, SelectInput, Pagination, TextInput, EmailInput, PasswordInput } from
  "../../Components";

import { download, searchIcon, chevronBottom } from "../../Components";

import UsersItem from "./UsersItem";
import { UsersModul } from "./UsersModul";

import { AnimatePresence } from "framer-motion";
import UsersDetailModal from "./UsersDetailModal";
import ModalSendEmail from "./ModalSendEmail";
import { ModalInfluencerRate } from "../../Components";
import ModalInfluencerProfile from "./ModalInfluencerProfile";
import ModalBrandProfile from "./ModalBrandProfile";
import {
  accountService,

} from '../../Services';

const selectList = [
  { id: "email", value: "Email" },
  { id: "influencers.handle", value: "Handle" },
  { id: "role", value: "Role" },
  { id: "created_at", value: "Date created" },
  { id: "firstName", value: "Name" },
  { id: "status", value: "Status" },
];



const initValue = {
  firstName: "",
  lastName: "",
  email: "",
  role: [],
  password: "",
  confirmPassword: "",
};
export default function UsersMain({ roleList, onChange, searchText, search, changeMediumDesc, setSearchTextOnChange, modal, setModal, modalData, setModalData, countOfUsers, setCountOfUsers, setTab, addToast, users, paginationData, onPaginate, toastList, downloadCsv, form, setInput, updateForm, impersonateUser }) {


  const [error, setError] = useState(false);
  const [hide, setHide] = useState(false);
  const [initvalue, setInitvalue] = useState(initValue);
  const refAddUser = useRef(null);
  const fname = useRef(null);
  const suspendUser = async () => {
    try {
      let fieldsToUpdate = {};
      fieldsToUpdate.request_type = "status";
      fieldsToUpdate.status = 0;
      let res = await accountService.update(modalData.id, fieldsToUpdate);
      addToast({
        error: false,
        msg: res.message.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        id: `${Date.now()}-${toastList.length}`
      });
      setModal(null)
      setCountOfUsers(countOfUsers - 1);
    } catch (err) {
      addToast({
        error: true,
        msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const sendEmail = async () => {
    try {
      let params = {};
      params.roleName = modalData.roles[0].name;
      params.firstName = modalData.firstName;
      params.id = modalData.id;
      params.email = modalData.email;
      let res = await accountService.sendMail(params);
      addToast({
        error: false,
        msg: res.message.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        id: `${Date.now()}-${toastList.length}`
      });
      setModal(null)
      setCountOfUsers(countOfUsers - 1);
    } catch (err) {
      addToast({
        error: true,
        msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterCalled();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchText]);
  const enterCalled = () => {
    let addUser = document.activeElement.parentElement.closest('.formUsers__new');
    if (addUser) {
      refAddUser.current.click();
    }
  }
  const saveBtnClick = async () => {
    if (
      form.firstName !== "" &&
      form.lastName !== "" &&
      form.email !== "" &&
      form.role.length > 0 &&
      form.password !== "" &&
      form.confirmPassword !== ""
    ) {
      var passwordFormat = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (form.password.match(passwordFormat)) {
        if (form.password === form.confirmPassword) {
          setError(false);
          try {
            createUser();
          } catch (err) {
            addToast({
              error: true,
              msg: err,
              id: `${Date.now()}-${toastList.length}`
            });
          }
        } else {
          addToast({
            error: true,
            msg: 'Password and Confirm Password should be same',
            id: `${Date.now()}-${toastList.length}`
          });
        }
      } else {
        addToast({
          error: true,
          msg: "Password should have atleast one uppercase, one lowercase letter, one number and one special character",
          successText: '',
          date: new Date()
        });
      }
    } else {
      setError(true);
      addToast({
        error: true,
        msg: "Please enter all fields to save user",
        successText: '',
        date: new Date()
      });

    }
  };
  const createUser = async () => {
    try {
      //form.role = form.role === "3" ? 'advertiser' : form.role === "1" ? 'influencer' : 'admin';
      let newObjUser = { ...form }
      let res = await accountService.create({ ...newObjUser })
      updateForm(initvalue);
      onPaginate(1);
      setCountOfUsers(countOfUsers + 1);
      addToast({
        error: false,
        msg: res.message.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        id: `${Date.now()}-${toastList.length}`
      });
    } catch (err) {
      addToast({
        error: true,
        msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);
  return (
    <>
      <div className="users">
        <div className={"form " + (hide ? "active" : "")}>
          <div className="form__title">
            <h3 className="big">New user</h3>
          </div>
          <div className="form__hide" onClick={() => {
            updateForm(initvalue); setHide(!hide); fname.current.focus();
          }}>
            {chevronBottom}
          </div>
          <div className="formUsers__new">
            <div className="formUsers__new-row">
              <TextInput
                value={form.firstName}
                type="text"
                onChange={setInput("firstName")}
                label="First name"
                error={error}
                errorText="Please enter First name"
                inpRef={fname}
              />
              <TextInput
                value={form.lastName}
                type="text"
                onChange={setInput("lastName")}
                label="Last name"
                error={error}
                errorText="Please enter Last name"
              />
              <EmailInput
                value={form.email}
                type="email"
                onChange={setInput("email")}
                label="Email"
                error={error}
                errorText="Please enter Email"
              />
              <div className="input__outer ">
                <label>Role</label>
                <div className="roleCheckBox">
                  {roleList.map((itemRole, index) => {
                    return (
                      <>
                        <input
                          value={itemRole.id}
                          type='checkbox'
                          checked={form.role.filter(item => item.id == itemRole.id).length > 0}
                          key={itemRole.id}
                          onChange={onChange}
                        /> {itemRole.name == 'advertiser' ? 'Brand' : (itemRole.name).charAt(0).toUpperCase() + itemRole.name.slice(1)}
                      </>)
                  })}

                </div>
              </div>
            </div>
            <div className="formUsers__new-inner">
              <div className="formUsers__new-passwords">
                <PasswordInput
                  value={form.password}
                  type="password"
                  onChange={setInput("password")}
                  label="Password"
                  error={error}
                  errorText={form.password === "" ? "Please enter New password" : "please enter 8 digit password"}
                />
                <PasswordInput
                  value={form.confirmPassword}
                  type="password"
                  onChange={setInput("confirmPassword")}
                  label="Confirm password"
                  error={error}
                  errorText={form.password === "" ? "Please enter confirm new password" : "please enter 8 digit confirm password"}
                />
              </div>
              <button ref={refAddUser} className="button primary" onClick={saveBtnClick}>
                Add new user
              </button>
            </div>
          </div>
        </div>
        <div className="users__table-title">
          <h4>Existing users</h4>
          <div className="users__table-title-inner">
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMediumDesc}
            />

            <SearchInput
              value={searchText}
              onClick={search}
              type="text"
              icon={searchIcon}
              placeholder="Search"
              onChange={setSearchTextOnChange} />
          </div>
        </div>

        <div className="users__table">
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Date created</th>
                <th>Handles</th>
                <th>Status</th>

              </tr>
            </thead>
            <tbody>
              {users.map((item, index) => {
                return (
                  <UsersItem
                    setModal={setModal}
                    setModalData={setModalData}
                    impersonateUser={impersonateUser}
                    item={item}
                    onClick={() => {
                      setModal("detail");
                      setModalData(item);
                    }}
                    key={index}
                    {...item}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="users__table-footer">
          <button className="button tertiary download" onClick={downloadCsv}>
            Download CSV {download}
          </button>
          <Pagination
            perPage={paginationData['per_page']}
            total={paginationData['total']}
            currentPage={paginationData['current_page']}
            totalPages={paginationData['total_pages']}
            onPaginate={onPaginate}
          />
        </div>
      </div>
      <AnimatePresence>
        {modal === "detail" && (
          <UsersDetailModal
            close={() => setModal(null)}
            suspendUser={suspendUser}
            {...modalData}
            setTab={setTab}
          />
        )}
        {modal === "sendEmail" && (
          <ModalSendEmail
            sendEmail={sendEmail}
            setModal={setModal}
            close={() => setModal(null)}
          />
        )}
        {modal === "showRate" && (
          <ModalInfluencerRate
            setModal={setModal}
            ModalData={modalData}
            close={() => setModal(null)}
          />
        )}
        {modal === "showInfProfile" && (
          <ModalInfluencerProfile
            setModal={setModal}
            ModalData={modalData}
            close={() => setModal(null)}
          />
        )}
        {modal === "showBrandsProfile" && (
          <ModalBrandProfile
            setModal={setModal}
            ModalData={modalData}
            close={() => setModal(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
}
