import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ReCAPTCHA from 'react-google-recaptcha';
import { accountService } from '../../Services';

import { useSearchParams } from 'react-router-dom'
import {
    HeaderAcc,
    Toast,
    PasswordInput
} from '../../Components';
const initForm = {
    password: "",
    confirmPassword: "",
};

export default function ResetPassword({ setModal, setRoleId }) {
    const [form, setForm] = useState(initForm);
    const [error, setError] = useState(false);
    const [toastList, setToastList] = useState([]);
    const [formSubmit, setFormSubmit] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [captcha, setCaptcha] = useState('');
    const navigate = useNavigate();
    const updateForm = (data) => {
        setForm({ ...form, ...data });
    };
    const setInput = (key) => (event) =>
        updateForm({ [key]: event.target.value });

    useEffect(() => {
        function checkToken() {
            let token = searchParams.get('id');
            accountService
                .validateResetToken(token)
                .then(res => {
                    console.log(res);
                })
                .catch(err => {
                    if (err === 'The link is not valid.') {
                        navigate('/account/login');
                    } else {
                        navigate('/account/forgot-pass?expired=' + 1);
                    }
                });

        }
        checkToken();
    }, [])
    const addToast = data => {
        const newToastList = [...toastList];
        newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
        setToastList(newToastList);
    };
    const resetPassword = async (payload) => {
        let token = searchParams.get('id');
        try {
            let res = await accountService
                .resetPassword(token, form.password, form.confirmPassword)
                .then(res => {
                    console.log(res);
                    addToast({
                        error: false,
                        msg: res.message,
                        date: new Date()
                    });
                    navigate("/account/login");
                })
                .catch(err => {
                    addToast({
                        error: true,
                        msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
                        successText: '',
                        date: new Date()
                    });
                });

        } catch (err) {
            addToast({ error: true, date: new Date(), msg: err.message });
        }
    };
    const createValidation = () => {
        if (form.password === '' ||
            form.password.length < 8 ||
            form.confirmPassword === '' ||
            form.confirmPassword < 8) {
            setError(true);
        } else {
            var passwordFormat = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            if (form.password.match(passwordFormat)) {
                if (form.password !== form.confirmPassword) {
                    setError(true);
                    addToast({
                        error: true,
                        msg: "Password and Confirm Password should be same",
                        successText: '',
                        date: new Date()
                    });
                } else {
                    resetPassword();
                }
            } else {
                setError(true);
                addToast({
                    error: true,
                    msg: "Password should have atleast one uppercase, one lowercase letter, one number and one special character",
                    successText: '',
                    date: new Date()
                });
            }
        }
    }

    return (
        <>
        <div className='account log' >
            <div className='account__inner'>
                <HeaderAcc />
                <div className='account__inner-col'>
                    <div className='account__inner-col-image'>
                        <img
                            src={process.env.PUBLIC_URL + '/images/accountBg.jpg'}
                            alt='accountBg'
                        />
                    </div>
                </div>
                <div className="account__footer">
                    <div className="account__footer-inner">
                        <div className="account__footer-copy">Copyright {new Date().getFullYear()} © TamaYou</div>
                        <Link target="_blank" to="https://tamayou.com/tama-privacy-policy/" className="account__footer-link">
                            Privacy
                        </Link>
                        <Link target="_blank" to="https://tamayou.com/terms/" className="account__footer-link">
                            Terms
                        </Link>
                    </div>
                </div>
                <div className='account__inner-col'>
                    <motion.div
                        initial={{ opacity: 0, y: 0 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4 }}
                        exit={{ opacity: 0, y: 0 }}
                        className='account__login'
                    >
                        <div className='account__login-inner'>
                            <h2 className='uniq'>Reset password</h2>
                            <div className='account__create-email'>
                                <PasswordInput
                                    value={form.password}
                                    type='password'
                                    onChange={setInput('password')}
                                    label='Password'
                                    error={error}
                                    errorText={form.password === "" ? 'Please enter Password' : "Please enter 8 digit password"}
                                />
                                <PasswordInput
                                    value={form.confirmPassword}
                                    type='password'
                                    onChange={setInput('confirmPassword')}
                                    label='Confirm password'
                                    error={error}
                                    errorText={form.confirmPassword === "" ? 'Please enter Confirmation' : "Please enter 8 digit confirm Password"}
                                />
                            </div>
                            <button className='button primary' onClick={createValidation}>
                                Reset Password
                            </button>
                            <p className='sm'>
                                Click Here to {' '}
                                <Link to='/account/login'>Sign in</Link>
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
        {
            toastList && <Toast list={toastList} setList={setToastList} />
        }
    </>
    );
}
