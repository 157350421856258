export const PrevSentModul = [
  {
    id: "1",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "read",
  },
  {
    id: "2",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "deleted",
  },
  {
    id: "3",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "unread",
  },
  {
    id: "4",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "read",
  },
  {
    id: "5",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "read",
  },
  {
    id: "6",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "read",
  },
  {
    id: "7",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "read",
  },
  {
    id: "8",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "read",
  },
  {
    id: "9",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "read",
  },
  {
    id: "10",
    name: "Jeremy Chapline",
    handle: "thecharredcask",
    email: "thatguy@whiskygoodness.com",
    role: "Influencer",
    dateSent: "03-20-2022",
    status: "read",
  },
];
