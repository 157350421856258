import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { utils } from '../../../Helpers/utils';
import SelectInput from "../../../Components/Base/Form/SelectInput";
import TextInput from "../../../Components/Base/Form/TextInput";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AssetVideo from "../../Assets/AssetVideo";
import { plusIcon, trashIcon } from "../../../Components";
import { EditorInput } from "../../../Components";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import {
  uploadService
} from '../../../Services';


export default function Goods(props) {
  const fileRef = useRef(null);
  const [pos, setPos] = useState(props.pos);
  const [assetFile, setAssetFile] = useState("");
  const [assetFileDetail, setAssetFileDetail] = useState("");
  const [mediaType, setMediaType] = useState(0);
  const [isAsset, setisAsset] = useState(true);
  const [deleteClicked, setIsDeleteClicked] = useState(false);
  let descHasValidJson = utils.testJSON(props.description);
  let contentGoodsDesc = convertFromRaw(
    props.description ? descHasValidJson === true ? JSON.parse(props.description) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`)
  );

  const [editorStateGoodDesc, setEditorStateGoodDesc] = useState(() =>
    EditorState.createWithContent(contentGoodsDesc));

  const updatePos = (data) => {
    setPos((pos) => ({ ...pos, ...data }));
  };


  const setGoodDesc = key => event => {
    setPos((pos) => ({
      ...pos,
      ['description']: JSON.stringify(event)
    }));
    props.updateData({
      ...pos
    });
  };
  const updateGoodDescription = async (state) => {
    await setEditorStateGoodDesc(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
  };
  useEffect(() => {

    props.updateData({
      ...pos,
    });
  }, [pos]);
  useEffect(() => {
    if (pos.photo != '') {
      if (pos.photo.split('.').pop() === 'mov' || pos.photo.split('.').pop() === 'mp4') {
        updatePos({ ['photo']: pos.photo, ['file']: { ['title']: pos.goods_name, ["mediafile"]: pos.photo, ["media_type_id"]: 1, ["campaigns"]: [], ['description']: "" } });
      } else {
        updatePos({ ['photo']: pos.photo, ['file']: { ['title']: pos.goods_name, ["mediafile"]: pos.photo, ["media_type_id"]: 2, ["campaigns"]: [], ['description']: "" } });
      }

    }
  }, []);

  const onChangeInput = (input) => (e) => {
    setPos((pos) => ({ ...pos, [input]: e.target.value }));
  };

  const readFile = ({ file, onLoad }) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = ({ target }) => onLoad(target.result);
      reader.readAsDataURL(file);
    }
  };

  const previewImage = () => {
    if (fileRef.current.files && fileRef.current.files[0]) {
      const fileType = fileRef.current.files[0].type;
      if (fileType.startsWith('image')) {
        setMediaType(2);
      }
      if (fileType.startsWith('video')) {
        setMediaType(1);
      }
      if ((fileType.startsWith('image') === false && fileType.startsWith('video') === false) || fileRef.current.files[0].size > 10000000) {
        setAssetFile("");
        setAssetFileDetail({});
        props.addToast({ error: true, date: new Date(), msg: " .GIF, .PNG, .JPG, .MP4, .MOV only with a maximum file size of 10MB" });
      } else {

        readFile({
          file: fileRef.current.files[0],
          onLoad: (result) => {
            // setAssetFile(result);
            setAssetFileDetail(fileRef.current.files[0]);
            upload();
          }
        });
      }
    }
  };

  const upload = () => {
    uploadService
      .upload(
        fileRef.current.files[0],
        false,
        false
      )
      .then(response => {
        if (response.message === 'success') {
          const fileType = fileRef.current.files[0].type;
          setAssetFile(response.thumbail_path);
          if (fileType.startsWith('image')) {
            //updatePos({ ['photo']: response.path.substring(response.path.lastIndexOf("/") + 1), ['file']: { ['title']: pos.goods_name, ["mediafile"]: response.path, ['thumbnail']: response.thumbail_path, ["media_type_id"]: 2 } });
            updatePos({ ['photo']: response.path, ['file']: { ['title']: pos.goods_name, ["mediafile"]: response.path, ['thumbnail']: response.thumbail_path, ["media_type_id"]: 2 } });
            //updatePos([ 'file'  ['title']: pos.goods_name, ['description']: pos.description.blocks[0].text, ["mediafile"]: response.path, ["media_type_id"]: 1, ["file_type"]: response.file_type, ["file_size"]: response.file_size }, 'photo': response.path ])
          }
          if (fileType.startsWith('video')) {
            updatePos({ ['photo']: response.path, ['file']: { ['title']: pos.goods_name, ["mediafile"]: response.path, ['thumbnail']: response.thumbail_path, ["media_type_id"]: 1 } });
            //updatePos({ 'file': { ['title']: pos.goods_name, ['description']: pos.description.blocks[0].text, ["mediafile"]: response.path, ["media_type_id"]: 1, ["file_type"]: response.file_type, ["file_size"]: response.file_size }, 'photo': response.path })
          }
        } else {
          props.addToast({ error: true, date: new Date(), msg: response.message });
        }
      })
      .catch(err => {
        setAssetFile("");
        setAssetFileDetail({});
        props.addToast({ error: true, date: new Date(), msg: err.message });
      });
  };
  return (
    <>
      <div className="good">
        <div className="good__title">
          <h4>Item {props.index + 1}</h4>
          {((props.index + 1 > 1) && (!props.readOnly)) && (
            <button
              className="button tertiary big grey"
              onClick={() => { props.setShowGoods(false); props.deleteFunc(); }}
            >
              {trashIcon} Delete
            </button>
          )}
        </div>
        <div className="good__row">
          <TextInput
            value={props.pos.goods_name}

            type="text"
            onChange={onChangeInput("goods_name")}
            label="Name of goods"
            error={props.error}
            errorText="Please enter name"
            readOnly={props.readOnly}
          />
          <TextInput
            value={props.pos.total}
            type="number"
            onChange={onChangeInput("total")}
            label="Quantity"
            error={props.error}
            errorText="Please enter value"
            readOnly={props.readOnly}
          />
          <TextInput
            value={props.pos.value}
            type="number"
            onChange={onChangeInput("value")}
            label="Monetary value of good"
            error={props.error}
            errorText="Please enter value"
            readOnly={props.readOnly}
          />
        </div>
        <div className="good__desc">
          <div className="label">Description of goods (Max length 1500 Characters)</div>
          <EditorInput
            value={props.pos.description === "" ? props.pos.description : utils.testJSON(props.pos.description) === true ? JSON.parse(props.pos.description).blocks : ""}
            editorState={editorStateGoodDesc}
            onEditorStateChange={updateGoodDescription}
            placeholder="Ender a description of the goods you will be offering."
            error={props.error}
            errorText="Please enter description"
            onChange={setGoodDesc(convertToRaw(editorStateGoodDesc.getCurrentContent()))}
            readOnly={props.readOnly}
          />
        </div>
        <div className="assetsAdd__form-file">
          <h6 className="semi">Upload file/logo</h6>
          <div className="assetsAdd__form-file-inner">
            {/* {assetFile && (
              <div className="assetsAdd__form-preview">
                {assetFileDetail.type === "image/jpeg" && (
                  <div className="assets__media">
                    <img src={process.env.PUBLIC_URL + assetFile} alt="" />
                  </div>
                )}
                {assetFileDetail.type === "application/pdf" && (
                  <div className="assets__media">
                    <img
                      src={process.env.PUBLIC_URL + "/images/pdf.png"}
                      alt=""
                    />
                  </div>
                )}
                {assetFileDetail.type === "video/mp4" && (
                  <AssetVideo asset={assetFile} />
                )}

                <h6 className="sm">{assetFileDetail.name}</h6>
              </div>
            )} */}
            {pos.file && (
              <>
                {pos.file.mediafile !== "" ? (
                  <div
                    className="assetsAdd__form-preview"
                    onClick={() => {
                      props.setModalData(pos.file);
                      props.setModal("assetDetailCamp");
                    }}
                  >
                    {(pos.file.media_type_id == 2 && pos.file.mediafile != '') && (
                      <div className="assets__media">
                        <img
                          src={process.env.PUBLIC_URL + pos.file.mediafile}
                          alt=""
                        />
                      </div>
                    )}

                    {pos.file.media_type_id == 1 && (
                      <div className="assets__media vid">
                        <video
                          src={process.env.PUBLIC_URL + pos.file.mediafile}
                          muted
                        ></video>
                      </div>
                    )}
                    <h6 className="sm">{pos.file.title}</h6>
                  </div>
                ) : (
                    assetFile && (
                      <div className="assetsAdd__form-preview">{pos.file}
                        {props.pos.file.media_type_id === 2 && (
                          <div className="assets__media">
                            <img src={process.env.PUBLIC_URL + props.pos.file.mediafile} alt="" />
                          </div>
                        )}

                        {props.pos.file.media_type_id === 1 && (
                          <AssetVideo asset={props.pos.file.mediafile} />
                        )}

                        <h6 className="sm">{props.pos.file.title}</h6>
                      </div>
                    )
                  )
                }</>)}
            {!props.readOnly &&

              <div
                className={
                  "assetsAdd__form-upload " +
                  (props.error && props.pos.file === "" ? "error" : "")
                }
              >
                <div className="assetsAdd__form-upload-icon">{plusIcon}</div>
                <h5 className="reg">Upload media</h5>
                <p className="sm">
                  .GIF, .PNG, .JPG .MP4, .MOV only with a maximum file size of 10MB
              </p>
                <div className="assetsAdd__form-upload-btns">
                  <button className="button primary">
                    Upload
                  <input
                      title=" "
                      type="file"
                      accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime"
                      ref={fileRef}
                      onChange={previewImage}
                    />
                  </button>
                  {/*} <button
                  className="button secondary"
                  onClick={() => {
                    props.setShowGoods(false);
                    props.setModal("assetGood");
                    props.setGoodIndex();
                  }}
                >
                  Select from assets
                </button>*/}
                </div>
                {props.error && props.pos.file === "" && (
                  <div className="assetsAdd__form-upload-error">
                    Please upload file
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </div >
    </>
  );
}
