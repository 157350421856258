import React from 'react';
import { dots, facebookFilled, instagramfilled } from '../../Components';
import { utils } from '../../Helpers/utils';
export default function OpportunitiesItem(props) {
  return (
    <tr onClick={props.openModal}>
      <td>
        <p>{props.opportunity.brand_name}</p>
      </td>
      <td>{props.opportunity.deadline_date}</td>
      {props.accountCount > 1 && (
        <td>
          <div className="opportunity__table-account">
            {utils.getIconFilled(props.opportunity.handle.icon[0])} <p>{props.opportunity.handle.handle}</p>
          </div>
        </td>
      )}
      <td>
        <p className="hideToolbar">
          <span>
            {props.opportunity.description
              ? utils.draftJsFormatingForDetails({ 'data': props.opportunity.description, 'view': 'column' })
              : 'NA'}
          </span>
        </p>
      </td>
      <td>
        <p>{props.opportunity.budget}</p>
      </td>
    </tr>
  );
}
