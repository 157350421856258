import React, { useState, useEffect } from "react";
import { Toast, Profile, arrowLeft, searchIcon, CampaignInsight } from "../../Components";
import ModalConfirmModalForPaid from "./ModalConfirmModalForPaid";
import { AnimatePresence } from "framer-motion";
import PostVerificationMain from "./PostVerificationMain";
import { ModalInfluencerRate } from "../../Components";
import {

  influencerService,
  productService
} from '../../Services';
import { utils } from "../../Helpers";
const ytdList = [
  { id: "0", value: "Year to date" },
  { id: "1", value: "Past 1 month" },
  { id: "6", value: "Past 6 months" },
  { id: "12", value: "Past 12 months" },
];
const postStatusList = [
  { id: "live", value: "Live" },
  { id: "verified", value: "Verified" },
  { id: "paid", value: "Paid" },
];
export default function PostVerification({ setAuth, NotifModul, profiles, user, setModalNotification, locationData }) {
  const [tab, setTab] = useState("main");
  const [refrenceNumber, setRefrenceNumber] = useState("");
  const [isloaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({});
  const [infEngList, setInfEngList] = useState(null);
  const [modal, setModal] = useState('close');
  const [postStatus, setPostStatus] = useState('live');
  const [loadData, setLoadData] = useState();
  const [postStatusListColumn, setPostStatusListColumn] = useState([]);
  const [refreshlist, setRefreshlist] = useState(false);
  const [newStatus, setNewStatus] = useState();
  const [loadList, setLoadList] = useState(false);
  const [error, setError] = useState(false);
  const [modalData, setModalData] = useState();

  // eslint-disable-next-line
  const [ytd, setYtd] = useState(0);
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  useEffect(() => {
    setTab("main");
    setPage(1);
    setPostStatus("live")
  }, [locationData.key]);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoadList(false);
        //setInfEngList([]);
        const res = await influencerService.getPostWithStatus({
          page: page,
          pageSize,
        }, postStatus);
        if (res.meta && res.meta.pagination) {
          setPaginationData(res.meta.pagination);
        }
        setInfEngList(res.data);
        let dropdownValue = [];
        if (postStatus == 'live') {
          postStatusList.map(item => {
            if (item.id !== 'paid') { dropdownValue.push(item); }
          })
        }
        if (postStatus == 'verified') {
          postStatusList.map(item => {
            dropdownValue.push(item);
          })
        }
        setPostStatusListColumn(dropdownValue);
        setIsLoaded(true);
        setLoadList(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, postStatus, refreshlist]);


  const onPaginate = page => {
    setPage(page);
  };
  const changePostStatus = (item) => {
    setPostStatus(item.id);
    setPage(1);
    setNewStatus();
    setLoadData();
  };
  const OnChangeDropdown = (item, index, status) => {
    setNewStatus({ status: status, index: index });
    setLoadData(item)
    if (status === 'paid') {
      setRefrenceNumber("");
      setError(false);
      setModal("confirm");
    } else {
      changeStatusOfPost(item, status);
    }
  };

  async function changeStatusOfPost(item, newStatus) {
    try {
      if (newStatus == 'paid') {
        if (refrenceNumber === "") {
          setError(true);
        } else {
          updateStatus(newStatus, item);
        }
      } else {
        updateStatus(newStatus, item);
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  async function updateStatus(status, item) {
    try {
      let payload;
      if (status == 'paid') {
        payload = {
          "engagement_id": item.engagement_id,
          "status": status,
          "payment_id": refrenceNumber,
          "id": item.id,
        }
      } else {
        payload = {
          "engagement_id": item.engagement_id,
          "status": status,
          "id": item.id,
        }
      }
      const res = await influencerService.updatePostStatus(payload, item.influencer_id);
      addToast({
        error: false,
        msg: res.message,
        id: `${Date.now()}-${toastList.length}`
      });
      setModal("null");
      setRefreshlist(!refreshlist);
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const setData = (data) => {
    async function fetchData() {
      try {
        const res = await productService.getInfluencerProductData(data);
        setModalData(res);
        setModal("showRate")
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  };
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);
  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">

            <div className="main__inner-title-col">
              <h2>Post Verification List</h2>
            </div>
            <Profile
              setAuth={setAuth}
              setModal={setModal}
              NotifModul={NotifModul}
              profiles={profiles}
              setNotifModul={setModalNotification}
            />
          </div>

          {(tab === "main" && isloaded) && (
            <PostVerificationMain
              infEngList={infEngList}
              changePostStatus={changePostStatus}
              postStatus={postStatus}
              paginationData={paginationData}
              onPaginate={onPaginate}
              setModal={setModal}
              modal={modal}
              setLoadData={setLoadData}
              OnChangeDropdown={OnChangeDropdown}
              postStatusListColumn={postStatusListColumn}
              postStatusList={postStatusList}
              setData={setData}
              newStatus={newStatus}
              loadData={loadData}
              loadList={loadList}
            />
          )}



        </div>
      </div>
      {toastList && (
        <Toast type="rate" list={toastList} setList={setToastList} />
      )}
      <AnimatePresence>
        {modal === "confirm" && (
          <ModalConfirmModalForPaid
            changeStatusOfPost={changeStatusOfPost}
            setRefrenceNumber={setRefrenceNumber}
            refrenceNumber={refrenceNumber}
            close={() => { setModal("close"); setLoadData(); setNewStatus(); }}
            error={error}
            newStatus={newStatus}
            loadData={loadData}
          />
        )}
        {modal === "showRate" && (
          <ModalInfluencerRate
            ModalData={modalData}
            setModal={setModal}
            close={() => {
              setModal(null);
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
}
