import React from "react";
import 'chart.js/auto';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChart(props) {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          title: () => null,
          label: function (tooltipItem) {
            console.log(tooltipItem)
            if (props.percentage) {
              let sum = 0;
              let dataArr = tooltipItem.dataset.data;
              dataArr.map((data) => {
                sum += Number(data);
              });
              let percentage =
                ((tooltipItem.parsed.y * 100) / sum).toFixed(2) + "%";
              return `${percentage}`;
            } else {
              var tooltipText = "";
              console.log(tooltipItem)
              if (tooltipItem.dataset.data[tooltipItem.dataIndex] != null)
                tooltipText = tooltipItem.dataset.data[tooltipItem.dataIndex].toString();
              return tooltipItem.label + " - " + tooltipText;
            }
          },
          labelTextColor: function (context) {
            return "red";
          },
        },
        displayColors: false,
        backgroundColor: "#FAFFFA",
        borderColor: "rgba(16, 38, 32, 0.15)",
        borderWidth: 1,
        yAlign: "bottom",
      },
    },
    elements: {
      point: {
        borderWidth: 0,
        radius: 10,
      },
      line: {
        tension: 0.5,
      },
    },
    scales: {
      y: {

        ticks: {
          callback: (label) => {
            if (label >= 1000) {
              return `${label / 1000}K`;
            } else {
              return label;
            }
          },

          font: { size: 6, family: "Inter" },
          color: "#000D57",
        },
        grid: {
          // drawTicks: false,
          color: "#E9F1E7",
          // offset: true,
        },
        beginAtZero: true,
      },
      x: {
        ticks: {
          font: { size: 6, family: "Inter" },
          color: "#000D57",
        },
        grid: {
          // drawTicks: false,
          color: "#E9F1E7",
          // offset: true,
        },
        beginAtZero: true,
      },
    },
  };

  const labels = props.labels;

  const data = {
    labels,

    datasets: [
      {
        type: "line",
        data: props.data,
        borderColor: "#08A708",
        borderWidth: 1,
        pointBackgroundColor: "rgba(0,0,0,0)",
        pointBorderColor: "rgba(0,0,0,0)",

      },
    ],
  };
  return <Bar options={options} data={data} />;
}
