import React, { useState, useEffect } from "react";
import { SearchInput, SelectInput } from "../../../../Components";

import {
  plusIcon,
  searchIcon,
  viewGrid,
  viewList,
} from "../../../../Components";
import AssetsTableItem from "./AssetsTableItem";
import { AssetsModul } from "./AssetsModul";
import { Pagination } from "../../../../Components";
import AssetsGridItem from "./AssetsGridItem";

const selectDescList = [
  { id: "1", value: "All assets" },
  { id: "2", value: "Title" },
  { id: "3", value: "Description" },
  { id: "4", value: "Upload date" },
  { id: "5", value: "Times used" },
];
export default function AssetsMain({
  setModal,
  setModalData,
  form,
  updateForm,
  modal,
  goodIndex,
  assetList,
  onPaginate,
  paginationData,
  selectedItems,
  setSelectedItems
}) {
  const [items, setItems] = useState([]);
  const [view, setView] = useState(
    modal === "assetGrid" || modal === "assetGridGood" ? false : true
  );
  const [allChecked, setAllChecked] = useState(false);
  const [checkedSolo, setCheckedSolo] = useState(false);
  const [selectDesc, setselectDesc] = useState(selectDescList[0].value);
  const { selectedContent, selectedProduct, selectedInfluencer } =
    selectedItems;
  const changeMediumDesc = (item) => {
    setselectDesc(item.value);
  };
  const attachSelected = () => {
    if (modal === "asset" || modal === "assetGrid") {
      // let newSelectedContent = [];
      let arr = [];
      let assestsArray = [...form.assets];
      items.map(item => {
        if (assestsArray.filter(itemarray => itemarray.id == item.id).length > 0) {
          //remove id.
          // newSelectedContent = selectedContent.filter((selitem) => selitem !== item.id);
          // newSelectedContent.push(selectedContent.filter((selitem) => selitem !== item.id));

        } else {
          //push id.
          //newSelectedContent.push(...selectedContent, item.id);
          arr.push(item);
        }

      })
      //let arrNew = [...form.assets, ...arr];
      //if (arr.length > 0) {
      let arrNew = [...form.assets, ...arr];
      updateForm({ assets: arrNew });
      //}
      // setSelectedItems({ ...selectedItems, selectedContent: [...selectedContent, ...newSelectedContent] });
      // updateForm({ assets: arr });

      setModal(null);
    } else if (modal === "assetGood" || modal === "assetGridGood") {
      let arr = [...form.goods];
      arr[goodIndex] = { ...arr[goodIndex], file: items[0], photo: items[0].mediafile };
      updateForm({ ['goods']: arr });
      setModal(null);
    }
  };


  return (
    <div className="assets">
      <div className="assets__table-title-outer">
        <h3 className="big">Assets</h3>
        <div className="assets__table-title">
          <div className="assets__table-title-info">
            <h4>Details</h4>
            <button
              className="button tertiary big"
              onClick={() => setModal("addAsset")}
            >
              Add asset {plusIcon}
            </button>
          </div>
          <div
            className={
              "assets__table-title-inner " +
              (!view && modal !== "assetGood" && modal !== "assetGridGood"
                ? "grid"
                : "")
            }
          >
            {!view && modal !== "assetGood" && modal !== "assetGridGood" && (
              <div className="checkbox">
                <input
                  checked={allChecked}
                  onChange={(e) => {
                    setAllChecked(e.target.checked);
                    if (e.target.checked) {
                      setItems([...assetList]);
                    } else {
                      setItems([]);
                    }
                  }}
                  type="checkbox"
                />
                <label htmlFor=""></label>
              </div>
            )}
            <button
              type="button"
              className="assets__table-title-view"
              onClick={() => setView(!view)}
            >
              {view ? viewGrid : viewList}
            </button>
            {/*<SelectInput
              list={selectDescList}
              selected={selectDescList[0]}
              onChange={changeMediumDesc}
            />
                <SearchInput type="text" icon={searchIcon} placeholder="Search" />*/}
          </div>
        </div>
      </div>
      {view ? (
        <>
          <div className="assets__table">
            <table>
              <thead>
                <tr>
                  <th>
                    {modal !== "assetGood" && (
                      <div className="checkbox">
                        <input
                          checked={allChecked}
                          onChange={(e) => {
                            setAllChecked(e.target.checked);
                            if (e.target.checked) {
                              setItems([...assetList]);
                            } else {
                              setItems([]);
                            }
                          }}
                          type="checkbox"
                        />
                        <label htmlFor=""></label>
                      </div>
                    )}
                  </th>
                  <th></th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Upload date</th>
                  <th>Times used</th>
                </tr>
              </thead>
              <tbody>
                {assetList.map((item, index) => {
                  return (
                    <AssetsTableItem
                      onClick={() => {
                        if (modal === "assetGood") {
                          setModal("assetDetailGood");
                        } else {
                          setModal("assetDetail");
                        }
                        setModalData(item);
                      }}
                      setCheckedSolo={setCheckedSolo}
                      checkedSolo={checkedSolo}
                      modal={modal}
                      items={items}
                      setItems={setItems}
                      itemData={item}
                      allChecked={allChecked}
                      key={index}
                      index={index}
                      {...item}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
          <div className="assets__items">
            {assetList.map((item, index) => {
              return (
                <AssetsGridItem
                  onClick={() => {
                    if (modal === "assetGood") {
                      setModal("assetDetailGridGood");
                    } else if (modal === "assetGridGood") {
                      setModal("assetDetailGridGood");
                    } else {
                      setModal("assetDetailGrid");
                    }
                    setModalData(item);
                  }}
                  items={items}
                  setItems={setItems}
                  allChecked={allChecked}
                  key={index}
                  itemData={item}
                  {...item}
                  index={index}
                  modal={modal}
                  setCheckedSolo={setCheckedSolo}
                  checkedSolo={checkedSolo}
                />
              );
            })}
          </div>
        )}
      <div className="assets__table-footer">
        <button
          className="button primary"
          disabled={items.length > 0 ? false : true}
          onClick={attachSelected}
        >
          Attach selected assets
        </button>
        <Pagination
          perPage={paginationData['per_page']}
          total={paginationData['total']}
          currentPage={paginationData['current_page']}
          totalPages={paginationData['total_pages']}
          onPaginate={onPaginate}
        />
      </div>
    </div>
  );
}
