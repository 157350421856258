import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import ModalCampAsset from "../Modals/Assets/ModalCampAsset";
import ModalAssetDetail from "../Modals/AssetsDetail/ModalAssetDetail";
import ModalAssetFull from "../Modals/AssetsDetail/ModalAssetFull";
import ModalCampDelete from "../Modals/ModalCampDelete";
import ModalCampPublish from "../Modals/ModalCampPublish";
import Assets from "./Assets";
import Compensation from "./Compensation";
import { Details, CampDetails, Products } from "../../../Components";
import ModalConfirmExit from "../Modals/ModalConfirmExit";

import { utils } from '../../../Helpers/utils';
import { convertFromRaw } from "draft-js";
import { format } from 'date-fns';
import {
  contentService,
  campaignService,
  influencerService
} from '../../../Services';

const initForm = {
  title: '',
  description: "",
  deliverable: "",
  kpi: "",
  hashtags: [],
  brand_logo: '',
  campaigns_type_id: 1,
  budget: '',
  compensationDetails: '',
  start_date: '',
  end_date: '',
  status: 'draft',
  reach: 0,
  interaction: 0,
  goods: [{
    goods_name: "",
    total: "",
    value: "",
    photo: "",
    description: "",
    file: { mediafile: '', media_type_id: 2 }
  }],

  assets: [],
  category: [],
};

export default function CampaignsCreate({ setSaveCampaign, saveCampaign, setTab, setLoadList, loadList, addToast, campaignId, mode, user, recommendEnteredData, setRedirectToBilling }) {
  const [assetIndex, setAssetIndex] = useState(null);
  const [goodIndex, setGoodIndex] = useState(null);
  const [modal, setModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [error, setError] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [assetList, setAssetList] = useState([]);
  const [allAssetList, setAllAssetList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [toastList, setToastList] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLoadedCampaign, setIsLoadedCampaign] = React.useState(false);
  const [form, setForm] = useState(initForm);
  const [isConfirmPublish, setIsConfirmPublish] = useState(false);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [categoriesData, setCategoriesData] = useState({});
  const [isAssetUploaded, setIsAssetUploaded] = useState([]);
  const [recommendationId, setRecommendationId] = useState(null);
  useEffect(() => {
    if (saveCampaign === true) {
      validateDraftCampaign();
      setSaveCampaign(false);
      updateForm({ ['status']: "draft" });
    }
  }, [saveCampaign]);
  useEffect(() => {
    async function fetchCategoriesData() {
      try {
        const res = await influencerService.getCategoriesDetails();
        let newCountry = [];
        Object.entries(res.countries).map((country) => { (newCountry.push(country[1])) })
        setCategoriesData(res);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchCategoriesData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      const filterString = `filter[asset.status]=active`;

      try {
        const res = await contentService.getAll({
          page: page,
          pageSize,
          filter: filterString
        });
        if (res.meta && res.meta.pagination) {
          setPaginationData(res.meta.pagination);
        }
        const resAll = await contentService.getAll({
          filter: filterString
        });
        setAllAssetList(resAll.data)
        setAssetList(res.data);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, isAssetUploaded]);

  useEffect(() => {
    async function getCampaignData() {
      try {
        const res = await campaignService.getById(campaignId);
        res['data']['start_date'] = res['data']['start_date'] !== "" ? new Date(res['data']['start_date'].replace(/-/g, '/')) : '';
        res['data']['end_date'] = res['data']['end_date'] !== "" ? new Date(res['data']['end_date'].replace(/-/g, '/')) : '';
        res['data']['hashtags'] = res['data']['hashtag'] != null ? res['data']['hashtag'].split(",") : [];
        res['data']['category'] = res['data']['category'] != null ? res['data']['category'] : [];

        let assetContent = res['data']['contents'];
        var assetsArray = allAssetList.filter(function (e) {
          return assetContent.indexOf(e.id) > -1;
        });

        res['data']['assets'] = assetsArray;
        res['data']['budget'] = res['data']['amount'];
        setForm(res.data);
        setIsLoadedCampaign(true);
        setSelectedItems({
          selectedContent: res['data']['contents'],
          selectedProduct: res['data']['products'] || [],
          selectedInfluencer: res['data']['influencers']
        });

      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    if (mode === "edit") {
      getCampaignData();
    } else {
      setIsLoadedCampaign(true);
    }
  }, [isLoaded]);
  const onPaginate = page => {
    setPage(page);
  };
  const [selectedItems, setSelectedItems] = useState({
    selectedContent: [],
    selectedInfluencer: [],
    selectedProduct: []
  });
  useEffect(() => {
    async function fetchData() {
      if (recommendEnteredData !== undefined && JSON.stringify(recommendEnteredData) !== '{}') {

        const res = await influencerService.getRecommendationById(recommendEnteredData[0]['recommendedId']);
        setRecommendationId(recommendEnteredData[0]['recommendedId'])
        updateForm({ ['category']: res.data['category'] != "" ? res.data['category'].split(",") : [], ['interaction']: (res.data['interaction']), ['reach']: (res.data['reach']), ['budget']: parseInt(res.data['budget']) });

        let newProductArray = [];
        if (res.data['fb_product_id'] != "") {
          res.data['fb_product_id'].split(",").map(item => newProductArray.push(parseInt(item)));
        }
        if (res.data['insta_product_id'] != "") {
          res.data['insta_product_id'].split(",").map(item => newProductArray.push(parseInt(item)));
        }
        if (res.data['tiktok_product_id'] != "") {
          res.data['tiktok_product_id'].split(",").map(item => newProductArray.push(parseInt(item)));
        }
        if (res.data['youtube_product_id'] != "") {
          res.data['youtube_product_id'].split(",").map(item => newProductArray.push(parseInt(item)));
        }

        setSelectedItems({ selectedProduct: newProductArray || [] });
      }
    }

    fetchData();
    setIsProductSelected(true);
  }, [])
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });

  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);
  useEffect(() => {
    setFormSubmit(formSubmit)
    if (formSubmit === true) {
      if (form.status === 'draft') {
        if (mode === "edit") {
          updateCampaign();
        } else {
          createCampaign();
        }
      } else {
        setModal("campPublish");
      }
    }
  }, [formSubmit]);
  const deletAsset = (index) => {
    let arr = [...form.assets];
    arr.splice(index, 1);
    updateForm({ assets: arr });
  };
  const chkEditorVal = (item) => {
    let editorError = false;
    if (item === "") {
      return editorError = true;
    } else {

      let isValidJson = utils.testJSON(item);
      if (isValidJson) {
        if (JSON.parse(item).blocks[0].text === "") {
          return editorError = true;
        } else {
          return false;
        }
      }
    }
  }
  const validateDraftCampaign = () => {

    let isFieldsEmpty = false;
    let lengthError = false;
    if (form.title === "") { isFieldsEmpty = true; }
    let msgError = "";

    if (form.title.length > 60) {
      lengthError = true;
    }
    if (isFieldsEmpty === true || lengthError === true) {
      if (isFieldsEmpty === true) {
        setFormSubmit(false);
        setErrorName(true);
        msgError = "Please enter campaign name to add/edit campaign.";
      } else {
        if (lengthError === true) {
          if (form.title.length > 60) {
            msgError = "Title length of title is greater then 60 character.";
          }
        }
        setError(true);
        setErrorName(false);
      }
      setRedirectToBilling(false);
      addToast({ error: true, date: new Date(), msg: msgError });
    } else {
      setErrorName(false);
      setFormSubmit(true);
    }
  }
  const validateCampaign = () => {

    let isFieldsEmpty = false;
    let budgetValidation = false;
    let goodsValidation = [];
    let lengthError = false;
    if (form.campaigns_type_id === 1 || form.campaigns_type_id == 3) {
      budgetValidation = (form.budget === "" || form.budget < 500);
    }
    if (form.campaigns_type_id === 2 || form.campaigns_type_id == 3) {
      form.goods.map((item, index) => {
        let chkEditorError = chkEditorVal(item.description);
        if (item.goods_name === "" || chkEditorError === true || item.value < 1 || item.total === "" || item.value < 1 || item.photo === "") {
          let errorField = "";
          if (item.goods_name === "") {
            errorField = "Name"
          } if (item.total < 1) {
            errorField += errorField === "" ? " Quantity" : ", Quantity";
          } if (item.value < 1) {
            errorField += errorField === "" ? " Monetary value" : ", Monetary value";
          } if (item.photo === "") {
            errorField += errorField === "" ? " Goods Image" : ", Goods Image";
          } if (chkEditorError === true) {
            errorField += errorField === "" ? " Description" : ", Description";
          }
          goodsValidation.push("Please enter " + errorField + " for 'Item  " + (index + 1) + "'");
        }
      })
    }
    const dt1 = new Date(form.start_date);
    const dt2 = new Date(form.end_date);
    const dt3 = new Date();
    let findInStartDate = Math.floor(
      (Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) -
        Date.UTC(dt3.getFullYear(), dt3.getMonth(), dt3.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    let findDiffInDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    if (
      form.title === "" ||
      chkEditorVal(form.description) === true ||
      chkEditorVal(form.deliverable) === true ||
      form.start_date === "" ||
      ((form.interaction === "" || form.interaction == null || form.interaction == 0) && (form.reach === "" || form.reach == null || form.reach == 0))

      ||
      form.end_date === ""
    ) { isFieldsEmpty = true; }
    let msgError = "";
    const {
      selectedContent,
      selectedProduct,
      selectedInfluencer
    } = selectedItems;
    let productError = selectedProduct.length === 0 ? true : false;
    let lengthOfDescription = isFieldsEmpty === false && form.description !== null ? (convertFromRaw(JSON.parse(form.description)).getPlainText().length) : 0;
    let lengthOfDeliverable = isFieldsEmpty === false && form.deliverable !== null ? (convertFromRaw(JSON.parse(form.deliverable)).getPlainText().length) : 0;
    //let lengthOfKpi = isFieldsEmpty === false ? (convertFromRaw(JSON.parse(form.kpi)).getPlainText().length) : 0;
    if (lengthOfDescription > 1500 || lengthOfDeliverable > 1500 || form.title.length > 60) {
      lengthError = true;
    }
    if (productError === true || isFieldsEmpty === true || budgetValidation === true || goodsValidation.length > 0 || findDiffInDays < 0 || findInStartDate < 7 || lengthError === true) {

      if (isFieldsEmpty === true) {
        setFormSubmit(false);
        setError(true);
        msgError = "Please enter all required field to add/edit campaign.";
      } else {
        if (lengthError === true) {
          if (form.title.length > 60) {
            msgError = "Title length of title is greater then 60 character.";
          } else if (lengthOfDescription > 1500) {
            msgError = "Description content length is greater then 1500.";
          } else if (lengthOfDeliverable > 1500) {
            msgError = "Deliverables content length is greater then 1500.";
          }
        } else if (productError === true) {
          msgError = "Select product for campaign.";
        } else if (budgetValidation === true) {
          msgError = "Minimum budget of 500 needed.";
        } else if (findInStartDate < 7) {
          msgError = "The start date must be at least 7 days after today's date.";
        } else if (findDiffInDays < 0) {
          msgError = "The start date must be a date before end date.";
        } else if (goodsValidation.length > 0) {
          msgError = goodsValidation[0];
        }
        setError(true);
        setFormSubmit(false);
      }

      setRedirectToBilling(false);
      addToast({ error: true, date: new Date(), msg: msgError });
    } else {
      setError(false);
      setFormSubmit(true);
    }
  }
  const createCampaign = async () => {
    const {
      selectedContent,
      selectedProduct,
      selectedInfluencer
    } = selectedItems;
    form.assets.map(item => { selectedContent.push(item.id) });
    const data = Object.assign(
      {},
      {
        ...form,
        start_date: form.start_date ? format(new Date(form.start_date), 'yyyy-MM-dd') : '',
        end_date: form.end_date ? format(new Date(form.end_date), 'yyyy-MM-dd') : '',
        proposal_id: "",
        contents: selectedContent,
        influencers: selectedInfluencer,
        products: selectedProduct,
        kpi: form.kpi,
        description: form.description,
        deliverable: form.deliverable,
        status: form.status,
        hashtag: form.hashtags.toString(),
        reach: (form.reach),
        interactions: (form.interactions),
        budget: parseInt(form.budget),
        recommendation_id: recommendationId !== undefined ? recommendationId : null
      }
    );
    try {

      const res = await campaignService.create(data);
      setModal(null);
      addToast({ error: false, date: new Date(), msg: res.message });
      setTab("main");
      setLoadList(!loadList);

    } catch (err) {
      setModal(null);
      addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
      setFormSubmit(false);
    }
  }
  const onDelete = async () => {
    if (campaignId) {
      try {
        form.tags = form.hashtags.toString();
        const res = await campaignService.delete(campaignId);
        addToast({ error: false, date: new Date(), msg: res.message });
        setModal(null);
        setTab("main");
        setLoadList(!loadList);
      } catch (err) {
        addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
        setModal(null);
        setTab("main");
        setLoadList(!loadList);
      }
    } else {
      setModal(null);
      setTab("main");
      setLoadList(!loadList);
    }
  };
  const updateCampaign = async () => {
    const {
      selectedContent,
      selectedProduct,
      selectedInfluencer
    } = selectedItems;

    let assetsArray = [];
    form.assets.map(item => { assetsArray.push(item.id) });
    let payload = {
      ...form,
      start_date: form.start_date !== "" ? format(new Date(form.start_date), 'yyyy-MM-dd') : '',
      end_date: form.end_date !== "" ? format(new Date(form.end_date), 'yyyy-MM-dd') : '',
      contents: assetsArray,
      influencers: selectedInfluencer,
      products: selectedProduct,
      kpi: form.kpi,
      description: form.description,
      deliverable: form.deliverable,
      status: form.status,
      hashtag: form.hashtags.toString(),
      reach: (form.reach),
      interactions: (form.interactions),
    };
    try {
      form.tags = form.hashtags.toString();
      const res = await campaignService.update(campaignId, payload);
      setModal(null);
      addToast({ error: false, date: new Date(), msg: res.message });
      setTab("main");
      setLoadList(!loadList);
    } catch (err) {
      setModal(null);
      addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
      setFormSubmit(false);
    }
  }

  const publishFunc = () => {
    validateCampaign();
    updateForm({ ['status']: "active" });
  };

  const saveAsDraftFunc = () => {
    updateForm({ ['status']: "draft" });
    validateDraftCampaign();
  };
  return (
    <>
      {(isLoadedCampaign && isProductSelected) && (<>
        <div className="campaignsCreate">
          {categoriesData.categories &&
            <Details
              error={error} errorName={errorName} form={form} setInput={setInput} updateForm={updateForm} categoriesData={categoriesData.categories}
              categories={categoriesData.categories.map(
                cat => cat.category
              )} />
          }
          <Products selectedItems={selectedItems} setSelectedItems={setSelectedItems}
            form={form} setInput={setInput} updateForm={updateForm} toastList={toastList} addToast={addToast} />
          {isLoaded && (
            <CampDetails
              error={error} form={form} setInput={setInput} updateForm={updateForm}
            />
          )}
          {isLoaded && (
            <Compensation
              form={form}
              updateForm={updateForm}
              setModal={setModal}
              setGoodIndex={setGoodIndex}
              setModalData={setModalData}
              error={error}
              categoriesData={categoriesData}
            />
          )}
          {isLoaded && (
            <Assets
              form={form}
              updateForm={updateForm}
              selectedItems={selectedItems}
              setModal={setModal}
              setModalData={setModalData}
              setAssetIndex={setAssetIndex}
              setSelectedItems={setSelectedItems}
            />
          )}
          <div className="campaignsCreate__btns">
            <div className="campaignsCreate__btns-row">
              <button className="button secondary" onClick={() => { setFormSubmit(false); saveAsDraftFunc(); }}>
                Save as Draft
              </button>
              <button
                className="button secondary"
                onClick={() => setModal("campDelete")}
              >
                Delete
              </button>
            </div>
            <button className="button primary" onClick={() => { setFormSubmit(false); publishFunc(); }}>
              Publish campaign
            </button>
          </div>
        </div>
        <AnimatePresence>
          {(modal === "asset" ||
            modal === "assetGood" ||
            modal === "assetGrid" ||
            modal === "assetGridGood" ||
            modal === "addAsset" ||
            modal === "addAssetCamp") && (
              <ModalCampAsset
                close={() => setModal(null)}
                modal={modal}
                setModal={setModal}
                setModalData={setModalData}
                form={form}
                updateForm={updateForm}
                goodIndex={goodIndex}
                paginationData={paginationData}
                onPaginate={onPaginate}
                assetList={assetList}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                addToast={addToast}
                setIsAssetUploaded={setIsAssetUploaded}
                isAssetUploaded={isAssetUploaded}
                user={user}
              />
            )}
          {(modal === "assetDetail" ||
            modal === "assetDetailGrid" ||
            modal === "assetDetailGood" ||
            modal === "assetDetailGridGood" ||
            modal === "assetDetailCamp") && (
              <ModalAssetDetail
                close={() => setModal(null)}
                {...modalData}
                modal={modal}
                setModal={setModal}
                mediaClick={() => {
                  if (modal === "assetDetail") {
                    setModal("assetFull");
                  } else if (modal === "assetDetailGrid") {
                    setModal("assetFullGrid");
                  } else if (modal === "assetDetailGood") {
                    setModal("assetFullCamp");
                  } else if (modal === "assetDetailGridGood") {
                    setModal("assetFullCampGrid");
                  } else if (modal === "assetDetailCamp") {
                    setModal("assetFullCamp");
                  }
                }}
                backClick={() => {
                  if (modal === "assetDetail") {
                    setModal("asset");
                  } else if (modal === "assetDetailGrid") {
                    setModal("assetGrid");
                  } else if (modal === "assetDetailGood") {
                    setModal("assetGood");
                  } else if (modal === "assetDetailGridGood") {
                    setModal("assetGridGood");
                  }
                }}
                delBtnClick={() => deletAsset(assetIndex)}
              />
            )}
          {(modal === "assetFull" ||
            modal === "assetFullCamp" ||
            modal === "assetFullGrid" ||
            modal === "assetFullGridCamp") && (
              <ModalAssetFull
                close={() => setModal(null)}
                {...modalData}
                backClick={() => {
                  if (modal === "assetFull") {
                    setModal("assetDetail");
                  } else if (modal === "assetFullCamp") {
                    setModal("assetDetailCamp");
                  } else if (modal === "assetFullGrid") {
                    setModal("assetDetailGrid");
                  } else if (modal === "assetFullGridGood") {
                    setModal("assetDetailGridGood");
                  }
                }}
              />
            )}
          {modal === "campDelete" && (
            <ModalCampDelete
              close={() => setModal(null)}
              delBtnClick={() => {

                onDelete()
              }}
            />
          )}
          {modal === "campPublish" && (
            <ModalCampPublish
              close={() => { setFormSubmit(false); setModal(null) }}
              publishBtnClick={() => {
                setFormSubmit(true);
                if (mode === "edit") {
                  updateCampaign();
                } else {
                  createCampaign();
                }

              }
              }
            />
          )}
          {modal === "confirmExit" && (
            <ModalConfirmExit
              state="campaign"
              close={() => {
                setModal(null);
                setRedirectToBilling(false);
              }}
              onSaveCamp={() => { setModal(null); setFormSubmit(false); setRedirectToBilling(true); saveAsDraftFunc(); }}
            />
          )}
        </AnimatePresence> </>
      )}
    </>
  );
}
