import React from 'react';
import { chevronLeft, chevronRight } from './SVG';

export default function Pagination(props) {
  const onPaginate = page => {
    props.onPaginate(page);
  };
  return (
    <div className='pagination'>
      <p>{`Viewing ${
        props.currentPage === 1
          ? 1
          : (props.currentPage - 1) * props.perPage + 1
      }-${
        props.total > props.currentPage * props.perPage
          ? props.currentPage * props.perPage
          : props.total
      } of ${props.total}`}</p>
      <ul>
        <li className='prev'>
          <button
            className={props.currentPage < 2 ? 'disabled' : ''}
            onClick={() =>
              onPaginate(props.currentPage === 1 ? 1 : props.currentPage - 1)
            }
          >
            {chevronLeft}
          </button>
        </li>
        {[...Array(props.totalPages).keys()].map(i => {
          let className =
            props.currentPage === i + 1 ? 'number selected' : 'number';
          return (
            <li key={i}>
              <button className={className} onClick={() => onPaginate(i + 1)}>
                {i + 1}
              </button>
            </li>
          );
        })}
        <li className='next'>
          <button
            className={props.currentPage === props.totalPages ? 'disabled' : ''}
            onClick={() =>
              onPaginate(
                props.currentPage === props.totalPages
                  ? props.currentPage
                  : props.currentPage + 1
              )
            }
          >
            {chevronRight}
          </button>
        </li>
      </ul>
    </div>
  );
}
