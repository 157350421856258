import React from "react";
import { utils } from '../../Helpers/utils';
export default function DashboardItem(props) {
  return (
    <tr>
      <td>
        <p>{props.brand_name}</p>
      </td>
      <td>{props.payment_date}</td>
      <td>
        {props.accountCount > 1 ? (
          <div className="dashboard__table-account">
            {props.handle.icon && utils.getIconFilled(props.handle.icon[0])}
            {props.handle && <p>{props.handle.handle}</p>}
          </div>
        ) : (
          <p>{props.description && utils.draftJsFormatingForDetails({ 'data': props.description, 'view': 'column' })}</p>
        )}
      </td>
      <td>{props.budget}</td>
    </tr>
  );
}
