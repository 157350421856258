import React, { useEffect, useRef, useState } from 'react';
import {
  SearchInput,
  SelectInput,
  Pagination,
  Profile,
  tickIconGreen,
  download,
  searchIcon,
  YearToDate,
  Toast
} from '../../Components';
import { useSearchParams } from 'react-router-dom'
import PaymentItem from './PaymentItem';
import { influencerService } from '../../Services';
import { utils } from '../../Helpers';
const ytdList = [
  { id: 0, value: 'Year to date' },
  { id: 1, value: 'Past 1 month' },
  { id: 6, value: 'Past 6 months' },
  { id: 12, value: 'Past 12 months' }
];
const selectDescList = [
  { id: 'firstName', value: 'Brand' },
  { id: 'cp.title', value: 'Campaign' },
  { id: 'campaigns_type', value: 'Type' },
  { id: 'amount', value: 'Compensation' }
];

export default function Payment(props) {
  const payPalRef = useRef(null);
  const stripeRef = useRef(null);
  // eslint-disable-next-line
  const [withData, setWithData] = useState(true);
  const [defaultPayment, setDefaultPayment] = useState(null);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [paymentSummary, setPaymentSummary] = useState([]);
  const [payeeDetails, setPayeeDetails] = useState({});
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState(0);
  const [paypal, setPaypal] = useState(false);
  const [stripe, setStripe] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [selectDesc, setselectDesc] = useState(selectDescList[0]);
  const [searchPaymentHistoryText, setSearchPaymentHistoryText] = useState('');
  const [paginationData, setPaginationData] = useState({});
  const [searchList, setSearchList] = useState(false);
  const [summary, setSummary] = useState(true);
  const [accountCount, setAccountCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false)
  const changeMedium = item => {
    setDateRange(item.id);
    setPage(1);
    setSummary(true);
    setSearchPaymentHistoryText('');
    setselectDesc(selectDescList[0]);
  };
  const changeMediumDesc = item => {
    setselectDesc(item);
    setPage(1);
    setSearchPaymentHistoryText('');
    setSearchList(!searchList);
  };
  const searchPaymentHistory = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchPaymentHistoryText(event.target.value);
  };
  const callPayPalLogin = async () => {
    try {
      const res = await influencerService.connectPayPal();
      window.location.replace(res.url);
    } catch (error) {
      addToast({
        error: true,
        msg: error,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);
    setSummary(false);
  };
  const onPaginate = page => {
    setPage(page);
    setSummary(false);
  };
  useEffect(() => {
    if (defaultPayment === 'paypal' && stripe) {
      stripeRef.current.checked = false;
    }
    if (defaultPayment === 'stripe' && paypal) {
      payPalRef.current.checked = false;
    }
    // eslint-disable-next-line
  }, [defaultPayment]);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        if (searchPaymentHistoryText != "") {
          search();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchPaymentHistoryText]);
  useEffect(() => {

    let paypalError = searchParams.get('error') !== null ? searchParams.get('error') == 1 ? "We're having trouble connecting to your payment account. Please try again later." : '' : '';

    if (paypalError !== '') {
      addToast({
        error: true,
        msg: paypalError,
        id: `${Date.now()}-${toastList.length}`
      });
    }

    async function fetchPaymentData() {
      try {
        let options = {
          page: page,
          pageSize: 10,
          range: dateRange
        };

        if (searchPaymentHistoryText !== '') {
          options['search'] = {
            key: selectDesc.id,
            value: searchPaymentHistoryText
          };
        }
        const res = await influencerService.getBillingHistory(options);
        setAccountCount(res.account_count ? res.account_count : 1);
        if (res.payment_history.meta && res.payment_history.meta.pagination) {
          setPaginationData(res.payment_history.meta.pagination);
        }
        if (res.payment_details && res.payment_details.payee_details != "") {
          setPaypal(true);
        }
        setPaymentHistoryData(res.payment_history.data);
        if (summary === true) {
          setPaymentSummary(res.payment_history.summary);
        }
        res.payment_history.data.length === 0
          ? setWithData(false)
          : setWithData(true);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchPaymentData();
  }, [page, dateRange, searchList]);
  const downloadCsv = async () => {
    try {
      let options = {
        page: page,
        pageSize: 10,
        range: dateRange,
        fileType: 'csv'
      };

      if (searchPaymentHistoryText !== '') {
        options['search'] = {
          key: selectDesc.id,
          value: searchPaymentHistoryText
        };
      }
      const response = await influencerService.getBillingHistory(options);

      utils.downloadFile(response, "paymentHistory_page_" + page + ".csv");
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title'>
            <h2>Payment history</h2>
            <Profile
              user={props.user}
              setModal={props.setModal}
              profiles={props.profiles}
              NotifModul={props.NotifModul}
              setNotifModul={props.setModalNotification}
            />
          </div>
          <div className='payment'>
            <YearToDate
              list={ytdList}
              selected={ytdList[0]}
              onChange={changeMedium}
            />
            <div className='payment__row'>
              <div className='payment__total'>
                <h4>Total payments received</h4>
                <div className='payment__total-inner'>
                  <div className='payment__total-item'>
                    <h2>
                      {paymentSummary.total ? paymentSummary.total : '0'}
                    </h2>
                    <p className='sm'>Total</p>
                  </div>
                  <div className='payment__total-item'>
                    <h2>
                      {paymentSummary.totalRevenue
                        ? paymentSummary.totalRevenue
                        : '$0'}
                    </h2>
                    <p className='sm'>Total revenue</p>
                  </div>
                </div>
              </div>
              <div className='payment__account'>
                <h4>Payment account</h4>
                <div className='payment__account-inner'>
                  <div
                    className={
                      'payment__account-item ' + (paypal ? 'active' : '')
                    }
                  >
                    {paypal && (
                      <div className='payment__account-item-row'>
                        <div className='payment__account-item-activated'>
                          <p className='sm'>Activated</p> {tickIconGreen}
                        </div>
                        {/* <div className='checkbox'>
                          <input
                            ref={payPalRef}
                            type='checkbox'
                            onChange={() => setDefaultPayment('paypal')}
                          />
                          <label htmlFor=''></label>
                          <p className='sm'>Default payment method</p>
                          
                      </div>*/}
                      </div>
                    )}
                    <div className='payment__account-item-row'>
                      <div className='payment__account-item-image'>
                        <img
                          src={process.env.PUBLIC_URL + '/images/paypal.png'}
                          alt='paypal'
                        />
                      </div>
                      {paypal && <button className='button edit' onClick={callPayPalLogin}>Edit</button>}
                      {!paypal && (
                        <button
                          className='button primary'
                          onClick={callPayPalLogin}
                        >
                          Connect
                        </button>
                      )}
                    </div>
                  </div>
                  {/*<div
                    className={
                      'payment__account-item ' + (stripe ? 'active' : '')
                    }
                  >
                    {stripe && (
                      <div className='payment__account-item-row'>
                        <div className='payment__account-item-activated'>
                          <p className='sm'>Activated</p> {tickIconGreen}
                        </div>
                        <div className='checkbox'>
                          <input
                            ref={stripeRef}
                            type='checkbox'
                            onChange={() => setDefaultPayment('stripe')}
                          />
                          <label htmlFor=''></label>
                          <p className='sm'>Default payment method</p>
                        </div>
                      </div>
                    )}

                    <div className='payment__account-item-row'>
                      <div className='payment__account-item-image'>
                        <img
                          src={process.env.PUBLIC_URL + '/images/stripe.png'}
                          alt='stripe'
                        />
                      </div>
                      {stripe && <button className='button edit'>Edit</button>}
                      {!stripe && (
                        <button
                          className='button primary'
                          onClick={() => setStripe(true)}
                        >
                          Connect
                        </button>
                      )}
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>

            <div className='payment__table-title'>
              <h4>Payment received</h4>
              {paymentHistoryData && (
                <>
                  <SelectInput
                    list={selectDescList}
                    selected={selectDesc}
                    onChange={changeMediumDesc}
                  />
                  <SearchInput
                    type='text'
                    icon={searchIcon}
                    placeholder={'Search ' + selectDesc.value}
                    onChange={searchPaymentHistory}
                    onClick={search}
                    value={searchPaymentHistoryText}
                  />
                </>
              )}
            </div>

            <div
              className={
                "payment__table " +
                (withData ? " " : "empty ") +
                (accountCount > 1 ? "moreAcc" : "")
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Brand</th>
                    <th>Date</th>
                    {accountCount > 1 && <th>Account</th>}
                    <th>Type</th>
                    <th>Compensation</th>
                  </tr>
                </thead>
                <tbody>
                  {withData && (
                    <>
                      {paymentHistoryData.map((item, index) => {
                        return <PaymentItem
                          accountCount={accountCount}
                          key={index}
                          {...item} />;
                      })}
                    </>
                  )}
                  {!withData && (
                    <>
                      <tr>
                        <td colSpan={accountCount > 1 ? '5' : '4'} style={{ textAlign: 'center' }}>
                          There is currently no payment history.
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>

            <div className='payment__table-footer'>
              <button className='button tertiary download' onClick={downloadCsv}>
                Download CSV {download}
              </button>
              {(withData && isLoaded === true) && (
                <Pagination
                  perPage={paginationData['per_page']}
                  total={paginationData['total']}
                  currentPage={paginationData['current_page']}
                  totalPages={paginationData['total_pages']}
                  onPaginate={onPaginate}
                />
              )}
            </div>

          </div>
        </div>
      </div >
      {toastList && <Toast list={toastList} setList={setToastList} />
      }
    </>
  );
}
