import React from 'react';
import { Modal } from '../../Components';
import { closeIcon } from '../../Components';
export default function ModalConfirmDecline(props) {
  return (
    <Modal addClass='notif' close={props.close}>
      <div className='modal__notification'>
        <div className='modal__notification-inner'>
          <div className='modal__notification-title solo'>
            <h4>Opportunity</h4>
            <button
              className='button tertiary big grey'
              onClick={props.close}
            >
              {closeIcon} Cancel
            </button>
          </div>
          <div className='modal__notification-delete'>
            <h4>Decline opportunity?</h4>
            <p className='sm'>
              Please confirm you would like to decline this opportunity.
            </p>
            <button className='button primary' onClick={props.onReject}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
