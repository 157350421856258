import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import Assign from "./Assign";
import Details from "./Details";
import ModalNotifDelete from "../Modals/ModalNotifDelete";
import ModalNotifSend from "../Modals/ModalNotifSend";
import { convertFromRaw } from "draft-js";
import { notificationService } from "../../../Services";

const initForm = {
  title: "",
  status: "draft",
  text: {
    blocks: [
      {
        key: "eerdn",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  },
  assignTo: [],
};

export default function NotifAdd({ setTab, addToast, allusers, deleteNotification }) {
  const [form, setForm] = useState(initForm);
  const [modal, setModal] = useState(null);
  const [error, setError] = useState(false);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);

  const sendFunc = () => {
    if (
      form.title !== "" &&
      form.text.blocks[0].text !== "" &&
      form.assignTo.length > 0
    ) {
      setModal("notifSend");
    } else {
      setError(true);
      addToast({
        error: true,
        msg: "Please add details for send notifications.",
        successText: "Data saved successfully.",
        date: new Date(),
      });
    }
  };
  const sendNotification = async () => {
    try {
      form.status = 'active';
      form.text = (JSON.stringify(form.text));
      const res = await notificationService.create(form);
      setTab("main");
      addToast({
        error: false,
        msg: "Data saved successfully.",
      });
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
    }
  }
  const saveAsDraftFunc = async () => {

    let lengthOfDescription = form.text.blocks[0].text !== "" ? convertFromRaw(form.text).getPlainText().length : 0;

    if (
      form.title !== "" &&
      form.text.blocks[0].text !== "" &&
      form.assignTo.length > 0
    ) {
      try {
        if (lengthOfDescription > 1500) {
          setError(true);
          addToast({
            error: true,
            msg: "Notification can be max 1500 characters.",
            successText: "Data saved successfully.",
            date: new Date(),
          });
        } else {
          form.text = (JSON.stringify(form.text));
          const res = await notificationService.create(form);
          setTab("main");
          addToast({
            error: false,
            msg: "Data saved successfully.",
          });
        }
      } catch (err) {
        addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
      }
    } else {
      setError(true);
      addToast({
        error: true,
        msg: "Please add details to save notifications",
        successText: "Data saved successfully.",
        date: new Date(),
      });
    }
  };
  return (
    <>
      <div className="admnotifAdd">
        <Details
          setInput={setInput}
          form={form}
          updateForm={updateForm}
          error={error}
        />
        <Assign form={form} updateForm={updateForm} error={error} allusers={allusers} />
        <div className="admnotifAdd__btns">
          <div className="admnotifAdd__btns-row">
            <button className="button secondary" onClick={saveAsDraftFunc}>
              Save as Draft
            </button>
            <button
              className="button secondary"
              onClick={() => setTab("main")}
            >
              Cancel
            </button>
          </div>
          <button className="button primary" onClick={sendFunc}>
            Send notification
          </button>
        </div>
      </div>
      <AnimatePresence>
        {modal === "notifDelete" && (
          <ModalNotifDelete
            close={() => setModal(null)}
            delBtnClick={() => {
              deleteNotification();
              setModal(null);
              setTab("main");
            }}
          />
        )}
        {modal === "notifSend" && (
          <ModalNotifSend
            close={() => setModal(null)}
            btnClick={() => {
              sendNotification()
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
}
