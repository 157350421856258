import React from 'react';
import ReactDOM from 'react-dom';
import { closeIcon } from './SVG';
import { motion } from 'framer-motion';

export default function Modal({ close, children, addClass }) {
  const closeOnFog = e => {
    if (e.target === e.currentTarget) close();
  };

  return ReactDOM.createPortal(
    <div className='modal' onClick={closeOnFog}>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0, y: 30 }}
        className={'modal__inner ' + (addClass ? addClass : '')}
      >
        <div className='modal__inner-close' onClick={close}>
          {closeIcon}
        </div>
        {children}
      </motion.div>
    </div>,
    document.getElementById('modals')
  );
}
