import React, { useRef } from "react";
import { copyIcon, instagramfilled } from "../../Components";

export default function URLItem(props) {
  const copyLinkWrapper = useRef(null);
  const clickFunc = (e) => {
    if (copyLinkWrapper.current.contains(e.target)) props.copyBtn(props.link);
    else props.onClick();
  };
  return (
    <tr onClick={clickFunc}>
      <td>{props.id}</td>
      <td>
        <p><span className='iconForHandle'>{props.claim ? instagramfilled : ' '}</span>
          <span className={props.claim ? '' : 'iconForHandleNAme'}>{props.handle}</span></p>
      </td>
      <td>{props.full_name}</td>
      <td>
        <p>{props.clicked_count}</p>
      </td>
      <td ref={copyLinkWrapper}>
        <div className="url__table-link">
          <div className="url__table-copy">
            {copyIcon}

          </div>
        </div>
      </td>
    </tr>
  );
}
