import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/proposals`;
export const campaignService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete,
  getConfigData,
  getCount,
  getCampaignStats,
  getBalance,
};
function getAllDateRange(options) {
  return `range[month]=${options.range}`;
}

function getSortOrder(options) {
  return `sortBy[order]=${options.sortBy}`;
}
function getAll(options) {
  let filterString = '';
  let filterStringRange = "";
  let filterStringSort = "";
  let filterStringId = "";
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  if (options.range !== undefined) {
    filterStringRange += getAllDateRange(options);
  }
  if (options.sortBy !== undefined) {
    filterStringSort += getSortOrder(options);
  }
  if (options.filter) {
    filterString += "&" + options.filter;
  }
  if (options.fields) {
    filterStringId += "&" + options.searchById;
  }

  let url =
    filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
  url +=
    filterStringRange.length > 0 ? `&${filterStringRange}` : "";
  url +=
    filterStringSort.length > 0 ? `&${filterStringSort}` : "";
  url +=
    filterStringId.length > 0 ? `&${filterStringId}` : "";
  return fetchWrapper.get(url);


}

function getAllPaging(options, filterString) {
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  }
  if (options.fileType) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[filetype]=${options.fileType}`;
  }
  return filterString;
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getCampaignStats(id) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/getCampaignStat/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function getConfigData() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/config`);
}
function getCount() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/user/menu_counts`);
}
function getBalance() {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/balance`);
}