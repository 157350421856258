import React, { useState } from "react";
import DetailsSystem from "./DetailsSystem";
import { utils } from '../../../Helpers/utils';
import { notificationService } from "../../../Services";
import { convertFromRaw } from "draft-js";
export default function NotifEditSystem({ setTab, addToast, type, tabData }) {

  const [error, setError] = useState(false);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const initForm = {
    title: tabData.title,
    text: utils.testJSON(tabData.text) ? JSON.parse(tabData.text) : "",
    assignTo: tabData.assignTo ? tabData.assignTo : [],
  };
  const [form, setForm] = useState(initForm);
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  const sendFunc = async () => {
    if (form.title !== "" && form.text.blocks[0].text !== "") {
      let lengthOfDescription = form.text.blocks[0].text !== "" ? convertFromRaw(form.text).getPlainText().length : 0;

      try {
        if (lengthOfDescription > 1500) {
          addToast({
            error: true,
            msg: "Notification can be max 1500 characters.",
            successText: "Data saved successfully.",
            date: new Date(),
          });
        } else {
          form.text = (JSON.stringify(form.text));
          form.status = 'active';
          const res = await notificationService.update(tabData.id, form);
          setTab("system");
          addToast({
            error: false,
            msg: "Data saved successfully.",
          });
        }

      } catch (err) {
        addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
      }

    } else {
      setError(true);
      addToast({
        error: true,
        msg: "Enter details to savesystem notification.",
        successText: "Data saved successfully.",
        date: new Date(),
      });
    }
  };

  console.log(form);
  return (
    <>
      <div className="admnotifAdd">
        <DetailsSystem
          setInput={setInput}
          form={form}
          updateForm={updateForm}
          error={error}
          type={type}
        />

        <div className="admnotifAdd__btns sys">
          <div className="admnotifAdd__btns-row">
            <button
              className="button secondary"
              onClick={() => setTab("system")}
            >
              Cancel
            </button>
          </div>
          <button className="button primary" onClick={sendFunc}>
            Save notification
          </button>
        </div>
      </div>
    </>
  );
}
