import React, { useEffect, useRef, useState } from "react";
import { chevronBottom2, closeIcon } from "../../../Components";
import { TagInput, TextInput } from "../../../Components";

//const searchList = ["Fashion", "Spirits", "Lifestyle"];

export default function Industries({ form, updateForm, categoriesList, categories, readOnly }) {
  const [searchValue, setSearchValue] = useState("");
  const inputWrapper = useRef(null);
  const inpRef = useRef(null);
  const [list, setList] = useState(categories);
  const [focus, setFocus] = useState(false);
  const delInterests = (id) => {
    let newIndustries = [...form.category];
    let index = newIndustries.indexOf(id);
    newIndustries.splice(index, 1);
    updateForm({ category: newIndustries });
  };
  const closeSearch = () => {
    setSearchValue("");
    setFocus(false);
    setList(categories);
    inpRef.current.value = "";
    inpRef.current.blur();
  };
  const setInterests = (obj) => {
    const foundItem = () => {
      return categories.filter(
        (item) => item.toUpperCase() === obj.value.toUpperCase()
      );
    };
    if (foundItem().length !== 0) {
      let newCategory = [...form.category];
      let selCategory = categoriesList.find(cat => cat.category.toUpperCase() === obj.value.toUpperCase());
      newCategory.push(selCategory.id);
      updateForm({ category: newCategory });
      closeSearch();
    }
  };
  const optionClickHandler = (item) => {
    let newCategory = [...form.category];
    let selCategory = categoriesList.find(cat => cat.category.toUpperCase() === item.toUpperCase());
    newCategory.push(selCategory.id);
    updateForm({ category: newCategory });
    closeSearch();
  };
  const searchListFunc = (text) => {
    return categories.filter((item) =>
      item.toUpperCase().startsWith(text.toUpperCase())
    );
  };
  const handleChange = (event) => {
    const list = searchListFunc(event.target.value);
    setSearchValue(event.target.value);
    setList(list);
  };
  const handlePress = (event) => {
    const inputItem = event.target;
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setInterests(inputItem);
      return;
    }
  };
  useEffect(() => {
    let newList = categories;
    form.category.map(item => {
      var filtered = categoriesList.filter(function (cat) { return cat.id == item; });
      if (filtered.length > 0) {
        const index = categories.indexOf(filtered[0].category);
        if (index > -1) { newList.splice(index, 1); }
      }

    });
    setList(newList);
    const windowClick = ({ target }) => {
      if (!inputWrapper.current.contains(target)) closeSearch();
    };

    if (focus) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [focus]);
  return (
    <div className="recomForm__industry" ref={inputWrapper}>
      {/*<TagInput
        btnClickHandler={setInterests}
        optionClickHandler={optionClickHandler}
        inpRef={inpRef}
        onKeydown={handlePress}
        type="text"
        label="Industry(s)"
        icon={chevronBottom2}
        list={form.industries}
        autoCompleteList={list}
        searchValue={searchValue}
        searchList={list}
        onFocus={() => setFocus(true)}
        showList={focus}
        onChange={handleChange}
      />*/}
      <TextInput
        btnClickHandler={() => setFocus(!focus)}
        optionClickHandler={optionClickHandler}
        inpRef={inpRef}
        onKeydown={handlePress}
        type='text'
        label='Desired target categories'
        icon={chevronBottom2}
        list={form.category}
        autoCompleteList={list}
        searchValue={searchValue}
        searchList={list}
        onFocus={() => setFocus(true)}
        showList={focus}
        onChange={handleChange}
        readOnly={readOnly}
      />
      {form.category.length > 0 && (
        <div className="recomForm__industry-items">
          {categoriesList.map((item, index) => {

            let obj = form.category.find(cat => parseInt(cat) === item.id);

            if (obj) {
              return (
                <div className="recomForm__industry-item" key={item.id}>
                  {item.category}
                  {!readOnly && <div
                    className="recomForm__industry-item-icon"
                    onClick={() => delInterests(item.id)}
                  >
                    {closeIcon}
                  </div>
                  }
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
