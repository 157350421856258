import { React, useState, useEffect } from "react";
import { utils } from "../../Helpers";
import { TextInput } from "../../Components";
export default function PostVerificationTableItem(props) {
  const [postStatusListColumn, setPostStatusListColumn] = useState([]);
  useEffect(() => {
    setPostStatusListColumn(props.postStatusListColumn);
  }, [props.item]);

  return (<>
    {

      <tr >
        <td  >
          <p>{props.item.id}</p>
        </td>
        <td>
          <p>{props.item.engagement_id}</p>
        </td>
        <td><a href={props.item.link} target="blank">{props.item.product_name}</a></td>
        <td onClick={() => props.setData(props.item.influencer_id)}>{utils.getIconFilled(props.item.handle.icon)} {props.item.handle.handle}[{props.item.influencer_id}]</td>
        <td className="bold">${props.item.influencer_rate}{(props.item.rate_overwritten && parseInt(props.item.rate_overwritten) == 1)
          && <img className="redFlag"
            src={`${process.env.PUBLIC_URL}/images/red-flag.png`} alt="red-flag" />
        }</td>
        <td className="posthashtag">{props.item.hashtag}</td>

        <td>
          {props.status != 'paid' ?
            <div className={"campaigns__table-status " + props.item.status} > <>
              {postStatusListColumn.map((item, index) => {
                return (
                  <>
                    <input
                      className='status_radio'
                      key={item.id}
                      type="radio"
                      value={item.id}
                      checked={props.newStatus === undefined ? item.id === props.item.status :
                        props.item.id == props.loadData.id ? item.id === props.newStatus.status :
                          item.id === props.item.status}
                      onChange={() => props.OnChangeDropdown(props.item, props.rowIndex, item.id)}
                    /><label className="radioLabel">{item.value}</label></>
                )

              })}
            </>
            </div> : 'Paid'
          }
        </td>
      </tr>
    }
  </>
  );
}
