import React, { useEffect, useRef, useState } from "react";
import { TextArea } from "../../Components";
import { TextInput, TextAmount } from "../../Components";

import { Modal } from "../../Components";
import { billingService } from '../../Services';
export default function ModalBilling({ close, setIsBalanceAdded, addToast }) {
  const paypal = useRef(null);
  const stripe = useRef(null);
  const [account, setAccount] = useState("paypal");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const addAmount = useRef(null);
  useEffect(() => {
    if (account === "paypal") {
      stripe.current.checked = false;
    } else if (account === "stripe") {
      paypal.current.checked = false;
    }
  }, [account]);

  const setInput = (key) => (event) => {
    if (key === 'description') {
      setDescription(event.target.value);
    } else {
      setAmount(event.target.value)
    }
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        addAmount.current.click();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [amount, description, account]);

  const saveChanges = () => {
    if (amount !== "" && description !== "" && account !== null && amount >= 500) {
      saveBalance();
    }
  };

  const saveBalance = async () => {
    setDisableButton(true);
    const payload = {
      amount: amount,
      description: description,
      account: account
    };
    try {
      const res = await billingService.addBalance(payload);
      window.location.replace(res.url);
      setIsBalanceAdded(false);
    } catch (error) {
      addToast({
        error: true,
        msg: error,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };
  return (
    <Modal addClass="modalFunds" close={close}>
      <div className="modal__funds">
        <div className="modal__funds-inner">
          <h3 className="big">Add funds</h3>
          <p className="sm">
            Select the account you would like to add funds from.
          </p>
          <div className="modal__funds-radio">
            <div className="modal__funds-radio-item">
              <input
                ref={paypal}
                type="radio"
                onChange={() => setAccount("paypal")}
                checked={account === 'paypal' ? true : false}

              />
              <label htmlFor=""></label>
              <div className="modal__funds-radio-item-image">
                <img
                  src={process.env.PUBLIC_URL + "/images/paypal.png"}
                  alt="paypal"
                />
              </div>
            </div>
            <div className="modal__funds-radio-item">
              <input
                ref={stripe}
              />
            </div>
          </div>
          <h6 className="semi">Amount (min 500)</h6>
          <div className="modal__funds-input">
            <div className="modal__funds-input-currency">USD</div>
            <TextAmount
              errorText='Minimum amount of 500 needed'
              error={error}
              value={amount}
              type="number"
              autoFocus
              onChange={setInput('amount')} />
          </div>
          <div className="modal__funds-textarea">
            <TextArea
              row="4"
              error={error}
              value={description}
              label="Description"
              errorText='Please enter Description'
              onChange={setInput('description')}
              placeholder="Enter description for what purpose you are adding this amount."
            />
          </div>
          <button ref={addAmount} className="button primary" disabled={disableButton} onClick={() => {
            saveChanges();
            setError(true);
          }}>
            Add balance
          </button>
        </div>
      </div>

    </Modal>
  );
}
