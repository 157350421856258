import React, { useState, useEffect } from "react";
import { SearchInput, SelectInput, Pagination, Toast } from "../../../Components";
import { AnimatePresence } from 'framer-motion';

import {
    influencerService
} from '../../../Services';

import { utils } from "../../../Helpers/utils";
import ModalAddRevision from './Modal/ModalAddRevision';
import ModalShowRevision from './Modal/ModalShowRevision';

export default function RevisionTree(props) {

    const [paginationData, setPaginationData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [toastList, setToastList] = useState([]);
    const [infEngList, setInfEngList] = useState([]);
    const [loadList, setLoadList] = useState(false);
    const [modal, setModal] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const addToast = (data) => {
        const newToastList = [];
        newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
        setToastList(newToastList);
    };
    let rowId = 0;
    useEffect(() => {
        async function fetchData() {
            try {
                let filterStr = '';
                if (props.engagementId) {
                    filterStr = 'filter[engagement_id]=' + props.engagementId;
                }
                if (props.campaignId) {
                    filterStr = 'campaign_id=' + props.campaignId;
                }
                if (props.influencerId) {
                    filterStr += '&filter[influencer_id]=' + props.influencerId;
                }
                const res = await influencerService.getRevisionWithStatus({
                    page: page,
                    pageSize,

                }, '', filterStr
                );
                if (res.meta && res.meta.pagination) {
                    setPaginationData(res.meta.pagination);
                }
                setInfEngList(res.data);
                setLoadList(true);
            } catch (err) {
                props.addToast({
                    error: true,
                    msg: err,
                    id: `${Date.now()}-${toastList.length}`
                });
            }
        }
        fetchData();
    }, [page]);

    const onPaginate = page => {
        setPage(page);
    };

    const saveChanges = (item) => {
        if (item.attempt == 1) {
            var f = document.getElementsByClassName("row_0_" + item.id);
            for (var p = 0; p < f.length; p++) {
                if (f[p].style.display == 'table-row') {
                    f[p].style = "display:none";
                    document.getElementById('valId_' + item.id).src = "images/plus.png";

                } else {
                    f[p].style = "display:table-row";
                    document.getElementById('valId_' + item.id).src = "images/minus.png";
                }

            }

        } else {
            setModalData(item);
            setModal("showRevision");
        }
    }
    return (
        <>
            <div className="campaigns">
                <div className="campaigns__table-title">
                    <div className="campaigns__table-title-info">
                        <h4>Product Revision List</h4>
                        {props.influencerId && props.addRevisionFlag == 1 ?
                            <button
                                className="button tertiary big"
                                onClick={() => {
                                    setModalData(props);
                                    setModal("addRevision");
                                }
                                }
                            >
                                Add Revision +
                            </button> : props.revisionMsg}
                    </div>
                    {props.influencerId ?
                        <div className="campaigns__table-title-inner poststatus">
                            <p>Attempts remaining ({props.usedrevision}/{props.totalrevision})</p>
                        </div> : ''}
                </div>

                <>
                    <div className="campaigns__table postStatus">
                        <table className="post_verification_table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Link</th>
                                    <th>Content</th>
                                    <th>Notes</th>
                                    <th>Feedback</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadList ?
                                    (infEngList.length > 0 && loadList) ?
                                        infEngList.map((item, index) => {
                                            if (item.attempt == 1) {
                                                rowId = item.id;
                                            }
                                            return (<>
                                                {
                                                    <tr class={"row_" + item.attempt + "_" + rowId} onClick={() => saveChanges(item)}
                                                        style={item.attempt == 0 ? { display: 'none' } : {}}
                                                    >
                                                        {item.attempt == 1 ?
                                                            <td target="_blank" colspan='2'><img style={{ verticalAlign: "middle" }} target="_blank" id={"valId_" + item.id} src='images/plus.png' /> <span>{item.link}</span> </td> : <td >{item.submission_date} </td>}
                                                        {item.attempt == 1 ?
                                                            '' :
                                                            <td><a href={item.link} target="blank"><p>{item.link} <img target="_blank" style={{ verticalAlign: "middle" }} src='images/external-link.png' /></p></a></td>}
                                                        <td>{item.content}</td>
                                                        <td>{item.notes}</td>
                                                        <td >{item.feedback ? item.feedback : ''}</td>
                                                        {props.influencerId ?
                                                            <td style={{ textTransform: 'capitalize' }}>
                                                                {item.status == 'pending' ? 'Submitted' : (item.status == 'changerequest') ? 'Change Request' : item.status}
                                                            </td>
                                                            : <td style={{ textTransform: 'capitalize' }}>
                                                                {item.status == 'pending' ? 'Needs Review' : (item.status == 'changerequest') ? 'Change Request' : item.status}
                                                            </td>}
                                                        {(item.status == 'pending' && item.attempt == 0) ?
                                                            <td><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0858 5.49997C14.8668 4.71892 16.1332 4.71892 16.9142 5.49997L18.5 7.08576C19.281 7.86681 19.281 9.13314 18.5 9.91418L9.41421 19H5V14.5858L14.0858 5.49997ZM7 15.4142V17H8.58579L17.0858 8.49997L15.5 6.91418L7 15.4142Z" fill="black"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M16.1464 10.8535L13.1464 7.85352L13.8536 7.14642L16.8536 10.1464L16.1464 10.8535Z" fill="black"></path></svg>
                                                                <div className="input overRide">
                                                                </div>
                                                            </td>
                                                            : (item.attempt == 0) ? <td><img target="_blank" id={"valId_" + item.id} src='images/eye.png' /></td> : <td> </td>}
                                                    </tr>

                                                }

                                            </>
                                            );
                                        })
                                        : (
                                            <tr>
                                                <td colSpan="7" style={{ textAlign: 'center' }}>
                                                    No Records Found
                                                </td>
                                            </tr>
                                        ) : (
                                        <tr>
                                            <td colSpan="7" style={{ textAlign: 'center' }}>
                                                Loading...
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </>
                <div className="campaigns__table-footer">
                    <button className="button tertiary download" >
                    </button>
                    <Pagination
                        perPage={paginationData['per_page']}
                        total={paginationData['total']}
                        currentPage={paginationData['current_page']}
                        totalPages={paginationData['total_pages']}
                        onPaginate={onPaginate}
                    />
                </div>
            </div>
            <AnimatePresence>
                {modal === "addRevision" && (
                    <ModalAddRevision
                        close={() => setModal(null)}
                        setModal={setModal}
                        setModalData={setModalData}
                        modalData={modalData}
                        engagementId={props.engagementId}
                        influencerId={props.influencerId}
                        product_id={props.product_id}
                        product_name={props.product_name}
                        engagement_influencer_map_id={props.engagement_influencer_map_id}
                        addToast={addToast}
                        setInfEngList={setInfEngList}
                        setLoadList={setLoadList}

                    />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {modal === "showRevision" && (
                    <ModalShowRevision
                        close={() => setModal(null)}
                        setModal={setModal}
                        setModalData={setModalData}
                        modalData={modalData}
                        influencerFlag={props.campaignId ? 0 : 1}
                        addToast={addToast}
                        setIsLoaded={setIsLoaded}
                        setInfEngList={setInfEngList}
                        setLoadList={setLoadList}
                        campaignId={props.campaignId}
                    />
                )}
            </AnimatePresence>
            {
                toastList && (
                    <Toast type='rate' list={toastList} setList={setToastList} />
                )
            }
        </>
    );
}