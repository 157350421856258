import React from "react";
import {Modal} from "../../../Components";
export default function ModalNotifSend(props) {
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="modal__camp">
        <div className="modal__assetDetail-delete perm long">
          <h4>Are you sure?</h4>
          <p className="sm">
            Sending this notification will make it active and no longer
            editable.
          </p>
          <div className="modal__assetDetail-delete-btns">
            <button className="button secondary" onClick={props.close}>
              Cancel
            </button>
            <button className="button primary" onClick={props.btnClick}>
              Send notification
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
