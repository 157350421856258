import React from "react";
import { motion } from 'framer-motion';
import { utils } from '../../../Helpers/utils';
import {
  arrowRight,
} from "../../../Components";
export default function CardDash(props) {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 * props.index }}
        exit={{ opacity: 0, y: 30 }}
        className={
          "card " +
          (props.accountCount > 1 ? "moreAcc " : "") +
          (props.type ? props.type : "")
        }
      >
        {(props.accountCount > 1 && props.handle) && (
          <div className="card__account">
            {props.handle.icon && utils.getIconFilled(props.handle.icon[0])}
            {props.handle && <span>{props.handle.handle}</span>}
          </div>
        )}
        <div className="card__title">
          <div className="card__title-image">
            <img
              src={
                props.brandLogo
                  ? props.brandLogo
                  : `${process.env.PUBLIC_URL}/images/bulls-eye.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.PUBLIC_URL}/images/bulls-eye.png`;
              }}
              alt='bulls-eye'
            />
          </div>
          <div className="card__ttile-content">
            <h3>{props.brand_name}</h3>
            <div className="card__title-content-row">
              <h6>{props.title}</h6>
            </div>
          </div>
        </div>
        <h6>{props.titleOverview}</h6>
        <div className="card__overview">
          <p>
            <span>
              {props.description
                ? utils.draftJsFormatingForDetails({ 'data': props.description, 'view': 'lessview' })
                : ''}
            </span>
          </p>
        </div>
        <div className='optinby'>
          <h6>
            Opt in by: <span>{props.deadline_date != "" && props.deadline_date !== null ? props.deadline_date : 'NA'}</span>
          </h6>
          <button className="button tertiary" onClick={props.openModal}>
            View full details {arrowRight}
          </button></div>

      </motion.div >

    </>
  );
}
