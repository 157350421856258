import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import ModalAssetDetail from "../../Campaigns/Modals/AssetsDetail/ModalAssetDetail";
import ModalAssetFull from "../../Campaigns/Modals/AssetsDetail/ModalAssetFull";
import ModalCampDelete from "../Modals/ModalCampDelete";
import ModalCampPublish from "../Modals/ModalCampPublish";
import Assets from "./Assets";
import Compensation from "./Compensation";
import { Details, CampDetails, Products } from "../../../Components";
import Influencers from "./Influencers";

import { convertFromRaw } from "draft-js";

import {
  contentService,
  campaignService,
  engagementService,
  influencerService
} from '../../../Services';
import { utils } from '../../../Helpers/utils';
import { format } from 'date-fns';
const initForm = {
  title: "",
  is_featured: 0,
  description: "",
  deliverable: "",
  kpi: "",
  hashtags: [],
  start_date: '',
  end_date: '',
  budget: "",
  goods: [],
  assets: [],
  reach: 0,
  interaction: 0,
  category: [],
};
export default function CampaignsCreate({ setTab, addToast, campaignId, mode, engagementId, campaignProducts, campaignAssets, readOnly, setSaveCampaign, saveCampaign, }) {

  const [form, setForm] = useState(initForm);
  const [assetIndex, setAssetIndex] = useState(null);
  const [modal, setModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [error, setError] = useState(false);
  const [isLoadedCampaign, setIsLoadedCampaign] = React.useState(false);
  const [toastList, setToastList] = useState([]);
  const [selectedGoods, setSelectedGoods] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [compensationTypeId, setCompensationTypeId] = useState();
  const [influencers, setInfluencers] = useState();
  const [allCampaignAssets, setAllCampaignAssets] = useState();
  const [categoriesData, setCategoriesData] = useState({});

  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const [selectedItems, setSelectedItems] = useState({
    selectedContent: [],
    selectedInfluencer: [],
    selectedProduct: [],

  });
  useEffect(() => {
    if (saveCampaign === true) {
      validateCampaign();
      setSaveCampaign(false);
      updateForm({ ['status']: "draft" });
    }
  }, [saveCampaign]);

  const chkEditorVal = (item) => {
    let editorError = false;
    if (item === "") {
      return editorError = true;
    } else {
      let isValidJson = utils.testJSON(item);
      if (isValidJson) {
        if (JSON.parse(item).blocks[0].text === "") {
          return editorError = true;
        } else {
          return false;
        }
      }
    }
  }
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);
  useEffect(() => {
    async function fetchCategoriesData() {
      try {
        const res = await influencerService.getCategoriesDetails();
        let newCountry = [];
        Object.entries(res.countries).map((country) => { (newCountry.push(country[1])) })
        setCategoriesData(res);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchCategoriesData();
  }, []);
  useEffect(() => {
    setFormSubmit(formSubmit);
    if (formSubmit === true) {
      if (form.status === 'draft') {
        if (mode === "edit") {
          updateEngagement();
        } else {
          createEngagement();
        }
      } else {
        setModal("campPublish");
      }
    }
  }, [formSubmit]);
  const deletAsset = (index) => {
    let arr = [...form.assets];
    arr.splice(index, 1);
    updateForm({ assets: arr });
  };
  const setInputChkeckbox = (key) => (event) => {
    event.target.checked ? updateForm({ ['is_featured']: 1 }) : updateForm({ ['is_featured']: 0 });
  }
  const updateEngagement = async () => {
    const { selectedContent, selectedProduct, selectedInfluencer } =
      selectedItems
    //  const influencerProductSel = formatProducts(selectedInfluencer.products);
    let payload = {
      ...form,
      start_date: format(new Date(form.start_date), 'yyyy-MM-dd'),
      end_date: format(new Date(form.end_date), 'yyyy-MM-dd'),
      deadline_date: form.deadline_date ? form.deadline_date != "" ? format(new Date(form.deadline_date), 'yyyy-MM-dd') : "" : "",
      kpi: form.kpi,
      description: form.description,
      deliverable: form.deliverable,
      status: form.status,
      contents: selectedContent,
      influencers: selectedInfluencer,
      products: selectedProduct,
      proposal_id: campaignId,
      goods: selectedGoods,
      brand_logo: "",
      hashtag: form.hashtags.toString(),
    };
    try {
      const res = await engagementService.update(engagementId, payload)
      setModal(null);
      addToast({ error: false, date: new Date(), msg: res.message });
      setTab("refreshDetail");
    } catch (err) {
      setModal(null);
      addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
      setFormSubmit(false);
    }

  }
  const createEngagement = async () => {
    const {
      selectedContent,
      selectedProduct,
      selectedInfluencer
    } = selectedItems;
    //form.assets.map(item => { selectedContent.push(item.id) });
    // delete form.assets;
    const data = Object.assign(
      {},
      {
        ...form,
        start_date: format(new Date(form.start_date), 'yyyy-MM-dd'),
        end_date: format(new Date(form.end_date), 'yyyy-MM-dd'),
        deadline_date: form.deadline_date ? form.deadline_date != "" ? format(new Date(form.deadline_date), 'yyyy-MM-dd') : "" : "",
        proposal_id: campaignId,
        contents: selectedContent,
        influencers: selectedInfluencer,
        products: selectedProduct,
        kpi: form.kpi,
        description: form.description,
        deliverable: form.deliverable,
        status: form.status,
        campaign_id: campaignId,
        goods: selectedGoods,
        brand_logo: "",
        hashtag: form.hashtags.toString(),
      }
    );
    try {
      const res = await engagementService.create(data);
      setModal(null);
      addToast({ error: false, date: new Date(), msg: res.message });
      setTab("refreshDetail");

    } catch (err) {
      setModal(null);
      addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
      setFormSubmit(false);
    }
  }
  const publishFunc = () => {
    validateCampaign();
    updateForm({ ['status']: "active" });
  };
  const negative = (number) => {
    return !Object.is(Math.abs(number), +number);
  }
  const validateCampaign = () => {

    let isFieldsEmpty = false;
    let budgetValidation = false;
    let productValidation = false;
    let influencerValidation = false;
    let lengthError = false;
    const {
      selectedContent,
      selectedProduct,
      selectedInfluencer
    } = selectedItems;



    if (form.campaigns_type_id === 1 || form.campaigns_type_id == 3) {
      budgetValidation = (form.budget === "" || form.budget < 0);
    }

    const dt1 = new Date(form.start_date);
    const dt2 = new Date(form.end_date);
    const dt3 = new Date(form.deadline_date);
    const todaysDate = new Date();
    let findDiffInDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    let findDiffInEndandToday = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    let findDiffInOptinandToday = Math.floor(
      (Date.UTC(dt3.getFullYear(), dt3.getMonth(), dt3.getDate()) -
        Date.UTC(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    let FindPostByandOptinDiff = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt3.getFullYear(), dt3.getMonth(), dt3.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    if (selectedProduct.length === 0) {
      productValidation = true;
    }

    //if (selectedInfluencer.length === 0) { influencerValidation = true; }
    let isGoodsPresent = [];
    if (selectedInfluencer.length === 0) { influencerValidation = true; } else {
      if (selectedGoods.length > 0) {
        selectedGoods.map((item) => {
          selectedInfluencer.map((itemInf) => {
            let goodsPresent = itemInf.goods.filter(goodsItem => goodsItem.parent_id === item.id);
            if (goodsPresent.length > 0) {
              isGoodsPresent.push(item.id);
            }
          })
        })
        if (isGoodsPresent.length === 0) { influencerValidation = true; }
      }

    }
    if (
      form.title === "" ||
      chkEditorVal(form.description) === true ||
      chkEditorVal(form.deliverable) === true ||
      chkEditorVal(form.kpi) === true ||
      form.start_date === "" ||
      form.end_date === "" ||
      form.deadline_date === "" ||
      ((form.interaction === "" || form.interaction == null || form.interaction == 0) && (form.reach === "" || form.reach == null || form.reach == 0))


    ) { isFieldsEmpty = true; }
    let msgError = "";
    let lengthOfDescription = isFieldsEmpty === false ? (convertFromRaw(JSON.parse(form.description)).getPlainText().length) : 0;
    let lengthOfDeliverable = isFieldsEmpty === false ? (convertFromRaw(JSON.parse(form.deliverable)).getPlainText().length) : 0;
    // let lengthOfKpi = isFieldsEmpty === false ? (convertFromRaw(JSON.parse(form.kpi)).getPlainText().length) : 0;
    if (lengthOfDescription > 1500 || lengthOfDeliverable > 1500 || form.title.length > 60) {
      lengthError = true;
    }
    if (isFieldsEmpty === true || budgetValidation === true || findDiffInDays < 0 || productValidation === true || influencerValidation === true || lengthError === true) {

      if (isFieldsEmpty === true) {
        setFormSubmit(false);
        setError(true);
        msgError = "Please enter all required field to add/edit engagement.";
      } else {
        if (lengthError === true) {
          if (form.title.length > 60) {
            msgError = "Title length of title is greater then 60 character.";
          } else if (lengthOfDescription > 1500) {
            msgError = "Description content length is greater then 1500.";
          } else if (lengthOfDeliverable > 1500) {
            msgError = "Deliverables content length is greater then 1500.";
          }
        } else if (budgetValidation === true) {
          msgError = "Min budget is required.";
        } else if (findDiffInDays < 0) {
          msgError = "The Post start date must be a date before Post by date.";
        } else if (negative(findDiffInEndandToday) || findDiffInEndandToday === 0 || findDiffInDays < 0) {
          msgError = "The Post by date must be after the Post start date and after today’s date .";
        } else if (negative(findDiffInOptinandToday) || findDiffInOptinandToday === 0 || FindPostByandOptinDiff <= 0) {
          msgError = "Optin by date needs to be before Post by date and after today’s date";
        } else if (productValidation === true) {
          msgError = "Please select product for engagement";
        } else if (influencerValidation === true) {
          msgError = "Please select influencer for engagement";
        }

        setError(true);
        setFormSubmit(false);
      }
      addToast({ error: true, date: new Date(), msg: msgError });
    } else {
      setError(false);
      setFormSubmit(true);
    }
  }

  const onDelete = async () => {
    if (engagementId) {
      try {

        const res = await engagementService.delete(engagementId);
        addToast({ error: false, date: new Date(), msg: res.message });
        setModal(null);
        setTab("detail");
      } catch (err) {
        addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
        setModal(null);
        setTab("detail");
      }
    } else {
      setModal(null);
      setTab("main");
    }
  };

  const saveAsDraftFunc = () => {
    updateForm({ ['status']: "draft" });
    validateCampaign();
  };
  useEffect(() => {

    async function getCampaignData() {
      try {
        const resAsset = await contentService.getAll({});
        // setIsLoaded(true);
        let AllCom = resAsset.data.filter(function (e) {
          return campaignAssets.indexOf(e.id) > -1;
        });
        setAllCampaignAssets(AllCom);

        try {
          let engagementData = null;
          let formValue = {};
          if (mode === "edit") {
            engagementData = await engagementService.getById(engagementId);

          } else {
            engagementData = await campaignService.getById(campaignId);
          }
          //formValue.hashtags = form.hashtags ? form.hashtags.split(",") : [];
          formValue.start_date = new Date(engagementData['data']['start_date'].replace(/-/g, '/'));
          formValue.is_featured = engagementData['data']['is_featured'] ? engagementData['data']['is_featured'] : 0;
          formValue.end_date = new Date(engagementData['data']['end_date'].replace(/-/g, '/'));
          formValue.deadline_date = engagementData['data']['deadline_date'] ? new Date(engagementData['data']['deadline_date'].replace(/-/g, '/')) : '';
          let assetContent = engagementData['data']['contents'];
          var assetsArray = resAsset.data.filter(function (e) {
            return assetContent.indexOf(e.id) > -1;
          });

          formValue.campaigns_type_id = engagementData['data']['campaigns_type_id']
          formValue.budget = engagementData['data']['amount'];
          formValue.goods = engagementData['data']['goods'];
          formValue.title = engagementData['data']['title']
          formValue.description = engagementData['data']['description'];
          formValue.deliverable = engagementData['data']['deliverable'];
          formValue.kpi = engagementData['data']['kpi'];
          formValue.products = engagementData['data']['products'];
          formValue.interaction = engagementData['data']['interaction'];
          formValue.reach = engagementData['data']['eng_reach'];
          formValue.category = engagementData['data']['category'];
          formValue.campaignMoney = engagementData['data']['campaignMoney'];
          if (mode === "edit") {
            formValue.assets = assetsArray;
            formValue.advertiser_id = engagementData['data']['advertiser_id'];
            let selG = [];
            engagementData['data']['goods'].map((good) => {
              if (good.is_select === true) {
                selG.push(good);
              }
            })
            setSelectedGoods(selG);
          } else {
            formValue.assets = [];
            formValue.advertiser_id = engagementData['data']['advertiser']['advertiser_id'];
            setSelectedGoods([]);
          }

          formValue.hashtags = engagementData['data']['hashtag'] ? engagementData['data']['hashtag'] != null ? engagementData['data']['hashtag'].split(",") : [] : [];

          form.selInfProduct = engagementData['data']['influencers'] ? engagementData['data']['influencers'] : [];
          setSelectedItems({
            selectedContent: engagementData['data']['contents'],
            selectedProduct: mode === "edit" ? engagementData['data']['products'] : engagementData['data']['products'],
            selectedInfluencer: engagementData['data']['influencers'] ? engagementData['data']['influencers'] : []
          });
          setCompensationTypeId(engagementData['data']['campaigns_type_id']);
          updateForm(formValue);
          setIsLoadedCampaign(true);
        } catch (err) {
          addToast({
            error: true,
            msg: err,
            id: `${Date.now()}-${toastList.length}`
          });
        }
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }

    }

    getCampaignData();

  }, []);
  return (
    <>
      {(isLoadedCampaign && categoriesData.categories) && (
        <div className="campaignsCreate">
          <Details
            error={error}
            form={form}
            engagementId={engagementId}
            setInput={setInput}
            updateForm={updateForm}
            setInputChkeckbox={setInputChkeckbox}
            categoriesData={categoriesData.categories}
            categories={categoriesData.categories.map(
              cat => cat.category
            )}
            readOnly={readOnly}
            isFeatured={true}
          />



          <Products form={form} readOnly={readOnly} updateForm={updateForm} setSelectedItems={setSelectedItems}
            selectedItems={selectedItems} toastList={toastList} addToast={addToast} campaignProducts={campaignProducts} />
          <CampDetails
            error={error} form={form} setInput={setInput} updateForm={updateForm} readOnly={readOnly}
          />
          <Compensation categoriesData={categoriesData} error={error} form={form} updateForm={updateForm} readOnly={readOnly} addToast={addToast} toastList={toastList} setSelectedGoods={setSelectedGoods} selectedGoods={selectedGoods} />
          <Assets
            form={form}
            updateForm={updateForm}
            setModal={setModal}
            setModalData={setModalData}
            setAssetIndex={setAssetIndex}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            campaignAssets={allCampaignAssets}
            readOnly={readOnly}
          />

          <Influencers
            form={form}
            selectedItems={selectedItems}
            selectedGoods={selectedGoods}
            setSelectedItems={setSelectedItems}
            setInfluencers={setInfluencers}
            engagementId={engagementId}
            influencers={influencers}
            campaign_id={campaignId}
            addToast={addToast}
            readOnly={readOnly}
            toastList={toastList}
            isAddMode={mode === 'add' ? true : false}

          />
          {!readOnly &&
            <div className="campaignsCreate__btns" >
              <div className="campaignsCreate__btns-row">
                <button className="button secondary" onClick={() => { setError(true); setFormSubmit(false); saveAsDraftFunc(); }}>
                  Save as Draft
                </button>
                <button
                  className="button secondary"
                  onClick={() => setModal("campDelete")}
                >
                  Delete
                </button>
              </div>
              <button className="button primary" onClick={() => { setFormSubmit(false); publishFunc(); }} disabled={selectedItems.selectedProduct.length === 0}>
                Create engagement
              </button>
            </div>
          }
        </div>
      )}
      <AnimatePresence>
        {modal === "assetDetail" && (
          <ModalAssetDetail
            backClick={() => setModal(null)}
            close={() => setModal(null)}
            {...modalData}
            modal={modal}
            setModal={setModal}
            mediaClick={() => setModal("assetFull")}
            delBtnClick={() => deletAsset(assetIndex)}
          />
        )}
        {modal === "assetFull" && (
          <ModalAssetFull
            close={() => setModal(null)}
            {...modalData}
            backClick={() => setModal("assetDetail")}
          />
        )}
        {modal === "campDelete" && (
          <ModalCampDelete
            close={() => setModal(null)}
            delBtnClick={() => {

              onDelete()
            }}
          />
        )}

        {modal === "campPublish" && (
          <ModalCampPublish
            close={() => { setFormSubmit(false); setModal(null) }}
            publishBtnClick={() => {
              setFormSubmit(true);
              if (mode === "edit") {
                updateEngagement();
              } else {
                createEngagement();
              }

            }
            }
            campaignMoney={form.campaignMoney}

            selectedItems={selectedItems}
          //budgetMsg={(form.campaignMoney > 0 && (parseInt(form.campaignMoney) - influencerBudget) > 0) ? 0 : parseInt(form.campaignMoney) - form.influencerBudget}// : form.budget}
          />
        )}
      </AnimatePresence>
    </>
  );
}