import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import { TextInput, TextArea } from "../../Components";
import {
  arrowRight,
  closeIcon,
  plusIcon,
} from "../../Components";
import { TagInput } from "../../Components";
import AssetVideo from "./AssetVideo";
import {
  contentService,
  uploadService

} from '../../Services';

const initForm = {
  title: "",
  tags: [],
  description: "",
  media_type_id: -1,
  mediafile: "",
  file_type: "",
  file_size: "",
};

export default function AssetsAdd({ setTab, setToastList, toastList }) {
  const tagsWrapper = useRef(null);
  const inpRef = useRef(null);
  const createAsset = useRef(null);
  const fileRef = useRef(null);

  const [form, setForm] = useState(initForm);
  const [error, setError] = useState(false);
  const [assetFile, setAssetFile] = useState("");
  const [assetFileDetail, setAssetFileDetail] = useState("");
  const [isAsset, setisAsset] = useState(true);
  const [mediaType, setMediaType] = useState(0);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };

  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });

  const readFile = ({ file, onLoad }) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = ({ target }) => onLoad(target.result);
      reader.readAsDataURL(file);
    }
  };
  const previewImage = () => {
    if (fileRef.current.files && fileRef.current.files[0]) {
      const fileType = fileRef.current.files[0].type;
      if (fileType.startsWith('image')) {
        setMediaType(2);
      }
      if (fileType.startsWith('video')) {
        setMediaType(1);
      }
      if ((fileType.startsWith('image') === false && fileType.startsWith('video') === false) || fileRef.current.files[0].size > 10000000) {
        setAssetFile("");
        setAssetFileDetail({});
        setDisableCreateButton(false);
        addToast({ error: true, date: new Date(), msg: " .GIF, .PNG, .JPG, .MP4, .MOV only with a maximum file size of 10MB" });
      } else {
        setDisableCreateButton(true);
        readFile({
          file: fileRef.current.files[0],
          onLoad: (result) => {

            // setAssetFile(result);
            setAssetFileDetail(fileRef.current.files[0]);
            upload();
          }
        });
      }
    }
  };

  const upload = () => {
    uploadService
      .upload(
        fileRef.current.files[0],
        true,
        isAsset
      )
      .then(response => {
        if (response.message === 'success') {
          const fileType = fileRef.current.files[0].type;
          setAssetFile(response.thumbail_path);
          // setAssetFileDetail(response);
          if (fileType.startsWith('image')) {
            setAssetFile(response.thumbail_path);
            updateForm({ ["mediafile"]: response.path, ["media_type_id"]: 2, ["file_type"]: response.file_type, ["file_size"]: response.file_size })
          }
          if (fileType.startsWith('video')) {
            setAssetFile(response.path);
            updateForm({ ["mediafile"]: response.path, ["media_type_id"]: 1, ["file_type"]: response.file_type, ["file_size"]: response.file_size })
          }
          setDisableCreateButton(false);
        } else {
          addToast({ error: true, date: new Date(), msg: response.message });
        }
      })
      .catch(err => {
        setAssetFile("");
        setAssetFileDetail({});
        setDisableCreateButton(false);
        addToast({ error: true, date: new Date(), msg: err.message });
      });
  };
  const delTags = (index) => {
    let newTags = [...form.tags];
    newTags.splice(index, 1);
    updateForm({ tags: newTags });
  };
  const setTags = (obj) => {
    if (obj.value !== '') {
      let newTags = [...form.tags];
      newTags.push(...obj.value.split(','));
      updateForm({ tags: newTags });
      obj.value = "";
    }
  };
  const handlePress = (event) => {
    const inputItem = event.target;
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      setTags(inputItem);
      return;
    }
  };

  /*useEffect(() => {
    updateForm({ ['mediafile']: assetFile });
  }, [assetFile]);*/
  const submitBtnClick = () => {

    if (form.title === "" || form.description === "" || form.mediafile === "" || form.tags.length === 0) {
      setError(true);
      addToast({ error: true, date: new Date(), msg: 'Enter details to add Asset.' });
    } else {
      saveAssest(form);
    }
  };
  const saveAssest = async () => {
    try {
      form.tags = form.tags.toString();
      const res = await contentService.create(form);
      setTab("main")
      addToast({ error: false, date: new Date(), msg: res.message });
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err.message });
      setTab("main")
    }
  };
  const addToast = data => {
    const newToastList = [...toastList];
    if (data.msg) {
      if (data.msg[data.msg.length - 1] === ".")
        data.msg = data.msg.slice(0, -1);
    }
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        createAsset.current.click();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="assetsAdd"
    >
      <div className="assetsAdd__form">
        <div className="assetsAdd__form-title">
          <h3 className="big">Add an asset</h3>
        </div>
        <div className="assetsAdd__form-inner">
          <div className="assetsAdd__form-head">
            <TextInput
              value={form.title}
              type="text"
              onChange={setInput("title")}
              label="Title"
              error={error}
              errorText="Please enter title"
              autoFocus
            />
          </div>
          <div className="assetsAdd__form-desc">
            <TextArea
              value={form.description}
              onChange={setInput("description")}
              label="Description"
              error={error}
              errorText="Please enter description"
              placeholder="Enter description"
              row="4"
            />
          </div>
          <div className="assetsAdd__form-tags" ref={tagsWrapper}>
            <TagInput
              btnClickHandler={setTags}
              inpRef={inpRef}
              onKeydown={handlePress}
              type="text"
              placeholder="Eg: Woman_wearing_glasses_300x250"
              label="Tags"
              icon={plusIcon}
              list={form.tags}
              error={error}
            />
            <p className="sm">
              TAMA highly recommends adding tags to your assets to help easily
              locate them when building campaigns.
            </p>
            <div className="assetsAdd__form-tags-items">
              {typeof form.tags !== 'string' &&
                form.tags.map((item, index) => {
                  return (
                    <div className="assetsAdd__form-tags-item" key={index}>
                      {item}
                      <div
                        className="assetsAdd__form-tags-item-icon"
                        onClick={() => delTags(index)}
                      >
                        {closeIcon}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="assetsAdd__form-file">
            <h6 className="semi">Upload file/logo</h6>
            <div className="assetsAdd__form-file-inner">
              {assetFile !== '' && (
                <div className="assetsAdd__form-preview">
                  {mediaType === 2 && (
                    <div className="assets__media">
                      <img src={process.env.PUBLIC_URL + assetFile} alt="" />
                    </div>
                  )}

                  {mediaType === 1 && (
                    <AssetVideo mediafile={assetFile} />
                  )}

                  <h6 className="sm">{assetFileDetail.name}</h6>
                </div>
              )}
              <div
                className={
                  "assetsAdd__form-upload " +
                  (error && form.mediafile === "" ? "error" : "")
                }
              >
                <div className="assetsAdd__form-upload-icon">{plusIcon}</div>
                <h5 className="reg">Upload media</h5>
                <p className="sm">
                  .GIF, .PNG, .JPG, .MP4, .MOV only with a maximum file size of 10MB
                </p>
                <button className="button primary">
                  Upload
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime"
                    ref={fileRef}
                    onChange={previewImage}
                  />
                </button>
                {error && form.mediafile === "" && (
                  <div className="assetsAdd__form-upload-error">
                    Please upload file
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button ref={createAsset} className="button primary" onClick={submitBtnClick} disabled={disableCreateButton}>
        Create asset
      </button>
    </motion.div>
  );
}
