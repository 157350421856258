import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { SearchInput, SelectInput, Pagination, Profile, arrowLeft } from "../../Components";
import { adminService } from "../../Services";


import CronMain from "./CronMain";
import CronDetail from "./CronDetail";

import { motion } from "framer-motion";
import { Toast } from "../../Components";
const selectDescList = [
  { id: "1", value: "Handle" },
  { id: "2", value: "Full Name" },
  { id: "3", value: "Email" },
  { id: "4", value: "Custom url" },
];

export default function Cron({ setAuth, setModal, NotifModul, profiles,
  setModalNotification }) {
  const inpRef = useRef(null);
  // eslint-disable-next-line
  const [selectDesc, setselectDesc] = useState();
  const [modalUrl, setModalUrl] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [modalDataUrl, setModalDataUrl] = useState(null);
  const [toastList, setToastList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cronDetails, setCronDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({});
  const [tab, setTab] = useState("main");
  const [tabData, setTabData] = useState();
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  const onPaginate = page => {
    setPage(page);
  };


  useEffect(() => {
    async function fetchCronDetails() {
      try {
        const cronData = await adminService.getCronDetails({
          page: page,
          pageSize: pageSize
        });
        //setCronDetails([{ name: 'cronname ', start: 'Mar 21 :10:00:01', end: 'Mar 21 :11:00:01', status: 'Finished', processed: '250' }, { name: 'fb cron', start: 'Mar 21 :10:00:01', end: 'Mar 21 :10:00:01', status: 'Running', processed: '100' }]);
        setCronDetails(cronData.data);
        setPaginationData(cronData.pagination)
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchCronDetails();
  }, [page, sortOrder]);

  const changeMediumDesc = (item) => {
    setselectDesc(item.value);
  };
  const sortList = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };
  useEffect(() => {
    if (modalUrl) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modalUrl]);
  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">
            <h2>Cron Details</h2>

            <Profile
              type="brand"
              setAuth={setAuth}
              setModal={setModal}
              NotifModul={NotifModul}
              profiles={profiles}
              setNotifModul={setModalNotification}
            />

          </div>
          {tab === "cronDetail" &&
            <>
              <button
                className="button tertiary big"
                onClick={() => setTab("main")}
              >
                {arrowLeft} Back
              </button>

            </>
          }
          <div className="url">
            <div className="url__table-title cronlist">
              {tab === "main" &&
                <h4>Cron Details</h4>}
              {tab === "cronDetail" &&
                <>

                  <h4>{tabData.label}</h4>
                </>
              }
              <div className="url__table-title-inner">
                {/*} <SelectInput
                  list={selectDescList}
                  selected={selectDescList[0]}
                  onChange={changeMediumDesc}
                />
                <SearchInput
                  type="text"
                  icon={searchIcon}
                  placeholder="Search"
                    />*/}

              </div>
            </div>
            {tab === "main" &&
              <CronMain onPaginate={onPaginate} cronDetails={cronDetails} isLoaded={isLoaded} setTab={setTab} setTabData={setTabData} paginationData={paginationData} />
            }
            {tab === "cronDetail" &&
              <CronDetail tabData={tabData} addToast={addToast} toastList={toastList} />
            }
          </div>
        </div>
      </div>
      {toastList && (
        <Toast
          type="rate"
          addClass="sm"
          list={toastList}
          setList={setToastList}
        />
      )}


    </>
  );
}
