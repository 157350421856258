import React from "react";

export default function ActivationItem(props) {
  return (
    <div className="accountContent__item">
      <h2 className="big">{props.number}</h2>
      <p>{props.text}</p>
    </div>
  );
}
