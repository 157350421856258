import React from "react";
import { useRef } from "react";
import AssetVideo from "./AssetVideo";

export default function AssetsGridItem(props) {
  const videoWrapperRef = useRef(null);
  const clickFunc = (e) => {
    if (!videoWrapperRef.current.contains(e.target)) props.onClick();
  };

  return (
    <div className="assetsItem" onClick={clickFunc}>
      <div className="assetsItem__inner">
        <div className="assetsItem__header">
          <div className="assetsItem__header-media" ref={videoWrapperRef}>
            {props.media_type_id === 2 && (
              <div className="assets__media">
                <img src={process.env.PUBLIC_URL + props.thumbnail} alt={props.title} />
              </div>
            )}
            {props.media_type_id === 1 && <AssetVideo {...props} />}
          </div>
          <h6>{props.title}</h6>
          <p className="sm desc">{props.description}</p>
        </div>
        <div className="assetsItem__row">
          <div className="assetsItem__col">
            <h6 className="sm">Upload date</h6>
            <p className="sm">{props.created_at}</p>
          </div>
          <div className="assetsItem__col">
            {props.campaigns && (
              <>
                <h6 className="sm">Times used</h6>
                <p className="sm bold">{props.campaigns.length}</p>
              </>
            )}
            {props.deletedOn && (
              <>
                <h6 className="sm">Deleted on</h6>
                <p className="sm">{props.deletedOn}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
