import React from 'react';
import { Modal } from '../../Components';

export default function ModalAuth(props) {
  return (
    <Modal addClass='modalRateConnect' close={props.close}>
      <div className='modal__rate'>
        <h6 className='mb'>
          Please confirm authorization to add this account or handle.
        </h6>
        <div className='modal__rate-btns'>
          <button className='button secondary' onClick={props.close}>
            Cancel
          </button>
          <button
            className='button primary'
            onClick={() => props.onAuthorize()}
          >
            Authorize
          </button>
        </div>
      </div>
    </Modal>
  );
}
