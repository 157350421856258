import React, { useState } from 'react';
import {
  facebookFilled,
  instagramfilled,
  facebook,
  instagram,
  tiktok,
  youtube
} from "../Components";
import Parser from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
}
function convertNumber(number) {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e+9

    ? (Math.abs(Number(number)) / 1.0e+9).toFixed(1) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(number)) >= 1.0e+6

      ? (Math.abs(Number(number)) / 1.0e+6).toFixed(1) + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(number)) >= 1.0e+3

        ? (Math.abs(Number(number)) / 1.0e+3).toFixed(1) + "K"

        : Math.abs(Number(number));
}
function testJSON(text) {
  if (typeof text !== "string") {
    return false;
  }
  try {
    var json = JSON.parse(text);
    return (typeof json === 'object');
  }
  catch (error) {
    return false;
  }
}
function convertJsontoString(text) {
  if (testJSON(text)) {
    return JSON.parse(text).blocks[0].text;
  } else {
    return text;
  }

}
const getIconFilled = platform => {
  switch (platform.toString().toUpperCase()) {
    case 'INSTAGRAM':
      return instagramfilled;
    case 'FACEBOOK':
      return facebookFilled;
    case 'TIKTOK':
      return tiktok;
    case 'YOUTUBE':
      return youtube;
  }
};
const getIcon = platform => {
  switch (platform.toString().toUpperCase()) {
    case 'INSTAGRAM':
      return instagram;
    case 'FACEBOOK':
      return facebook;
    case 'TIKTOK':
      return tiktok;
    case 'YOUTUBE':
      return youtube;
  }
};
const downloadFile = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}
const draftJsFormatingForDetails = (children) => {

  let editorState = EditorState.createWithContent(utils.testJSON(children.data) ?
    convertFromRaw(JSON.parse(children.data)) :
    convertFromRaw({
      blocks: [{
        key: "75gsm", text: "", type: "unstyled", depth: 0, inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      ], entityMap: {}
    }))

  //  const markup = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  //const markup = draftToHtml(rawContentState);
  let data = draftToHtml(rawContentState).replaceAll('style="color: rgb(13,13,13);background-color: rgb(255,255,255);font-size: 14px;font-family: Inter, sans-serif;">', '');
  let markup = data.replaceAll('</span>', '').replaceAll("<span ", "");
  let stringToShow = (editorState.getCurrentContent().getPlainText(''));
  return (
    <>
      {children.view === 'fullview' ?
        <span>{Parser(markup)}</span> :
        <span>{children.view === "lessview" ? (stringToShow.substring(0, 150) + "...") : (stringToShow.substring(0, 40) + "...")}</span>
      }
    </>)
}
const redirectUrl = (redirectObj) => {
  let arrayRedirect = [];
  if (redirectObj.linkaccount === 0 || redirectObj.rate === 0) {
    arrayRedirect.push('/influencer/social_accounts');
  } else if (redirectObj.profile === 0) {
    arrayRedirect.push('/influencer/profile');
  } else if (redirectObj.payee === 0) {
    arrayRedirect.push('/influencer/payment');
  } else {
    arrayRedirect.push('/influencer/dashboard');
  }
  return arrayRedirect;
}
export const utils = { convertNumber, redirectUrl, kFormatter, testJSON, convertJsontoString, getIconFilled, draftJsFormatingForDetails, getIcon, downloadFile };

