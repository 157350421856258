import React from 'react';

export default function ProductGuidelines(props) {
  return (
    <div className='main'>
      {Array.from(Array(4).keys()).map((item, index) => {
        return (
          <div key={index} style={{ padding: '10px', textAlign: 'center' }}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/product_guidelines/TAMA_Collateral_${index + 1}.png`}
              alt='product-guidelines'
              style={{ border: '1px solid' }}
            />
          </div>
        );
      })}
    </div>
  );
}
