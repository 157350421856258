import React, { useState, useEffect } from "react";
import { Modal } from "../../../../Components";


export default function ModalInConfirmExitCampaign(props) {
  const [confirmMessage, setConfirmMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  useEffect(() => {
    if (props.showConfirmAction === "createcamp") {
      setConfirmMessage("Save draft for your campaign?");
      setButtonText("Save campaign")
    }
    if (props.showConfirmAction === "editcamp") {
      setConfirmMessage("Save changes to your campaign?");
      setButtonText("Save campaign")
    }
    if (props.showConfirmAction === "createeng") {
      setConfirmMessage("Save draft for your engagement?");
      setButtonText("Save engagement")
    }
    if (props.showConfirmAction === "editeng") {
      setConfirmMessage("Save changes to your engagement?");
      setButtonText("Save engagement")
    }
  }, [])
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="modal__camp">
        <div className="modal__assetDetail-delete perm long">
          <h4>You are leaving this page.</h4>
          <p className="sm">
            {confirmMessage}
          </p>
          <div className="modal__assetDetail-delete-btns">
            <button className="button secondary" onClick={props.leave}>
              No
            </button>
            <button className="button primary" onClick={props.onSaveCamp}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </Modal>


  );
}

