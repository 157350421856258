import React from "react";
import { Modal } from "../../Components";
import { copyIcon, instagramfilled } from "../../Components";
import { utils } from '../../Helpers';
export default function ModalUrl(props) {
  return (
    <Modal addClass="customUrl" close={props.close}>
      <div className="modal__url">
        {/* <div className="modal__url-inner">
          <h3 className="big">{props.claim ? instagramfilled:''} {props.handle}</h3>
          <div className="modal__url-name">
            <h4>Full name</h4>
            <p>{props.full_name}</p>
          </div>
          <div className="modal__url-email">
            <h4>Email</h4>
            <p>{props.email_1}</p>
          </div>
          <div className="modal__url-custom">
            <h4>Custom url</h4>
            <div
              className="modal__url-link"
              onClick={() => props.copyBtn(props.link)}
            >
              <p>{props.link}</p>
              <div className="modal__url-copy">{copyIcon}</div>
            </div>
  </div>*/}
        <div className="campaignsInfluItem customUrlInner">
          <div className="campaignsInfluItem__head">
            <div className="campaignsInfluItem__head-image">
              <img
                src={
                  props.avatar && props.avatar !== ''
                    ? props.avatar
                    : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = `${process.env.PUBLIC_URL}images/placeholder/avatar.png`;
                }}
                alt="avatar" />
            </div>
            <div className="campaignsInfluItem__head-content">
              <h4>{props.claim ? instagramfilled : ''} {props.handle}</h4>
              <div className="campaignsInfluItem__head-row">
                <p className="sm">
                  <strong>{props.number_of_posts ? props.number_of_posts : 0}</strong>
                  Posts
                </p>
                <p className="sm">
                  <strong>{props.followers ? utils.convertNumber(props.followers) : 0}</strong>
                  Followers
                </p>
                <p className="sm">
                  <strong>{props.following ? utils.convertNumber(props.following) : 0}</strong>
                  Following
                </p>
              </div> <div className="campaignsInfluItem__head-row">

                <p className="sm">
                  <strong>{props.post_engagement ? props.post_engagement + "%" : 0}</strong>
                  Engagement
                </p>
              </div>
            </div>
          </div>
          <div >
            <div className="modal__url-name">
              <h4>Bio</h4>
              <p>{props.bio != "" && props.bio != null ? props.bio : 'NA'}</p>
            </div>
            <div className="modal__url-name">
              <h4>Full name</h4>
              <p>{props.full_name}</p>
            </div>
            <div className="modal__url-email">
              <h4>Email</h4>
              <p>{props.email_1}</p>
            </div>
            <div className="modal__url-custom">
              <h4>Custom url</h4>
              <div
                className="modal__url-link"
                onClick={() => props.copyBtn(props.link)}
              >
                <p>{props.link}</p>
                <div className="modal__url-copy">{copyIcon}</div>
              </div>
            </div>
          </div>
          <button
            className="button secondary"
            onClick={() => props.setModalUrl("delete")}
          >
            Remove influencer url
          </button>
          <p className="lastUpdated"> Last Updated: {props.updated_at}</p>
        </div>
      </div>
    </Modal>
  );
}
