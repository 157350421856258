import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';
import Modal from '../../../Components/Base/Modal';
import { accountService, alertService } from '../../../Services';

export default function ModalAccActivation(props) {
  const [termsContent, setTermsContent] = useState('<p></p>');
  useEffect(() => {

    (async () => {
      try {
        let roleId = props.roleId === "I'm a influencer" ? 1 : 3;
        const res = await accountService.getUserTermsByRole(roleId);
        setTermsContent(res['note']);
      } catch (err) {
        alertService.error(err);
      }
    })();
  }, []);

  return (
    <Modal active={props.active} close={props.close}>
      <div className='modal__terms'>
        <div className='modal__terms-image'>
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt='logo' />
        </div>
        <h3>Terms & conditions</h3>
        <div>
          <p>{Parser(termsContent)}</p>
        </div>
      </div>
    </Modal>
  );
}
