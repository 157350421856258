import React, { useEffect, useRef, useState } from "react";
import { chevronBottom } from "./SVG";

export default function YearToDate({ selected = null, list, onChange }) {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  //   const [currentList, setCurrentList] = useState(list);
  const [currentSelected, setCurrentSelected] = useState(selected);
  const onClick = (item) => {
    setCurrentSelected(item);
    if (onChange) onChange(item);

    setActive(false);
  };

  const toggleActive = () => {
    setActive(!active);
  };

  // useEffect(() => {
  //   if (currentSelected)
  //     setCurrentList(
  //       list.filter((item) => {
  //         let compareKey = key ?? "value";
  //         return item[compareKey] !== currentSelected[compareKey];
  //       })
  //     );
  // }, [currentSelected]);

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);
  return (
    <div className={"ytd " + (active ? "active" : "")} ref={wrapper}>
      <div className={"ytd__selected"} onClick={toggleActive}>
        {currentSelected ? (
          currentSelected.value
        ) : (
          <span>-Select an option-</span>
        )}
        {chevronBottom}
      </div>
      <ul className={"ytd__options " + (active ? "active" : "")}>
        {list.map((item, index) => (
          <li className="ytd__option" key={index} onClick={() => onClick(item)}>
            {item.value}
          </li>
        ))}
      </ul>
    </div>
  );
}
