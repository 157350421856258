import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { accountService } from '../Services';
import { storageHandler } from './storage';

export default function PrivateRoute({ children }) {
  const user = accountService.userValue;
  const token = storageHandler.getToken();
  const location = useLocation();
  if (token == null || token == '' || token == undefined) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/account/login' replace={true} state={location} />;
  }
  return children;
}
