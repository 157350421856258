import React, { useState, useEffect } from 'react';
import {
  Profile, Toast
} from '../../Components';
import { influencerService } from '../../Services';
import { link } from '../../Components';
import TamaCoinItem from './TamaCoinItem';
export default function UserTamaCoin(props) {
  const [tamaCoinList, settamaCoinList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toastList, setToastList] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {

        const res = await influencerService.getTamaCoinDetails();
        settamaCoinList(res['data']);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, []);
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title'>
            <h2>Tama Coins</h2>
            <Profile
              user={props.user}
              setModal={props.setModal}
              profiles={props.profiles}
              NotifModul={props.NotifModul}
              setNotifModul={props.setModalNotification}
            />
          </div>
          <div className='myprofile'>
            <div className='dashboard__table tamacoin_table'>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Activity</th>
                    <th>Coin</th>
                  </tr>
                </thead>
                <tbody>
                  {(isLoaded && tamaCoinList.length > 0) ? (
                    <>
                      {tamaCoinList.map((item, index) => {
                        return <TamaCoinItem
                          key={index}
                          {...item}
                        />;
                      })}
                    </>
                  ) : (
                      <tr>
                        <td colSpan='4' style={{ textAlign: 'center', fontWeight: 'normal' }}>
                          No records
                            </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {toastList && (
        <Toast type='coin' list={toastList} setList={setToastList} />
      )}
    </>
  );
}