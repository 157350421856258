import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import Details from "./Details";



import { tamacoinService, influencerService } from "../../../Services";
import { arrowLeft } from "../../../Components";
const initForm = {
  activity: "",
  notes: "",
  coin: "",
};

export default function NotifAdd({ setTab, tab, addToast, allusers, deleteNotification }) {
  const [form, setForm] = useState(initForm);
  const [modal, setModal] = useState(null);
  const [error, setError] = useState(false);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);

  const saveFunc = async () => {


    // console.log(form);
    if (
      form.activity !== "" &&
      form.notes !== "" &&
      form.coin !== ""
    ) {
      try {

        form.notes = ((form.notes));
        console.log(form);
        const res = await tamacoinService.create(form);
        setTab("main");
        addToast({
          error: false,
          msg: "Data saved successfully.",
        });

      } catch (err) {
        addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
      }
    } else {
      setError(true);
      addToast({
        error: true,
        msg: "Please add details to save activity",
        successText: "Data saved successfully.",
        date: new Date(),
      });
    }
  };
  return (
    <>
      {(tab === "add") && (
        <div className="main__inner-title-col">
          <div className="main__inner-title-row">
            <h3>Add Activity</h3>
            <button
              className="button tertiary big"
              onClick={() => setTab("main")}
            >
              {arrowLeft} Back
                  </button>
          </div>

        </div>
      )}

      <div className="admnotifAdd">
        <Details
          setInput={setInput}
          form={form}
          updateForm={updateForm}
          error={error}
        />

        <div className="admnotifAdd__btns">
          <div className="admnotifAdd__btns-row">
            <button className="button secondary" onClick={saveFunc}>
              Save
            </button>
            <button
              className="button secondary"
              onClick={() => setTab("main")}
            >
              Cancel
            </button>
          </div>

        </div>
      </div>
      
    </>
  );
}
