import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import {
  productService
} from '../../../Services';
export default function Products({ form, updateForm, toastList, readOnly, addToast, setSelectedItems, selectedItems, campaignProducts }) {
  const [facebookProducts, setFacebookProducts] = useState([]);
  const [instaProducts, setInstaProducts] = useState([]);
  const [tikTokProducts, setTikTokProducts] = useState([]);
  const [youtubeProducts, setYoutubeProducts] = useState([]);
  const { selectedContent, selectedProduct, selectedInfluencer, selectedGoods } =
    selectedItems;
  useEffect(() => {
    async function fetchProduct() {
      try {
        const productData = await productService.getAll({});
        let fb = [];
        let insta = [];
        let tikTok = [];
        let youtube = [];
        if (campaignProducts === undefined) {
          productData.data.map((item, index) => {
            if (item.name.startsWith("Facebook")) {
              fb.push(item);
            } else if (item.name.startsWith("Insta")) {
              insta.push(item);
            } else if (item.name.startsWith("Tik")) {
              tikTok.push(item);
            } else if (item.parent === 17) {
              youtube.push(item);
            }
          })
        } else {
          campaignProducts.map(selProduct => {
            productData.data.map((item, index) => {
              if (item.id === selProduct) {
                if (item.name.startsWith("Facebook")) {
                  fb.push(item);
                } else if (item.name.startsWith("Insta")) {
                  insta.push(item);
                } else if (item.name.startsWith("Tik")) {
                  tikTok.push(item);
                } else if (item.parent === 17) {
                  youtube.push(item);
                }
              }
            })
          })
        }
        setFacebookProducts(fb);
        setInstaProducts(insta);
        setTikTokProducts(tikTok);
        setYoutubeProducts(youtube);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchProduct();
  }, []);
  function onSelectProduct(id) {

    let newSelectedProduct = [];
    if (selectedProduct.indexOf(id) > -1) {
      //remove id.
      newSelectedProduct = selectedProduct.filter((item) => item !== id);
    } else {
      //push id.
      newSelectedProduct.push(...selectedProduct, id);
    }
    setSelectedItems({ ...selectedItems, selectedProduct: newSelectedProduct });
  }

  return (
    <div className="campaignsCreate__products">
      <div className="campaignsCreate__products-title">
        <h3 className="big">Products</h3>
        <p>
          Select all products you are looking to have included in this campaign.
        </p>

      </div>
      <div className="campaignsCreate__products-title">
        <p className="bold">
          <i>Note: A "post" refers to a static piece of content shared on Facebook or Instagram, while a "Story" typically refers to temporary content that disappears after 24 hours.  A video can be a post or a story.</i>
        </p>
      </div>
      <div className="campaignsCreate__products-content">
        <div className="campaignsCreate__products-content-title">
          <div className="campaignsCreate__products-content-title-left">
            <div className="campaignsCreate__products-content-title-image">
              <img
                src={process.env.PUBLIC_URL + "images/facebook.png"}
                alt="facebook"
              />
            </div>
            <h3 className="big">Facebook</h3>
          </div>
          <Link className="button edit" to="/admin/product_guidelines" target="_blank" rel="noopener noreferrer" >Product guidelines</Link>
        </div>
        <div className="campaignsCreate__products-items">
          {facebookProducts.map((item, index) => {
            return (
              <SocialItem
                itemData={item}
                key={index}
                updateForm={updateForm}
                form={form}
                selectedProduct={selectedProduct}
                onChange={onSelectProduct}
                readOnly={readOnly}
              />
            );
          })}
        </div>
      </div>
      <div className="campaignsCreate__products-content">
        <div className="campaignsCreate__products-content-title">
          <div className="campaignsCreate__products-content-title-left">
            <div className="campaignsCreate__products-content-title-image">
              <img
                src={process.env.PUBLIC_URL + "images/instagram.png"}
                alt="instagram"
              />
            </div>
            <h3 className="big">Instagram</h3>
          </div>
          <Link className="button edit" to="/admin/product_guidelines" target="_blank" rel="noopener noreferrer" >Product guidelines</Link>
        </div>
        <div className="campaignsCreate__products-items">
          {instaProducts.map((item, index) => {
            return (
              <SocialItem
                itemData={item}
                key={index}
                updateForm={updateForm}
                form={form}
                selectedProduct={selectedProduct}
                onChange={onSelectProduct}
                readOnly={readOnly}
              />
            );
          })}
        </div>
      </div>
      <div className="campaignsCreate__products-content">
        <div className="campaignsCreate__products-content-title">
          <div className="campaignsCreate__products-content-title-left">
            <div className="campaignsCreate__products-content-title-image">
              <img
                src={process.env.PUBLIC_URL + "images/tiktok.png"}
                alt="tiktok"
              />
            </div>
            <h3 className="big">TikTok</h3>
          </div>
          <Link className="button edit" to="/admin/product_guidelines" target="_blank" rel="noopener noreferrer" >Product guidelines</Link>
        </div>
        <div className="campaignsCreate__products-items">
          {tikTokProducts.map((item, index) => {
            return (
              <SocialItem
                itemData={item}
                key={index}
                updateForm={updateForm}
                form={form}
                selectedProduct={selectedProduct}
                onChange={onSelectProduct}
                readOnly={readOnly}
              />
            );
          })}
        </div>
      </div>
      <div className="campaignsCreate__products-content">
        <div className="campaignsCreate__products-content-title">
          <div className="campaignsCreate__products-content-title-left">
            <div className="campaignsCreate__products-content-title-image">
              <img
                src={process.env.PUBLIC_URL + "images/youtube.png"}
                alt="Youtube"
              />
            </div>
            <h3 className="big">Youtube</h3>
          </div>
          <Link className="button edit" to="/admin/product_guidelines" target="_blank" rel="noopener noreferrer" >Product guidelines</Link>
        </div>
        <div className="campaignsCreate__products-items">
          {youtubeProducts.map((item, index) => {
            return (
              <SocialItem
                itemData={item}
                key={index}
                updateForm={updateForm}
                form={form}
                selectedProduct={selectedProduct}
                onChange={onSelectProduct}
                readOnly={readOnly}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
const SocialItem = (props) => {
  const [checked, setChecked] = useState(false);
  return (
    <div
      className={"campaignsCreate__productsItem " + (props.selectedProduct.indexOf(props.itemData.id) > -1 ? "active" : "")}
    >
      <div className="checkbox">
        <input
          value={props.itemData.id}
          onChange={(e) => props.onChange(props.itemData.id)}
          type="checkbox"
          checked={props.selectedProduct.includes(props.itemData.id)}
          disabled={props.readOnly} />
        <label htmlFor=""></label>
      </div>
      <h4>{props.itemData.name}</h4>
      <p>{props.itemData.description}</p>
    </div >
  );
};
