import React from "react";
import { Modal } from "../../Components";

export default function ModalUrlDel(props) {
  return (
    <Modal addClass="modalFunds" close={props.close}>
      <div className="modal__url">
        <div className="modal__url-inner">
          <div className="modal__url-delete">
            <h4>Are you sure?</h4>
            <p className="sm">
              Deleting this influencer will remove their custom url. This will
              not remove them from the system.
            </p>
            <div className="modal__url-delete-btns">
              <button
                className="button secondary"
                onClick={() => props.setModalUrl("details")}
              >
                Cancel
              </button>
              <button className="button primary pink" onClick={props.close}>
                Delete url
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
