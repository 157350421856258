import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}/notifications`;
const tamayouInfluencerbaseUrl = `${process.env.REACT_APP_API_URL}/tamayou_influencers`;

export const notificationService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete,
  getAllUsers
};

function getAll(options) {
  let filterString = '';
  if (options.filter) {
    filterString = options.filter;
  }
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${baseUrl}?${filterString}` : `${baseUrl}`;
  return fetchWrapper.get(url);
}

function getAllPaging(options, filterString) {
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  }
  if (options.fileType) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[filetype]=${options.fileType}`;
  }
  if (options.search) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `filter[${options.search.key}]=${options.search.value}`;
  }
  if ('range' in options) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `range[month]=${options.range}`;
  }
  return filterString;
}


function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}



function getAllUsers(id, params) {
  return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/notification-user/${id}`, params);
 
}


// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}











