import React, { useState, useEffect } from "react";
import { Toast, Profile, arrowLeft, searchIcon, CampaignInsight, RevisionList, RevisionTree } from "../../Components";
import YearToDate from "../../Components/Base/YearToDate";
import CampaignsCreate from "./CampaignsCreate/CampaignsCreate";
import CampaignsDetail from "./CampaignsDetail";
import EngagementInsight from "./EngagementInsight";
import ViewCampaign from "./ViewCampaign";
import { useSearchParams } from 'react-router-dom';
import CampaignsMain from "./CampaignsMain";
import {
  campaignService
} from '../../Services';
import { utils } from "../../Helpers";

const ytdList = [
  { id: "0", value: "Year to date" },
  { id: "1", value: "Past 1 month" },
  { id: "6", value: "Past 6 months" },
  { id: "12", value: "Past 12 months" },
];

export default function AdminCampaigns({ setAuth, setModal, NotifModul, profiles, user, setModalNotification, locationData, setSaveCampaign, setShowConfirmModal, setShowConfirmAction, saveCampaign }) {
  const [tab, setTab] = useState("main");
  const [tabData, setTabData] = useState(null);
  const [isloaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({});
  const [campaignData, setCampaignData] = useState(null);
  const [engagementId, setEngagementId] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState('active');
  const [selectDesc, setselectDesc] = useState("title");
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [engagementOverview, setEngagementOverview] = useState({});
  const [loadList, setLoadList] = useState(false);
  const [isseacrhByIdDone, setIsseacrhByIdDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const campaignId = searchParams.get('id') ? searchParams.get('id') !== null || searchParams.get('id') !== undefined ? searchParams.get('id') : null : null;
  // eslint-disable-next-line
  const [ytd, setYtd] = useState(0);
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const sortStartDate = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }
  const changeMedium = (item) => {
    setYtd(item.id);
    setSearchList(!searchList);
    setPage(1);
    setSearchText("");

  };
  const changeCampaignStatus = (item) => {
    setCampaignStatus(item.id);
    setSearchText("");
    setPage(1);
  };
  const changeMediumDesc = (item) => {
    setselectDesc(item.id);
    setSearchText("");
  };
  const setSearchTextOnChange = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchText(event.target.value);
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);
  };
  useEffect(() => {
    setTab("main");
    setPage(1);
    setSearchText("");
    setCampaignStatus("active")
  }, [locationData.key]);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchText]);
  useEffect(() => {
    async function fetchData() {
      try {
        let filter = '';
        //filter = "sort[start_date]=" + sortOrder;
        if (campaignStatus != 'all') {
          filter = 'filter[status]=' + campaignStatus;
        }
        console.log(campaignId)
        if (searchText !== '') {
          if (filter != "") {
            filter += '&filter[' + selectDesc + ']=' + searchText;
          } else {
            filter = 'filter[' + selectDesc + ']=' + searchText;
          }
        } else {
          if (campaignId != "" && campaignId !== null && campaignId !== undefined && isseacrhByIdDone === false) {
            let id = 'id';
            filter += '&fields[' + id + ']=' + campaignId;
            setIsseacrhByIdDone(true);
          }
        }
        const res = await campaignService.getAll({
          page: page,
          pageSize: pageSize,
          filter: filter,
          sortBy: sortOrder
        });
        if (res.meta && res.meta.pagination) {
          setPaginationData(res.meta.pagination);
        }
        setCampaignData(res.data);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, ytd, loadList, campaignStatus, searchList, sortOrder]);
  useEffect(() => {
    if (tab === "mainList") {
      setLoadList(!loadList)
      setTab("main")
    }
    if (tab === "refreshDetail") {
      setLoadList(!loadList);
    }
    if (tab === "create" || tab === "edit") {
      setShowConfirmModal(true);
      setShowConfirmAction(tab + "eng");
    } else {
      setShowConfirmModal(false);
      setShowConfirmAction("");
    }
  }, [tab]);
  const downloadCsv = async () => {
    let filter = '';
    if (campaignStatus != 'all') {
      filter = 'filter[status]=' + campaignStatus;
    }
    filter = "sort[start_date]=" + sortOrder;
    if (searchText !== '') {
      if (filter != "") {
        filter += '&filter[' + selectDesc + ']=' + searchText;
      } else {
        filter = 'filter[' + selectDesc + ']=' + searchText;
      }
    }

    try {
      const response = await campaignService.getAll({
        page: page,
        pageSize: pageSize,
        fileType: 'csv',
        filter: filter,
        sortBy: sortOrder
      });
      utils.downloadFile(response, "adminCampaign_" + page + ".csv");

    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }


  const onPaginate = page => {
    setPage(page);
  };
  useEffect(() => {
    if (tab === "refreshDetail" && isloaded == true) {
      if (tabData !== null) {
        if (tabData.id != "" && tabData.id !== null) {
          let selCampaign = campaignData.filter(item => item.id == tabData.id);
          console.log(selCampaign);
          setTabData(selCampaign.length > 0 ? selCampaign[0] : tabData)
        }
      }
    }
  }, [campaignData]);

  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">
            {tab === "main" && (
              <div className="main__inner-title-col">
                <h2>Campaigns</h2>
              </div>
            )}
            {(tab === "detail" || tab === "refreshDetail") && (
              <div className="main__inner-title-col">
                <div className="main__inner-title-row">
                  <h2>{tabData.title} {' '}[{tabData.id}]</h2>
                  <div className={"main__inner-title-status " + tabData.status}>
                    {tabData.status}
                  </div>
                </div>
                <button
                  className="button tertiary big"
                  onClick={() => setTab("mainList")}
                >
                  {arrowLeft} Back
                </button>
              </div>
            )}
            {tab === "viewCampaign" && (
              <div className="main__inner-title-col">
                <div className="main__inner-title-row">
                  <h2>{tabData.title} </h2>
                  <div className={"main__inner-title-status " + tabData.status}>
                    {tabData.status}
                  </div>
                </div>
                <button
                  className="button tertiary big"
                  onClick={() => setTab("detail")}
                >
                  {arrowLeft} Back
                </button>
              </div>
            )}
            {(tab === "viewEngagement") && (
              <div className="main__inner-title-col">
                <div className="main__inner-title-row">
                  <h2>{engagementOverview.title}</h2>
                  <div className={"main__inner-title-status " + engagementOverview.status}>
                    {engagementOverview.status}
                  </div>
                </div>
                <button
                  className="button tertiary big"
                  onClick={() => setTab("engInsights")}
                >
                  {arrowLeft} Back
                </button>
              </div>
            )}
            {(tab === "revisiontree") && (
              <div className="main__inner-title-col">
                <div className="main__inner-title-row">
                  <h2>{engagementOverview.title}</h2>
                </div>

              </div>
            )}

            {tab === "create" && (
              <div className="main__inner-title-col">
                <h2>Create an engagement</h2>

                <button
                  className="button tertiary big"
                  onClick={() => setTab("detail")}
                >
                  {arrowLeft} Back
                </button>
              </div>
            )}
            {tab === "insights" && (
              <div className="main__inner-title-col">
                <h2>Insight  - {tabData.title}</h2>

                <button
                  className="button tertiary big"
                  onClick={() => setTab("detail")}
                >
                  {arrowLeft} Back
                </button>
              </div>
            )}
            {tab === "engInsights" && (
              <div className="main__inner-title-col">
                <h2>Insight - {engagementOverview.title}</h2>

                <button
                  className="button tertiary big"
                  onClick={() => setTab("detail")}
                >
                  {arrowLeft} Back
                </button>
              </div>
            )}
            {tab === "edit" && (
              <div className="main__inner-title-col">
                <h2>Edit an engagement</h2>

                <button
                  className="button tertiary big"
                  onClick={() => setTab("detail")}
                >
                  {arrowLeft} Back
                </button>
              </div>
            )}

            <Profile
              type="brand"
              setAuth={setAuth}
              setModal={setModal}
              NotifModul={NotifModul}
              profiles={profiles}
              setNotifModul={setModalNotification}
            />
          </div>

          {(tab === "main" && isloaded) && (
            <CampaignsMain sortOrder={sortOrder} sortStartDate={sortStartDate} search={search} searchText={searchText} setSearchTextOnChange={setSearchTextOnChange} selectDesc={selectDesc} changeMediumDesc={changeMediumDesc} campaignStatus={campaignStatus} changeCampaignStatus={changeCampaignStatus} downloadCsv={downloadCsv} setTab={setTab} setTabData={setTabData} campaignData={campaignData} paginationData={paginationData} onPaginate={onPaginate} />
          )}
          {(tab === "detail" || tab === "refreshDetail") && (
            <CampaignsDetail setTab={setTab} setEngagementOverview={setEngagementOverview} tabData={tabData} setEngagementId={setEngagementId} setM />
          )}
          {tab === "viewCampaign" && (
            <ViewCampaign addToast={addToast} setTab={setTab} campaignId={tabData.id} mode="edit" />
          )}
          {tab === "create" && (
            <CampaignsCreate setSaveCampaign={setSaveCampaign} saveCampaign={saveCampaign} mode={"add"} addToast={addToast} setTab={setTab} campaignId={tabData.id} campaignProducts={tabData.products} campaignAssets={tabData.contents} />
          )}
          {tab === "edit" && (
            <CampaignsCreate setSaveCampaign={setSaveCampaign} saveCampaign={saveCampaign} mode={"edit"} addToast={addToast} setTab={setTab} campaignId={tabData.id}
              engagementId={engagementId} campaignProducts={tabData.products} campaignAssets={tabData.contents} />
          )}
          {tab === "insights" && (
            <CampaignInsight setTab={setTab} campaignId={tabData.id} engagementId={engagementId} modalDataCampaigns={tabData} />
          )}
          {tab === "engInsights" && (
            <EngagementInsight engagementOverview={engagementOverview} setTab={setTab} campaignId={tabData.id} engagementId={engagementId} />
          )}
          {tab === "viewEngagement" && (
            <CampaignsCreate mode="edit" addToast={addToast} readOnly={true} setTab={setTab} engagementOverview={engagementOverview}
              campaignId={tabData.id} engagementId={engagementId} campaignProducts={tabData.products} campaignAssets={tabData.contents} />
          )}

          {tab === "revisionlist" && (
            <RevisionList
              setTab={setTab}
              engagementId={engagementId}
            />
          )}
          {tab === "revisiontree" && (
            <RevisionTree
              setTab={setTab}
              engagementId={engagementId}
            />
          )}
        </div>
      </div>
      {toastList && (
        <Toast type="rate" list={toastList} setList={setToastList} />
      )}
    </>
  );
}
