import React from "react";

export default function AgeChart({ ageData }) {
  /*const ageData = [
    {
      id: "1",
      age: "13-17",
      percent: "0.5%",
    },
    {
      id: "2",
      age: "18-24",
      percent: "11.6%",
    },
    {
      id: "3",
      age: "25-34",
      percent: "47.1%",
    },
    {
      id: "4",
      age: "35-44",
      percent: "26.3%",
    },
    {
      id: "5",
      age: "45-54",
      percent: "9.7%",
    },
    {
      id: "6",
      age: "55-64",
      percent: "2.8%",
    },
    {
      id: "7",
      age: "65+",
      percent: "1.5%",
    },
  ];*/
  return (
    <div className="campaignsChartTop__ageChart">
      {ageData[0].map((item, index) => {

        return (
          <div className="campaignsChartTop__ageChart__row" key={index}>
            <div className="campaignsChartTop__ageChart__text">{item.age}</div>
            <div className="campaignsChartTop__ageChart__line">
              <span style={{ width: item.percent }}></span>
            </div>
            <div className="campaignsChartTop__ageChart__text">
              {item.percent + "%"}
            </div>
          </div>
        );


      })}
    </div>
  );
}
