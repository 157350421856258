import React, { useState } from "react";
import { SearchInput, SelectInput, Pagination } from "../../Components";

import {
  arrowTop,
  download,
  searchIcon,
  arrowBottom
} from "../../Components";
import { CampaignsModul } from "./CampaignsModul";
import CampaignsTableItem from "./CampaignsTableItem";

const selectDescList = [
  { id: "title", value: "Title" },
  // { id: "3", value: "Description" },
  //{ id: "4", value: "Start date" },
  //{ id: "5", value: "End date" },
  //{ id: "6", value: "Budget" },
];
const selectCampTypeList = [
  { id: "active", value: "Active" },
  { id: "draft", value: "Draft" },
  { id: "all", value: "All" },
];
export default function CampaignsMain({ sortOrder, sortStartDate, search, setSearchTextOnChange, changeMediumDesc, selectDesc, setTab, setTabData, campaignData, paginationData, onPaginate, downloadCsv, changeCampaignStatus, campaignStatus, searchText }) {

  return (
    <div className="campaigns adm">
      <div className="campaigns__table-title">
        <div className="campaigns__table-title-info">
          <h4>Manage Campaigns</h4>
        </div>
        <div className="campaigns__table-title-inner">
          <SelectInput
            list={selectCampTypeList}
            value={campaignStatus}
            selected={selectCampTypeList.filter(item => item.id === campaignStatus)[0]}
            onChange={changeCampaignStatus}
          />
          <SelectInput
            list={selectDescList}
            value={selectDesc}
            selected={selectDescList.filter(item => item.id === selectDesc)[0]}
            onChange={changeMediumDesc}
          />
          <SearchInput value={searchText} onClick={search} type="text" icon={searchIcon} placeholder="Search" onChange={setSearchTextOnChange} />
        </div>
      </div>
      <>
        <div className="campaigns__table">
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Title</th>
                <th>
                  <div className="campaigns__table-filter" onClick={sortStartDate}>
                    Start date {sortOrder === 'asc' ? arrowTop : arrowBottom}
                  </div>
                </th>
                <th>End date</th>
                <th>Budget</th>
                <th>Comp</th>
                <th>Engmnts</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {campaignData.map((item, index) => {
                return (
                  <CampaignsTableItem
                    onClick={() => {
                      setTabData(item);
                      setTab("detail");
                    }}
                    key={index}
                    {...item}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </>
      <div className="campaigns__table-footer">
        <button className="button tertiary download" onClick={downloadCsv}>
          Download CSV {download}
        </button>
        <Pagination
          perPage={paginationData['per_page']}
          total={paginationData['total']}
          currentPage={paginationData['current_page']}
          totalPages={paginationData['total_pages']}
          onPaginate={onPaginate}
        />
      </div>
    </div>
  );
}
