import React, { useState } from 'react';
import Modal from '../Modal';
import NotificationItem from '../Notification/NotificationItem';
export default function ModalNotification(props) {
  const [readAll, setReadAll] = useState(false);

  return (
    <Modal addClass='notif' close={props.close}>
      <div className='modal__notification'>
        <div className='modal__notification-inner'>
          {props.NotifModul.length > 0 ?
            <>
              <div className='modal__notification-title solo'>
                <h4>Notifications</h4>

                <button
                  className='button tertiary'
                  onClick={() => { setReadAll(true); props.updateNotificationStatus('all', 'read'); }}
                >
                  Mark all as read
                </button>
              </div>
              <div className='modal__notification-items'>
                {props.NotifModul.map((item, index) => {
                  return (
                    <NotificationItem
                      accountCount={props.accountCount}
                      account={item.handle ? item.handle.handle : ''}
                      accountIcon={item.handle.icon ? item.handle.icon[0] : ''}
                      updateNotificationStatus={props.updateNotificationStatus}
                      NotifModul={props.NotifModul}
                      setNotifModul={props.setNotifModul}
                      readAll={readAll}
                      setModalData={() => props.setModalData(item)}
                      setModal={props.setModal}
                      setModalNotification={props.setModalNotification}
                      setModalNotificationData={props.setModalData}
                      modal={"notifDetail"}
                      key={index}
                      {...item}
                    />
                  );
                })}
              </div>
            </>
            : <>
              <div
                className=
                'notification__placeholder full' >
                < div className='notification__placeholder-image'>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/placeholder/notificationPlaceholder.png'
                    }
                    alt='notificationPlaceholder.png'
                  />
                </div>
                <h6>
                  You don’t have any notifications yet.
                </h6>
              </div>
            </>
          }
        </div>
      </div>
    </Modal>
  );
}
