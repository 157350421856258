import React from 'react';

export default function SearchInput(props) {
  return (
    <div className='input__outer'>
      {props.label && <label>{props.label}</label>}
      <div
        className={
          'input ' +
          (props.error && props.value === '' ? 'error' : '') +
          (props.icon ? 'wIcon' : '')
        }
      >
        <input
          ref={props.inpRef}
          onChange={props.onChange}
          value={props.value}
          defaultValue={props.defaultValue}
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disable ? props.disable === true ? true : false : false}
        />
        {props.icon && (
          <div className='input__icon' onClick={props.onClick}>
            {props.icon}
          </div>
        )}
      </div>
      {props.error && props.value === '' && (
        <div className='input__error'>{props.errorText}</div>
      )}
    </div>
  );
}
