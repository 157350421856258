import React, { useState, useEffect } from "react";
import { Modal } from "../../Components";
export default function ModalBrandProfile(props) {
  return (

    <Modal addClass="modalFunds" close={props.close} >
      <div className="modal__users">
        <div className="modal__users-row">
          <div className="modal__users-col" style={{ marginRight: 'auto' }}>
            <h4>{props.ModalData.data.brands ? props.ModalData.data.brands[0].brand_name : ''}</h4>
            <h6>Point Of Contact</h6>
            <p>{props.ModalData.data.contact_info.length > 0 ?
              <>

                <p>{props.ModalData.data.contact_info ? props.ModalData.data.contact_info[0].first_name + " " + props.ModalData.data.contact_info[0].last_name : ''}</p>
                <p>{props.ModalData.data.contact_info ? props.ModalData.data.contact_info[0].job_title : ''}</p>
                <p>{props.ModalData.data.contact_info ? props.ModalData.data.contact_info[0].email : ''}
                  {props.ModalData.data.contact_info ? props.ModalData.data.contact_info[0].preferred_contact_type === 'email' ? '(Preferred)' : '' : ''}
                </p>
                <p>{props.ModalData.data.contact_info ? props.ModalData.data.contact_info[0].contact_no : ''}
                  {props.ModalData.data.contact_info ? props.ModalData.data.contact_info[0].preferred_contact_type === 'phone' ? '(Preferred)' : '' : ''}</p>

              </> : 'NA'}
            </p>
          </div>
          <div className="modal__users-col">
            <img
              style={{ width: '48px', height: '48px' }}
              src={
                props.ModalData.data.brands ? props.ModalData.data.brands[0].photo_thumbnail

                  : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
              }}
              alt='avatar'
            />
          </div>
        </div>
      </div>
    </Modal >

  );
}

