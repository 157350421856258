import React, { useState } from "react";
import { EmailInput } from "../../Components";
import { TextInput } from "../../Components";
import { motion } from "framer-motion";
import { SelectInput } from "../../Components";

const selectPreferredMethodList = [
  { id: 'email', value: "Email" },
  { id: 'phone', value: "Phone" },
];

export default function BrandPointOfContact({
  form,
  setInput,
  saveChanges,
  updateForm,
  refPoc
}) {
  const [error, setError] = useState(false);
  const changeMedium = (key) => (item) => updateForm({ ["pointofContact"]: { ...form.pointofContact, "preferred_contact_type": item.id } });
  const selectContactType = React.useMemo(() => {
    if (form.pointofContact.preferred_contact_type !== "") {
      return selectPreferredMethodList.findIndex(
        (item) => item.id === form.pointofContact.preferred_contact_type
      );
    }
    return null;
    // eslint-disable-next-line
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 * 2 }}
      exit={{ opacity: 0, y: 30 }}
      className="form rel"
    >
      <div className="form__title">
        <h3 className="big">Point of contact</h3>
      </div>
      <button
        ref={refPoc}
        className="button secondary"
        onClick={() => {
          saveChanges("point");
          setError(true);
        }}
      >
        Save changes
      </button>
      <div className="form__point">
        <div className="form__point-row">
          <TextInput
            value={form.pointofContact.first_name}
            type="text"
            onChange={setInput("pointofContact", "first_name")}
            label="First name"
            error={error}
            errorText="Please enter First name"
            autocomplete="brandfirstname"
          />
          <TextInput
            value={form.pointofContact.last_name}
            type="text"
            onChange={setInput("pointofContact", "last_name")}
            label="Last name"
            error={error}
            errorText="Please enter Last name"
            autocomplete="brandlastname"
          />
          <TextInput
            value={form.pointofContact.job_title}
            type="text"
            onChange={setInput("pointofContact", "job_title")}
            label="Job title"
            error={error}
            errorText="Please enter Job title"
            autocomplete="brandjobtitle"
          />
          <EmailInput
            value={form.pointofContact.email}
            type="email"
            onChange={setInput("pointofContact", "email")}
            label="E-mail"
            error={error}
            errorText="Please enter E-mail"
          />
          <TextInput
            value={form.pointofContact.contact_no}
            type="text"
            onChange={setInput("pointofContact", "contact_no")}
            label="Phone"
            placeholder="(XXX) XXX-XXXX"
            error={error}
            errorText="Please enter phone"
            autocomplete="tel"
          />
          <SelectInput
            label="Preferred contact method"
            value={form.pointofContact.preferred_contact_type}
            list={selectPreferredMethodList}
            selected={selectPreferredMethodList[selectContactType]}
            onChange={changeMedium("pointofContact", "contactMethod")}
            error={error}
            errorText="Please select method"
          />
        </div>
      </div>
    </motion.div>
  );
}
