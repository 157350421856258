import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { closeIcon, toastErrorIcon, toastSuccesIcon } from '../SVG';
import { motion } from 'framer-motion';

export default function Toast({ list, setList }) {
  const removeItem = id => {
    setList(list => {
      return list.filter(item => item.id !== id);
    });
  };

  return ReactDOM.createPortal(
    <div className='toast__outer'>
      {list.map((item, index) => {
        return (
          <ToastItem
            data={item}
            key={index}
            remove={() => removeItem(item.id)}
          />
        );
      })}
    </div>,
    document.getElementById('toast')
  );
}

const ToastItem = ({ data, remove }) => {
  const dateFormat = date => {
    date = date ? date : new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let dateOfMonth = date.getDate();
    if (dateOfMonth < 10) {
      dateOfMonth = '0' + dateOfMonth;
    }

    const strDate = `${dateOfMonth}/${month}/${year}`;

    return strDate;
  };
  const timeFormat = date => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
  };
  useEffect(() => {
    setTimeout(() => {
      remove();
    }, 5 * 1000);
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className={'toast ' + (data.error ? 'error' : '')}
    >
      <div className='toast__icon'>
        {data.error ? toastErrorIcon : toastSuccesIcon}
      </div>
      {data.error ? (
        <>
          {data.msg ? data.msg : 'Error'} {' '}
        </>
      ) : (
        <>
          {data.msg ? data.msg : 'Data saved successfully'} {' '}
        </>
      )}
      <div className='toast__close' onClick={remove}>
        {closeIcon}
      </div>
    </motion.div>
  );
};
