import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChart(props) {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => null,
          label: function (tooltipItem) {
            var tooltipText = "";
            if (tooltipItem.dataset.data[tooltipItem.dataIndex] != null)
              tooltipText =
                tooltipItem.dataset.data[tooltipItem.dataIndex].toString();
            return tooltipItem.label + " - " + tooltipText;
          },
          labelTextColor: function (context) {
            return "#000D57";
          },
        },
        displayColors: false,
        backgroundColor: "#FAFFFA",
        borderColor: "rgba(16, 38, 32, 0.15)",
        borderWidth: 1,
        yAlign: "bottom",
      },
    },
    elements: {
      point: {
        borderWidth: 0,
        radius: 10,
      },
      line: {
        tension: 0.5,
      },
    },
    scales: {

      y: {

        ticks: {
          callback: (label) => {
            if (label >= 1000) {
              return `${label / 1000}K`;
            } else {
              return label;
            }
          },

          font: { size: 6, family: "Inter" },
          color: "#000D57",
        },
        grid: {
          // drawTicks: false,
          color: "#E9F1E7",
        },
        beginAtZero: true,
      },
      x: {
        ticks: {
          font: { size: 6, family: "Inter" },
          color: "#000D57",
        },
        grid: {
          // drawTicks: false,
          color: "#E9F1E7",
        },
        beginAtZero: true,
      },
    },
  };

  const labels = props.labels;

  const data = {
    labels,
    datasets: [
      {
        data: props.data,
        // borderColor: "#08A708",
        backgroundColor: "#08A708",
        hoverBackgroundColor: "#102620",
        borderWidth: 0,
        pointBackgroundColor: "rgba(0,0,0,0)",
        pointBorderColor: "rgba(0,0,0,0)",
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
