import React from "react";
import {Modal} from "../../../Components";
export default function ModalNotifDelete(props) {
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="modal__camp">
        <div className="modal__assetDetail-delete perm long">
          <h4>Are you sure?</h4>
          <p className="sm">
            Deleting this draft notification will remove it from your library
            for notification.
          </p>
          <div className="modal__assetDetail-delete-btns">
            <button className="button secondary" onClick={props.close}>
              Cancel
            </button>
            <button className="button primary pink" onClick={props.delBtnClick}>
              Delete notification
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
