import React from "react";
import { Modal } from "../../../../Components";
import { arrowLeft } from "../../../../Components";
import AssetVideo from "../Assets/AssetVideo";

export default function ModalAssetFull(props) {
  return (
    <Modal addClass="modalAsset" close={props.close}>
      <div className="modal__asset">
        <div className="modal__asset-inner">
          <div className="modal__assetFull">
            <button className="button tertiary big" onClick={props.backClick}>
              {arrowLeft} Back
            </button>
            <div className="modal__assetFull-media">
              {props.media_type_id === 2 && (
                <div className="assets__media">
                  <img src={process.env.PUBLIC_URL + props.mediafile} alt="" />
                </div>
              )}
              {props.media_type_id === 1 && <AssetVideo {...props} />}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
