import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  Header,
  Sidebar,
  NotificationModul,
  ModalSwitch,

} from '../Components';
import { accountService, campaignService } from '../Services';
import { PrivateRoute } from '../Helpers';
import {
  Billing,
  BrandProfile,
  Assets,
  BrandDashboard,
  Campaigns,
  ProductGuidelines,
  Recommend
} from './index';

export default function Brand({ user, setChangePage, changePage, path, allNotifications,
  setModalNotification,
  setModalNotificationData,
  updateNotificationStatus,
  accountCount, locationData, impersonationBar, handleAnchorClick, setSaveCampaign, setShowConfirmModal, setShowConfirmAction, saveCampaign
}) {
  const [NotifModul, setNotifModul] = useState(NotificationModul);
  const [modalData, setModalData] = useState(null);
  const [modal, setModal] = useState(null);
  const [menu, setMenu] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const location = useLocation();
  const [toastList, setToastList] = useState([]);

  const navigate = useNavigate();
  const [newNavigation, setNewNavigation] = useState({});
  useEffect(() => {
    if (user) {
      const roleId =
        user['data']['roles'].length > 0
          ? user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['id']
          : '';
      if (roleId === 1) {
        //navigate('/influencer/dashboard');
      } else if (roleId === 2) {
        //navigate('/admin/dashboard');
      }
    }
  }, []);
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    if (modal) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [modal]);
  const deleteNotif = id => {
    setNotifModul(NotifModul.filter(item => item.id !== id));
    if (modal === 'notifDelete') {
      setModal('notifications');
    } else if (modal === 'notifDeleteDash') {
      setModal(null);
    }
  };
  const leaveCampaign = () => {
    console.log("dsd")
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove('active');
  }, [location]);
  const close = e => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  async function getActiveOpportunityCount() {
    try {
      const res = await campaignService.getCount();
      setNewNavigation({ 'campaigns_count': res.campaign_count });
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err });
    }
  }
  useEffect(() => {
    getProfiles();
    getActiveOpportunityCount();
  }, []);

  async function getProfiles() {
    try {
      const res = await accountService.getProfiles({});
      if (res && res['data']) {
        setProfiles(res['data']['profiles']);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const hideHeaderSidebar =
    path.indexOf('product_guidelines') > -1 ? true : false;

  return (
    <>
      <div className={impersonationBar ? "impersonationDivMargin" : ''}>
        {!hideHeaderSidebar && (
          <>
            <Header
              setModal={setModal}
              menu={menu}
              setMenu={setMenu}
              user={user}
              profiles={profiles}
            />
            <Sidebar menu={menu} handleAnchorClick={handleAnchorClick} close={close} user={user} type={'brand'} newNavigation={newNavigation} impersonationBar={impersonationBar} />
          </>
        )}
        <main>
          <Routes>
            <Route
              exact
              path='/billing'
              element={
                <PrivateRoute>
                  <Billing user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification} />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/profile'
              element={
                <PrivateRoute>
                  <BrandProfile
                    user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/'
              element={
                <PrivateRoute>
                  <Billing user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification} />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/assets'
              element={
                <PrivateRoute>
                  <Assets user={user}
                    setModal={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    locationData={locationData} />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/dashboard'
              element={
                <PrivateRoute>
                  <BrandDashboard
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotify={setModalNotification}
                    setModalNotification={setModalNotification}
                    setModalNotificationData={setModalNotificationData}
                    accountCountNotification={accountCount}
                    updateNotificationStatus={updateNotificationStatus}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/campaigns'
              element={
                <PrivateRoute>
                  <Campaigns
                    setShowConfirmModal={setShowConfirmModal}
                    setShowConfirmAction={setShowConfirmAction}
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                    locationData={locationData}
                    saveCampaign={saveCampaign}
                    setSaveCampaign={setSaveCampaign}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/recommendations'
              element={
                <PrivateRoute>
                  <Recommend
                    user={user}
                    setModalSwitch={setModal}
                    profiles={profiles}
                    NotifModul={allNotifications}
                    setModalNotification={setModalNotification}
                  />
                </PrivateRoute>
              }
            />


            <Route
              exact
              path='/product_guidelines'
              element={
                <PrivateRoute>
                  <ProductGuidelines user={user} />
                </PrivateRoute>
              }
            />
            <Route path="*" element={
              <PrivateRoute>
                <BrandDashboard
                  user={user}
                  setModalSwitch={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotify={setModalNotification}
                  setModalNotification={setModalNotification}
                  setModalNotificationData={setModalNotificationData}
                  updateNotificationStatus={updateNotificationStatus}
                />
              </PrivateRoute>
            } />
          </Routes>
        </main>
      </div>
      <AnimatePresence>
        {modal === 'switch' && (
          <ModalSwitch
            changePage={changePage}
            setChangePage={setChangePage}
            profiles={profiles}
            close={() => setModal(null)}
          />
        )}

      </AnimatePresence>
    </>
  );
}