import React from "react";

export default function EngagementTableItem(props, setTab) {
  return (
    <tr >
      <td onClick={props.onClick}>{props.id}</td>
      <td onClick={props.onClick}>
        <p>{props.title}</p>
      </td>
      <td onClick={props.onClick}>{props.start_date}</td>
      <td onClick={props.onClick}>{props.end_date}</td>
      <td className="bold" onClick={props.onClick}>{props.budget}</td>
      <td className="bold" onClick={props.onClick}>{props.views}</td>
      <td className="bold" onClick={props.onClick}>{props.reach}</td>
      <td className="bold" onClick={props.onClick}>{props.likes}</td>
      <td className="bold" onClick={props.onClick}>{props.shares}</td>
      <td className="bold" onClick={props.onClick}>{props.engagement ? parseFloat(props.engagement).toFixed(3) : 0}</td>
      <td onClick={props.onClick}>
        <div className={"engagement__table-status " + props.status}>
          {props.status}
        </div>
      </td>
      <td >
        {(props.status == 'active' && props.revision != 0) ?
          <a onClick={() => {
            props.setEngagementId(props.id); props.setTab("revisiontree")
          }}>
            <img src='images/revision-edit.png' />
          </a>
          : <img src='images/revision-ban.png' />
        }


      </td>
    </tr >
  );
}
