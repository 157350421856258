import React, { useEffect, useState } from "react";
import {SelectInput} from "../../../Components";
import {SearchInput} from "../../../Components";
import {
  closeIcon,
  filterIcon,
  searchIcon,
} from "../../../Components";

const selectList = [
  { id: "1", value: "Follower count" },
  { id: "2", value: "Follower count" },
  { id: "3", value: "Follower count" },
];

export default function Assign({ form, updateForm, error,allusers }) {
  const [filter, setFilter] = useState(true);
  const [select, setSelect] = useState();
  const [checkedAll, setCheckedAll] = useState(false);
  const changeMedium = (item) => {
    setSelect(item.id);
  };
  return (
    <div className="admnotifAssign">
      <div className="admnotifAssign__title">
        <h3 className="big">Assign to</h3>
        <button
          className="admnotifAssign__title-btn"
          onClick={() => setFilter(!filter)}
        >
          {filter ? closeIcon : filterIcon}
        </button>
      </div>
      <div className="admnotifAssign__filter">
        {filter && (
          <>
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
            <SelectInput
              list={selectList}
              selected={selectList[0]}
              onChange={changeMedium}
            />
          </>
        )}
        <SearchInput type="text" icon={searchIcon} placeholder="Search" />
        <div className="checkbox">
          <input
            type="checkbox"
            checked={checkedAll}
            onChange={(e) => {
              setCheckedAll(e.target.checked);
              if (e.target.checked) {
                updateForm({ assignTo: allusers });
              } else {
                updateForm({ assignTo: [] });
              }
            }}
          />
          <label htmlFor=""></label>
          <p className="sm">{checkedAll ? "Unselect all" : "Select all"}</p>
        </div>
      </div>
      <div className="admnotifAssign__items">
        {allusers.map((item, index) => {
          return (
            <AssignItem
              checkedAll={checkedAll}
              updateForm={updateForm}
              form={form}
              key={index}
              itemData={item}
              setCheckedAll={setCheckedAll}
            />
          );
        })}
      </div>
      {error && form.assignTo.length === 0 && (
        <div className="admnotifAssign__error">Please select</div>
      )}
    </div>
  );
}
const AssignItem = (props) => {

  
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.assignTo];
      arr.push(props.itemData);
      props.updateForm({ assignTo: arr });
    } else {
      props.setCheckedAll(false)
      props.updateForm({
        assignTo: props.form.assignTo.filter(
          (item) => (!(item.detail_id ===props.itemData.detail_id
            && item.role_id ===props.itemData.role_id &&  item.user_id ===props.itemData.user_id))
        ),
      });
    }
  };
  useEffect(() => {
    // if(props.checkedAll!== false)
    setChecked(props.checkedAll);
    let selUser=[];
    if(props.form.assignTo) {
      setChecked(false)
      selUser = props.form.assignTo.filter(item=>(item.detail_id === props.itemData.detail_id
      && item.role_id === props.itemData.role_id &&  item.user_id === props.itemData.user_id))
     
      setChecked(selUser.length >  0 ?true :false)
    } 
    }, [props.checkedAll]);
  
  return (
    <div className={"admnotifAssignItem " + (checked ? "active" : "")}>
      <div className="checkbox">
        <input checked={checked} onChange={onChange} type="checkbox" />
        <label htmlFor=""></label>
      </div>
      <div className="admnotifAssignItem__head">
        <div className="admnotifAssignItem__head-image">
          <img
            src={process.env.PUBLIC_URL + props.itemData.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
            }}
            alt="avatar"
          />
        </div>
        <div className="admnotifAssignItem__head-content">
          <h4>{props.itemData.name}</h4>
          <p className="sm bold">{props.itemData.type}</p>
          <div className="admnotifAssignItem__head-row">
            <p className="sm">
              <strong>{props.itemData.posts?props.itemData.posts:0}</strong>
              Posts
            </p>
            <p className="sm">
              <strong>{props.itemData.followers?props.itemData.followers:0}</strong>
              Followers
            </p>
            {props.itemData.social_media_name === 'Instagram' && 
            <p className="sm">
              <strong>{props.itemData.following ?props.itemData.following:0}</strong>
              Following
            </p>}
          </div>
        </div>
      </div>
    </div>
  );
};
