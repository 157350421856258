import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import { TextInput, arrowRight, closeIcon } from '../../Components';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { motion } from 'framer-motion';
import { json } from 'react-router-dom';

export default function MyProfileAbout({
  form,
  setInput,
  updateForm,
  saveChanges,
  categories,
  categoriesList,
  setBio,
  refAbout
}) {
  let bioHasValidJson = testJSON(form.bio);
  let contentState = "";
  if (bioHasValidJson === true) {
    let paseVal = JSON.parse(form.bio);
    if (paseVal.blocks) {
      contentState = convertFromRaw(
        form.bio ? bioHasValidJson === true ? JSON.parse(form.bio) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`)
      );
    } else {
      let position1 = (form.bio.indexOf('"text":'));
      let position2 = (form.bio.indexOf('}', 34));
      let newString = form.bio.substring(position1 + 8, position2 - 1);
      if (typeof newString === "string") {
        contentState = (ContentState.createFromText(newString));
      } else {
        contentState = convertFromRaw(
          JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`)
        );
      }
    }
  } else {
    contentState = (ContentState.createFromText(form.bio));
  }

  function testJSON(text) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      var json = JSON.parse(text);
      return (typeof json === 'object');
    }
    catch (error) {
      return false;
    }
  }
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );

  const updateTextDescription = async (state) => {
    await setEditorState(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
  };

  const [searchValue, setSearchValue] = useState('');
  const inputWrapper = useRef(null);
  const inpRef = useRef(null);
  const [error, setError] = useState(false);
  const [list, setList] = useState(categories);
  const [focus, setFocus] = useState(false);
  const delCategory = (catId) => {
    let newCategory = [...form.category];
    newCategory.splice(newCategory.indexOf(catId), 1);
    updateForm({ category: newCategory });
  };
  const closeSearch = () => {
    setSearchValue('');
    setFocus(false);
    setList(categories);
    inpRef.current.value = '';
    inpRef.current.blur();
  };
  const setCategory = (obj) => {
    const foundItem = () => {
      return categories.filter(
        (item) => item.toUpperCase() === obj.value.toUpperCase()
      );
    };
    if (foundItem().length !== 0) {
      let newCategory = [...form.category];
      let selCategory = categoriesList.find(cat => cat.category.toUpperCase() === obj.value.toUpperCase());
      newCategory.push(selCategory.id);
      updateForm({ category: newCategory });
      closeSearch();
    }
  };
  const optionClickHandler = (item) => {
    let newCategory = [...form.category];
    let selCategory = categoriesList.find(cat => cat.category.toUpperCase() === item.toUpperCase());
    newCategory.push(selCategory.id);
    updateForm({ category: newCategory });
    closeSearch();
  };
  const searchListFunc = (text) => {
    return categories.filter((item) =>
      item.toUpperCase().startsWith(text.toUpperCase())
    );
  };
  const handleChange = (event) => {
    const list = searchListFunc(event.target.value);
    setSearchValue(event.target.value);
    setList(list);
  };
  const handlePress = (event) => {
    const inputItem = event.target;
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setCategory(inputItem);
      return;
    }
  };
  useEffect(() => {
    let newList = categories;
    form.category.map(item => {
      var filtered = categoriesList.filter(function (cat) { return cat.id == item; });
      const index = categories.indexOf(filtered[0].category);
      if (index > -1) { newList.splice(index, 1); }
    });
    setList(newList);

    const windowClick = ({ target }) => {
      if (!inputWrapper.current.contains(target)) closeSearch();
    };

    if (focus) window.addEventListener('click', windowClick);
    else window.removeEventListener('click', windowClick);

    return () => window.removeEventListener('click', windowClick);
  }, [focus]);


  // const content = JSON.parse(form.bio);
  // const editorState = EditorState.createWithContent(content.blocks, content.entityMap);
  // const editorState = '';
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className='form'
    >
      <div className='form__title'>
        <h3 className='big'>About</h3>
      </div>
      <button
        ref={refAbout}
        className='button secondary'
        onClick={() => {
          saveChanges('about');
          setError(true);
        }}
      >
        Save changes
      </button>

      <div className='form__about'>
        <div className='form__about-inner'>
          <div className='form__about-info'>
            <div className='form__about-row'>
              <TextInput
                value={form.profile_name}
                type='text'
                onChange={setInput('profile_name')}
                label='Profile name'
                error={error}
                errorText='Please enter Profile name'
                autoComplete="profilename"
                autoFocus
              />
            </div>
            <div className='form__about-editor'>
              <span className='label'>Bio</span>
              <Editor
                editorState={editorState}
                onEditorStateChange={updateTextDescription}
                toolbar={{
                  options: ['inline', 'list'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                  },
                  list: {
                    options: ['unordered', 'ordered'],
                  },
                }}
                onChange={setBio(convertToRaw(editorState.getCurrentContent()))}
              />
            </div>
          </div>
          <div className='form__about-interests' ref={inputWrapper}>
            <TextInput
              btnClickHandler={setCategory}
              optionClickHandler={optionClickHandler}
              inpRef={inpRef}
              onKeydown={handlePress}
              type='text'
              label='Interests'
              icon={arrowRight}
              list={form.category}
              autoCompleteList={list}
              searchValue={searchValue}
              searchList={list}
              onFocus={() => setFocus(true)}
              showList={focus}
              onChange={handleChange}
            />
            <div className='form__about-interests-items'>
              {categoriesList.map((item, index) => {
                let obj = form.category.find(cat => cat === item.id);
                if (obj) {
                  return (
                    <div className='form__about-interests-item' key={item.id}>
                      {item.category}
                      <div
                        className='form__about-interests-item-icon'
                        onClick={() => delCategory(item.id)}
                      >
                        {closeIcon}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
