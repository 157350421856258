export const NotifModul = [
  {
    id: "1",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
  },
  {
    id: "2",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
  },
  {
    id: "3",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
  },
  {
    id: "4",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
  },
  {
    id: "5",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
  },
  {
    id: "6",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
  },
  {
    id: "7",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
  },
  {
    id: "8",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "draft",
  },
  {
    id: "9",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "draft",
  },
  {
    id: "10",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "draft",
  },
];
export const NotifSystemModul = [
  {
    id: "1",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditOppr",
  },
  {
    id: "2",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditPay",
  },
  {
    id: "3",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditPay",
  },
  {
    id: "4",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditOppr",
  },
  {
    id: "5",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditOppr",
  },
  {
    id: "6",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditPay",
  },
  {
    id: "7",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditPay",
  },
  {
    id: "8",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditOppr",
  },
  {
    id: "9",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditPay",
  },
  {
    id: "10",
    title: "Longer test campaign title",
    text: ";alskdfja ;dslfkjadf; klasjdfg ;lkasdjg a;skldfj a;kldsf ja;skldf ja;ksdlf adks f...",
    assigned: "300 Users",
    date: "11-30-2021",
    status: "active",
    edit: "systemEditOppr",
  },
];
