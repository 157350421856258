import React, { useRef, useEffect, useState } from "react";
import AssetVideo from "./AssetVideo";

export default function AssetsTableItem(props) {
  const videoWrapperRef = useRef(null);
  const clickFunc = (e) => {
    if (!videoWrapperRef.current.contains(e.target)) props.onClick();
  };
  const [differenceInDays, setDifferenceInDays] = useState(0);
  useEffect(() => {
    const dt1 = new Date(props.updated_at);
    const dt2 = new Date();
    let findDiffInDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
    setDifferenceInDays(30 - findDiffInDays);
  }, []);

  return (
    <tr onClick={clickFunc}>
      <td ref={videoWrapperRef}>
        <div className="assets__table-media">
          {props.media_type_id === 2 && (
            <div className="assets__media">
              <img src={process.env.PUBLIC_URL + props.thumbnail} alt={props.title} />
            </div>
          )}
          {props.media_type_id === 1 && <AssetVideo {...props} />}
        </div>
      </td>
      <td>
        <p>{props.title}</p>
        {(differenceInDays <= 5 && differenceInDays !== 0 && props.list === "deletedlist") && (
          <div className="assets__table-notif">
            <span className="pc">
              This asset will be permanently removed in {differenceInDays} days
            </span>
            <span className="mob">Permanent remove in {differenceInDays} days</span>
          </div>
        )}
      </td>
      <td>
        <p>{props.description}</p>
      </td>
      <td>{props.created_at}</td>
      <td className={props.list === "activelist" && "bold"}>
        {(props.list === "activelist") && props.campaigns && props.campaigns.length}
        {(props.list === "deletedlist") && props.updated_at && props.updated_at}
      </td>
    </tr>
  );
}
