import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { plusIcon, TextInput } from "../../Components";

import {
  uploadService
} from '../../Services';
export default function BrandProfileCompany({
  form,
  setInput,
  updateForm,
  saveChanges,
  setToastList,
  toastList,
  refCompany
}) {
  const [error, setError] = useState(false);
  const fileRef = useRef(null);
  const [file, setFile] = useState(form.brand && form.brand.photo);
  const isAsset = false;
  const [fileDetail, setFileDetail] = useState({
    type: form.brand && form.brand.media_type_id ? form.brand.media_type_id : 2,
    name: form.brand && form.brand.photo_thumbnail,
  });
  const readFile = ({ file, onLoad }) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = ({ target }) => onLoad(target.result);
      reader.readAsDataURL(file);
    }
  };
  const previewImage = () => {
    if (fileRef.current.files && fileRef.current.files[0]) {
      const fileType = fileRef.current.files[0].type;
      if ((fileType.startsWith('image') === false) || fileRef.current.files[0].size > 10000000) {
        addToast({ error: true, date: new Date(), msg: " .GIF, .PNG, .JPG only with a maximum file size of 10MB" });
      } else {
        readFile({
          file: fileRef.current.files[0],
          onLoad: (result) => {
            //setFile(result);
            //setFileDetail(fileRef.current.files[0]);
            upload();
          }
        });
      }
    }
  };
  const upload = () => {
    uploadService
      .upload(
        fileRef.current.files[0],
        true,
        isAsset
      )
      .then(response => {
        if (response.message === 'success') {
          setFile(response.path);
          setFileDetail({ "name": response.thumbail_path });
          const fileType = fileRef.current.files[0].type;
          if (fileType.startsWith('image')) {
            updateForm({ ["brand"]: { ...form.brand, ["photo"]: response.path } })
          }
        } else {
          addToast({ error: true, date: new Date(), msg: response.message });
        }
      })
      .catch(err => {
        setFile("");
        setFileDetail({});
        addToast({ error: true, date: new Date(), msg: err.message });
      });
  };
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    updateForm({ mediafile: file });
  }, [file]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="form"
    >
      <div className="form__title">
        <h3 className="big">Company</h3>
      </div>
      <button
        ref={refCompany}
        className="button secondary"
        onClick={() => {
          saveChanges("company");
          setError(true);
        }}
      >
        Save changes
      </button>

      <div className="form__company">
        <div className="form__company-inner">
          <div className="form__company-input">
            <TextInput
              value={form.brand.brand_name}
              type="text"
              onChange={setInput("brand", "brand_name")}
              label="Company name"
              error={error}
              errorText="Please enter Company name"
              autocomplete="brandname"
              autoFocus
            />
          </div>
          <div className="form__company-file">
            <h6 className="semi">Upload logo</h6>
            <div className="form__company-file-inner">
              {form.brand && (
                <div className="form__company-preview">
                  {fileDetail.name && (
                    <div className="assets__media">
                      <img src={process.env.PUBLIC_URL + fileDetail.name} alt="" />
                    </div>
                  )}
                  <h6 className="sm">{fileDetail.name && fileDetail.name.substring(fileDetail.name.lastIndexOf('/') + 1)}</h6>
                </div>
              )}
              <div
                className={
                  "form__company-upload " +
                  (error && form.file === "" ? "error" : "")
                }
              >
                <div className="form__company-upload-icon">{plusIcon}</div>
                <h5 className="reg">Upload media</h5>
                <p className="sm">
                  .GIF, .PNG, .JPG only with a maximum file size of 10MB
                </p>
                <button className="button primary">
                  Upload
                  <input
                    title=" "
                    type="file"
                    accept="image/jpeg,image/gif,image/png"
                    ref={fileRef}
                    onChange={previewImage}
                  />
                </button>
                {error && form.brand.photo === "" && (
                  <div className="form__company-upload-error">
                    Please upload file
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
