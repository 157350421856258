import React from "react";

export default function PasswordInput(props) {
  return (
    <div className="input__outer">
      {props.label && <label htmlFor={props.label.replace(/\s/g, '')}>{props.label}</label>}
      <div
        className={
          "input " + (((props.error && props.value === "") || (props.page != "loginpage" && props.error && props.value.length < 8)) ? "error" : "")
        }
      >
        <input
          onChange={props.onChange}
          value={props.value}
          type={props.type}
          placeholder={props.placeholder}
        />
        {props.icon && (
          <div className="input__icon" onClick={props.iconClickHandler}>
            {props.icon}
          </div>
        )}
      </div>
      {((props.error && props.value === "") || (props.error && props.value.length < 8)) && (
        <div className="input__error">{props.errorText}</div>
      )}
    </div>
  );
}
