import React, { useState } from "react";
import { Modal } from "../../Components";
import { utils } from "../../Helpers";
export default function UsersDetailModal(props) {

  let roles = "";
  props.roles.map(item => roles += ", " + (item.name != 'advertiser' ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : 'Brand'));
  const [tab, setTab] = useState("main");
  return (
    <Modal addClass="modalFunds" close={props.close}>
      {tab === "main" && (
        <div className="modal__users">
          <h3 className="big">{props.firstName + " " + props.lastName + ' ' + '[' + props.id + ']'}<p>Refered By:{} {props.referedBY}</p></h3>

          <div className="modal__users-col">
            <h5>Email</h5>
            <p>{props.email}</p>
          </div>
          <div className="modal__users-row">
            <div className="modal__users-col">
              <h5>Role</h5>
              <p>{roles.charAt(0) == "," ? roles.substring(1) : roles}</p>
            </div>
            <div className="modal__users-col">
              <h5>Date created</h5>
              <p>{props.created_at}</p>
            </div>
          </div>
          <div className="modal__users-handles">
            <h5>Handles</h5>
            {props.handles && (
              props.handles.map((item, index) => {
                return (
                  <div className="modal__users-handles-item" key={index}>
                    {utils.getIconFilled(item.icon)}
                    <p>{item.name}</p>
                  </div>
                );
              })
            )}
          </div>
          <div className="modal__users-btns">
            <button
              className="button secondary"
              onClick={() => setTab("suspend")}
            >
              Suspend users
            </button>
            <button
              className="button primary"
              onClick={() => {
                props.close();
                document.body.classList.remove("active");
                props.setTab("edit");
              }}
            >
              Edit user
            </button>
          </div>
        </div>
      )}
      {tab === "suspend" && (
        <div className="modal__users">
          <div className="modal__users-suspend">
            <h4>Are you sure?</h4>
            <p className="sm">
              Suspending this user will remove their access to TAMA. This will
              not remove them from the system.
            </p>
            <div className="modal__users-suspend-btns">
              <button
                className="button secondary"
                onClick={() => setTab("main")}
              >
                Cancel
              </button>
              <button className="button primary pink" onClick={props.suspendUser}>
                Suspend
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
