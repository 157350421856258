import React from "react";
import { Modal } from "../../Components";

export default function ModalSendEmail(props) {
  return (
    <Modal addClass="modalFunds" close={props.close}>
      <div className="modal__url">
        <div className="modal__url-inner">
          <div className="modal__url-delete">
            <h4>Resend Welcome Email?</h4>
            <p className="sm">
              Are you sure you want to send Welcome Email to user?
            </p>
            <div className="modal__url-delete-btns">
              <button
                className="button secondary"
                onClick={() => props.setModal(null)}
              >
                Cancel
              </button>
              <button className="button primary pink" onClick={props.sendEmail}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
