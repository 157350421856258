import React, { useRef } from "react";
import { viewList } from "../../Components";
import {
  adminService
} from '../../Services';
import { utils } from "../../Helpers/utils";

export default function CronDetailItem(props) {
  const downloadLog = async (payload) => {
    try {
      const response = await adminService.downloadLog(payload);
      utils.downloadFile(response, payload.file);

    } catch (err) {
      console.log(err);
      props.addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${props.toastList.length}`
      });
    }
  }
  return (
    <tr>
      <td>
        {props.start_at}
      </td>
      <td>{props.end_at}</td>
      <td>
        <p>{(props.end_at == undefined || props.end_at == '') ? 'Failed' : 'Finished'}</p>
      </td>
      <td >
        <a onClick={() => downloadLog({
          file: props.file_name
        })}
        >{viewList}</a>
      </td>
    </tr >
  );

}
