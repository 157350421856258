import React, { useState } from "react";
import { Modal } from "../../Components";
import { facebook, instagram } from "../../Components";
import Parser from 'html-react-parser';
import { TextInput, EmailInput } from '../../Components';
import {
  accountService,
} from '../../Services';
export default function ModalOpportunityTerms(props) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [error, setError] = useState(false);
  const getIcon = platform => {
    switch (platform.toString().toUpperCase()) {
      case 'INSTAGRAM':
        return instagram;
      case 'FACEBOOK':
        return facebook;
    }
  };

  const submitTerms = async () => {
    try {
      const res = await accountService.saveUserTerms({ id: props.termsDetails.id });
      if (res['status'] === 'success') {
        props.setModal("opportunityTerms");
        props.setModalData({ ...props.opportunity, showPlatformTerms: false });
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        date: new Date()
      });
      props.setModal(null);
      props.setModalData(null);
    }
  };
  const addToast = data => {
    const newToastList = [...props.toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${props.toastList.length}` });
    props.setToastList(newToastList);
  };


  return (<>
    {!props.showPlatformTerms && (
      <div className="modal__opportunity">
        <div className="modal__opportunity-title">
          <div className="modal__opportunity-title-image">
            <img
              src={
                props.brand_logo
                  ? props.brand_logo
                  : `${process.env.PUBLIC_URL}/images/bulls-eye.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.PUBLIC_URL}/images/bulls-eye.png`;
              }}
              alt="bulls-eye"
            />
          </div>
          <div className="modal__opportunity-title-content">
            <h3>{props.brand_name}</h3>
            <div className="modal__opportunity-title-content-row">
              <h6>{props.title}</h6>
              {props.products && props.products.map((product, idx) => (
                <a href='#' key={idx}>
                  {getIcon(product)}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="modal__opportunity-subtitle">
          <h3>Terms & conditions</h3>
        </div>
        <div className="modal__opportunity-content">
          {Parser(props.note)}
        </div>
        <div className="modal__opportunity-footer">
          <div className="modal__opportunity-footer-row">
            <div className="modal__opportunity-footer-col">
              <h3>Compensation</h3>
              <div className="modal__opportunity-footer-col-inner">
                <h6>
                  Amount: <span>{props.compensationDetails
                    ? props.compensationDetails.amount
                    : 'NA'}</span>
                  {(props.rate_overwritten && parseInt(props.rate_overwritten) == 1)
                    && <img className="redFlag"
                      src={`${process.env.PUBLIC_URL}/images/red-flag.png`} alt="red-flag" />
                  }
                </h6>
                {props.compensationDetails && props.compensationDetails.product != '' &&
                  <h6>
                    Product:
                    <span>
                      {props.compensationDetails
                        ? props.compensationDetails.product != '' ? 'Yes'
                          : 'No' : 'NA'}
                    </span>
                  </h6>
                }
              </div>
            </div>
            <div className="modal__opportunity-footer-col">
              <h3>Post Window</h3>
              <div className="modal__opportunity-footer-col-inner">
                <h6>
                  <span>{props.start_date}{' -> '}{props.end_date}</span>
                </h6>
                {/*<h6>
                  Analytics by: <span>{props.end_date}</span>
                    </h6>*/}
              </div>
            </div>
            <div className='modal__opportunity-footer-col'>
              <h3> Opt in by</h3>
              <div className='modal__opportunity-footer-col-inner'>
                <h6>
                  <span>{props.deadline_date != "" && props.deadline_date !== null ? props.deadline_date : 'NA'}</span>
                </h6>
                {/*<h6>
                      Opt in by: <span>{props.opportunity.end_date}</span>
                        </h6>*/}
              </div>
            </div>
          </div>
          <div className="modal__opportunity-footer">

            <div className="modal__opportunity-title">
              <div className="modal__opportunity-title-image"> <h6>Email</h6></div>
              <div className="modal__opportunity-title-content emailClass">
                <TextInput
                  value={props.email}
                  validateOnBlur={true}
                  type="text"
                  onChange={(event) => {
                    props.setEmail(event.target.value)
                    if (event.target.value == "" || emailRegex.test(props.email) === false) {
                      document.getElementsByClassName("input")[0].className = "input error";
                      props.setError(true);
                    } else {
                      props.setError(false);
                      document.getElementsByClassName("input")[0].className = "input ";
                    }
                  }
                  }
                  error={error}
                  errorText="Please enter email"
                  autoFocus
                  placeholder="Enter email"
                  className="secondary"
                />
              </div>
              {(props.error || emailRegex.test(props.email) === false) &&
                <div className="input__error" style={{ left: '60px' }}>Please enter valid email</div>
              }
            </div>
          </div>

          <div className="modal__opportunity-footer-btns">
            <button className="button secondary ext" onClick={props.onCollaborate}>
              I accept
            </button>
            <button className="button secondary red" onClick={props.close}>
              Decline
            </button>
          </div>
        </div>
      </div>
    )
    }
    {
      props.showPlatformTerms === true && (
        <div className='modal__terms'>
          <div className='modal__terms-image'>
            <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt='logo' />
          </div>
          <h3>Terms & conditions</h3>
          <div><p><div>{props.termsDetails ? Parser(props.termsDetails.note) : ''}</div></p></div>
          <button className="button primary" onClick={submitTerms}>
            Accept
          </button>
        </div>
      )
    }
  </>
  );
}
