import React, { useState } from "react";
import { EmailInput, SelectInput, TextInput } from "../../Components";
import { motion } from "framer-motion";

export default function MyProfileShipAdd({
  form,
  updateForm,
  saveChanges,
  countries,
  states,
  refShip
}) {
  const [error, setError] = useState(false);

  const selectCountryList = countries
    ? Object.entries(countries).map((country) => {
      return { id: country[0], value: country[1] };
    })
    : [];
  const selectStateList = states
    ? Object.entries(states).map((state) => {
      return { id: state[0], value: state[1] };
    })
    : [];
  const changeMedium = (key) => (item) =>
    updateForm({ address: { ...form.address, [key]: item.value } });
  const selectCountryIndex = React.useMemo(() => {
    if (form.address.country !== "") {
      return selectCountryList.findIndex(
        (item) => item.value === form.address.country
      );
    }
    return null;
    // eslint-disable-next-line
  }, [form.address.country]);
  const selectStateIndex = React.useMemo(() => {
    if (form.address.state !== "") {
      return selectStateList.findIndex(
        (item) => item.value === form.address.state
      );
    }
    return null;
    // eslint-disable-next-line
  }, [form.address.state]);

  const updateAdderess = (item) => (event) => {
    updateForm({
      address: { ...form.address, [item]: event.target.value },
    });
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 * 3 }}
      exit={{ opacity: 0, y: 30 }}
      className="form"
    >
      <div className="form__title sub">
        <h3 className="big">Shipping address</h3>
        <h5 className="reg">
          Your collaborations may require product to be sent. Please be sure to
          maintain a current shipping address here.
        </h5>
      </div>
      <button
        ref={refShip}
        className="button secondary"
        onClick={() => {
          saveChanges("shipAdd");
          setError(true);
        }}
      >
        Save changes
      </button>
      <div className="form__shipping">
        <div className="form__shipping-row">
          <SelectInput
            label="Country"
            value={form.address.country}
            list={selectCountryList}
            selected={selectCountryList[selectCountryIndex]}
            onChange={changeMedium("country")}
            error={error}
            errorText="Please select Country"
          />
        </div>
        <div className="form__shipping-row">
          <TextInput
            value={form.address.address}
            type="text"
            onChange={updateAdderess("address")}
            label="Address"
            error={error}
            errorText="Please enter Address"
            autoComplete="address"
          />
          <TextInput
            value={form.address.apartment}
            type="text"
            onChange={updateAdderess("apartment")}
            label="Apartment / Suite number"
            autoComplete="apaprtment"
          />
        </div>
        <div className="form__shipping-row">
          <TextInput
            value={form.address.city}
            type="text"
            onChange={updateAdderess("city")}
            label="City"
            error={error}
            errorText="Please enter City"
            autoComplete="city"
          />
          <SelectInput
            label="State / Province"
            value={form.address.state}
            list={selectStateList}
            selected={selectStateList[selectStateIndex]}
            onChange={changeMedium("state")}
            error={error}
            errorText="Please select State"
          />
          <EmailInput
            value={form.address.zipCode}
            type="number"
            onChange={updateAdderess("zipCode")}
            label="Zip code"
            error={error}
            errorText="Please enter Zip code"
            autoComplete="zipcode"
          />
        </div>
      </div>
    </motion.div>
  );
}
