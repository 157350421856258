import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";

import Assets from "../Campaigns/CampaignsCreate/Assets";
import Compensation from "../Campaigns/CampaignsCreate/Compensation";
import { Details, CampDetails, Products } from "../../Components";
import ModalAssetDetail from "../Campaigns/Modals/AssetsDetail/ModalAssetDetail";
import ModalAssetFull from "../Campaigns/Modals/AssetsDetail/ModalAssetFull";
import { utils } from '../../Helpers/utils';
import { convertFromRaw } from "draft-js";
import { format } from 'date-fns';
import {
  contentService,
  campaignService,
  influencerService
} from '../../Services';

const initForm = {
  title: '',
  description: "",
  deliverable: "",
  kpi: "",
  hashtags: [],
  brand_logo: '',
  campaigns_type_id: 1,
  budget: '',
  compensationDetails: '',
  start_date: '',
  end_date: '',
  status: 'draft',
  reach: 0,
  interaction: 0,
  goods: [{
    goods_name: "",
    total: "",
    value: "",
    photo: "",
    description: "",
    file: { mediafile: '', media_type_id: 2 }
  }],
  category: [],
  assets: [],
};

export default function ViewCampaign({ setTab, addToast, campaignId, mode }) {

  const [assetIndex, setAssetIndex] = useState(null);
  const [goodIndex, setGoodIndex] = useState(null);
  const [modal, setModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [error, setError] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [assetList, setAssetList] = useState([]);
  const [allAssetList, setAllAssetList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [toastList, setToastList] = useState([]);

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLoadedCampaign, setIsLoadedCampaign] = React.useState(false);
  const [form, setForm] = useState(initForm);
  const [categoriesData, setCategoriesData] = useState({});

  const [isAssetUploaded, setIsAssetUploaded] = useState(false);
  useEffect(() => {
    async function fetchCategoriesData() {
      try {
        const res = await influencerService.getCategoriesDetails();
        let newCountry = [];
        Object.entries(res.countries).map((country) => { (newCountry.push(country[1])) })
        setCategoriesData(res);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchCategoriesData();
  }, []);

  useEffect(() => {
    async function getCampaignData() {
      try {
        const res = await campaignService.getById(campaignId);
        res['data']['start_date'] = new Date(res['data']['start_date'].replace(/-/g, '/'));
        res['data']['end_date'] = new Date(res['data']['end_date'].replace(/-/g, '/'));
        res['data']['hashtags'] = res['data']['hashtag'] != null ? res['data']['hashtag'].split(",") : [];
        res['data']['category'] = res['data']['category'] != null ? res['data']['category'] : [];
        const filterString = `filter[asset.status]=active`;
        const resAll = await contentService.getAll({
          filter: filterString
        });
        let assetContent = res['data']['contents'];
        var assetsArray = resAll.data.filter(function (e) {
          return assetContent.indexOf(e.id) > -1;
        });
        res['data']['assets'] = assetsArray;
        res['data']['budget'] = res['data']['amount'];
        setForm(res.data);
        setIsLoadedCampaign(true);
        setIsLoaded(true);
        setSelectedItems({
          selectedContent: res['data']['contents'],
          selectedProduct: res['data']['products'] || [],
          selectedInfluencer: res['data']['influencers']
        });

      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    if (mode === "edit") {
      getCampaignData();
    } else {
      setIsLoadedCampaign(true);
    }
  }, []);



  const [selectedItems, setSelectedItems] = useState({
    selectedContent: [],
    selectedInfluencer: [],
    selectedProduct: []
  });

  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });


  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);
  return (
    <>
      {(isLoadedCampaign && categoriesData.categories) && (<>
        <div className="campaignsCreate">
          <Details
            error={error} form={form} setInput={setInput} updateForm={updateForm} readOnly={true} categoriesData={categoriesData.categories}
            categories={categoriesData.categories.map(
              cat => cat.category
            )} />
          <Products selectedItems={selectedItems} setSelectedItems={setSelectedItems} form={form} setInput={setInput} updateForm={updateForm}
            readOnly={true} />
          {isLoaded && (
            <CampDetails
              error={error} form={form} setInput={setInput} updateForm={updateForm} readOnly={true}
            />
          )}
          {isLoaded && (
            <Compensation
              form={form}
              updateForm={updateForm}
              setModal={setModal}
              setGoodIndex={setGoodIndex}
              setModalData={setModalData}
              error={error}
              readOnly={true}
              categoriesData={categoriesData}
            />
          )}
          {isLoaded && (
            <Assets
              form={form}
              updateForm={updateForm}
              selectedItems={selectedItems}
              setModal={setModal}
              setModalData={setModalData}
              setAssetIndex={setAssetIndex}
              setSelectedItems={setSelectedItems}
              readOnly={true}
            />
          )}

        </div>
        <AnimatePresence>
          {(modal === "assetDetail" ||
            modal === "assetDetailGrid" ||
            modal === "assetDetailGood" ||
            modal === "assetDetailGridGood" ||
            modal === "assetDetailCamp") && (
              <ModalAssetDetail
                close={() => setModal(null)}
                {...modalData}
                modal={modal}
                setModal={setModal}
                mediaClick={() => {
                  if (modal === "assetDetail") {
                    setModal("assetFull");
                  } else if (modal === "assetDetailGrid") {
                    setModal("assetFullGrid");
                  } else if (modal === "assetDetailGood") {
                    setModal("assetFullCamp");
                  } else if (modal === "assetDetailGridGood") {
                    setModal("assetFullCampGrid");
                  } else if (modal === "assetDetailCamp") {
                    setModal("assetFullCamp");
                  }
                }}
                backClick={() => {
                  if (modal === "assetDetail") {
                    setModal("asset");
                  } else if (modal === "assetDetailGrid") {
                    setModal("assetGrid");
                  } else if (modal === "assetDetailGood") {
                    setModal("assetGood");
                  } else if (modal === "assetDetailGridGood") {
                    setModal("assetGridGood");
                  }
                }}

              />
            )}
          {(modal === "assetFull" ||
            modal === "assetFullCamp" ||
            modal === "assetFullGrid" ||
            modal === "assetFullGridCamp") && (
              <ModalAssetFull
                close={() => setModal(null)}
                {...modalData}
                backClick={() => {
                  if (modal === "assetFull") {
                    setModal("assetDetail");
                  } else if (modal === "assetFullCamp") {
                    setModal("assetDetailCamp");
                  } else if (modal === "assetFullGrid") {
                    setModal("assetDetailGrid");
                  } else if (modal === "assetFullGridGood") {
                    setModal("assetDetailGridGood");
                  }
                }}
              />
            )}


        </AnimatePresence>
      </>
      )}
    </>
  );
}