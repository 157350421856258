import React, { useState, useEffect } from "react";
import { Toast, Profile } from "../../Components";

import { useLocation, useNavigate } from 'react-router-dom';
import {
    influencerService,
    productService
} from '../../Services';

export default function AdminHome({ setAuth, setModal, NotifModul, account, setModalSwitch, setModalNotification, user,
    profiles, }) {



    const navigate = useNavigate();
    const [toastList, setToastList] = useState([]);
    const [categoriesData, setCategoriesData] = useState({});
    const [countryData, setConuntryData] = useState({});
    const [facebookProducts, setFacebookProducts] = useState([]);
    const [instaProducts, setInstaProducts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const addToast = (data) => {
        const newToastList = [...toastList];
        newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
        setToastList(newToastList);
    };






    return (
        <>
            <div className="main">
                <div className="main__inner">
                    <div className="main__inner-title">
                        <div className="main__inner-title-col extra">
                            <h2>Home</h2>

                        </div>

                        <Profile
                            user={user}
                            setModal={setModalSwitch}
                            NotifModul={NotifModul}
                            profiles={profiles}
                            setNotifModul={setModalNotification}
                        />
                    </div>
                    <div className="recom">

                    </div>
                </div>
            </div>

            {toastList && (
                <Toast type="rate" list={toastList} setList={setToastList} />
            )}
        </>
    );
}
