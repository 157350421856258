import React, { useEffect, useState } from "react";

export default function RecommendContent({ estReach, estInteraction, budgetLength, setBudgetVal, budgetVal, calcAge, form, updateForm, buildCamp, categoriesData, facebookProducts, instaProducts }) {

  const [disabled, setDisabled] = useState(true);
  const [budgetValue, setBudgetValue] = useState(form.budget);
  const formatNumber = (value) => {
    if (value === "" || value === '$---') {
      return "$---";
    }
    value = value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "$" + value;
  };

  return (
    <div className="recomContent">
      <h3 className="big">Campaign estimates</h3>
      <p>Estimates are based on 30 days campaigns.</p>
      <div className="recomContent__row">
        <div className="recomContent__col">
          <div className="recomContent__col-inner">
            <h6>Recommended budget</h6>
            <input
              type="text"
              disabled={disabled}
              value={formatNumber(budgetVal)}
              onChange={(e) => setBudgetVal(formatNumber(e.target.value))}
            />
          </div>

        </div>

      </div>
      <div className="recomContent__overview">
        <h3>Campaign overview</h3>
        <p>
          {
            (form.facebookDel.length > 0 || form.instagramDel.length > 0 || form.tikTokDel.length > 0 || form.youtubeDel.length > 0) ? (
              <>

                {form.facebookDel.length > 0 && <strong>Facebook</strong>}{" "}


                {((form.facebookDel.length > 0 &&
                  form.instagramDel.length > 0 && form.youtubeDel.length > 0) || (form.facebookDel.length > 0 &&
                    form.tikTokDel.length > 0 && form.youtubeDel.length > 0)) &&
                  "and "}
                {form.instagramDel.length > 0 && <strong>Instagram</strong>}{" "}
                {((form.instagramDel.length > 0 &&
                  form.tikTokDel.length > 0 && form.youtubeDel.length > 0)) &&
                  "and "}


                {form.tikTokDel.length > 0 && <strong>TikTok</strong>}{" "}
                {form.youtubeDel.length > 0 && <strong>Youtube</strong>}{" "}
                campaign
                {((form.facebookDel.length > 0 &&
                  form.instagramDel.length > 0) || (form.facebookDel.length > 0 &&
                    form.tikTokDel.length > 0) || (form.instagramDel.length > 0 &&
                      form.tikTokDel.length > 0 || form.youtubeDel.length > 0)) &&
                  "s"}

                {form.category.length > 0 && " that focuses on the "}
                {form.category.map((item, index) => {
                  return index + 1 === form.category.length &&
                    form.category.length !== 1 ? (
                    <strong key={index}>
                      <span>and</span> {categoriesData.filter(catData => catData.id === item)[0].category}{" "}
                    </strong>
                  ) : (
                    <strong key={index}>
                      {categoriesData.filter(catData => catData.id === item)[0].category}
                      {form.category.length > 1 ? "," : ""}{" "}
                    </strong>
                  );
                })}
                {form.category.length > 0 ? form.category.length > 1 ? "industries" : "industry" : ''}.
                Influencers will be asked to create{" "}
                {form.facebookDel.length > 0 && (
                  <strong>
                    {form.facebookDel.map((item, index) =>
                      index + 1 !== form.facebookDel.length
                        ? item.name + ", "
                        : item.name
                    )}{" "}
                    content for Facebook
                  </strong>
                )}
                {form.facebookDel.length > 0 &&
                  form.instagramDel.length > 0 &&
                  " and "}
                {form.instagramDel.length > 0 && (
                  <strong>
                    {form.instagramDel.map((item, index) =>
                      index + 1 !== form.instagramDel.length
                        ? item.name + ", "
                        : item.name
                    )}{" "}
                    content for Instagram
                  </strong>
                )}
                .
              </>
            ) : (
              "---"
            )}
        </p>
      </div>
      <div className="recomContent__goals">
        <h3>Campaign goals</h3>
        <div className="recomContent__goals-items">
          <div className="recomContent__goals-item">
            <div className="recomContent__goals-item-left">
              <h5>Estimated total Reach</h5>
            </div>
            <div className="recomContent__goals-item-right">
              <h5>{estReach === "" ? "---" : estReach}</h5>
            </div>
          </div>
          <div className="recomContent__goals-item">
            <div className="recomContent__goals-item-left">
              <h5>Estimated total Interactions</h5>
              {form.interaction !== "" && (
                <p className="sm">
                  Example of how interactions may breakdown: <br /> 9,500 Likes
                  | 400 Comments | 100 Shares
                </p>
              )}
            </div>
            <div className="recomContent__goals-item-right">
              <h5>{estInteraction === "" ? "---" : estInteraction}</h5>
            </div>
          </div>
          <div className="recomContent__goals-item">
            <div className="recomContent__goals-item-left">
              <h5>Target Audience</h5>
            </div>
            <div className="recomContent__goals-item-right">
              <h5>
                {form.gender.length === 0
                  ? "---"
                  : form.gender.map((item, index) =>
                    index + 1 !== form.gender.length
                      ? item.value + ", "
                      : item.value
                  )}
              </h5>
            </div>
          </div>
          <div className="recomContent__goals-item">
            <div className="recomContent__goals-item-left">
              <h5>Age</h5>
            </div>
            <div className="recomContent__goals-item-right">
              <h5>
                {calcAge().map((item, index) =>
                  index + 1 !== calcAge().length ? item + ", " : item
                )}
              </h5>
            </div>
          </div>
          {/*} <div className="recomContent__goals-item">
            <div className="recomContent__goals-item-left">
              <h5>Country(s)</h5>
            </div>
            <div className="recomContent__goals-item-right">
              {form.countries.length > 0 ? (
                form.countries.map((item, index) => {
                  return <h5 key={index}>{item}</h5>;
                })
              ) : (
                <h5>---</h5>
              )}
            </div>
              </div>*/}
        </div>
      </div>
      <button type="button" className="button primary" onClick={buildCamp}>
        Build this campaign
      </button>
    </div>
  );
}