import React from "react";

export default function CampaignsTableItem(props) {
  return (
    <tr onClick={props.onClick}>
      <td>
        <p>{props.id}</p>
      </td>
      <td>
        <p>{props.title}</p>
      </td>
      <td>{props.start_date}</td>
      <td>{props.end_date}</td>
      <td className="bold">{props.budget}</td>
      <td className="bold">
        {props.campaigns_type_id === 1 ?
          <img src={
            process.env.PUBLIC_URL + '/images/dollar.png'} /> :
          props.campaigns_type_id === 2 ? <img src={
            process.env.PUBLIC_URL + '/images/gift.png'
          } /> : props.campaigns_type_id === 3 ? <><img src={process.env.PUBLIC_URL + '/images/dollar.png'} />  <span className="campaign_comp_plusSign">+</span>  <img src={process.env.PUBLIC_URL + '/images/gift.png'} /></> : ''}
      </td>
      <td>
        <p>{props.engagement_count}</p>
      </td>
      <td>
        <div className={"campaigns__table-status " + props.status}>
          {props.status}
        </div>
      </td>
    </tr>
  );
}
