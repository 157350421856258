import React, { useEffect, useRef, useState } from 'react';
export default function Kpi({ reach, interaction, updateForm, error, readOnly }) {

    return (
        <>
            <div className="recomForm__desired">
                <div className="recomForm__desired-col">
                    <h6 className="semi">Desired total reach</h6>
                    <input
                        type="number"
                        value={reach}
                        disabled={readOnly}
                        onChange={(e) =>
                            updateForm({ reach: (e.target.value) })
                        }
                        onKeyDown={e => { updateForm({ interaction: '' }); (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault() }}
                    />
                    <p className="sm">
                        Reach is the total number of unique users that saw your content.
                </p>

                </div>
                <div className="recomForm__desired-col reachOrInteraction"><p >OR</p></div>

                <div className="recomForm__desired-col">
                    <h6 className="semi">Desired total interactions</h6>
                    <input
                        type="number"
                        value={(interaction)}
                        disabled={readOnly}
                        onChange={(e) =>
                            updateForm({ interaction: (e.target.value) })
                        }
                        onKeyDown={e => { updateForm({ reach: '' }); (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault() }}
                    />
                    <p className="sm">
                        Interactions are the total likes, comments, and shares.
                </p>

                </div>


            </div>

            <div className="recomForm__desired_error"> {error && ((reach === "" || reach == 0 || reach === null) && (interaction === "" || interaction == 0 || interaction === null)) && (
                <div className="input__error">Enter Reach Or Interaction</div>
            )}</div></>);
}
