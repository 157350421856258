import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { SelectInput, DateInput, calendarIcon, MoneyInput } from "../../../Components";

import Goods from "./Goods";
import {
  campaignService
} from '../../../Services';


export default function Compensation({ form, categoriesData, readOnly, updateForm, error, addToast, toastList, setSelectedGoods, selectedGoods }) {
  const [select, setSelect] = useState(form.campaigns_type_id != "" ? form.campaigns_type_id : 1);
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [goodsModul, setGoodsModul] = useState(form.goods);
  const [isselGoodsUpdated, setIsselGoodsUpdated] = useState(true);

  const changeMedium = (item) => {
    setSelect(item.id);
    updateForm({ ['campaigns_type_id']: item.id })
  };
  useEffect(() => {
    async function fetchConfig() {
      try {

        if (form.campaigns_type_id !== 1 && form.campaigns_type_id !== 2) {
          const arrayObj = categoriesData.campaigns_type.map(item => {

            return {
              id: item.id,
              value: item.campaigns_type
            };
          })
          setCampaignTypes(arrayObj);
        } else {
          const arrayObj = categoriesData.campaigns_type.filter(item => item.id === form.campaigns_type_id);
          let compensationType = [{
            id: arrayObj[0].id,
            value: arrayObj[0].campaigns_type
          }];
          setCampaignTypes(compensationType);
        };


      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchConfig();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="form rel"
    >
      <div className="form__title">
        <h3 className="big">Compensation Details</h3>
      </div>
      <div className="formCampaigns__comp">
        <div className="formCampaigns__comp-main">
          <div className="formCampaigns__comp-main-select">
            <SelectInput
              label="Compensation type"
              list={campaignTypes}
              onChange={changeMedium}
              value={form.campaigns_type_id}
              name='campaigns_type_id'
              selected={campaignTypes.filter(item => item.id === form.campaigns_type_id)[0]}
              readOnly={readOnly}
            />
          </div>
          <div className="formCampaigns__comp-main-dates">
            <div className="formCampaigns__comp-main-date">
              <DateInput
                startDate={form.start_date}
                placeholder="MM / DD / YYYY"
                onChange={(date) => updateForm({ start_date: date })}
                label="Post start date"
                icon={calendarIcon}
                error={error}
                value={form.start_date}
                errorText="Please enter post start date"
                readOnly={readOnly}
              />
            </div>
            <div className="formCampaigns__comp-main-date">
              <DateInput
                startDate={form.end_date}
                placeholder="MM / DD / YYYY"
                onChange={(date) => updateForm({ end_date: date })}
                label="Post by date"
                icon={calendarIcon}
                value={form.end_date}
                error={error}
                errorText="Please enter post by date"
                readOnly={readOnly}
              />
            </div>
            <div className="formCampaigns__comp-main-date">
              <DateInput
                startDate={form.deadline_date}
                placeholder="MM / DD / YYYY"
                onChange={(date) => updateForm({ deadline_date: date })}
                label="Optin by date"
                icon={calendarIcon}
                value={form.deadline_date}
                error={error}
                errorText="Please enter optin by date"
                readOnly={readOnly}
              />
            </div>
          </div>
        </div>
        {(select === 1 || select === 3) && (
          <div className="formCampaigns__comp-money">
            <h3 className="big">Money details</h3>
            <div className="formCampaigns__comp-money-budget">
              <MoneyInput
                label="Campaign Budget"
                type="number"
                currency="USD"
                error={error}
                value={form.budget}
                disabled={true}
                readOnly={readOnly}
                onChange={() => { }}
              />
            </div>
          </div>
        )}
        {(select === 2 || select === 3) && (
          <div className="formCampaigns__comp-goods">
            <h3 className="big">Goods details</h3>
            <div className="formCampaigns__comp-goods-content nopad">
              <div className="formCampaigns__comp-goods-items">
                {goodsModul.map((item, index) => {
                  return (
                    <Goods
                      itemData={item}
                      key={index}
                      form={form}
                      updateForm={updateForm}
                      setSelectedGoods={setSelectedGoods}
                      selectedGoods={selectedGoods}
                      setIsselGoodsUpdated={setIsselGoodsUpdated}
                      isselGoodsUpdated={isselGoodsUpdated}
                      readOnly={readOnly}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}