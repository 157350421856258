import React, { useState } from 'react';
import { PasswordInput } from '../../Components';
import { motion } from 'framer-motion';

export default function MyProfilePass({ form, setInput, saveChanges, refPass }) {
  const [error, setError] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 * 4 }}
      exit={{ opacity: 0, y: 30 }}
      className='form'
    >
      <div className='form__title'>
        <h3 className='big'>Reset password</h3>
      </div>
      <button
        ref={refPass}
        className='button secondary'
        onClick={() => {
          saveChanges('pass');
          setError(true);
        }}
      >
        Save changes
      </button>

      <div className='form__password'>
        <div className='form__password-row'>
          <PasswordInput
            value={form.currentPassword || ''}
            type='password'
            onChange={setInput('currentPassword')}
            label='Current password'
            error={error}
            errorText={form.currentPassword === "" ? "Please enter Current password" : "Please enter 8 digit password"}
          />
        </div>
        <div className='form__password-row'>
          <PasswordInput
            value={form.newPassword || ''}
            type='password'
            onChange={setInput('newPassword')}
            label='New password'
            error={error}
            errorText={form.newPassword === "" ? "Please enter new password" : "Please enter 8 digit new password"}
          />
          <PasswordInput
            value={form.confirmNewPassword || ''}
            type='password'
            onChange={setInput('confirmNewPassword')}
            label='Confirm new password'
            error={error}
            errorText={form.confirmNewPassword === "" ? "Please enter confirm new password" : "Please enter 8 digit confirm new password"}
          />
        </div>
      </div>
    </motion.div>
  );
}
