import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from '../../../Components';
import { arrowLeft, trashIcon } from '../../../Components';
import { utils } from '../../../Helpers/utils';
export default function ModalNotifDetail(props) {
  console.log(props);
  const navigate = useNavigate();
  const navClickHandler = () => {
    console.log("===" + props.buttonText);
    if (props.buttonText === "View payment") {
      props.setModal(null);
      navigate("/influencer/payment");
    } else if (props.buttonText === "View details") {
      console.log("ty..." + props.type);
      if (props.sytemNotiType === 'campaign_activity' || props.sytemNotiType === 'campaign_complete' || props.sytemNotiType === 'revision_activity') {
        props.setModal(null);
        navigate("/brand/campaigns?id=" + props.opportunity_id);
      } else if (props.sytemNotiType === 'revision_activity_inf') {
        props.setModal(null);
        navigate("/influencer/collaborations?id=" + props.opportunity_id);
      } else {
        console.log("ddd");
        let expirayDate = props.expired.split("-");
        if (props.expired && (new Date(expirayDate[1] + "-" + expirayDate[2] + "-" + expirayDate[0]) < new Date())) {
          props.setModalData(props);
          props.setModal("notifExpired");
          navigate("/influencer/opportunities");
        } else {
          props.setModal(null);
          navigate("/influencer/opportunities");
        }
      }
    }
  };
  return (
    <Modal addClass="notif" close={props.close}>
      <div className="modal__notification">
        <div className="modal__notification-inner">
          <div
            className={
              "modal__notification-title " + (props.dash ? "solo" : "")
            }
          >
            {!props.dash && (
              <button
                className="button tertiary big"
                onClick={() => props.setModal("notifications")}
              >
                {arrowLeft} Back
              </button>
            )}
            <h4>Notifications</h4>
            <button
              className="button tertiary big grey"
              onClick={() => {
                props.setModalData(props);
                props.dash
                  ? props.setModal("notifDeleteDash")
                  : props.setModal("notifDelete");
              }}
            >
              {trashIcon} Delete
            </button>
          </div>

          <div
            className={
              "modal__notification-item " +
              (props.accountCount > 1 ? "moreAcc " : "")
            }
          >
            {props.accountCount > 1 && (
              <div className="modal__notification-item-account">
                {(props.handle.icon && props.handle.icon[0] !== "" && props.handle.icon[0] !== null) && utils.getIconFilled(props.handle.icon[0])}
                {props.handle && <span>{props.handle.handle}</span>}
              </div>
            )}
            <div className="modal__notification-item-image">
              <img src={process.env.PUBLIC_URL + props.image} alt={props.id} />
            </div>
            <h3>{props.notiType !== "" ? props.brandName : props.detailTitle}</h3>
            <p className="sm">{props.detailText}</p>
            {props.buttonText && props.buttonText === "View payment" && (
              <button onClick={navClickHandler} className="button primary">
                {props.buttonText}
              </button>
            )}
            {props.buttonText && props.buttonText === "View details" && (
              <button onClick={navClickHandler} className="button primary">
                {props.buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}