import React, { useState, useEffect } from "react";
import { SearchInput, SelectInput, Pagination, Toast } from "../../../Components";
import { AnimatePresence } from 'framer-motion';

import {
  influencerService
} from '../../../Services';

import { utils } from "../../../Helpers/utils";
import ModalAddRevision from './Modal/ModalAddRevision';
import ModalShowRevision from './Modal/ModalShowRevision';

export default function RevisionList(props) {

  const [paginationData, setPaginationData] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [infEngList, setInfEngList] = useState(null);
  const [loadList, setLoadList] = useState(false);
  const [modal, setModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const addToast = (data) => {
    const newToastList = [];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let filterStr = '';
        if (props.engagementId) {
          filterStr = 'filter[engagement_id]=' + props.engagementId;
        }
        if (props.campaignId) {
          filterStr = 'campaign_id=' + props.campaignId;
        }
        if (props.influencerId) {
          filterStr += '&filter[influencer_id]=' + props.influencerId;
        }
        const res = await influencerService.getRevisionWithStatus({
          page: page,
          pageSize,

        }, '', filterStr
        );
        if (res.meta && res.meta.pagination) {
          setPaginationData(res.meta.pagination);
        }
        setInfEngList(res.data);
        setLoadList(true);
      } catch (err) {
        props.addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page]);

  const onPaginate = page => {
    setPage(page);
  };

  return (
    <>
      <div className="campaigns">
        <div className="campaigns__table-title">
          <div className="campaigns__table-title-info">
            <h4>Product Revision List</h4>
            {props.influencerId && props.addRevisionFlag == 1 ?
              <button
                className="button tertiary big"
                onClick={() => {
                  setModalData(props);
                  setModal("addRevision");
                }
                }
              >
                Add Revision +
              </button> : props.revisionMsg}
          </div>
          {props.influencerId ?
            <div className="campaigns__table-title-inner poststatus">
              <p>Attempts remaining ({props.usedrevision}/{props.totalrevision})</p>
            </div> : ''}
        </div>

        <>
          <div className="campaigns__table postStatus">
            <table className="post_verification_table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Link</th>
                  <th>Content</th>
                  <th>Notes</th>
                  <th>Feedback</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loadList ?
                  (infEngList.length > 0 && loadList) ?

                    infEngList.map((item, index) => {

                      return (<>
                        {
                          <tr onClick={() => {
                            setModalData(item);
                            setModal("showRevision");
                          }
                          }>
                            <td  >
                              <p>{item.submission_date}</p>
                            </td>
                            <td><p>{item.link}</p>
                              {/* <a href={item.link} target="blank"> */}
                            </td>
                            <td>{item.content}</td>
                            <td>{item.notes}</td>
                            <td >{item.feedback ? item.feedback : 'N/A'}</td>
                            {props.influencerId ?
                              <td style={{ textTransform: 'capitalize' }}>
                                {item.status == 'pending' ? 'Submitted' : (item.status == 'changerequest') ? 'Change Request' : item.status}
                              </td>
                              : <td style={{ textTransform: 'capitalize' }}>
                                {item.status == 'pending' ? 'Needs Review' : (item.status == 'changerequest') ? 'Change Request' : item.status}
                              </td>}
                          </tr>
                        }
                      </>
                      );
                    })
                    : (
                      <tr>
                        <td colSpan="7" style={{ textAlign: 'center' }}>
                          No Records Found
                        </td>
                      </tr>
                    ) : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: 'center' }}>
                        Loading...
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </>
        <div className="campaigns__table-footer">
          <button className="button tertiary download" >
          </button>
          <Pagination
            perPage={paginationData['per_page']}
            total={paginationData['total']}
            currentPage={paginationData['current_page']}
            totalPages={paginationData['total_pages']}
            onPaginate={onPaginate}
          />
        </div>
      </div>
      <AnimatePresence>
        {modal === "addRevision" && (
          <ModalAddRevision
            close={() => setModal(null)}
            setModal={setModal}
            setModalData={setModalData}
            modalData={modalData}
            engagementId={props.engagementId}
            influencerId={props.influencerId}
            product_id={props.product_id}
            product_name={props.product_name}
            engagement_influencer_map_id={props.engagement_influencer_map_id}
            addToast={addToast}
            setInfEngList={setInfEngList}
            setLoadList={setLoadList}

          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modal === "showRevision" && (
          <ModalShowRevision
            close={() => setModal(null)}
            setModal={setModal}
            setModalData={setModalData}
            modalData={modalData}
            influencerFlag={props.campaignId ? 0 : 1}
            addToast={addToast}
            setIsLoaded={setIsLoaded}
            setInfEngList={setInfEngList}
            setLoadList={setLoadList}
          />
        )}
      </AnimatePresence>
      {
        toastList && (
          <Toast type='rate' list={toastList} setList={setToastList} />
        )
      }
    </>
  );
}