import React, { useState } from "react";
import { utils } from '../../../Helpers/utils';
export default function Goods(props) {

  let descHasValidJson = utils.testJSON(props.itemData.description);
  let description = "";
  if (descHasValidJson) {
    description = JSON.parse(props.itemData.description).blocks[0].text;
  }

  const [checked, setChecked] = useState(props.itemData.is_select ? props.itemData.is_select === true ? true : false : false);
  // const [checked, setChecked] = useState(props.itemData.is_select ? props.itemData.is_select === true ? true : false : false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      props.setIsselGoodsUpdated(false);
      //setSelectedItems, selectedItems
      let arr = (props.selectedGoods.length > 0 ? [...props.selectedGoods] : []);
      //console.log(props.selectedGoods.indexOf(props.itemData.id));
      if (props.selectedGoods.indexOf(props.itemData.id) === -1) {
        // let arr = [];
        let selectedG = props.itemData;

        selectedG.is_select = true;
        arr.push(selectedG);
        // props.updateForm({ goods: arr });
        props.setSelectedGoods(arr);
      }
      // props.setSelectedItems({});
    } else {
      let value = (e.target.value);
      let goodsCheckedOnly = [];
      props.selectedGoods.map((item) => {
        if (item.id != value) {
          goodsCheckedOnly.push(item);
        }
      });
      //let goodsCheckedOnly = props.selectedGoods.map((item) => { return item.id !== e.target.value ? item : null });

      props.setSelectedGoods(goodsCheckedOnly);

      props.setIsselGoodsUpdated(true);
    }
  };
  //  console.log(props.selectedGoods);
  return (
    <div
      className={"campaignsCreate__productsItem " + (checked ? "active" : "")}
    >
      <div className="checkbox">
        <input checked={checked} onChange={onChange} type="checkbox" value={props.itemData.id} disabled={props.readOnly} />
        <label htmlFor=""></label>
      </div>
      <div className="campaignsCreate__productsItem-amount">
        <h4>{props.itemData.value}</h4>
      </div>
      <h4>{props.itemData.goods_name}</h4>
      <p>{description}</p>
    </div>
  );
}
