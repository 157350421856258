import { fetchWrapper } from '../Helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}`;
export const profileService = {
    getBrandDetails,
    updateBrandDetails,
};

function getBrandDetails(id) {
    return fetchWrapper.get(`${baseUrl}/users/${id}`);
}
function updateBrandDetails(id, params) {
    return fetchWrapper.put(
        `${process.env.REACT_APP_API_URL}/userprofile/${id}`,
        params
    );
}


