import React, { useState, useEffect } from 'react';
import {
  Profile,
  Card,
  useMediaQuery,
  Pagination,
  chevronBottom,
  download,
  Toast
} from '../../Components';
import { utils } from '../../Helpers';
import CardOpportunity from './Card/CardOpportunity';
import ModalOpportunity from './ModalOpportunity';
import OpportunitiesItem from './OpportunitiesItem';
import { engagementService, influencerService } from '../../Services';
export default function Opportunities(props) {
  const [isloaded, setIsLoaded] = useState(false);
  const [modal, setModal] = useState(null);
  const [featuredOpportunities, setFeaturedOpportunities] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const width = useMediaQuery();
  const [modalData, setModalData] = useState(null);
  const [max, setMax] = useState(null);
  const [accountCount, setAccountCount] = useState(0);
  const perPage = 5;
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await engagementService.getCampaignsByInfluencer({
          page: page,
          pageSize: perPage
        });


        setOpportunities(res['data']);
        const resFeatured = await engagementService.getCampaignsByInfluencer({
          page: page,
          pageSize: 10
        });
        setAccountCount(resFeatured.account_count ? resFeatured.account_count : 1);
        let featuredOpportunities = resFeatured['data'].filter(
          item => item.is_featured && item.is_featured === 1
        );
        if (featuredOpportunities.length === 0) {
          if (resFeatured['data'].length > 0) {
            featuredOpportunities = resFeatured['data'];
          }
        }
        const max =
          width <= 1180 && featuredOpportunities.length > 2
            ? 2
            : featuredOpportunities.length > 3
              ? 3
              : featuredOpportunities.length;
        setMax(max);

        setFeaturedOpportunities(featuredOpportunities.splice(0, max));
        if (res['meta'] && res['meta']['pagination']) {
          setPaginationData(res['meta']['pagination']);
        }
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, width]);

  const onPaginate = page => {
    setPage(page);
  };

  const updateOnCollaborate = async () => {
    try {
      const res = await engagementService.getCampaignsByInfluencer({
        page: page,
        pageSize: perPage
      });
      setOpportunities(res['data']);
      const resFeatured = await engagementService.getCampaignsByInfluencer({
        page: page,
        pageSize: perPage
      });
      let featuredOpportunities = resFeatured['data'].filter(
        item => item.is_featured && item.is_featured === 1
      );
      if (featuredOpportunities.length === 0) {
        featuredOpportunities = resFeatured['data'];
      }
      const max =
        width <= 1180 && featuredOpportunities.length > 2
          ? 2
          : featuredOpportunities.length > 3
            ? 3
            : featuredOpportunities.length;
      setMax(max);
      setFeaturedOpportunities(featuredOpportunities.splice(0, max));
      if (res['meta'] && res['meta']['pagination']) {
        setPaginationData(res['meta']['pagination']);
      }
      setIsLoaded(true);
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  };

  const deleteCard = id => {
    setOpportunities(opportunities.filter(item => item.id !== id));
  };
  const downloadCsv = async () => {
    try {
      let options = {
        page: page,
        pageSize: 10,
        fileType: 'csv'
      };
      const response = await engagementService.getCampaignsByInfluencer(options);
      utils.downloadFile(response, "opportunity_page_" + page + ".csv");
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const maxPlaceholder = width <= 1180 ? 2 : 3;
  return (
    <div className='main'>
      <div className='main__inner'>
        <div className='main__inner-title'>
          <h2>Current opportunities</h2>
          <Profile
            user={props.user}
            setModal={props.setModal}
            profiles={props.profiles}
            NotifModul={props.NotifModul}
            setNotifModul={props.setModalNotification}
          />
        </div>
        {isloaded && (
          <div className='opportunity'>
            <>
              <h4>Featured opportunities</h4>
              <div className='opportunity__cards'>
                {featuredOpportunities.map((item, index) => {
                  return (
                    <CardOpportunity
                      modal={modal}
                      setModal={setModal}
                      accountCount={accountCount}
                      index={index}
                      openModal={() => {
                        setModalData(item);
                        setModal('opportunity');
                      }}
                      key={index}
                      deleteCard={() => deleteCard(item.id)}
                      last={featuredOpportunities.length === 1}
                      opportunity={item}
                    >
                    </CardOpportunity>
                  );
                })}
                {maxPlaceholder - max === 1 && (
                  <div className='dashboard__cards-placeholder'>
                    <div className='dashboard__cards-placeholder-image'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/images/placeholder/cardPlaceholder.png'
                        }
                        alt='cardPlaceholder'
                      />
                    </div>
                    <h6>More opportunities coming soon!</h6>
                  </div>
                )}
                {maxPlaceholder - max === 2 && (
                  <>
                    <div className='dashboard__cards-placeholder'>
                      <div className='dashboard__cards-placeholder-image'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/placeholder/cardPlaceholder.png'
                          }
                          alt='cardPlaceholder'
                        />
                      </div>
                      <h6>More opportunities coming soon!</h6>
                    </div>
                    <div className='dashboard__cards-placeholder'>
                      <div className='dashboard__cards-placeholder-image'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/placeholder/cardPlaceholder.png'
                          }
                          alt='cardPlaceholder'
                        />
                      </div>
                      <h6>More opportunities coming soon!</h6>
                    </div>
                  </>
                )}
                {maxPlaceholder - max === 3 && (
                  <>
                    <div className='dashboard__cards-placeholder'>
                      <div className='dashboard__cards-placeholder-image'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/placeholder/cardPlaceholder.png'
                          }
                          alt='cardPlaceholder'
                        />
                      </div>
                      <h6>More opportunities coming soon!</h6>
                    </div>
                    <div className='dashboard__cards-placeholder'>
                      <div className='dashboard__cards-placeholder-image'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/placeholder/cardPlaceholder.png'
                          }
                          alt='cardPlaceholder'
                        />
                      </div>
                      <h6>More opportunities coming soon!</h6>
                    </div>
                    <div className='dashboard__cards-placeholder'>
                      <div className='dashboard__cards-placeholder-image'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/placeholder/cardPlaceholder.png'
                          }
                          alt='cardPlaceholder'
                        />
                      </div>
                      <h6>More opportunities coming soon!</h6>
                    </div>
                  </>
                )}
              </div>
            </>

            <div className='opportunity__table-title'>
              <h4>All opportunities</h4>
            </div>
            <div
              className={
                "opportunity__table " +
                (accountCount > 1 ? "moreAcc" : "")
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Brand</th>
                    <th>Opt in by</th>
                    {accountCount > 1 && <th>Account</th>}
                    <th>Deliverables</th>
                    <th>Compensation</th>
                  </tr>
                </thead>
                <tbody>
                  {opportunities.length > 0 ? (
                    opportunities.map((item, index) => {
                      return (
                        <OpportunitiesItem
                          key={index}
                          opportunity={item}
                          accountCount={accountCount}
                          openModal={() => {
                            setModalData(item);
                            setModal('opportunity');
                          }}
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={accountCount > 1 ? '5' : '4'} style={{ textAlign: 'center' }}>
                        More opportunities coming soon!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='opportunity__table-footer'>
              <button className='button tertiary download' onClick={downloadCsv}>
                Download CSV {download}
              </button>
              {opportunities.length > 0 && (
                <Pagination
                  perPage={paginationData['per_page']}
                  total={paginationData['total']}
                  currentPage={paginationData['current_page']}
                  totalPages={paginationData['total_pages']}
                  onPaginate={onPaginate}
                />)}
            </div>

            {modal === 'opportunity' && (
              <ModalOpportunity
                opportunity={modalData}
                setModal={setModal}
                setModalData={setModalData}
                close={() => setModal(null)}
                setToastList={setToastList}
                toastList={toastList}
                accountCount={accountCount}
                updateOnCollaborate={updateOnCollaborate}
              />
            )}
            {toastList && <Toast list={toastList} setList={setToastList} />}
          </div>
        )}
      </div>
    </div>
  );
}
