import React from "react";
import { Modal } from "../../Components";

export default function ModalTransaction(props) {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <Modal addClass="modalTrans" close={props.close}>
      <div className="modal__trans">
        <div className="modal__trans-inner">
          <h3>{props.transaction}</h3>
          <h5>Description</h5>
          <p>{props.description}</p>
          <div className="modal__trans-info">
            <h6>
              Date: <span>{props.date}</span>
            </h6>
            <h6>
              Charge type: <span>{capitalizeFirstLetter(props.transaction_type)}</span>
            </h6>
            <h6>
              Amount: <span>{props.amount}</span>
            </h6>
          </div>
        </div>
      </div>
    </Modal>
  );
}
