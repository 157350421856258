export default function MoneyBlock({ tabData, title }) {
  return (
    <>
      <h3 className="big">{title}</h3>
      <div className="engagement__col-inner">
        <div className="engagement__col-row">
          <h6 className="semi">Date</h6>
          <p>{tabData.start_date + '->' + tabData.end_date}</p>
        </div>
        <div className="engagement__col-row">
          <h6 className="semi">Type</h6>
          <p>{tabData.comp}</p>
        </div>
        <div className="engagement__col-row">
          <h6 className="semi">Campaign Value</h6>
          <p>{tabData.budget}</p>
        </div>
        <div className="engagement__col-row">
          <h6 className="semi">Total Budget</h6>
          <p>{tabData.engagement_budget}</p>
        </div>
        <div className="engagement__col-row">
          <h6 className="semi">Commited Budget</h6>
          <p>{tabData.commitedBudget}</p>
        </div>
        <div className="engagement__col-row">
          <h6 className="semi">Locked Budget</h6>
          <p>{tabData.lockedBudget}</p>
        </div>
        <div className="engagement__col-row">
          <h6 className="semi">Available Budget</h6>
          <p>{tabData.availableBudget}</p>
        </div>
      </div>
    </>
  )

}