import { fetchWrapper } from '../Helpers';

export const adminService = {
  getCustomUrl,
  getAvailableHandles,
  getCronDetails,
  getCronIdLog,
  downloadLog
};

function getCronDetails(options) {
  let filterString = '';
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  const url =
    filterString.length > 0 ? `${process.env.REACT_APP_API_URL}/tasks?${filterString}` : `${process.env.REACT_APP_API_URL}/tasks}`;
  return fetchWrapper.get(url);
}

function getCronIdLog(id) {
  console.log(id);
  let filterString = '';
  // if (options.page !== undefined) {
  //   filterString = getAllPaging(options, filterString);
  // }
  const url = `${process.env.REACT_APP_API_URL}/tasks/${id}`;
  //filterString.length > 0 ? `${process.env.REACT_APP_API_URL}/tasks/${id}` : `${process.env.REACT_APP_API_URL}/tasks/${id}}`;
  //return fetchWrapper.get(`${baseUrl}/${id}`);
  return fetchWrapper.get(url);
}

function downloadLog(params) {
  console.log("in downlaod");
  const url = `${process.env.REACT_APP_API_URL}/tasks/download`;
  //filterString.length > 0 ? `${process.env.REACT_APP_API_URL}/tasks/${id}` : `${process.env.REACT_APP_API_URL}/tasks/${id}}`;
  //return fetchWrapper.get(`${baseUrl}/${id}`);
  console.log("in downlaod");
  return fetchWrapper.post(url, params);
}

function getCustomUrl(handle) {
  return fetchWrapper.get(
    `${process.env.REACT_APP_API_URL}/getencryptUrl/${handle}`
  );
}


function getAvailableHandles(options) {

  let filterString = '';
  if (options.page !== undefined) {
    filterString = getAllPaging(options, filterString);
  }
  if (options.filter !== undefined) {
    filterString = getAllPaging(options, filterString);
  }

  const url =
    filterString.length > 0 ? `${process.env.REACT_APP_API_URL}/tamahandles?${filterString}` : `${process.env.REACT_APP_API_URL}/tamahandles}`;
  return fetchWrapper.get(url);
  //return fetchWrapper.get(  `${process.env.REACT_APP_API_URL}/tamahandles`);
}
function getAllPaging(options, filterString) {
  filterString += filterString.length > 0 ? `&` : '';
  filterString += `page[number]=${options.page}`;
  if (options.pageSize) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[size]=${options.pageSize}`;
  }
  if (options.search) {

    if (options.search.searchIn) {
      filterString += filterString.length > 0 ? `&` : '';
      filterString += `filter[searchIn]=${options.search['searchIn']}`;
    }
    if (options.search.searchBy && options.search.searchText != '') {
      filterString += filterString.length > 0 ? `&` : '';
      filterString += `filter[${options.search['searchBy']}]=${options.search['searchText']}`;
    }
    if (options.search.searchText && options.search.searchText != '') {
      //filterString += filterString.length > 0 ? `&` : '';
      //filterString += `filter[searchText]=${options.search['searchText']}`;
    }

  }
  if (options.range) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `range[month]=${options.range}`;
  }
  if (options.fileType) {
    filterString += filterString.length > 0 ? `&` : '';
    filterString += `page[filetype]=${options.fileType}`;
  }
  return filterString;
}