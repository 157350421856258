import React, { useRef } from "react";
import { mailIcon, prime } from '../../Components';
import { useState } from "react";
import { utils } from "../../Helpers";
import { influencerService, productService, profileService } from "../../Services";
export default function UsersItem(props) {
  let roles = "";
  const [toastList, setToastList] = useState([]);
  props.roles.map(item => roles += ", " + item.name);

  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const showProfile = (roleId, userId) => {
    //https://backendapp.tamayou.com/api/influencer/61
    async function fetchInfData() {
      try {
        const res = await influencerService.getInfluencerDetails(userId);
        props.setModalData(res);
        props.setModal('showInfProfile');
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    async function fetchBrandData() {
      try {
        const res = await profileService.getBrandDetails(userId);
        props.setModalData(res);
        props.setModal('showBrandsProfile');
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    if (roleId == 1) {
      fetchInfData();
    }
    if (roleId == 3) {
      fetchBrandData();
    }
  };
  const setData = (data, id) => {
    async function fetchData() {
      try {
        const res = await productService.getInfluencerProductData(data.id);
        props.setModalData(res);
        props.setModal('showRate');
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  };


  const sendEmailLinkWrapper = useRef(null);
  const rateLinkWrapper = useRef(null);
  const profileLinkWrapper = useRef(null);
  const impersonationLinkWrapper = useRef(null);
  const clickFunc = (e) => {
    if (sendEmailLinkWrapper.current.contains(e.target)) {
      props.setModalData(props.item); props.setModal('sendEmail');
    } else if (profileLinkWrapper.current.contains(e.target)) {
      // props.setModalData(props.item); props.setModal('sendEmail');
    } else if (impersonationLinkWrapper.current.contains(e.target)) {
      props.impersonateUser(props.id)
      // props.setModalData(props.item); props.setModal('sendEmail');
    } else {
      if (rateLinkWrapper.current.contains(e.target)) {
      } else {
        props.onClick();
      }
    }
  };
  return (
    <tr onClick={clickFunc}>
      <td>{props.id}</td>
      <td>
        <p>{props.firstName + " " + props.lastName}</p>
      </td>
      <td>
        <p>{props.email}</p>
      </td>
      <td ref={profileLinkWrapper}>
        <p>
          {props.roles && (
            props.roles.map((item, index) => {
              return (
                <div className="users__table-handle-item" key={index} onClick={() => showProfile(item.id, props.id)}>
                  {item.name != 'advertiser' ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : 'Brand'}
                </div>
              );
            })
          )}</p>
      </td>
      <td>{props.created_at}</td>
      <td ref={rateLinkWrapper}>
        <div className="users__table-handle">
          {props.handles && (
            props.handles.map((item, index) => {
              return (
                <div className="users__table-handle-item" key={index} onClick={() => setData(item)}>
                  {utils.getIconFilled(item.icon)}
                  {item.name}
                </div>
              );
            })
          )}
        </div>
      </td>
      <td>
        <div className={"users__table-status " + props.status}>
          {props.status}
        </div>
        <span className="headerMargin" ref={sendEmailLinkWrapper}>{mailIcon}</span>
        <span ref={impersonationLinkWrapper} className="headerMarginPrime" >{prime}</span>
      </td>

    </tr>
  );
}
