import React, { useState } from "react";
import DetailsSystem from "./DetailsSystem";

const initForm = {
  title: "Target updated your collaboration.",
  text: {
    blocks: [
      {
        key: "eerdn",
        text: "Thanks Jeremy! We love the creative you’ve put together. Could we get you to make the...  Could we get you to make the... ",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  },
};

export default function NotifAddSystem({ setTab, addToast }) {
  const [form, setForm] = useState(initForm);
  const [error, setError] = useState(false);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });

  const sendFunc = () => {
    if (form.title !== "" && form.text.blocks[0].text !== "") {
      setTab("system");
      addToast({
        error: false,
        errorText: "Error.",
        successText: "Data saved successfully.",
        date: new Date(),
      });
    } else {
      setError(true);
      addToast({
        error: true,
        errorText: "Error.",
        successText: "Data saved successfully.",
        date: new Date(),
      });
    }
  };
  return (
    <>
      <div className="admnotifAdd">
        <DetailsSystem
          setInput={setInput}
          form={form}
          updateForm={updateForm}
          error={error}
          type="opportunity"
        />
        <DetailsSystem
          setInput={setInput}
          form={form}
          updateForm={updateForm}
          error={error}
          type="payment"
        />
        <div className="admnotifAdd__btns sys">
          <div className="admnotifAdd__btns-row">
            <button
              className="button secondary"
              onClick={() => setTab("system")}
            >
              Cancel
            </button>
          </div>
          <button className="button primary" onClick={sendFunc}>
            Save notification
          </button>
        </div>
      </div>
    </>
  );
}
