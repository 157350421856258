import React, { useRef } from "react";
import { motion } from "framer-motion";
import AssetVideo from "../../Assets/AssetVideo";
import { trashIcon } from "../../../Components";

export default function Assets({
  form,
  updateForm,
  setModal,
  setModalData,
  setAssetIndex,
  readOnly
}) {

  const deletAsset = (assetItem) => () => {
    let arr = [...form.assets];
    let indexnew = arr.findIndex(item => assetItem.id === item.id);
    arr.splice(indexnew, 1);
    updateForm({ assets: arr });
  };
  /*const fetchContentLibraryData = async (productId) => {
    const contentLibraryData = await contentService.getAll({
      filter: ``,
    });
    setContentLibrary(contentLibraryData['data']);
  };*/

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="form p16"
    >
      <div className="formCampaigns__assets">
        <div className="formCampaigns__assets-title">
          <h3 className="big">Assets</h3>
        </div>
        <div className="formCampaigns__assets-content">
          <div className="formCampaigns__assets-content-title">
            {form.assets.length === 0 && (
              <h5 className="reg">
                There are currently no assets selected for this campaign.
              </h5>
            )}
            {form.assets.length > 0 && (
              <h5 className="reg">
                There are currently {form.assets.length} assets assigned to this
                campaign.
              </h5>
            )}
            <div className="formCampaigns__assets-items">
              {form.assets.map((item, index) => {
                return (
                  <AssetsItem
                    {...item}
                    key={index}
                    readOnly={readOnly}
                    deletAsset={deletAsset(item)}
                    onItemClick={() => {
                      setAssetIndex(index);
                      setModalData(item);
                      setModal("assetDetailCamp");
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {!readOnly &&
          <div className="formCampaigns__assets-btns">
            <button
              className="button secondary"
              onClick={() => setModal("asset")}
            >
              Select from assets
          </button>
            <button
              className="button edit"
              onClick={() => setModal("addAssetCamp")}
            >
              Upload asset
          </button>
          </div>
        }
      </div>
    </motion.div>
  );
}
const AssetsItem = (props) => {
  const delBtnRef = useRef(null);
  const clickFunc = (e) => {
    if (!delBtnRef.current.contains(e.target)) props.onItemClick();
  };
  return (
    <div className="formCampaigns__assetsItem" onClick={clickFunc}>
      {!props.readOnly ?
        <button
          className="button tertiary big grey"
          ref={delBtnRef}
          onClick={props.deletAsset}
        >
          {trashIcon} Delete
      </button> : <button
          className="button tertiary big grey"
          ref={delBtnRef}
          onClick={props.onItemClick}
        >
          View
      </button>
      }
      <div className="assetsAdd__form-preview">
        {props.media_type_id === 2 && (
          <div className="assets__media">
            <img src={process.env.PUBLIC_URL + props.thumbnail} alt="" />
          </div>
        )}
        {props.media_type_id === 1 && (
          // <AssetVideo asset={props.asset} />
          <div className="assets__media vid">
            <video src={process.env.PUBLIC_URL + props.mediafile} muted></video>
          </div>
        )}
        <h6 className="sm">{props.title}</h6>
      </div>
    </div>
  );
};
