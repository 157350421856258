import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { accountService } from '../Services';
import { storageHandler } from './storage';

export default function PrivateRouteAuth({ children }) {
  const user = accountService.userValue;

  const token = storageHandler.getToken();
  const location = useLocation();
  if (token == null || token == '' || token == undefined) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/account/login' replace={true} state={location} />;
  } else {
    let roleId = user ? user.data.active_role : '';

    if (roleId != "") {
      if (roleId == 3) {
        if (children.props.path.includes("/brand/")) {
          return children;
        } else {
          return <Navigate to='/brand/dashboard' />;
        }
      }
      else if (roleId == 1) {
        if (children.props.path.includes("/influencer/")) {
          return children;
        } else {
          return <Navigate to='/influencer/dashboard' />;
        }
      }
      else if (roleId == 2) {
        if (children.props.path.includes("/admin/")) {
          return children;
        } else {
          return < Navigate to='/admin/dashboard' />;
        }
      } else {
        return <Navigate to='/account/login' replace={true} state={location} />;
      }
    } else {
      return children;
    }
  }
  //return children;
}
