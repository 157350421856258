import React from "react";
import { Modal } from "../../../Components";
import { facebook, instagram } from "../../../Components";
import { utils } from '../../../Helpers/utils';
export default function ModalCampDetail(props) {
  console.log(props)
  return (
    <Modal addClass="modalCamp" close={props.close}>
      <div className="modal__camp">
        <div className="modal__camp-title">
          <div className="modal__camp-title-image">
            <img
              src={
                props.brand_logo
                  ? props.brand_logo
                  : `${process.env.PUBLIC_URL}/images/bulls-eye.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.PUBLIC_URL}/images/bulls-eye.png`;
              }}
              alt='bulls-eye'
            />
          </div>
          <div className="modal__camp-title-content">
            <h3>Title</h3>
            <div className="modal__camp-title-content-row">
              <h6>{props.title}</h6>
            </div>
          </div>
        </div>
        <div className="modal__camp-content">
          <div className="modal__camp-content-title">
            <h3>Overview</h3>
            {props.status === "draft" && (
              <div className={"modal__camp-content-status " + props.status}>
                Starts on {props.start_date}
              </div>
            )}


            {props.status === "complete" && (
              <div className={"modal__camp-content-status " + props.status}>
                Completed on {props.end_date}
              </div>
            )}
          </div>
          <div className="modal__camp-content-inner">
            <p className="hideToolbar">
              <span>
                {props.description
                  ? utils.draftJsFormatingForDetails({ 'data': props.description, 'view': 'fullview' })
                  : 'NA'}
              </span></p>
            {props.revision === 1 && (
              <>
                <h3>Revision campaign</h3>
                <div className={"modal__camp-content-statu"}>
                  {props.revisioncount}
                </div>
              </>
            )}

            <h3>Deliverables</h3>
            <p className="hideToolbar">
              {props.deliverable ?
                <span>
                  {props.deliverable
                    ? utils.draftJsFormatingForDetails({ 'data': props.deliverable, 'view': 'fullview' })
                    : 'NA'}
                </span>
                :
                'NA'}
            </p>
            {props.compensationDetails.product != "" && (
              <>
                <h3>Product</h3>
                <p>
                  {props.compensationDetails.product
                    ? props.compensationDetails.product != "" ? props.compensationDetails.product
                      : '' : ''}
                </p></>
            )}
          </div>
        </div>
        <div className="modal__camp-footer">
          <div className="modal__camp-footer-row">
            <div className="modal__camp-footer-col">
              <h3>Compensation</h3>
              <div className="modal__camp-footer-col-inner">
                <h6>
                  Amount: <span> {props.amount
                    ? '$' + props.amount
                    : 'NA'}</span>
                </h6>
                {props.compensationDetails.product &&
                  <h6>
                    Product: <span> {props.compensationDetails.product
                      ? props.compensationDetails.product !== '' ? 'Yes'
                        : '' : ''}</span>
                  </h6>
                }
              </div>
            </div>
            <div className="modal__camp-footer-col">
              <h3>Campaign timeline</h3>
              <div className="modal__camp-footer-col-inner">
                <h6>
                  Start Date: <span>{props.start_date !== "" ? props.start_date : 'NA'}</span>
                </h6>
                <h6>
                  End Date: <span>{props.end_date !== "" ? props.end_date : 'NA'}</span>
                </h6>
              </div>
            </div>
          </div>
          {props.status !== "draft" ? (
            <button className="button secondary" onClick={() => {
              props.setTab("insights")
              props.close();
            }}>
              View full campaign insights
            </button>
          ) : (
            <button className="button secondary" onClick={() => { props.setModalCampaigns(null); props.setCampaignId(props.id); props.setTab("edit") }}>
              Edit campaign
            </button>
          )}
        </div>
      </div>
    </Modal >
  );
}
