import React, { useState } from "react";
import { SearchInput, SelectInput } from "../../Components";

import {
  arrowTop,
  download,
  plusIcon,
  searchIcon,
  arrowBottom
} from "../../Components";
import { Pagination } from "../../Components";
import { CampaignsModul } from "./CampaignsModul";
import CampaignsTableItem from "./CampaignsTableItem";

const selectDescList = [
  { id: "title", value: "Title" },
  { id: "description", value: "Description" },
  { id: "start_date_before", value: "Start date" },
  { id: "end_date_before", value: "End date" },
  { id: "status", value: "Status" },
  { id: "comp", value: "Comp" },
  { id: "budget", value: "Budget" },
];
export default function CampaignsMain({
  setTab,
  setModalCampaigns,
  setModalDataCampaigns,
  campaignData,
  paginationData,
  onPaginate,
  downloadCsv,
  sortList,
  sortOrder,
  search,
  setSearchTextOnChange,
  searchText,
  changeMediumDesc,
  setRecommandDataId,
  setCampaignId
}) {

  return (
    <div className="campaigns brandlist">
      <div className="campaigns__table-title">
        <div className="campaigns__table-title-info">
          <h4>Manage Campaigns</h4>
          <button
            className="button tertiary big"
            onClick={() => { setRecommandDataId({}); setTab("create") }}
          >
            Add campaign {plusIcon}
          </button>
        </div>
        <div className="campaigns__table-title-inner">
          <SelectInput
            list={selectDescList}
            selected={selectDescList[0]}
            onChange={changeMediumDesc}
          />
          <SearchInput
            value={searchText}
            onClick={search}
            type="text"
            icon={searchIcon}
            placeholder="Search"
            onChange={setSearchTextOnChange} />
        </div>
      </div>
      <>
        <div className="campaigns__table">
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>
                  <div className="campaigns__table-filter" onClick={sortList}>
                    Start date {sortOrder === 'asc' ? arrowTop : arrowBottom}
                  </div>
                </th>
                <th>End date</th>
                <th>Budget</th>
                <th>Comp</th>
                <th>Status</th>
                <th>Revision</th>
              </tr>
            </thead>
            <tbody>
              {campaignData.length > 0 ? (
                <>
                  {campaignData.map((item, index) => {
                    return (
                      <CampaignsTableItem
                        onClick={() => {
                          setModalCampaigns("detail");
                          setModalDataCampaigns(item);
                        }}
                        key={index}
                        {...item}
                        setTab={setTab}
                        setCampaignId={setCampaignId}
                      />
                    )
                  })}</>) : (
                <tr>
                  <td colSpan='8' style={{ textAlign: 'center' }}>
                    No records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
      <div className="campaigns__table-footer">
        <button className="button tertiary download" onClick={downloadCsv}>
          Download CSV {download}
        </button>
        {campaignData.length > 0 &&
          <Pagination
            perPage={paginationData['per_page']}
            total={paginationData['total']}
            currentPage={paginationData['current_page']}
            totalPages={paginationData['total_pages']}
            onPaginate={onPaginate}
          />
        }
      </div>
    </div>
  );
}
