import React from 'react';
import { utils } from '../../Helpers/utils';
export default function CollaborationItem(props) {
  return (
    <tr >
      <td onClick={props.setModal}>
        <p>{props.title}</p>
      </td>
      <td onClick={props.setModal}>
        {(props.accountCount > 1 && props.handle.handle) ? (
          <div className="collaboration__table-account">
            {props.handle.icon ? utils.getIconFilled(props.handle.icon[0]) : ''} <p>{props.handle.handle}</p>
          </div>
        ) : (
          <p className="hideToolbar">
            <span>
              {props.description
                ? utils.draftJsFormatingForDetails({ 'data': props.description, 'view': 'column' })
                : 'NA'}
            </span>
          </p>
        )}
      </td>
      <td></td>
      <td onClick={props.setModal}>{props.end_date}</td>
      <td onClick={props.setModal}>{props.budget}{(props.rate_overwritten && parseInt(props.rate_overwritten) == 1)
        && <img className="redFlag"
          src={`${process.env.PUBLIC_URL}/images/red-flag.png`} alt="red-flag" />
      }</td>
      <td onClick={props.setModal}>
        <div
          className={'collaboration__table-status ' + (props.status !== 'paid' ? 'active' : 'complete')}
          onClick={props.setModal}
        >
          {props.status}
        </div>
        <div
          className={'collaboration__table-status '}
          onClick={props.setModal}
        >

        </div>
      </td>
      {props.revision != 0 ?
        <td>
          <a onClick={() => {
            props.setEngagementId(props.id);
            props.setInfluencerId(props.influencer_id);
            props.setAddRevisionFlag(props.addRevisionFlag);
            props.setTotalrevision(props.totlarevision);
            props.setUsedrevision(props.usedrevision);
            props.setRevisionMsg(props.revisionMsg);
            props.setTab("revisionlist")
          }}>
            <img src='images/revision-edit.png' />
          </a>
        </td>
        : <td>  <img src='images/revision-ban.png' /></td>
      }
    </tr>
  );
}
