import React, { useState, useEffect } from 'react';
import { Modal, TextInput, TextArea } from '../../Components';

export default function ModalRates(props) {
  const [data, setData] = useState(props.modalData);
  const [error, setError] = useState(false);
  const updateBtnHandler = () => {
    setError(true);
    if (data['price'] === "" || data['price'] == 0 || data['price'] < 0) {
      props.addToast({
        error: true,
        msg: "Enter rate details to save.",
        date: new Date()
      });
    } else {
      props.updateHandler(data);
      props.close();
    }

  };

  const onChange = (e, key) => {
    const val = e.target.value;
    const newData = Object.assign({}, data);
    newData[key] = val;
    setData(newData);
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        updateBtnHandler();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [data]);
  return (
    <Modal addClass='modalRate' close={props.close}>
      <div className='modal__rate'>
        <div className='modal__rate-title'>
          <div className='modal__rate-image'>
            {props.type === 'Facebook' && (
              <img
                src={process.env.PUBLIC_URL + '/images/facebook.png'}
                alt='facebook'
              />
            )}
            {props.type === 'Instagram' && (
              <img
                src={process.env.PUBLIC_URL + '/images/instagram.png'}
                alt='instagram'
              />
            )}
            {props.type === 'TikTok' && (
              <img
                src={process.env.PUBLIC_URL + '/images/tiktok.png'}
                alt='tiktok'
              />
            )}
            {props.type === 'Youtube' && (
              <img
                src={process.env.PUBLIC_URL + '/images/youtube.png'}
                alt='youyube'
              />
            )}
          </div>
          <h3 className='big'>{data.rateName}</h3>
        </div>
        <div className='modal__rate-price'>
          <p>{data.priceText}</p>
          <TextInput
            type='text'
            error={error}
            errorZero={error}
            errorText="Please enter rate."
            value={data.price}
            onChange={e => onChange(e, 'price')}
            autoFocus
          />
        </div>
        <div className='modal__rate-details'>
          <TextArea
            label='Share details or additional info here.'
            value={data.desc}

            row={4}
            onChange={e => onChange(e, 'desc')}
          />
        </div>
        <div className='modal__rate-link'>
          <TextInput
            value={data.link}

            label='Link to sample work.'
            onChange={e => onChange(e, 'link')}
          />
        </div>
        <button className='button primary' onClick={updateBtnHandler}>
          Update
        </button>
      </div>
    </Modal>
  );
}
