import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { chevronBottom } from './SVG';
import { accountService } from '../../Services';

export default function Header({ menu, setMenu, setModal, type = null, profiles }) {
  const wrapper = useRef(null);
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setDropdown(false);
    };

    if (dropdown) window.addEventListener('click', windowClick);
    else window.removeEventListener('click', windowClick);

    return () => window.removeEventListener('click', windowClick);
  }, [dropdown]);
  const logout = () => {
    accountService.logout(() => {
      navigate('/account/login');
    });
  };
  return (
    <header className={'header ' + (type === 'brand' ? 'brand' : '')}>
      <div className='header__inner'>
        <div
          className={'burger ' + (menu ? 'active' : '')}
          id='menuBtn'
          onClick={() => {
            setMenu(!menu);
          }}
        >
          <span></span>
        </div>
        <NavLink to='/' className='header__inner-logo'>
          <img
            src={
              process.env.PUBLIC_URL +
              (type === 'brand'
                ? 'images/logo-white-text.png'
                : 'images/logo.png')
            }
            alt='logo'
          />
        </NavLink>
        <div
          className={'header__avatar ' + (dropdown ? 'active' : '')}
          ref={wrapper}
          onClick={() => setDropdown(!dropdown)}
        >
          <div className='header__avatar-image'>
            <img
              src={
                process.env.PUBLIC_URL +
                (type === 'brand'
                  ? '/images/avatarBrand.png'
                  : '/images/avatar.png')
              }
              alt='png'
            />
          </div>
          {chevronBottom}
          <div className='header__avatar-content'>
            <Link
              to='/influencer/profile'
              className='header__avatar-content-link'
            >
              My Profile
            </Link>
            {profiles.length > 1 && (
              <button
                type='button'
                className='header__avatar-content-link'
                onClick={() => setModal('switch')}
              >
                Switch account
              </button>
            )}
            <Link
              to='/'
              className='header__avatar-content-link'
              onClick={() => logout()}
            >
              Log out
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
