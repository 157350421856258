import { AnimatePresence } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import {
  YearToDate,
  searchIcon,
  download,
  Profile,
  Pagination,
  SelectInput,
  SearchInput,
  Toast,
  RevisionList
} from '../../Components';
import { useLocation } from 'react-router-dom';
import CollaborationItem from './CollaborationItem';
import { CollaborationModul } from './CollaborationModul';
import { influencerService } from '../../Services';
import ModalCollaboration from './ModalCollaboration';
import { utils } from '../../Helpers';
import { useSearchParams } from 'react-router-dom';

const ytdList = [
  { id: '0', value: 'Year to date' },
  { id: '1', value: 'Past 1 month' },
  { id: '6', value: 'Past 6 months' },
  { id: '12', value: 'Past 12 months' }
];
const selectDescList = [
  { id: 'title', value: 'Title' },
  { id: 'start_date', value: 'Start date' },
  { id: 'end_date', value: 'End date' },
  { id: 'budget', value: 'Compensation' },
  { id: 'status', value: 'Status' }
];

export default function Collaboration(props) {
  const location = useLocation();
  const [ytd, setYtd] = useState(0);
  const [modal, setModal] = useState(null);
  const [selectDesc, setselectDesc] = useState(selectDescList[0]);
  const [searchString, setSearchString] = useState('');
  const [toastList, setToastList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [collaborations, setCollaborations] = useState([]);
  const [summary, setSummary] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState(0);
  const [engStatus, setEngStatus] = useState(location.state ? location.state.engStatus : "");
  const [searchList, setSearchList] = useState(false);
  const [tabData, setTabData] = useState(null);
  const [tab, setTab] = useState("main");
  const perPage = 10;
  const [accountCount, setAccountCount] = useState(0);

  const [engagementId, setEngagementId] = useState("");
  const [influencerId, setInfluencerId] = useState("");
  const [addRevisionFlag, setAddRevisionFlag] = useState("");
  const [totalrevision, setTotalrevision] = useState("");
  const [usedrevision, setUsedrevision] = useState("");
  const [revisionMsg, setRevisionMsg] = useState("");
  const [isseacrhByIdDone, setIsseacrhByIdDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlCampaignId = searchParams.get('id') ? searchParams.get('id') !== null || searchParams.get('id') !== undefined ? searchParams.get('id') : null : null;

  const changeMedium = item => {
    setDateRange(item.id);
    setPage(1);
    setSummary(true);
    setSearchString('');
    setselectDesc(selectDescList[0]);
  };

  const changeMediumDesc = item => {
    setPage(1);
    setEngStatus("");
    setselectDesc(item);
    setSearchString('');
    setSearchList(!searchList);
  };

  const onSearchStringChange = e => {
    setEngStatus("");
    setPage(1);
    if (e.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchString(e.target.value);
  };
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        onSearch();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchString]);
  const downloadCsv = async () => {
    try {
      let options = {
        page: page,
        pageSize: perPage,
        range: dateRange,
        fileType: 'csv'
      };
      if (searchString != '') {
        options['search'] = searchString;
      }
      const response = await influencerService.getCollaborationsData(options);
      utils.downloadFile(response, "collaboration_page_" + page + ".csv");
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  useEffect(() => {
    if (engStatus !== "") {
      setselectDesc({ id: 'status', value: 'Status' });
      setSearchString(engStatus.charAt(0).toUpperCase() + engStatus.slice(1));
      fetchData({ key: 'status', value: engStatus });
    } else {
      fetchData();
    }

  }, [page, dateRange, searchList]);


  const onPaginate = page => {
    setPage(page);
  };

  const onSearch = () => {
    fetchData({ key: selectDesc['id'], value: searchString });
  };

  async function fetchData(search) {
    try {
      let options = {
        page: page,
        pageSize: perPage,
        range: dateRange,
      };
      if (search) {
        options['search'] = search;
      } else {
        if (urlCampaignId != "" && urlCampaignId !== null && urlCampaignId !== undefined && isseacrhByIdDone === false) {
          let id = 'id';
          options['filter'] = '&fields[' + id + ']=' + urlCampaignId;
          setIsseacrhByIdDone(true);
        }
      }
      console.log(options)
      const res = await influencerService.getCollaborationsData(options);
      setCollaborations(res['data']);
      setAccountCount(res.account_count ? res.account_count : 1);
      setSummary(res['summary'] ? res['summary'] : null);
      if (res['meta'] && res['meta']['pagination']) {
        setPaginationData(res['meta']['pagination']);
      }
      setIsLoaded(true);
    } catch (err) {
      setCollaborations([]);
      setPaginationData(null);
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title'>
            {tab === "main" && (<h2>Brand collaborations</h2>
            )}
            {tab === "revisionlist" && (<h2>Revisions</h2>
            )}
            <Profile
              user={props.user}
              setModal={props.setModal}
              profiles={props.profiles}
              NotifModul={props.NotifModul}
              setNotifModul={props.setModalNotification}
            />
          </div>
          {isLoaded && tab === "main" && (
            <div className='collaboration'>
              <YearToDate
                list={ytdList}
                selected={ytdList[0]}
                onChange={changeMedium}
              />
              <div className='collaboration__row'>
                <div className='collaboration__col'>
                  <h5>Total revenue</h5>
                  <div className='collaboration__col-inner'>
                    <h2>
                      {summary !== null ? summary['totalRevenue'] : `$0.00`}
                    </h2>
                  </div>
                </div>
                <div className='collaboration__col'>
                  <h5>Active collaborations</h5>
                  <div className='collaboration__col-inner'>
                    <h2>
                      {summary !== null &&
                        summary['campaignsInProgress'] &&
                        summary['campaignsInProgress']['count']
                        ? summary['campaignsInProgress']['count']
                        : 0}
                    </h2>
                    <h2>
                      {summary !== null &&
                        summary['campaignsInProgress'] &&
                        summary['campaignsInProgress']['revenue']
                        ? summary['campaignsInProgress']['revenue']
                        : `$0.00`}
                    </h2>
                  </div>
                </div>
                <div className='collaboration__col'>
                  <h5>Completed collaborations</h5>
                  <div className='collaboration__col-inner'>
                    <h2>
                      {summary !== null &&
                        summary['campaignsCompleted'] &&
                        summary['campaignsCompleted']['count']
                        ? summary['campaignsCompleted']['count']
                        : 0}
                    </h2>
                    <h2>
                      {summary !== null &&
                        summary['campaignsCompleted'] &&
                        summary['campaignsCompleted']['revenue']
                        ? summary['campaignsCompleted']['revenue']
                        : `$0.00`}
                    </h2>
                  </div>
                </div>
              </div>
              <div className='collaboration__table-title'>
                <h4>Collaboration details</h4>
                <SelectInput
                  list={selectDescList}
                  selected={selectDesc}
                  onChange={changeMediumDesc}
                />
                <SearchInput
                  type='text'
                  icon={searchIcon}
                  placeholder={'Search ' + selectDesc.value}
                  onChange={onSearchStringChange}
                  onClick={onSearch}
                  value={searchString}
                />
              </div>
              <div
                className={
                  "collaboration__table " +
                  (accountCount > 1 ? "moreAcc" : "")
                }
              >
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>
                        {accountCount > 1 ? "Account" : "Description"}
                      </th>
                      <th></th>
                      <th>Post by</th>
                      <th>Compensation</th>
                      <th>Status</th>
                      <th>Revision</th>
                    </tr>
                  </thead>
                  <tbody>
                    {collaborations.length > 0 ? (
                      collaborations.map((item, index) => {
                        return (
                          <CollaborationItem
                            accountCount={accountCount}
                            key={index}
                            {...item}
                            setModal={() => setModal(item)}
                            setTab={setTab}
                            setEngagementId={setEngagementId}
                            setInfluencerId={setInfluencerId}
                            setAddRevisionFlag={setAddRevisionFlag}
                            setTotalrevision={setTotalrevision}
                            setUsedrevision={setUsedrevision}
                            setRevisionMsg={setRevisionMsg}
                          />
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='6' style={{ textAlign: 'center' }}>
                          No records
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='collaboration__table-footer'>
                <button className='button tertiary download' onClick={downloadCsv}>
                  Download CSV {download}
                </button>
                {collaborations.length > 0 && (
                  <Pagination
                    perPage={paginationData['per_page']}
                    total={paginationData['total']}
                    currentPage={paginationData['current_page']}
                    totalPages={paginationData['total_pages']}
                    onPaginate={onPaginate}
                  />
                )}
              </div>
              {toastList && <Toast list={toastList} setList={setToastList} />}
            </div>
          )}

          {tab === "revisionlist" && (
            <RevisionList
              setTab={setTab}
              engagementId={engagementId}
              influencerId={influencerId}
              setTabData={setTabData}
              setEngagementId={setEngagementId}
              setInfluencerId={setInfluencerId}
              setModal={setModal}
              addRevisionFlag={addRevisionFlag}
              totalrevision={totalrevision}
              usedrevision={usedrevision}
              revisionMsg={revisionMsg}
              addToast={addToast}
            />
          )}
        </div>
      </div>
      <AnimatePresence>
        {modal && (
          <ModalCollaboration
            collaboration={modal}
            close={() => setModal(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
}