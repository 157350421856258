import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { chevronBottom, notifIcon } from './SVG';
import { accountService } from '../../Services';

export default function Profile(props) {
  const navigate = useNavigate();
  const wrapper = useRef(null);
  const [isRead, setIsRead] = useState(0);
  const [dropdown, setDropdown] = useState(false);

  const data = props.user ? (props.user.data ? props.user.data : null) : null;
  let profilePath = ``;
  if (data) {
    const roleId =
      data['roles'].length > 0
        ? data['roles'].filter(item => item['id'] == data['active_role'])[0][
        'id'
        ]
        : '';
    if (roleId === 1) {
      profilePath = `/influencer/profile`;
    } else if (roleId === 3) {
      profilePath = `/brand/profile`;
    } else if (roleId === 2) {
      profilePath = `/admin/profile`;
    }
  }

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setDropdown(false);
    };

    if (dropdown) window.addEventListener('click', windowClick);
    else window.removeEventListener('click', windowClick);

    return () => window.removeEventListener('click', windowClick);
  }, [dropdown]);

  const logout = () => {
    accountService.logout(() => {
      navigate('/account/login');
    });
  };

  return (
    <div className='profile'>
       <div
        className={'profile__icon ' + (props.NotifModul.length > 0 ? props.NotifModul[0].activeNotification === 0 ? 'read' : '' : 'read')}
        onClick={() => { props.setNotifModul("notifications"); }}
      >
        {notifIcon}
      </div>
      <div
        className={'profile__inner-wrapper ' + (dropdown ? 'active' : '')}
        ref={wrapper}
        onClick={() => setDropdown(!dropdown)}
      >
        <div className='profile__inner'>
          <div className='profile__inner-content'>
            <div className='profile__inner-image'>
              <img
                style={{ width: '48px', height: '48px' }}
                src={
                  data && data.photo && data.photo !== ''
                    ? data.photo
                    : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
                }}
                alt='avatar'
              />
            </div>
            <div className='profile__inner-info'>
              <p className='sm'>
                {data &&
                  data.influencers &&
                  data.influencers.length > 0 &&
                  data.influencers[0].social_handle
                  ? data.influencers[0].social_handle
                  : ''}
              </p>
              <p className='sm'>
                {data && data.firstName ? data.firstName : ''}{' '}
                {data && data.lastName ? data.lastName : ''}
              </p>
            </div>
          </div>
          {chevronBottom}
        </div>
        <div className='profile__dropdown'>
          <Link to={profilePath} className='profile__dropdown-link'>
            My Profile
          </Link>
          {props.profiles.length > 1 && (
            <button
              type='button'
              className='profile__dropdown-link'
              onClick={() => props.setModal('switch')}
            >
              Switch account
            </button>
          )}
          <Link
            to='/'
            className='profile__dropdown-link'
            onClick={() => logout()}
          >
            Log out
          </Link>
        </div>
      </div>
    </div>
  );
}
