import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { HeaderAcc, Toast, EmailInput, PasswordInput } from '../../Components';
import { motion } from 'framer-motion';
import { accountService, alertService } from '../../Services';
import { useSearchParams } from 'react-router-dom';
import { utils } from '../../Helpers/utils';
import Parser from 'html-react-parser';
const initForm = {
  email: '',
  password: ''
};

export default function Login({ setModal, referPageUrl }) {
  const [form, setForm] = useState(initForm);
  const [error, setError] = useState(false);
  const [isLinkVerified, setIsLinkVerified] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [toastList, setToastList] = useState([]);
  const [isLoginWithTamaEmail, setIsLoginWithTamaEmail] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const validateId = searchParams.get('validateId') ? searchParams.get('validateId') : null
  const updateForm = data => {
    setForm({ ...form, ...data });
  };
  const setInput = key => event => updateForm({ [key]: event.target.value });
  useEffect(() => {

    const isError = searchParams.get('error') !== null ? searchParams.get('error') == 1 ? Parser("Please <a className='paidLink' href='/#/account/signup'> click here </a> to signup") : searchParams.get('error') == 2 ? Parser("Not have enough permission to login") : '' : '';
    if (isError !== '') {
      addToast({
        error: true,
        msg: isError,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }, [])
  let pathurl = (referPageUrl !== null && referPageUrl !== undefined && referPageUrl != "/account/validateEmail") ? referPageUrl.state ? referPageUrl.state.pathname : '' : '';
  const handleLoginWithFacebook = () => {
    setIsLoginWithTamaEmail(false);
    //console.log((`${process.env.REACT_APP_API_URL}/login/facebook/action/signin/referalPath/${pathurl}`));
    window.location.href = `${process.env.REACT_APP_API_URL}/login/facebook/action/signin/role/1/0/referalPath/${pathurl}`;
  };

  const handleLoginWithGoogle = () => {
    setIsLoginWithTamaEmail(false);
    window.location.href = `${process.env.REACT_APP_API_URL}/login/google/action/signin/role/1/0/referalPath/${pathurl}`;
  };
  const handleLoginWithTikTok = () => {
    setIsLoginWithTamaEmail(false);
    window.location.href = `${process.env.REACT_APP_API_URL}/login/tiktok/action/signin/role/1/0/referalPath/${pathurl}`;
  };
  const handleLoginWithInsta = () => {
    setIsLoginWithTamaEmail(false);
    window.location.href = `${process.env.REACT_APP_API_URL}/login/instagram/action/signin/role/1/0/referalPath/${pathurl}`;
  };
  const loginWithTamaEmail = () => {
    setIsLoginWithTamaEmail(true);
  }
  const backTomainPage = () => {
    setIsLoginWithTamaEmail(false);
  }
  const loginValidation = () => {
    if (form.email === '' || form.password === '') {
      setError(true);
    } else {
      alertService.clear();
      accountService
        .login(form['email'], form['password'])
        .then(res => {
          if (res['data'] && res['data']['roles']) {


            let arrPath = [];
            if (referPageUrl !== null) {
              arrPath = referPageUrl.state.pathname.split('/');
            }

            if (res['data']['roles'][0] && res['data']['roles'][0]['id'] === 1 && res['data']['active_role'] === 1) {
              if (arrPath.length > 0 && arrPath[1] === 'influencer') {
                navigate(referPageUrl.state.pathname);
              } else {
                navigate(utils.redirectUrl(res.profilestatus)[0]);
              }
            } else if (
              res['data']['role'] &&
              res['data']['role'] === 'advertiser' &&
              res['data']['active_role'] === 3
            ) {
              if (arrPath.length > 0 && arrPath[1] === 'brand') {
                navigate(referPageUrl.state.pathname);
              } else {
                navigate('/brand/dashboard');
              }
            } else if (
              res['data']['role'] &&
              res['data']['role'] === 'admin' &&
              res['data']['active_role'] === 2
            ) {
              if (arrPath.length > 0 && arrPath[1] === 'admin') {
                if (referPageUrl.state.search !== "" && referPageUrl.state.search !== null) {
                  navigate(referPageUrl.state.pathname + referPageUrl.state.search);
                } else {
                  navigate(referPageUrl.state.pathname);
                }

              } else {
                navigate('/admin/dashboard');
              }
            } else {
              navigate('/account/login');
            }
          } else if (res.isEmailVerified === false) {
            if (res.isEmailVerified === false && res.reason === "resendverification") {
              navigate('/account/validateEmail', { state: { 'email': res.data.email, 'userId': res.data.id, 'reason': res.reason } });
            }
          } else {
            navigate('/account/login');
          }

        })
        .catch(err => {
          addToast({
            error: true,
            msg: err,
            id: `${Date.now()}-${toastList.length}`
          });
        });
    }
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };


  useEffect(() => {
    const validateEmailLink = async () => {
      if (validateId && validateId !== "") {
        try {
          let res = await accountService.validateEmail(validateId);
          setIsLinkVerified(true);
          if (res.isEmailVerified) {
            setIsLinkVerified(true);
            setVerificationMessage("Thank You. Email is verified.")
          } else {
            if (res.reason === "expired") {
              navigate('/account/validateEmail', { state: { 'userId': res.data.id, 'reason': res.reason } });
            }
            if (res.reason === "unverified") {
              navigate('/account/validateEmail', { state: { 'userId': '', 'reason': res.reason } });
            }
          }
        } catch (err) {
          addToast({
            error: true,
            date: new Date(),
            msg: err
          });
        }

      } else {
        setIsLinkVerified(true);
      }
    }
    validateEmailLink();
  }, []);


  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        loginValidation();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);
  return (


    <> {isLinkVerified &&
      <div className='account log'>
        <div className='account__inner'>
          <HeaderAcc />
          <div className='account__inner-col'>
            <div className='account__inner-col-image'>
              <img
                src={process.env.PUBLIC_URL + '/images/accountBg.jpg'}
                alt='accountBg'
              />
            </div>
          </div>
          <div className="account__footer">
            <div className="account__footer-inner">
              <div className="account__footer-copy">
                Copyright {new Date().getFullYear()} © TamaYou
              </div>
              <Link target="_blank" to="https://tamayou.com/tama-privacy-policy/" className="account__footer-link">
                Privacy
              </Link>
              <Link target="_blank" to="https://tamayou.com/terms/" className="account__footer-link linkTerms">
                Terms
              </Link>
            </div>
          </div>

          <div className='account__inner-col'>
            <motion.div
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ opacity: 0, y: 0 }}
              className='account__login'
            >
              {isLoginWithTamaEmail ?
                <div className='account__login-inner'>
                  <p className="messageText"><strong>{verificationMessage}</strong></p>
                  <h6 className='account__login-row-link backLink' onClick={backTomainPage}>Back</h6>
                  <h2 className='uniq'>Login</h2>

                  <EmailInput
                    value={form.email}
                    type='email'
                    onChange={setInput('email')}
                    label='E-mail'
                    error={error}
                    autoFocus
                    errorText='Please enter Email'
                  />
                  <PasswordInput
                    value={form.password}
                    type='password'
                    onChange={setInput('password')}
                    label='Password'
                    error={error}
                    page="loginpage"
                    errorText={form.password === "" ? 'Please enter Password' : ""}
                  />
                  <div className='account__login-row'>
                    <div className='checkbox'>
                      <input type='checkbox' />
                      <label htmlFor=''></label>
                      <p>Remember me</p>
                    </div>
                    <Link to='/account/forgot-pass' className='account__login-row-link'>
                      Forgot Password
                    </Link>
                  </div>
                  <button className='button primary' onClick={loginValidation}
                  >
                    Login
                  </button>
                  <p className='sm'>
                    Don’t have an account?{' '}
                    <Link to='/account/signup'>Sign Up</Link>
                  </p>
                </div> :
                <div className='account__login-inner'>
                  <p className="messageText"><strong>{verificationMessage}</strong></p>
                  <h2 className='uniq'>Login</h2>
                  <h4>How would you like to sign in?</h4>
                  <div className='modal__platform'>

                    <button className='button' onClick={handleLoginWithFacebook}>
                      <img
                        src={process.env.PUBLIC_URL + '/images/facebookSign.png'}
                        alt=''
                      />
                      Sign in with Facebook
                    </button>
                    <button className='button' onClick={handleLoginWithInsta}>
                      <img
                        src={process.env.PUBLIC_URL + '/images/instagramSign.png'}
                        alt=''
                      />
                      Sign in with Instagram
                    </button>
                    <button className='button' onClick={handleLoginWithTikTok}>
                      <img
                        src={process.env.PUBLIC_URL + '/images/tiktokSign.png'}
                        alt=''
                      />
                      Sign in with TikTok
                    </button>
                    <button className='button' onClick={handleLoginWithGoogle}>
                      <img src={process.env.PUBLIC_URL + '/images/googleSign.png'} alt='' />
                      Sign in with Google
                    </button>
                    <button className='button' onClick={loginWithTamaEmail}>
                      <img src={process.env.PUBLIC_URL + '/images/envelope.png'} alt='' />
                      Sign in with Email
                    </button>
                    <p className='sm'>
                      Don’t have an account?{' '}
                      <Link to='/account/signup'>Sign Up</Link>
                    </p>
                  </div>
                </div>
              }
            </motion.div>
          </div>
        </div>
      </div>}
      {toastList && <Toast list={toastList} setList={setToastList} />}
    </>

  );
}
