import React, { useRef } from "react";

export default function CronItem(props) {

  return (
    <tr onClick={props.onClick}>
      <td >
        <img style={{ verticalAlign: "middle" }} target="_blank" id={"valId_" + props.id} src='images/info-circle.png' title={props.info ? props.info : 'NA'} /> {props.label}
      </td>
      <td >
        <p>{props.frequency}</p>
      </td>
      <td>{props.last_started_at}</td>
      <td>
        <p>{props.last_finished_at}</p>
      </td>
      <td >
        <p>{props.next_run_date}</p>
      </td>
      <td style={{ padding: "0px" }}>
        <img target="_blank" id={"valId_" + props.id} src='images/eye.png' />
      </td>

    </tr>
  );
}
