import React from "react";
import { utils } from "../../Helpers/utils";
export default function DashboardItem(props) {
  return (
    <tr>
      <td>
        <p>{props.title}</p>
      </td>
      <td>{props.start_date}</td>
      <td>
        <p>{props.description && utils.draftJsFormatingForDetails({ 'data': props.description, 'view': 'column' })}</p>
      </td>
      <td>{props.budget}</td>
    </tr>
  );
}
