import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils } from '../../../Helpers/utils';

export default function NotificationItem(props) {
  const delButton = useRef(null);
  const viewButton = useRef(null);
  const [tab, setTab] = useState("main");
  const navigate = useNavigate();
  const setRead = () => {
    let newNotifModul = [...props.NotifModul];
    const newVisitedItemIndex = newNotifModul.findIndex((item) => {
      return item.id === props.id;
    });
    props.updateNotificationStatus(newNotifModul[newVisitedItemIndex].id, 'read')
    newNotifModul[newVisitedItemIndex].read = true;
    props.setNotifModul(newNotifModul);
    props.setModalData();
    if (props.modal !== "notifDetail") {
      props.setModalNotification("notifDetailDash");
    } else {
      props.setModalNotification("notifDetail");
    }
    props.setModalNotificationData(newNotifModul[newVisitedItemIndex]);
    //props.setModalNotification("notifDetailDash");
  };
  const clickFunc = (e) => {
    props.setModalData();
    //props.setModalData("notifDetail");
    if (e.target === delButton.current) setTab("delete");
    else if (e.target === viewButton.current) navClickHandler();
    else setRead();
  };

  useEffect(() => {
    if (props.readAll === true) {
      let newNotifModul = [...props.NotifModul];
      newNotifModul.forEach((item) => {
        item.read = true;
      });
      props.setNotifModul(newNotifModul);
    }
    // eslint-disable-next-line
  }, [props.readAll]);
  const navClickHandler = () => {
    console.log(props);
    if (props.buttonText === "View payment") {
      let newNotifModul = [...props.NotifModul];
      const newVisitedItemIndex = newNotifModul.findIndex((item) => {
        return item.id === props.id;
      });
      newNotifModul[newVisitedItemIndex].read = true;
      props.setNotifModul(newNotifModul);
      props.setModal(null);
      navigate("/influencer/payment");
    } else if (props.buttonText === "View details") {
      if (props.sytemNotiType === 'campaign_activity' || props.sytemNotiType === 'campaign_complete' || props.sytemNotiType === 'revision_activity') {
        props.setModal(null);
        navigate("/brand/campaigns?id=" + props.opportunity_id);
      } else if (props.sytemNotiType === 'token_expired') {
        props.setModal(null);
        navigate("/influencer/social_accounts");
      } else if (props.sytemNotiType === 'revision_activity_inf') {
        props.setModal(null);
        navigate("/influencer/collaborations?id=" + props.opportunity_id);
      } else {
        let newNotifModul = [...props.NotifModul];
        const newVisitedItemIndex = newNotifModul.findIndex((item) => {
          return item.id === props.id;
        });
        newNotifModul[newVisitedItemIndex].read = true;
        props.setNotifModul(newNotifModul);
        let expirayDate = props.expired.split("-");
        if (props.expired && (new Date(expirayDate[1] + "-" + expirayDate[2] + "-" + expirayDate[0]) < new Date())) {

          props.setModalData();
          props.setModal("notifExpired");
          navigate("/influencer/opportunities");
        } else {
          props.setModal(null);
          navigate("/influencer/opportunities");
        }
      }
    }
  };
  return (
    <>
      {tab === "main" && (
        <div
          className={
            "notificationItem mod " +
            (props.read ? "" : "unread ") +
            (props.accountCount > 1 ? "moreAcc " : "")
          }
          onClick={clickFunc}
        >
          {props.accountCount > 1 && (
            <div className="notificationItem__account">
              {(props.handle.icon && props.handle.icon[0] !== "" && props.handle.icon[0] !== null) && utils.getIconFilled(props.handle.icon[0])}
              {props.handle && <span>{props.handle.handle}</span>}
            </div>
          )}
          <span className="notificationItem__circle"></span>
          <div className="notificationItem__image">
            <img src={process.env.PUBLIC_URL + props.image} alt={props.id} />
          </div>
          <div
            className={
              "notificationItem__content " + (props.buttonText ? "pb" : "")
            }
          >
            <div className="notificationItem__content-title">
              <h6>{props.title}</h6>
              {props.date && <p className="sm">{props.date}</p>}
            </div>
            {/*<p className="sm">{props.subtitle}</p>*/}
            <p className="sm">{props.text}</p>
            {props.buttonText && (
              <div className="notificationItem__content-btns">
                {props.buttonText === "View payment" && (
                  <button ref={viewButton} className="button primary">
                    {props.buttonText}
                  </button>
                )}
                {props.buttonText === "View details" && (
                  <button ref={viewButton} className="button primary">
                    {props.buttonText}
                  </button>
                )}
                <button className="button secondary" ref={delButton}>
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {tab === "delete" && (
        <div className="notificationDelete">
          <h4>Delete notification?</h4>
          <p className="sm">
            Please confirm you would like to delete this notification.
          </p>
          <div className="notificationDelete__btns">
            <button
              className="button primary"
              onClick={() => {
                setTab("main");
                props.updateNotificationStatus(props.id, 'deleted');
              }}
            >
              Yes, delete
            </button>
            <button className="button secondary" onClick={() => setTab("main")}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}