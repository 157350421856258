import React, { useState, useEffect } from 'react';
import { Modal, TextInput, TextArea } from '../../../../Components';
import {
  influencerService
} from '../../../../Services';
export default function ModalAddRevision(props) {

  const [data, setData] = useState(props);
  const [error, setError] = useState(false);
  // const [toastList, setToastList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const saveDetails = async () => {
    try {
      if (data['link'] !== undefined && data['link'] != ''
        && data['content'] !== undefined && data['content'] != '') {
        console.log("in if");

        data['influencer_id'] = props.influencerId;
        data['engagement_id'] = props.engagementId;
        data['status'] = 'pending';
        data['engagement_influencer_map_id'] = props.engagement_influencer_map_id;
        data['product_id'] = props.product_id;

        await influencerService.saveRevisionDetails(data);
        props.close();
        props.addToast({ error: false, date: new Date() });
        let pageSize = 10;
        let filterStr = '';
        filterStr = 'filter[engagement_id]=' + props.engagementId;
        filterStr += '&filter[influencer_id]=' + props.influencerId;
        const res = await influencerService.getRevisionWithStatus({
          page: 1,
          pageSize,

        }, '', filterStr
        );
        if (res.meta && res.meta.pagination) {
          // props.setPaginationData(res.meta.pagination);
        }
        props.setInfEngList(res.data);
        props.setLoadList(true);
      } else {
        setError(true);
        props.addToast({
          error: true,
          msg: "Please enter all fields to save.",
          successText: '',
          date: new Date()
        });

      }
    } catch (err) {
      //console.log("err....." + err);
      props.addToast({
        error: true,
        date: new Date(),
        msg: 'Error!' //err.replaceAll(/<\/?[^>]+(>|$)/gi, '')
      });
      props.close();
    }
  };

  const onChange = (e, key) => {
    const val = e.target.value;
    const newData = Object.assign({}, data);
    newData[key] = val;
    setData(newData);
  };

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        updateBtnHandler();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [data]);
  const updateBtnHandler = data => {
    setError(true);
    if (data['link'] === "" || data['content'] == "") {
      props.addToast({
        error: true,
        msg: "Enter details to save.",
        date: new Date()
      });
    } else {
      //.updateHandler(data);
      props.addToast({
        error: false,
        msg: "suceess",
        date: new Date()
      });
      props.close();
    }

  };


  return (

    <Modal addClass='modalRate' close={props.close}>
      <div className='modal__rate'>
        <div className='modal__rate-title'>

          <h3 className='big'>Add Revision</h3>
        </div>
        <div className='modal__rate-link'>
          <TextInput
            value={data.link}
            label='Link'
            error={error}
            errorText="Please enter link"
            onChange={e => onChange(e, 'link')}
          />
        </div>


        <div className='modal__rate-link'>
          <TextArea
            label='Content'
            value={data.content}
            row={4}
            onChange={e => onChange(e, 'content')}
          />
        </div>

        <div className='modal__rate-details'>
          <TextArea
            label='Notes'
            value={data.notes}
            row={4}
            onChange={e => onChange(e, 'notes')}
          />
        </div>

        <button className='button primary' onClick={() => {
          console.log("save revision");
          saveDetails()
        }
        }>
          Submit
        </button>
      </div>
    </Modal >
  );
}
