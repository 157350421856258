import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { SearchInput, SelectInput, Pagination, Profile } from "../../Components";
import { adminService } from "../../Services";
import {
  arrowTop,
  copyIcon,
  download,
  searchIcon,
} from "../../Components/Base/SVG";
import URLItem from "./URLItem";
import { URLModul } from "./URLModul";
import { motion } from "framer-motion";
import { Toast } from "../../Components";
import ModalUrl from "./ModalUrl";
import ModalUrlDel from "./ModalUrlDel";
import { utils } from "../../Helpers";
const initForm = {
  urls: [],
};

const selectDescList = [
  { id: "handle", value: "Handle" },
  { id: "full_name", value: "Full Name" },
  { id: "email_1", value: "Email" },
  { id: "bio", value: "Bio" },
  { id: "id", value: "Id" },
];
const selectUsersTypeList = [
  { id: "1", value: "All" },
  { id: "2", value: "Signed up" },
  { id: "3", value: "Not signed up" },
];

export default function CustomUrl({ setAuth, setModal, NotifModul, profiles,
  setModalNotification }) {
  const inpRef = useRef(null);
  const refCustomUrl = useRef(null);
  // eslint-disable-next-line
  const [selectDesc, setselectDesc] = useState(selectDescList[0].id);
  const [modalUrl, setModalUrl] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [modalDataUrl, setModalDataUrl] = useState(null);
  const [form, setForm] = useState(initForm);
  const [toastList, setToastList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [handles, setHandles] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({});
  const [userType, setUserType] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [callSearch, setCallSearch] = useState(false);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };

  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  const onPaginate = page => {
    setPage(page);
  };
  async function onSubmit() {
    try {
      if (inpRef.current.value !== "") {
        const res = await adminService.getCustomUrl(inpRef.current.value);
        if (res['error']) {
          addToast({
            error: true,
            msg: res['error'],
            id: `${Date.now()}-${toastList.length}`
          });
        } else {
          let arr = [];
          arr.push(res['data'][0].encodedUrl);
          updateForm({ urls: arr });
          inpRef.current.value = "";
        }
      } else {
        addToast({
          error: true,
          msg: "Influencer handle is required",
          id: `${Date.now()}-${toastList.length}`
        });
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  useEffect(() => {
    async function fetchAvailableHandles() {
      let filter = "";
      let search = {};
      if (searchClicked === true) {
        search = { searchIn: userType, searchBy: selectDesc, searchText: searchText }
      } else {
        search = { searchIn: userType }
      }
      try {
        const res = await adminService.getAvailableHandles({
          page: page,
          pageSize,
          search
        });
        setHandles(res.data);
        setPaginationData(res.meta.pagination)
        setIsLoaded(true);

      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchAvailableHandles();
  }, [callSearch, userType, page]);

  const downloadCsv = async () => {
    try {
      let filter = "";
      let search = {};
      if (searchClicked === true) {
        search = { searchIn: userType, searchBy: selectDesc, searchText: searchText }
      } else {
        search = { searchIn: userType }
      }
      const response = await adminService.getAvailableHandles({
        page: page,
        pageSize,
        search, fileType: 'csv'
      });
      utils.downloadFile(response, "adminSignupUrls_" + page + ".csv");

    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  const copyBtn = (data) => {
    navigator.clipboard.writeText(data);
    addToast({
      error: false,
      errorText: "Error.",
      msg: "Link copied to clipboard.",
    });
  };
  const changeMediumDesc = (item) => {
    setselectDesc(item.id);
  };
  const setSearchValue = (item) => {
    setSearchText(item.target.value);
  };

  const searchUser = () => {
    setSearchClicked(true);
    setCallSearch(!callSearch);


  }
  const changeUserType = (item) => {
    setUserType(item.id);
    setselectDesc('handle');
    setSearchText("");
  };
  const sortList = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };
  useEffect(() => {
    if (modalUrl) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modalUrl]);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterCalled();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form,searchText]);
  const enterCalled = () => {
    let customUrl = document.activeElement.parentElement.closest('.form__url');
    let serachList = document.activeElement.parentElement.closest('.url__table-title');
    if (customUrl) {
      refCustomUrl.current.click();
    }
    if (serachList) {
      searchUser();
    }
  }
  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">
            <h2>SignUp Url</h2>
            <Profile
              type="brand"
              setAuth={setAuth}
              setModal={setModal}
              NotifModul={NotifModul}
              profiles={profiles}
              setNotifModul={setModalNotification}
            />
          </div>
          <div className="url">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ opacity: 0, y: 30 }}
              className="form"
            >
              <div className="form__title">
                <h3 className="big">New user</h3>
              </div>
              <div className="form__url">
                <div className="form__url-input">
                  <div className="input__outer">
                    <label>Influencer handle</label>
                    <div className="input ">
                      <input ref={inpRef} type="text" autoFocus/>
                      <button ref={refCustomUrl} className="button primary" onClick={onSubmit}>
                        Create custom url
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form__url-custom">
                  <p className="bold">Custom url</p>
                  <div className="form__url-links">
                    {form?.urls?.length > 0 ? (
                      form?.urls.map((item, index) => {
                        return (
                          <div className="form__url-link" key={index}>
                            <p className="lnk">{item}</p>

                            <div
                              className="form__url-copy"
                              onClick={() => copyBtn(item)}
                            >
                              {copyIcon} <p>Copy</p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                        <div className="form__url-link">
                          <p>--</p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </motion.div>
            <div className="url__table-title ">
              <h4>Existing influencers</h4>
              <div className="url__table-title-inner rightAlign">
                <SelectInput
                  list={selectUsersTypeList}
                  selected={selectUsersTypeList[0]}
                  onChange={changeUserType}
                />
              </div>
            </div>
            <div className="url__table-title">
              <div className="url__table-title-inner searchRightAlign">
                <SelectInput
                  list={selectDescList}
                  selected={selectDescList.filter(item => item.id === selectDesc)[0]}
                  onChange={changeMediumDesc}
                />
                <SearchInput
                  type="text"
                  value={searchText}
                  icon={searchIcon}
                  onChange={setSearchValue}
                  placeholder="Search"
                  onClick={searchUser}
                />
              </div>
            </div>
            <div className="url__table">
              <table>
                <thead>
                  <tr>
                    <th ><span className="headerMargin">Id</span></th>
                    <th ><span className="headerMargin">Handle</span></th>
                    <th>
                      <div className="url__table-filter">
                        Full Name <a onClick={sortList}>{arrowTop}</a>
                      </div>
                    </th>
                    <th>Clicked</th>
                    <th>SignUp Url</th>
                  </tr>
                </thead>
                {isLoaded && (
                  <tbody>
                    {handles.length > 0 ? (
                      handles.map((item, index) => {
                        return (
                          <URLItem
                            onClick={() => {
                              setModalUrl("details");
                              setModalDataUrl(item);
                            }}
                            copyBtn={copyBtn}
                            key={index}
                            {...item}
                          />
                        )
                      })
                    ) : (
                        <tr>
                          <td colSpan='5' style={{ textAlign: 'center' }}>
                            No handles found
                        </td>
                        </tr>
                      )}
                  </tbody>
                )}
              </table>
            </div>
            <div className="url__table-footer">
              <button className="button tertiary download" onClick={downloadCsv}>
                Download CSV {download}
              </button>
              <Pagination
                perPage={paginationData['per_page']}
                total={paginationData['total']}
                currentPage={paginationData['current_page']}
                totalPages={paginationData['total_pages']}
                onPaginate={onPaginate}
              />
            </div>
          </div>
        </div>
      </div>
      {toastList && (
        <Toast
          type="rate"
          addClass="sm"
          list={toastList}
          setList={setToastList}
        />
      )}

      <AnimatePresence>
        {modalUrl === "details" && (
          <ModalUrl
            {...modalDataUrl}
            close={() => setModalUrl(null)}
            setModalUrl={setModalUrl}
            copyBtn={copyBtn}
          />
        )}
        {modalUrl === "delete" && (
          <ModalUrlDel
            {...modalDataUrl}
            setModalUrl={setModalUrl}
            close={() => setModalUrl(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
}
