import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { TextInput, TagInput, Kpi } from "../../../Components";
import { closeIcon, plusIcon } from "../../../Components/Base/SVG";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { utils } from '../../../Helpers/utils';
import { EditorInput } from "../../../Components";
export default function CampDetails({ error, form, setInput, updateForm, readOnly }) {
  const tagsWrapper = useRef(null);
  const inpRef = useRef(null);
  let contentStateDeliverable = "";
  let contentStateApply = "";
  if (form.deliverable !== null) {
    if (typeof form.deliverable === "string") {
      let bioHasValidJson = utils.testJSON(form.deliverable);
      contentStateDeliverable = convertFromRaw(
        form.deliverable ? bioHasValidJson === true ? JSON.parse(form.deliverable) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`)
      );
    } else {
      contentStateDeliverable = convertFromRaw(form.deliverable);
    }
  } else {
    contentStateDeliverable = convertFromRaw(JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`));
  }
  if (form.kpi !== null) {
    if (typeof form.kpi === "string") {
      let bioHasValidJson = utils.testJSON(form.kpi);
      contentStateApply = convertFromRaw(
        form.kpi ? bioHasValidJson === true ? JSON.parse(form.kpi) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`) : JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`)
      );
    } else {
      contentStateApply = convertFromRaw(form.kpi);
    }
  } else {
    contentStateApply = convertFromRaw(JSON.parse(`{"blocks":[{"key":"cdfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":5,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`));
  }

  const [editorStateDelivery, setEditorStateDelivery] = useState(() =>
    EditorState.createWithContent(contentStateDeliverable)
  );
  const [editorStateApply, setEditorStateApply] = useState(() =>
    EditorState.createWithContent(contentStateApply)
  );

  const updateTextDelivery = async (state) => {
    await setEditorStateDelivery(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
  };
  const updateTextApply = async (state) => {
    await setEditorStateApply(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
  };
  const setDesc = key => event => {
    updateForm({ ['description']: JSON.stringify(event) });
  };
  const setDelivery = key => event => {
    updateForm({ ['deliverable']: JSON.stringify(event) });
  };
  const setApply = key => event => {
    updateForm({ ['kpi']: JSON.stringify(event) });
  };
  const delTags = (index) => {
    let newTags = [...form.hashtags];
    newTags.splice(index, 1);
    updateForm({ hashtags: newTags });
  };
  const setTags = (obj) => {
    let newTags = [...form.hashtags];
    if (obj.value != "") {
      newTags.push(...obj.value.split(','));
      updateForm({ hashtags: newTags });
      obj.value = "";
    }
  };


  const handlePress = (event) => {
    const inputItem = event.target;

    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      setTags(inputItem);
      return;
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="form"
    >
      {(form.hashtags) && (

        <>

          <div className="formCampaigns__detail">
            <div className="formCampaigns__detail-deliver">
              <EditorInput
                value={form.deliverable === "" ? form.deliverable : utils.testJSON(form.deliverable) === true ? JSON.parse(form.deliverable).blocks : ""}
                editorState={editorStateDelivery}
                onEditorStateChange={updateTextDelivery}
                label="Deliverables  (Max length 1500 Characters)"
                error={error}
                placeholder="Eg: Must mention our website: http://www.mysite.com
                Use of attached assets are optional "
                errorText="Please enter deliverables"
                onChange={setDelivery(convertToRaw(editorStateDelivery.getCurrentContent()))}
                readOnly={readOnly}
              />
            </div>
            <div className="formCampaigns__detail-apply">
              <Kpi readOnly={readOnly} reach={form.reach} interaction={form.interaction} error={error} updateForm={updateForm} />
            </div>
            <div className="formCampaigns__detail-hashtags" ref={tagsWrapper}>
              <TagInput
                btnClickHandler={setTags}
                inpRef={inpRef}
                onKeydown={handlePress}
                type="text"
                label="Required hashtags"
                icon={plusIcon}
                list={form.hashtags}
                readOnly={readOnly}
              />
              <p className="sm">
                Include all required hashtags you influencers must use in this
                campaign.
              </p>
              <div className="formCampaigns__detail-hashtags-items">
                {form.hashtags.map((item, index) => {
                  return (
                    <div
                      className="formCampaigns__detail-hashtags-item"
                      key={index}
                    >
                      {item}
                      {!readOnly &&
                        <div
                          className="formCampaigns__detail-hashtags-item-icon"
                          onClick={() => delTags(index)}
                        >
                          {closeIcon}
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )
      }
    </motion.div >
  );
}