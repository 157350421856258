import React from 'react';
import { Modal } from '../../Components';
import { utils } from '../../Helpers/utils';
export default function ModalCollaboration(props) {
  return (
    <Modal close={props.close}>
      <div className='modal__opportunity'>
        <div className='modal__opportunity-title'>
          <div className='modal__opportunity-title-image'>
            <img
              src={
                props.collaboration.brand_logo
                  ? props.collaboration.brand_logo
                  : `${process.env.PUBLIC_URL}/images/bulls-eye.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.PUBLIC_URL}/images/bulls-eye.png`;
              }}
              alt='bulls-eye'
            />
          </div>
          <div className='modal__opportunity-title-content'>
            <h3>{props.collaboration.brand_name}</h3>
            <div className='modal__opportunity-title-content-row'>
              <h6>{props.collaboration.title}</h6>
              {props.collaboration.products && props.collaboration.products != "" &&
                props.collaboration.products.map((product, idx) => (
                  <a href='#' key={idx}>
                    {utils.getIcon(product)}
                  </a>
                ))
              }
            </div>
          </div>
        </div>
        <div className='modal__opportunity-content'>
          <p><strong>{props.collaboration.social_product ? props.collaboration.social_product : ""}</strong></p>
          <h3>Overview</h3>
          <p className="hideToolbar">
            {props.collaboration.description
              ? utils.draftJsFormatingForDetails({ 'data': props.collaboration.description, 'view': 'fullview' })
              : 'NA'}
          </p>
          {props.collaboration.revisionWindow ?
            <>
              <h3>Revision Submission Deadline</h3>
              <p>
                {props.collaboration.revisionWindow}
              </p>  </> : ''}
          <h3>Required Hashtags</h3>
          <p>
            {props.collaboration.hashtag}
          </p>
          <h3>Deliverables</h3>
          <p className="hideToolbar">
            {props.collaboration.deliverable
              ? utils.draftJsFormatingForDetails({ 'data': props.collaboration.deliverable, 'view': 'fullview' })
              : 'NA'}
          </p>
          {props.collaboration.compensationDetails && (
            <>
              {
                props.collaboration.compensationDetails.product != '' && (
                  <>
                    <h3>Product</h3>
                    <p>
                      {props.collaboration.compensationDetails.product}
                    </p>
                  </>
                )
              }
            </>
          )}
        </div>
        <div className='modal__opportunity-footer'>
          <div className='modal__opportunity-footer-row'>
            <div className='modal__opportunity-footer-col collabrationhalfWidth'>
              <h3>Compensation</h3>
              <div className='modal__opportunity-footer-col-inner'>
                <h6>
                  Amount:{' '}
                  <span>
                    {props.collaboration.compensationDetails
                      ? props.collaboration.compensationDetails.amount
                      : 'NA'}
                    {(props.collaboration.rate_overwritten && parseInt(props.collaboration.rate_overwritten) == 1)
                      && <img className="redFlag"
                        src={`${process.env.PUBLIC_URL}/images/red-flag.png`} alt="red-flag" />
                    }
                  </span>
                </h6>
                {props.collaboration.compensationDetails && props.collaboration.compensationDetails.product != '' &&
                  <h6>
                    Product:
                    <span>
                      {props.collaboration.compensationDetails
                        ? props.collaboration.compensationDetails.product != "" ? 'Yes' : 'No'
                        : 'NA'}
                    </span>
                  </h6>
                }
              </div>
            </div>
            <div className='modal__opportunity-footer-col'>
              <h3> Post Window</h3>
              <div className='modal__opportunity-footer-col-inner'>
                <h6>
                  <span> {props.collaboration.start_date}{' -> '}{props.collaboration.end_date}</span>
                </h6>
                {/*<h6>
                      Opt in by: <span>{props.opportunity.end_date}</span>
                        </h6>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
}
