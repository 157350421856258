import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderAcc, Toast } from '../../../Components';
import ActivationItem from './ActivationItem';
import { ActivationModul } from './ActivationModul';
import ModalActivation from './ModalActivation';
import { influencerService, alertService } from '../../../Services';
import { utils } from '../../../Helpers';
import { useSearchParams } from 'react-router-dom'
import Parser from 'html-react-parser';
export default function Activation() {
  const [modal, setModal] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [toastList, setToastList] = useState([]);
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [influencerObj, setInfluencerObj] = useState({});
  const [instaConnectURL, setInstaConnectURL] = useState('');
  const [isInstaConnectDisabled, setIsInstaConnectDisabled] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hash, query] = window.location.href.split('?');
  const params = Object.fromEntries(new URLSearchParams(query));
  const profileId = params['profile_id'];

  useEffect(() => {
    const isError = searchParams.get('error') !== null ?
      searchParams.get('error') == 1 ?
        Parser("Oops, it appears that you lack the necessary authorization to authenticate this account. Kindly ensure that your Facebook account is linked to this handle and make another attempt or <a className='paidLink' href='/#/account/login'> click here </a>to sign up.") :
        Parser("Oops, it appears that you lack the necessary authorization to authenticate this account. Kindly ensure that your Facebook account is linked to this handle and make another attempt or <a className='paidLink' href='/#/influencer/social_accounts'>click here </a> to link your accounts.")
      : '';

    if (isError !== '') {
      addToast({
        error: true,
        msg: isError,
        id: `${Date.now()}-${toastList.length}`
      });
    }
    (async () => {
      influencerService
        .getByEncrptedUserId(profileId)
        .then(res => {
          const { data } = res;
          setIsInstaConnectDisabled(
            data['insta_profile_status'] && data['insta_profile_status'] === '1'
              ? true
              : false
          );
          setInfluencerObj({
            handle: data['handle'],
            avatar: data['avatar'] || '',
            location: data['location'] || '',
            categories: data['categories'] || [],
            location: data['location'] || '',
            followers: data['followers']
              ? utils.convertNumber(data['followers'])
              : 0,
            following: data['following']
              ? utils.convertNumber(data['following'])
              : 0,
            engagement: data['engagement'] || 0,
            postsPerWeek: data['postsPerWeek'] || 0,
            bio: data['bio'] || '',
            medias: data['medias'] || [],
            name: data['firstName'] + ' ' + data['lastName'] || ''
          });
          setInstaConnectURL(data['insta_pull_url']);
        })
        .catch(err => {
          addToast({
            error: true,
            msg: err,
            id: `${Date.now()}-${toastList.length}`
          });
          setError(err);
        });

    })();

  }, [profileId]);

  const handleInstagramConnect = () => {
    if (instaConnectURL === '' || isInstaConnectDisabled) {
      addToast({
        error: true,
        msg: error,
        id: `${Date.now()}-${toastList.length}`
      });
      return null;
    } else {
      window.location.href = instaConnectURL;
    }
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };

  const isloaded =
    influencerObj !== undefined && Object.keys(influencerObj).length > 0;
  // if (!isloaded) {
  //   return null;
  // }
  return (
    <>
      <div className='account'>
        <div className='account__inner'>
          <HeaderAcc />
          <div className='account__inner-col'>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ opacity: 0, y: 30 }}
            >
              <h1>Monetize your influence while doing what you love.</h1>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              exit={{ opacity: 0, y: 30 }}
            >
              <ul>
                If you’re an influencer...
                <li>Complete your profile</li>
                <li>
                  Let TAMA find the <span>right brands</span> for you to
                  collaborate with.
                </li>
                <li>Start earning!</li>
              </ul>
            </motion.div>
          </div>
          {error === '' && (
            <div className='account__inner-col'>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                exit={{ opacity: 0, y: 30 }}
                className='accountContent'
              >
                <div className='accountContent__title'>
                  <div className='accountContent__title-image'>
                    <img
                      src={influencerObj.avatar}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src =
                          process.env.PUBLIC_URL + '/images/avatar.png';
                      }}
                      alt='avatar'
                    />
                  </div>
                  <div className='accountContent__title-content'>
                    <h3>{influencerObj.handle}</h3>
                    <h5>{influencerObj.name}</h5>
                    <h5 className='reg'>{influencerObj.bio}</h5>
                  </div>
                </div>
                <div className='accountContent__items'>
                  <ActivationItem
                    key={1}
                    number={influencerObj.followers}
                    text={'Followers'}
                  />
                  <ActivationItem
                    key={2}
                    number={influencerObj.following}
                    text={'Following'}
                  />
                  <ActivationItem
                    key={3}
                    number={influencerObj.engagement}
                    text={'Engagement'}
                  />
                  <ActivationItem
                    key={4}
                    number={influencerObj.postsPerWeek}
                    text={'Posts'}
                  />
                </div>
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    onClick={() => setIsChecked(!isChecked)}
                  />
                  <label htmlFor=''></label>
                  <p>
                    Accept{' '}
                    <span onClick={() => setModal('terms')}>
                      Terms & Conditions
                    </span>
                  </p>
                </div>
                <button
                  className='button primary'
                  disabled={isChecked && !isInstaConnectDisabled ? false : true}
                  onClick={() => handleInstagramConnect()}
                >
                  Claim your Instagram account
                </button>
              </motion.div>
            </div>
          )}
        </div>
      </div>
      {toastList && <Toast list={toastList} setList={setToastList} />}
      <AnimatePresence>
        {modal === 'terms' && <ModalActivation roleId="I'm a influencer" close={() => setModal(null)} />}
      </AnimatePresence>
    </>
  );
}
