import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Profile, YearToDate, arrowLeft, CampaignInsight, RevisionList, RevisionTree } from "../../Components";
import CampaignsCreate from "./CampaignsCreate/CampaignsCreate";
import CampaignsMain from "./CampaignsMain";
import ModalCampDetail from "./Modals/ModalCampDetail";
import Toast from "../../Components/Base/Form/Toast";
import { useLocation, useNavigate } from 'react-router-dom';
import { utils } from "../../Helpers";
import { useSearchParams } from 'react-router-dom';

import {
  campaignService
} from '../../Services';
const ytdList = [
  { id: "0", value: "Year to date" },
  { id: "1", value: "Past 1 month" },
  { id: "6", value: "Past 6 months" },
  { id: "12", value: "Past 12 months" },
];

export default function Campaigns({ setSaveCampaign, setAuth, setModalSwitch, NotifModul, profiles, user, setModalNotification, locationData, setShowConfirmModal, setShowConfirmAction, saveCampaign }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state ? location.state : {};
  // navigate(location.pathname, {});
  const [recommandDataId, setRecommandDataId] = useState(data);
  const [tab, setTab] = useState("main");
  // eslint-disable-next-line
  const [ytd, setYtd] = useState(0);
  const [modalCampaigns, setModalCampaigns] = useState(null);
  const [modalDataCampaigns, setModalDataCampaigns] = useState(null);
  const [campaignData, setCampaignData] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [isloaded, setIsLoaded] = React.useState(false);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({});
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectDesc, setselectDesc] = useState("title");
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState(false);
  const [loadList, setLoadList] = useState(false);
  const [redirectToBilling, setRedirectToBilling] = useState(false);
  const [isseacrhByIdDone, setIsseacrhByIdDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlCampaignId = searchParams.get('id') ? searchParams.get('id') !== null || searchParams.get('id') !== undefined ? searchParams.get('id') : null : null;
  const changeMedium = (item) => {
    setYtd(item.id);
    setSearchList(!searchList);
    setPage(1);
    setSearchText("");
  };
  const sortList = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    if (modalCampaigns) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modalCampaigns]);

  const changeMediumDesc = (item) => {
    setselectDesc(item.id)
    setPage(1);
    setSearchText("");
    setSearchList(!searchList);
  };
  useEffect(() => {
    if (recommandDataId === undefined) {
      setTab("main");
      setPage(1);
      setselectDesc("title");
      setSortOrder('desc');
      setSearchText("");
    }
  }, [locationData.key]);
  useEffect(() => {
    if (recommandDataId !== undefined) {
      setTab('create');
    }
  }, [recommandDataId])

  useEffect(() => {
    if (redirectToBilling === true && tab === "main") {
      setShowConfirmModal(false);
      setShowConfirmAction("");
      navigate("/brand/billing", { state: { 'openAddFunds': true }, replace: true });
    } else {
      if (tab === "create" || tab === "edit") {
        setShowConfirmModal(true);
        setShowConfirmAction(tab + "camp");
      } else {
        setShowConfirmModal(false);
        setShowConfirmAction("");
      }
    }
  }, [redirectToBilling, tab])
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchText]);
  useEffect(() => {
    async function fetchData() {
      try {
        let filter = '';
        if (searchText !== '') {
          if (filter != "") {
            filter += '&filter[' + selectDesc + ']=' + searchText;
          } else {
            filter = 'filter[' + selectDesc + ']=' + searchText;
          }
        } else {
          if (urlCampaignId != "" && urlCampaignId !== null && urlCampaignId !== undefined && isseacrhByIdDone === false) {
            let id = 'id';
            filter += '&fields[' + id + ']=' + urlCampaignId;
            setIsseacrhByIdDone(true);
          }
        }
        const res = await campaignService.getAll({
          page: page,
          pageSize: pageSize,
          sortBy: sortOrder,
          filter: filter
        });
        if (res.meta && res.meta.pagination) {
          setPaginationData(res.meta.pagination);
        }
        if (res.data) {
          setCampaignData(res.data);
        } else {
          setCampaignData([]);
        }

        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, ytd, sortOrder, searchList, loadList]);

  const downloadCsv = async () => {
    try {
      let filter = '';
      if (searchText !== '') {
        if (filter != "") {
          filter += '&filter[' + selectDesc + ']=' + searchText;
        } else {
          filter = 'filter[' + selectDesc + ']=' + searchText;
        }
      }
      const response = await campaignService.getAll({
        page: page,
        pageSize: pageSize,
        fileType: 'csv',
        sortBy: sortOrder,
        filter: filter
      });
      utils.downloadFile(response, "brandCampaign_" + page + ".csv");

    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const onPaginate = page => {
    setPage(page);
  };
  const setSearchTextOnChange = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchText(event.target.value);
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);
  };
  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">
            {tab === "main" && (
              <div className="main__inner-title-col">
                <h2>Campaigns</h2>

              </div>
            )}
            {tab === "create" && (
              <div className="main__inner-title-row">
                <h2>Create a campaign</h2>
                <div className="main__inner-title-status">Draft</div>
              </div>
            )}
            {tab === "edit" && (
              <div className="main__inner-title-row">
                <h2>Edit a campaign</h2>
                <div className="main__inner-title-status">Draft</div>
              </div>
            )}
            {tab === "insights" && (
              <div className="main__inner-title-col">
                <div className="main__inner-title-row">
                  <h2>{modalDataCampaigns.title}</h2>
                  <div
                    className={
                      "main__inner-title-status " + modalDataCampaigns.status
                    }
                  >
                    {modalDataCampaigns.status === "complete"
                      ? "completed on " + modalDataCampaigns.endDate
                      : modalDataCampaigns.status}
                  </div>
                </div>
                <button
                  className="button tertiary big"
                  onClick={() => setTab("main")}
                >
                  {arrowLeft} Back to all campaigns
                </button>
              </div>
            )}

            {(tab === "revisiontree") && (
              <div className="main__inner-title-col">
                <div className="main__inner-title-row">
                  <h2></h2>

                </div>
              </div>
            )}


            <Profile
              type="brand"
              setAuth={setAuth}
              setModal={setModalSwitch}
              NotifModul={NotifModul}
              profiles={profiles}
              user={user}
              setNotifModul={setModalNotification}
            />

          </div>

          {(tab === "main" && isloaded) && (
            <CampaignsMain
              setTab={setTab}
              setModalCampaigns={setModalCampaigns}
              setModalDataCampaigns={setModalDataCampaigns}
              campaignData={campaignData}
              paginationData={paginationData}
              onPaginate={onPaginate}
              downloadCsv={downloadCsv}
              sortList={sortList}
              sortOrder={sortOrder}
              search={search}
              setSearchTextOnChange={setSearchTextOnChange}
              searchText={searchText}
              changeMediumDesc={changeMediumDesc}
              setRecommandDataId={setRecommandDataId}
              setCampaignId={setCampaignId}
            />
          )}

          {tab === "insights" && (
            <CampaignInsight
              setTab={setTab}
              modalDataCampaigns={modalDataCampaigns}
            />
          )}
          {tab === "create" && <CampaignsCreate setSaveCampaign={setSaveCampaign} saveCampaign={saveCampaign} setRedirectToBilling={setRedirectToBilling} setLoadList={setLoadList} loadList={loadList} mode={"add"} addToast={addToast} setTab={setTab} user={user} recommendEnteredData={recommandDataId} />}
          {tab === "edit" && <CampaignsCreate setSaveCampaign={setSaveCampaign} saveCampaign={saveCampaign} setRedirectToBilling={setRedirectToBilling} mode={"edit"} setLoadList={setLoadList} loadList={loadList} addToast={addToast} setTab={setTab} campaignId={campaignId} user={user} />}
          {tab === "revisionlist" && (
            //RevisionTree
            <RevisionList
              setTab={setTab}
              campaignId={campaignId}
              setCampaignId={setCampaignId}
            />
          )}
          {tab === "revisiontree" && (
            <RevisionTree
              setTab={setTab}
              campaignId={campaignId}
              setCampaignId={setCampaignId}
            />
          )}
        </div>
      </div>
      <AnimatePresence>
        {modalCampaigns === "detail" && (
          <ModalCampDetail
            close={() => setModalCampaigns(null)}
            {...modalDataCampaigns}
            setTab={setTab}
            setModalCampaigns={setModalCampaigns}
            setCampaignId={setCampaignId}
          />
        )}
      </AnimatePresence>
      {toastList && (
        <Toast type="rate" list={toastList} setList={setToastList} />
      )}
    </>
  );
}
