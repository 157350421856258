export const NotificationModul = [
  {
    id: '1',
    image: '/images/notification/1.svg',
    date: 'Feb. 15, 2023',
    title: 'Updated opportunity',
    subtitle: 'The Target - Shampoo opportunity.',
    text:
      'Some text here Some text here Some text here Some text here Some text here Some text...',
    detailTitle: 'Target updated your collaboration.',
    detailText:
      'Details for XXXXXXXXXX collaboration were updated. Be sure to view the collaboration details and ensure you’re up to date. ',
    read: false
  },
  {
    id: '2',
    image: '/images/notification/1.svg',
    date: 'Feb. 15, 2023',
    title: 'Updated opportunity',
    subtitle: 'The Target - Shampoo opportunity.',
    text:
      'Some text here Some text here Some text here Some text here Some text here Some text...',
    detailTitle: 'Target updated your collaboration.',
    detailText:
      'Details for XXXXXXXXXX collaboration were updated. Be sure to view the collaboration details and ensure you’re up to date. ',
    read: false
  },
  {
    id: '3',
    image: '/images/notification/1.svg',
    date: 'Feb. 15, 2023',
    title: 'Updated opportunity',
    subtitle: 'The Target - Shampoo opportunity.',
    text:
      'Some text here Some text here Some text here Some text here Some text here Some text...',
    detailTitle: 'Target updated your collaboration.',
    detailText:
      'Details for XXXXXXXXXX collaboration were updated. Be sure to view the collaboration details and ensure you’re up to date. ',
    read: false
  },
  {
    id: '4',
    image: '/images/notification/2.svg',
    date: 'Feb. 15, 2023',
    title: 'Update - Target campaign',
    text:
      'Thanks Jeremy! We love the creative you’ve put together. Could we get you to make the...',
    detailTitle: 'Target updated your collaboration.',
    detailText:
      'Details for XXXXXXXXXX collaboration were updated. Be sure to view the collaboration details and ensure you’re up to date. ',
    read: false
  },
  {
    id: '5',
    image: '/images/notification/2.svg',
    date: 'Feb. 15, 2023',
    title: 'Update - Target',
    text:
      'Thanks Jeremy! We love the creative you’ve put together. Could we get you to make the...',
    detailTitle: 'Target updated your collaboration.',
    detailText:
      'Details for XXXXXXXXXX collaboration were updated. Be sure to view the collaboration details and ensure you’re up to date. ',
    read: false
  },
  {
    id: '6',
    image: '/images/notification/3.svg',
    date: 'Feb. 15, 2023',
    title: 'New Opportunity',
    text: 'Please take a moment to review this new and exciting opportunity!',
    buttonText: 'View details',
    detailTitle: 'Target',
    detailText:
      'Please take a moment to review this new and exciting opportunity!',
    read: false,
    expired: true,
    expirationDate: '01/23/2023'
  },
  {
    id: '7',
    image: '/images/notification/3.svg',
    date: 'Feb. 15, 2023',
    title: 'New Opportunity',
    text: 'Please take a moment to review this new and exciting opportunity!',
    buttonText: 'View details',
    detailTitle: 'Target',
    detailText:
      'Please take a moment to review this new and exciting opportunity!',
    read: false,
    expired: false,
    expirationDate: '01/23/2023'
  },
  {
    id: '8',
    image: '/images/notification/4.svg',
    date: 'Feb. 15, 2023',
    title: 'You got paid!',
    text: 'Check out your payment details.',
    buttonText: 'View payment',
    detailTitle: 'Target',
    detailText: 'Check out your payment details.',
    read: false
  }
];
