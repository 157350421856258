import React from "react";

export default function MoneyInput(props) {
  return (
    <div className={props.className ? props.className + "  input__outer" : "input__outer"}>
      {props.label && <label>{props.label}</label>}
      <div
        className={
          "input money " + (((props.error && props.value === "") || (props.error && props.checkBudgetValue === "1" && props.value !== "" && props.value < 500)) ? "error" : "")
        }
      >
        {props.currency && <div className="input__money">{props.currency}</div>}
        <input
          ref={props.inpRef}
          onChange={props.onChange}
          value={props.value}
          defaultValue={props.defaultValue}
          type={props.type}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
        />
      </div>
      {((props.error && props.value === "") || (props.error && props.checkBudgetValue === "1" && props.value !== "" && props.value < 500)) && (
        <div className="input__error">{props.errorText}</div>
      )}
    </div>
  );
}
