import React, { useState } from 'react';
import { Modal } from '../../Components';

export default function ModalConnect(props) {
  const [accounts, setAccounts] = useState(props.accounts.handle);
  const [status, setStatus] = useState(props.accounts.status);
  console.log(accounts);
  console.log(accounts.length);
  const handleCheckboxChange = i => {
    const newAccounts = accounts.map((account, index) => {
      if (i === index) {
        return { ...account, checked: !account['checked'] };
      }
      return account;
    });
    setAccounts(newAccounts);
  };

  const onSave = () => {
    props.onSave(accounts);
  };
  console.log(accounts);
  if (status != "1") {
    return (
      <Modal addClass='modalRateConnect' close={props.close}>
        <div className='modal__rate'>
          <h6>Please select the account(s) you would like to connect.</h6>
          <div className='modal__rate-checkboxes'>
            {accounts.length > 0 ? (
              accounts.map((account, i) => (
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    checked={account.checked}
                    onChange={e => handleCheckboxChange(i)}
                  />
                  <label htmlFor=''></label>
                  <p className='sm'>{account['handle']}</p>
                </div>
              ))
            ) : (
              <span> {status != "" ? status : ''}  </span>
            )}
          </div>
          <div className='modal__rate-btns'>
            <button className='button secondary' onClick={props.close}>
              {accounts.length > 0 ? 'Cancel' : 'Ok'}
            </button>
            {accounts.length > 0 &&
              <button
                className='button primary'
                disabled={props.accounts.length === 0}
                onClick={onSave}
              > Connect
              </button>
            }
          </div>
        </div>
      </Modal >
    );
  }
}
