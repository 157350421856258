import React, { useRef } from "react";
import CronItem from "./CronItem";
import {
  download,
} from "../../Components/Base/SVG";
import { Pagination } from "../../Components";
export default function CronMain({ cronDetails, isLoaded, setTab, setTabData, paginationData, onPaginate }) {
  return (
    <>
      <div className="url__table cronDetails">
        <table>
          <thead>
            <tr>
              <th>Label</th>
              <th>Frequency</th>
              <th>
                <div className="url__table-filter">
                  Last start at
                </div>
              </th>

              <th>Last end at</th>
              <th>Next run date</th>
              <th>Log</th>
            </tr>
          </thead>
          {isLoaded && (
            <tbody>
              {cronDetails.map((item, index) => {
                return (
                  <CronItem
                    key={index}
                    {...item}
                    onClick={() => { setTabData(item); setTab("cronDetail"); }
                    } />
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="url__table-footer">
        <button className="button tertiary download">
          Download CSV {download}
        </button>
        <Pagination
          perPage={paginationData['per_page']}
          total={paginationData['total']}
          currentPage={paginationData['current_page']}
          totalPages={paginationData['total_pages']}
          onPaginate={onPaginate}
        />
      </div>
    </>
  );
}
