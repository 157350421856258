import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { utils } from '../../Helpers/utils';
import {
  SearchInput,
  SelectInput,
  Pagination,
  download,
  plusIcon,
  searchIcon,
  Profile,
  Toast
} from '../../Components';
import BillingItem from './BillingItem';
import ModalBilling from './ModalBilling';
import ModalTransaction from './ModalTransaction';
import { billingService } from '../../Services';

const selectDescList = [
  { id: '1', value: 'All transactions' },
  { id: 'description', value: 'Description' },
  { id: '30days', value: '30 days' },
  { id: 'lastyr', value: 'Last year ' },
  { id: 'transaction_type', value: 'Charge type' },
  { id: 'amount', value: 'Amount' }
];

export default function Billing({
  setAuth,
  setModal,
  NotifModul,
  user,
  profiles,
  setModalNotification
}) {
  // eslint-disable-next-line
  const [selectDesc, setselectDesc] = useState(selectDescList[0].value);
  const [modalBill, setModalBill] = useState(null);
  const [modalDataBill, setModalDataBill] = useState(null);
  const [isloaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [walletDetails, setWalletDetails] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchList, setSearchList] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [disableSearchBox, setDisableSearchBox] = useState(false);

  const [isBalanceAdded, setIsBalanceAdded] = useState(false);
  const location = useLocation();
  const [isAddFundsOpen, setIsAddFundsOpen] = useState(location.state ? location.state.openAddFunds === true ? true : false : false);

  useEffect(() => {
    if (modalBill) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [modalBill]);
  useEffect(() => {
    //delete location.state.openAddFunds;
    if (isAddFundsOpen === true) {
      setModalBill('addFunds')
    } else {
      setModalBill(null)
    }
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        let filter = '';
        if (searchString !== '') {
          if (filter != "") {
            filter += '&filter[' + selectDesc + ']=' + searchString;
          } else {
            filter = 'filter[' + selectDesc + ']=' + searchString;
          }
        } else {
          if (selectDesc === 'lastyr' || selectDesc === '30days') {
            filter = 'filter[created_at]=' + selectDesc;
          }
        }
        const res = await billingService.getAll({
          page: page,
          pageSize,
          filter: filter
        });
        if (
          res.transaction_details['meta'] &&
          res.transaction_details['meta']['pagination']
        ) {
          setPaginationData(res.transaction_details['meta']['pagination']);
        }
        setTransactionDetails(res.transaction_details.data);
        setWalletDetails(res.wallet_details);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, searchList]);
  const downloadCsv = async () => {
    try {
      let filter = "";
      if (searchString !== '') {
        if (filter != "") {
          filter += '&filter[' + selectDesc + ']=' + searchString;
        } else {
          filter = 'filter[' + selectDesc + ']=' + searchString;
        }
      } else {
        if (selectDesc === 'lastyr' || selectDesc === '30days') {
          filter = 'filter[created_at]=' + selectDesc;
        }
      }
      const response = await billingService.getAll({
        page: page,
        pageSize,
        filter: filter,
        fileType: 'csv'
      });
      utils.downloadFile(response, "brandBilling_page_" + page + ".csv");
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const onPaginate = page => {
    setPage(page);
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };


  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        search();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [searchString]);


  const changeMediumDesc = item => {
    setselectDesc(item.id)
    setPage(1);
    if (item.id === 'lastyr' || item.id === '30days') {
      setDisableSearchBox(true);
    } else {
      setDisableSearchBox(false);
    }
    setSearchString("");
    setSearchList(!searchList);
  };

  const setSearchStringText = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchString(event.target.value);
  };

  const search = () => {
    setSearchList(!searchList);
    setPage(1);

  };

  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title'>
            <h2>Billing</h2>
            <Profile
              type='brand'
              setAuth={setAuth}
              setModal={setModal}
              NotifModul={NotifModul}
              user={user}
              profiles={profiles}
              setNotifModul={setModalNotification}
            />
          </div>
          {isloaded && (
            <div className='billing'>
              <div className='billing__row'>
                <div className='billing__col'>
                  <h5>Account balance</h5>
                  <h2>{walletDetails ? walletDetails.total : '$0'}</h2>
                </div>
                <div className='billing__col'>
                  <h5>Value of committed campaigns</h5>
                  <h2>{walletDetails ? walletDetails.committed : '$0'}</h2>
                </div>
                <div className='billing__col'>
                  <div className='billing__col-title'>
                    <h5>Available balance</h5>
                    <button
                      className='button tertiary big'
                      onClick={() => setModalBill('addFunds')}
                    >
                      Add funds {plusIcon}
                    </button>
                  </div>
                  <h2>{walletDetails ? walletDetails.available : '$0'}</h2>
                </div>
              </div>
              <div className='billing__table-title'>
                <h4>Transaction details</h4>
                <div className='billing__table-title-inner'>
                  <SelectInput
                    list={selectDescList}
                    selected={selectDescList[0]}
                    onChange={changeMediumDesc}
                  />
                  <SearchInput
                    type='text'
                    icon={searchIcon}
                    placeholder='Search'
                    onClick={search}
                    onChange={setSearchStringText}
                    value={searchString}
                    disable={disableSearchBox}
                  />

                </div>
              </div>
              <div className='billing__table'>
                <table>
                  <thead>
                    <tr>
                      <th>Transaction</th>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Charge type</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(transactionDetails.length > 0) ? (
                      <>
                        {transactionDetails.map((item, index) => {
                          return (
                            <BillingItem
                              onClick={() => {
                                setModalBill('transaction');
                                setModalDataBill(item);
                              }}
                              key={index}
                              {...item}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan='5' style={{ textAlign: 'center' }}>
                          No records
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='billing__table-footer'>
                <button className='button tertiary download' onClick={downloadCsv}>
                  Download CSV {download}
                </button>
                {transactionDetails.length > 0 &&
                  <Pagination
                    perPage={paginationData['per_page']}
                    total={paginationData['total']}
                    currentPage={paginationData['current_page']}
                    totalPages={paginationData['total_pages']}
                    onPaginate={onPaginate}
                  />
                }
              </div>
            </div>
          )}
        </div>
      </div>
      {toastList && <Toast list={toastList} setList={setToastList} />}
      <AnimatePresence>
        {modalBill === 'addFunds' && (
          <ModalBilling
            close={() => {
              setIsBalanceAdded(false);
              setModalBill(null);
            }}
            addToast={addToast}
          />
        )}
        {modalBill === 'transaction' && (
          <ModalTransaction
            {...modalDataBill}
            close={() => setModalBill(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
}
