import React, { useState, useEffect, useRef } from "react";
import {
  contentService,
  campaignService,
  engagementService
} from '../../../Services';
import {
  commentsIcon,
  graphIcon,
  heartIcon,
  imageIcon,
  searchIcon,
  pencilIcon,
  reset
}
  from "../../../Components";
import { utils } from '../../../Helpers';
import { SearchInput, SelectInput, YearToDate } from "../../../Components";
const selectList = [
  { id: "1", value: "Follower count" },
  { id: "2", value: "Follower count" },
  { id: "3", value: "Follower count" },
];

const ytdList = [
  { id: 1, value: "Past 30 days" },
  { id: 3, value: "Past 3 months" },

];
let infBudget = 0;
export default function Influencers({ form, isAddMode, selectedItems, selectedGoods, setSelectedItems, setInfluencers, campaign_id, addToast, toastList, influencers, engagementId, readOnly, setInfluencerBudget }) {
  const [select, setSelect] = useState();
  const { selectedContent, selectedProduct, selectedInfluencer } =
    selectedItems;
  const [isInfluencerLoaded, setIsInfluencerLoaded] = useState(false);
  const [loadChildComponenet, setLoadChildComponenet] = useState(false);
  useEffect(() => {
    //setSelInf(selectedInfluencer)
  }, [])
  useEffect(() => {
    async function getInfluencerData() {
      let selectedGoodsIds = [];
      if (selectedGoods.length > 0) {
        selectedGoods.map((item) => selectedGoodsIds.push(item.id));
      }

      try {
        const influencerData = await engagementService.getInfluencers({
          products: selectedProduct,
          campaign_type_id: form.campaigns_type_id,
          engagement_id: isAddMode ? 0 : engagementId,
          campaign_id: campaign_id + '',
          campaign_goods_id: selectedGoodsIds,
          category_id: form.category
        });
        influencerData['influencers'].map((item, infindex) => {
          if (isInfluencerLoaded === false) {

            item.compensations.map((itemCom, index) => {

              if (itemCom.type === 'goods') {
                let selInf = selectedGoods.filter(f => (f.campaign_goods_id === itemCom.goods_id));
                if (selInf) {
                  let selInf1 = selInf.filter(f => (f.id === itemCom.goods_id && itemCom.is_selected === true));
                  if (selInf1.length > 0) {
                    influencerData.influencers[infindex]['compensations'][index].is_selected = true;
                    //console.log(influencerData.influencers[infindex]['compensations'][index].is_selected);
                  }
                } else {
                  influencerData.influencers[infindex]['compensations'][index].is_selected = false;
                }
              } else {
                let selInf = selectedInfluencer.filter(f => (f.product_id === itemCom.product_id && parseInt(f.influencer_id) === parseInt(item.influencer_id)));

                if (selInf.length > 0) {
                  influencerData.influencers[infindex]['compensations'][index].is_selected = true;
                  //console.log(influencerData.influencers[infindex]['compensations'][index].is_selected);
                }
              }
            })
          } else {
            item.compensations.map((itemCom, index) => {
              if (itemCom.type === 'goods') {
                let selInf = selectedGoods.filter(f => (f.campaign_goods_id === itemCom.goods_id));
                if (selInf) {
                  let selInf1 = selInf.filter(f => (f.id === itemCom.goods_id && itemCom.is_selected == true));
                  if (selInf1.length > 0) {
                    influencerData.influencers[infindex]['compensations'][index].is_selected = true;
                    //console.log(influencerData.influencers[infindex]['compensations'][index].is_selected);
                  } else {
                    influencerData.influencers[infindex]['compensations'][index].is_selected = false;
                  }
                } else {
                  influencerData.influencers[infindex]['compensations'][index].is_selected = false;
                }
              } else {
                let selInf = selectedInfluencer.filter(f => f.influencer_id == item.influencer_id);

                if (selInf.length > 0) {
                  let selInf1 = selInf[0].products.filter(f => f.prodId === itemCom.product_id);
                  if (selInf1.length > 0) {
                    influencerData.influencers[infindex]['compensations'][index].is_selected = true;
                    //console.log(influencerData.influencers[infindex]['compensations'][index].is_selected);
                  }
                }
              }
            });
          }
        })

        setInfluencers(influencerData['influencers']);
        setIsInfluencerLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    getInfluencerData();
  }, [selectedProduct, selectedGoods]);

  const changeMedium = (item) => {
    setSelect(item.id);
  };
  useEffect(() => {
    updateSelectedInfluencers();
  }, [influencers]);
  const updateSelectedInfluencers = () => {
    if (influencers) {
      if (influencers.length > 0) {
        let selectedInfluencers = [];
        influencers.forEach((influencer) => {
          let influencerObj = {
            influencer_id: influencer.influencer_id,
            user_id: influencer.user_id,
            products: [],
            goods: [],
          };

          influencer.compensations.forEach((compensation) => {
            if (compensation.is_selected) {
              if (compensation.type === 'goods') {
                influencerObj.goods.push({
                  parent_id: compensation.parent_id,
                  goods_name: compensation.name,
                  value: 1,
                });
              } else {
                console.log(compensation);
                influencerObj.products.push(compensation);
              }
            }
          });

          influencerObj.products = formatProducts(influencerObj.products);
          // influencerObj.value = formatPrice(influencerObj.value);
          selectedInfluencers.push(influencerObj);
        });

        selectedInfluencers = selectedInfluencers.filter(
          (influencer) =>
            influencer.products.length > 0 || influencer.goods.length > 0
        );
        console.log(selectedInfluencers)
        setSelectedItems({
          ...selectedItems,
          selectedInfluencer: selectedInfluencers,
        });
      } else {
      }
    }
  };
  const formatProducts = (products) => {
    const formatedProducts = [];
    products.forEach((prod) => {
      formatedProducts.push({ 'prodId': prod.product_id, 'org_rate': prod.org_rate, 'value': prod.value, 'overwrite': prod.value !== prod.org_rate ? "1" : "0" })

    });
    return formatedProducts;
  };

  const setCompensationData = (influencerId, item, checked, price) => {
    console.log(price);

    setLoadChildComponenet(!loadChildComponenet)
    let newInfl = influencers;
    influencers.map((itemInf, infindex) => {
      itemInf.compensations.map((itemCom, index) => {
        if (itemCom.type === "goods") {
          if ((itemCom.goods_id === item.goods_id) && checked === true && influencerId === itemInf.influencer_id) {
            newInfl[infindex]['compensations'][index].is_selected = true;
          }
          if ((itemCom.goods_id === item.goods_id) && checked === false && influencerId === itemInf.influencer_id) {
            newInfl[infindex]['compensations'][index].is_selected = false;
          }
        } else {

          if ((itemCom.product_id === item.product_id) && parseInt(influencerId) === parseInt(itemInf.influencer_id)) {
            newInfl[infindex]['compensations'][index].value = price;
          }
          if ((itemCom.product_id === item.product_id) && checked === true && parseInt(influencerId) === parseInt(itemInf.influencer_id)) {
            newInfl[infindex]['compensations'][index].is_selected = true;


          }
          if ((itemCom.product_id === item.product_id) && checked === false && parseInt(influencerId) === parseInt(itemInf.influencer_id)) {
            newInfl[infindex]['compensations'][index].is_selected = false;
            //newInfl[infindex]['compensations'][index].value = price;
          }
        }
      })
    })
    console.log(newInfl)
    let newInfluencerObj = [...newInfl];
    setInfluencers(newInfluencerObj);
  }
  return (
    <div className="campaignsInflu">
      <h3 className="big">Influencers</h3>
      {(selectedProduct.length === 0) ?
        <div className="campaignsInflu__filter">Please select Product to get Influencer details.</div>
        :
        <>
          <div className="campaignsInflu__filter">
          </div>
          {(influencers && isInfluencerLoaded) && (
            <div className="campaignsInflu__items">
              {influencers.length > 0 && (
                <>
                  {
                    influencers.map((item, index) => {
                      return <InfluencerItem loadChildComponenet={loadChildComponenet} readOnly={readOnly} form={form} key={item.influencer_id} {...item} setCompensationData={setCompensationData} selectedGoods={selectedGoods} />;
                    })
                  }</>)}
            </div>
          )}
        </>
      }
    </div>
  );
}
const InfluencerItem = (props) => {
  const [ytd, setYtd] = useState(0);
  const [facebookProducts, setFacebookProducts] = useState([]);
  const [instaProducts, setInstaProducts] = useState([]);
  const [tiktokProduct, setTikTokProducts] = useState([]);
  const [youtubeProduct, setYoutubeProducts] = useState([]);
  const [goods, setGoods] = useState([]);
  const [influencerInsignt, setInfluencerInsignt] = useState({});
  const changeMedium = (item) => {
    setYtd(item.value);
    if (item.id === 3) {
      setInfluencerInsignt(props.influencer_stats.filter(item => item.heading === 'Last 3 months')[0]);
    } else {
      setInfluencerInsignt(props.influencer_stats.filter(item => item.heading === 'Last Month')[0]);
    }
  };
  useEffect(() => {
    let fb = [];
    let insta = [];
    let tiktok = [];
    let youtube = [];
    let compensationGoods = [];
    setInfluencerInsignt(props.influencer_stats.filter(item => item.heading === 'Last Month')[0]);
    props.compensations.map((item, index) => {
      if (item.name.startsWith("Facebook")) {
        fb.push(item);
      } else if (item.name.startsWith("Insta")) {
        insta.push(item);
      }
      else if (item.name.startsWith("Tik")) {
        tiktok.push(item);
      }
      else if (item.parent_id === 17) {
        youtube.push(item);
      }
      else if (item.type === 'goods') {
        let newArra = (props.selectedGoods.filter(itemSelGoods => itemSelGoods.id === item.goods_id));
        if (newArra.length > 0) {
          compensationGoods.push(item);
        }
      }
    })

    setFacebookProducts(fb);
    setInstaProducts(insta);
    setTikTokProducts(tiktok);
    setYoutubeProducts(youtube);
    setGoods(compensationGoods);
  }, [props.compensations]);
  return (
    <div className="campaignsInfluItem">
      <div className="campaignsInfluItem__head">
        <div className="campaignsInfluItem__head-image">
          <img
            src={
              props.influencer_avatar && props.influencer_avatar !== ''
                ? props.influencer_avatar
                : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `${process.env.PUBLIC_URL}/images/avatar.png`;
            }}
            alt="avatar" />
        </div>
        <div className="campaignsInfluItem__head-content">
          <h4>{props.influencer_name}</h4>
          <div className="campaignsInfluItem__head-row">
            <p className="sm">
              <strong>{props.post ? props.post : 0}</strong>
              Posts
            </p>
            <p className="sm">
              <strong>{props.influencer_followers ? utils.convertNumber(props.influencer_followers) : 0}</strong>
              Followers
            </p>
            <p className="sm">
              <strong>{props.influencer_following ? utils.convertNumber(props.influencer_following) : 0}</strong>
              Following
            </p>
          </div>
        </div>
      </div>
      <div className="campaignsInfluItem__insight">
        <div className="campaignsInfluItem__insight-title">
          <h3>Insights</h3>
          <YearToDate
            list={ytdList}
            selected={ytdList[0]}
            onChange={changeMedium}
          />
        </div>
        <div className="campaignsInfluItem__insight-items">
          <div className="campaignsInfluItem__insight-item">
            {heartIcon}
            <h6>{influencerInsignt.no_like ? influencerInsignt.no_like : 0} Likes</h6>
          </div>
          <div className="campaignsInfluItem__insight-item">
            {imageIcon}
            <h6>{influencerInsignt.post ? influencerInsignt.post.count ? influencerInsignt.post.count : 0 : 0} Posts</h6>
          </div>
          <div className="campaignsInfluItem__insight-item">
            {commentsIcon}
            <h6>{influencerInsignt.no_comment ? influencerInsignt.no_comment : 0} Comments</h6>
          </div>
          <div className="campaignsInfluItem__insight-item">
            {graphIcon}
            <h6>{influencerInsignt.engagement_rate ? influencerInsignt.engagement_rate : 0} Engagement</h6>
          </div>
        </div>
      </div>
      {facebookProducts.length > 0 && (
        <div className="campaignsInfluItem__product">
          <h3>Facebook Rates</h3>
          <div className="campaignsInfluItem__product-items">
            {props.compensations.map((item, index) => {
              if (item.name.startsWith("Facebook")) {
                return <ProductItem loadChildComponenet={props.loadChildComponenet} readOnly={props.readOnly} {...item} key={item.product_id} setCompensationData={props.setCompensationData} influencerId={props.influencer_id} selectedGoods={props.selectedGoods} />;

              }
            })}
          </div>
        </div>
      )}

      {instaProducts.length > 0 && (
        <div className="campaignsInfluItem__product">
          <h3>Instagram Rates</h3>
          <div className="campaignsInfluItem__product-items">
            {props.compensations.map((item, index) => {
              if (item.name.startsWith("Insta")) {
                return <ProductItem loadChildComponenet={props.loadChildComponenet} readOnly={props.readOnly} {...item} key={item.product_id} setCompensationData={props.setCompensationData} influencerId={props.influencer_id} selectedGoods={props.selectedGoods} />;
              }
            })}
          </div>
        </div>
      )}
      {tiktokProduct.length > 0 && (
        <div className="campaignsInfluItem__product">
          <h3>TikTok Rates</h3>
          <div className="campaignsInfluItem__product-items">
            {props.compensations.map((item, index) => {
              if (item.name.startsWith("TikTok")) {
                return <ProductItem loadChildComponenet={props.loadChildComponenet} readOnly={props.readOnly} {...item} key={item.product_id} setCompensationData={props.setCompensationData} influencerId={props.influencer_id} selectedGoods={props.selectedGoods} />;
              }
            })}
          </div>
        </div>
      )}
      {youtubeProduct.length > 0 && (
        <div className="campaignsInfluItem__product">
          <h3>Youtube Rates</h3>
          <div className="campaignsInfluItem__product-items">
            {props.compensations.map((item, index) => {
              if (item.parent_id === 17) {
                return <ProductItem loadChildComponenet={props.loadChildComponenet} readOnly={props.readOnly} {...item} key={item.product_id} setCompensationData={props.setCompensationData} influencerId={props.influencer_id} selectedGoods={props.selectedGoods} />;
              }
            })}
          </div>
        </div>
      )}
      {goods.length > 0 && (
        <div className="campaignsInfluItem__product">
          <h3>Goods</h3>
          <div className="campaignsInfluItem__product-items">
            {goods.map((item, index) => {
              // if (item.type.startsWith("goods")) {
              return <ProductItem itemType={item.type} loadChildComponenet={props.loadChildComponenet} readOnly={props.readOnly} {...item} key={item.goods_id} setCompensationData={props.setCompensationData} influencerId={props.influencer_id} selectedGoods={props.selectedGoods} />;
              // }
            })}
          </div>
        </div>
      )}
    </div>
  );
};
const ProductItem = (props) => {
  const [checked, setChecked] = useState(props.is_selected);
  const [isOverWrite, setIsOverWrite] = useState((props.org_rate === props.value ? true : false));
  const [price, setPrice] = useState(props.value);

  const makeOverWriteEnabled = () => {
    setIsOverWrite(!isOverWrite);

  };
  useEffect(() => {
    setIsOverWrite((props.org_rate === props.value ? true : false))
  }, [props.loadChildComponenet])
  return (
    <div className="campaignsInfluItem__product-item" >
      <div className="checkbox" >
        <input
          disabled={props.readOnly}
          checked={checked}
          type="checkbox"
          onChange={(e) => { setChecked(e.target.checked); props.setCompensationData(props.influencerId, props, !checked, props.itemType !== 'goods' ? props.org_rate : props.value); }}
        />
        <label htmlFor=""></label>
      </div>
      <h6>{props.name}</h6>
      {props.itemType !== 'goods' ?
        (isOverWrite && checked && !props.readOnly) ? <div className="showFlex">
          <div onClick={makeOverWriteEnabled}>{pencilIcon}</div>
          <div className="productVal">{props.value}</div></div> :
          (!isOverWrite && checked && !props.readOnly) ?
            <div className="showFlex"><div className="input overRide" onClick={(e) => { props.setCompensationData(props.influencerId, props, checked, props.org_rate); }}>
              {reset}</div>
              <div className="input">$< input type='text' className="overRidePrice" value={props.value.replace('$', '')} onChange={(e) => { props.setCompensationData(props.influencerId, props, checked, "$" + e.target.value); }} />
              </div></div>

            : <div>
              {props.value}</div> : <div>{props.value}</div>
      }
    </div >
  );
};