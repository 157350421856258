import React, { useEffect, useState } from "react";

import { Profile, Toast } from "../../Components";
import { arrowLeft } from "../../Components";
import UsersMain from "./UsersMain";
import UsersEdit from "./UsersEdit";
import {
  accountService,
} from '../../Services';
import { utils } from "../../Helpers";
const initForm = {
  firstName: "",
  lastName: "",
  email: "",
  role: [],
  password: "",
  confirmPassword: "",
};
export default function Users({ setAuth, NotifModul, profiles, setModalNotification, locationData }) {
  const [tab, setTab] = useState("main");
  const [toastList, setToastList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(1);
  const [isloaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [countOfUsers, setCountOfUsers] = useState(0);
  const [modal, setModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [selectDesc, setselectDesc] = useState("email");
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState(false);
  const [roleList, setRoleList] = useState();
  const [isRoleLoaded, setIsRoleLoaded] = useState(false);
  const [form, setForm] = useState(initForm);
  const changeMediumDesc = (item) => {
    setselectDesc(item.id)
    setPage(1);
    setSearchText("");
    setSearchList(!searchList);
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);
  };
  const setSearchTextOnChange = event => {
    console.log(event.target)
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchText(event.target.value);
  };
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const onPaginate = page => {
    setPage(page);
  };
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  const perPage = 10;
  useEffect(() => {
    async function fetchRole() {
      try {
        const res = await accountService.getRole();
        setRoleList(res);
        setIsRoleLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchRole();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        let filter = '';
        if (searchText !== '') {
          if (selectDesc === 'status') {
            if (searchText === 'active' || searchText === 'Active') {
              filter = 'filter[' + selectDesc + ']=1';
            } else {
              filter = 'filter[' + selectDesc + ']=0';
            }
          } else {
            filter = 'filter[' + selectDesc + ']=' + searchText;
          }
        }
        const res = await accountService.getAll({
          page: page,
          pageSize: perPage,
          filter: filter
        });
        setUsers(res['data']);
        setCountOfUsers(res['data'].length);
        if (res['meta'] && res['meta']['pagination']) {
          setPaginationData(res['meta']['pagination']);
        }
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page, countOfUsers, tab, searchList]);

  const downloadCsv = async () => {
    try {
      let filter = '';
      if (searchText !== '') {
        if (selectDesc === 'status') {
          if (searchText === 'active' || searchText === 'Active') {
            filter = 'filter[' + selectDesc + ']=1';
          } else {
            filter = 'filter[' + selectDesc + ']=0';
          }
        } else {
          filter = 'filter[' + selectDesc + ']=' + searchText;
        }
      }
      const response = await accountService.getAll({ page: page, pageSize: perPage, filter: filter, fileType: 'csv' });
      utils.downloadFile(response, "adminUsers_" + page + ".csv");

    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const onChange = (e) => {
    //setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...form.role];
      arr.push(roleList.filter(
        (item) => item.id === parseInt(e.target.value)
      )[0]);
      updateForm({ role: arr });
    } else {
      updateForm({
        role: form.role.filter(
          (item) => item.id !== parseInt(e.target.value)
        ),
      });
    }
  };
  useEffect(() => {
    setTab("main");
    setPage(1);
    setSearchText("");
    setselectDesc("email");
  }, [locationData.key]);

  const impersonateUser = async (item) => {
    try {
      const res = await accountService.impersonateUser({ impersonateUserID: item });
      if (res['data']['active_role'] && res['data']['active_role'] == 1) {
        window.location = '/#/influencer/dashboard';
      }
      if (res['data']['active_role'] && res['data']['active_role'] == 3) {
        window.location = '/#/brand/dashboard';
      }
      if (res['data']['active_role'] && res['data']['active_role'] == 2) {
        window.location = '/#/admin/dashboard';
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  return (
    <>
      <div className="main">
        <div className="main__inner">
          <div className="main__inner-title">
            {tab === "main" && <h2>Manage users</h2>}
            {tab === "edit" && (
              <div className="main__inner-title-col">
                <h2>Edit user {'[' + modalData.id + ']'}</h2>
                <button
                  className="button tertiary big"
                  onClick={() => setTab("main")}
                >
                  {arrowLeft} Back
                </button>
              </div>
            )}
            <Profile
              type="brand"
              setAuth={setAuth}
              setModal={setModal}
              NotifModul={NotifModul}
              profiles={profiles}
              setNotifModul={setModalNotification}
            />
          </div>
          {(tab === "main" && isRoleLoaded) && <UsersMain
            downloadCsv={downloadCsv}
            modal={modal}
            setModal={setModal}
            countOfUsers={countOfUsers}
            setCountOfUsers={setCountOfUsers}
            toastList={toastList}
            addToast={addToast}
            setTab={setTab}
            users={users}
            onPaginate={onPaginate}
            paginationData={paginationData}
            setModalData={setModalData}
            modalData={modalData}
            setSearchTextOnChange={setSearchTextOnChange}
            changeMediumDesc={changeMediumDesc}
            search={search}
            searchText={searchText}
            roleList={roleList}
            form={form}
            onChange={onChange}
            setInput={setInput}
            updateForm={updateForm}
            impersonateUser={impersonateUser}
          />}
          {tab === "edit" && <UsersEdit form={form}
            onChange={onChange} item={modalData} addToast={addToast} toastList={toastList}
            roleList={roleList} setInput={setInput}
            updateForm={updateForm} />}
        </div>
      </div>
      {toastList && (
        <Toast type="rate" list={toastList} setList={setToastList} />
      )}
    </>
  );
}
