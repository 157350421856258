import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import Assign from "./Assign";
import Details from "./Details";
import ModalNotifDelete from "../Modals/ModalNotifDelete";
import ModalNotifSend from "../Modals/ModalNotifSend";
import PrevSent from "./PrevSent";
import { utils } from '../../../Helpers/utils';
import { notificationService } from "../../../Services";

export default function NotifEdit({ setTab, addToast, tabData,allusers,deleteNotification }) {
  const [modal, setModal] = useState(null);
  const [error, setError] = useState(false);
  const updateForm = (data) => {
    setForm({ ...form, ...data });
  };
  const initForm = {
    title: tabData.title,
    text: utils.testJSON(tabData.text) ? JSON.parse(tabData.text):"",
    assignTo: tabData.assignedusers  ?tabData.assignedusers
    :[],
    status:tabData.status,
  };
  const [form, setForm] = useState(initForm);
  const setInput = (key) => (event) =>
    updateForm({ [key]: event.target.value });
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);

  const sendFunc = () => {
    if (
      form.title !== "" &&
      form.text.blocks[0].text !== "" &&
      form.assignTo.length > 0
    ) {
      setModal("notifSend");
    } else {
      setError(true);
      addToast({
        error: true,
        msg: "Please add details to send notifications.",
        successText: "Data saved successfully.",
        date: new Date(),
      });
    }
  };
  const sendNotification = async () => {
    try {      
      form.text = (JSON.stringify(form.text));
      form.status='active';
      const res = await notificationService.update(tabData.id,form);       
      setTab("main");
      addToast({
        error: false,
        msg: "Data saved successfully.",
      });  
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
    }
  }
  const saveAsDraftFunc = async () => {
    if (
      form.title !== "" &&
      form.text.blocks[0].text !== "" &&
      form.assignTo.length > 0
    ) {
      try {      
        form.text = (JSON.stringify(form.text));
        const res = await notificationService.update(tabData.id,form);           
        setTab("main");
        addToast({
          error: false,
          msg: "Data saved successfully.",
        });  
      } catch (err) {
        addToast({ error: true, date: new Date(), msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, '') });
      }
    } else {
      setError(true);
      addToast({
        error: true,
        msg: "Please add details to save notifications.",
        successText: "Data saved successfully.",
        date: new Date(),
      });
    }
  };
  return (
    <>
      <div className="admnotifAdd">
        <Details
          setInput={setInput}
          form={form}
          updateForm={updateForm}
          error={error}
          tabData={tabData}
        />
        {(tabData.status === "active" && form.assignTo.length > 0 ) && <PrevSent prevSentUser={form.assignTo}/>}
        {allusers.length > 0 &&
        <Assign form={form} updateForm={updateForm} error={error} allusers={allusers}/> }
        <div className="admnotifAdd__btns">
          <div className="admnotifAdd__btns-row">
          {tabData.status !== "active" && <>
            <button className="button secondary" onClick={saveAsDraftFunc}>
            Save as Draft
            </button>
            <button
            className="button secondary"
            onClick={() => setModal("notifDelete")}
          >
            Delete
          </button></>
          }
          {tabData.status === "active" && 
            <button
            className="button secondary"
            onClick={() => setTab("main")}
          >
           Cancel
          </button>
          }
          </div>
          <button className="button primary" onClick={sendFunc} disabled={!form.text.blocks}>
            Send notification
          </button>
        </div>
      </div>
      <AnimatePresence>
        {modal === "notifDelete" && (
          <ModalNotifDelete
            close={() => setModal(null)}
            delBtnClick={() => {
              deleteNotification()
            }}
          />
        )}
        {modal === "notifSend" && (
          <ModalNotifSend
            close={() => setModal(null)}
            btnClick={() => {
              sendNotification()
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
}
