import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { SelectInput, DateInput } from "../../../Components";
import { calendarIcon } from "../../../Components";
import { MoneyInput, plusIcon } from "../../../Components";

import Goods from "./Goods";


import {
  campaignService
} from '../../../Services';
const selectList = [
  { id: "money", value: "Money only" },
  { id: "moneyGoods", value: "Money with goods" },
  { id: "goods", value: "Goods only" },
];
export default function Compensation({
  form,
  updateForm,
  error,
  setModal,
  setModalData,
  setGoodIndex,
  readOnly,
  categoriesData
}) {
  const [select, setSelect] = useState(form.campaigns_type_id != "" ? form.campaigns_type_id : 1);
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [count, setCount] = useState(form.goods.length);
  const [showGoods, setShowGoods] = useState(true);
  const [availableBalance, setAvailableBalance] = useState(null);
  const changeMedium = (item) => {

    setSelect(item.id);
    setShowGoods(false);
    /*if (item.id != 1) {

      console.log("goods");
      let arr = [
        {
          goods_name: "",
          photo: "",
          description: "",
          total: 0,
          value: 0,
          file: {}
        }

      ];
      // updateForm({ ['goods']: arr });
      updateForm({ ...form, goods: arr });
      //updateForm({ budget: "" });
    } else {
      console.log("else");
      let arr = [];
      updateForm({ ...form, goods: arr });
      // updateForm({ ...form, budget: "" });
    }

    /*updateForm({
      ...form,
      "budget": "",
      'goods': ((item.id != 1) ? [{
        goods_name: "",
        photo: "",
        description: "",
        total: 0,
        value: 0,
        file: {}
      }
      ]
        : [])
    }
    );*/
  };
  //console.log(form);
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const updateGoods = (index) => (data) => {
    let arr = [...form.goods];
    arr[index] = data;
    updateForm({ goods: arr });

  };
  const addGoods = () => {
    let arr = [
      ...form.goods,
      {
        goods_name: "",
        total: "",
        value: "",
        photo: "",
        description: "",
        file: "",
      },
    ];
    updateForm({ goods: arr });
  };
  const deleteGoods = (index) => {
    setShowGoods(false);
    let arr = [...form.goods];
    form.goods.filter(item => item.id === index)
    arr.splice(index, 1);
    //updateForm({ goods: arr });
    updateForm({
      ...form,
      ['goods']: arr
    })
    setShowGoods(true);
    //setCount(arr.length);

  };
  useEffect(() => {
    form.goods.map(goods => {
      if (goods.photo != '') {
        let newInfo = { 'mediafile': goods.photo, 'media_type_id': 2 }
        let newGoods = goods.file = newInfo;
      }
    });

    async function fetchConfig() {
      try {
        let arrayObj = categoriesData.campaigns_type.map(item => {
          return {
            id: item.id,
            value: item.campaigns_type
          };
        });
        setCampaignTypes(arrayObj);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    async function fetchBalance() {
      if (!readOnly) {
        try {
          const res = await campaignService.getBalance();
          setAvailableBalance(res.wallet_details.available)
        } catch (err) {
          addToast({
            error: true,
            msg: err,
            id: `${Date.now()}-${toastList.length}`
          });
        }
      }
    }
    fetchConfig();
    fetchBalance()
  }, []);
  useEffect(() => {
    if (select !== 1) {
      if (form.goods.length === 0) {
        let arr = [
          {
            goods_name: "",
            total: "",
            value: "",
            photo: "",
            description: "",
          },
        ];
        updateForm({
          ...form,
          ['compensationDetails']: select,
          ['budget']: '',
          ['campaigns_type_id']: select,
          ['goods']: arr
        });
      } else {
        let arr = form.goods;
        updateForm({
          ...form,
          ['compensationDetails']: select,
          ['budget']: '',
          ['campaigns_type_id']: select,
          ['goods']: arr
        });
      }
      setShowGoods(true);
    } else {
      updateForm({
        ...form,
        ['compensationDetails']: select,
        ['budget']: '',
        ['campaigns_type_id']: select,
        ['goods']: []
      });
    }


    //updateForm({ ...form, ['budget']: '' });
    //updateForm({ ...form, ['campaigns_type_id']: select });

    // updateForm({ ...form, ['goods']: arr });
  }, [select]);
  useEffect(() => {
    setShowGoods(true);
    setCount(form.goods.length);

  }, [form.goods]);
  useEffect(() => {
    updateForm({
      ...form,
      ['goods']: form.goods
    });

    setShowGoods(true);

  }, [count]);
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="form rel"
    >
      <div className="form__title">
        <h3 className="big">Compensation Details</h3>
      </div>
      <div className="formCampaigns__comp">
        <div className="formCampaigns__comp-main">
          <div className="formCampaigns__comp-main-select">
            <SelectInput
              label="Compensation type"
              list={campaignTypes}
              onChange={changeMedium}
              value={form.campaigns_type_id}
              name='campaigns_type_id'
              selected={campaignTypes.filter(item => item.id === form.campaigns_type_id)[0]}
              readOnly={readOnly}
            />
          </div>
          <div className="formCampaigns__comp-main-dates">
            <div className="formCampaigns__comp-main-date">
              <DateInput
                startDate={form.start_date}
                placeholder="MM / DD / YYYY"
                onChange={(date) => updateForm({ start_date: date })}
                label="Start date"
                icon={calendarIcon}
                error={error}
                value={form.start_date}
                errorText="Please enter Start date"
                readOnly={readOnly}
              />
            </div>
            <div className="formCampaigns__comp-main-date">
              <DateInput
                startDate={form.end_date}
                placeholder="MM / DD / YYYY"
                onChange={(date) => updateForm({ end_date: date })}
                label="End date"
                icon={calendarIcon}
                value={form.end_date}
                error={error}
                errorText="Please enter End date"
                readOnly={readOnly}
              />
            </div>
          </div>
        </div>
        {(select === 1 || select === 3) && (
          <div className="formCampaigns__comp-money">
            <h3 className="big">Money details</h3>
            <div className="addFunds">
              <div className="formCampaigns__comp-main-date">
                <MoneyInput
                  label="Budget (min 500)"
                  type="number"
                  checkBudgetValue="1"
                  currency="USD"
                  error={error}
                  errorText="Minimum budget of 500 needed"
                  value={form.budget}
                  onChange={(e) => updateForm({ budget: e.target.value })}
                  readOnly={readOnly}
                  className="moneyInput"
                />
              </div>
              <div className="formCampaigns__comp-main-date input__outer">
                {!readOnly && <label>Available Balance: {availableBalance}</label>}
                {!readOnly && <div class="input__outer addFundsButton">
                  <button
                    className='button tertiary big'
                    onClick={() => setModal('confirmExit')}
                  >
                    Add funds {plusIcon}
                  </button>
                </div>}
              </div>
            </div>
          </div>
        )}

        {(select === 2 || select === 3 && showGoods === true) && (
          <div className="formCampaigns__comp-goods">
            <h3 className="big">Goods details</h3>
            <div className="formCampaigns__comp-goods-content">
              {form.goods.map((item, index) => {

                return (
                  <Goods
                    {...item}
                    key={index + "_" + count + (item.file ? item.file.id ? item.file.id : '' : '')}
                    index={index}
                    pos={item}
                    updateData={updateGoods(index)}
                    deleteFunc={() => deleteGoods(index)}
                    setModal={setModal}
                    setModalData={setModalData}
                    setGoodIndex={() => setGoodIndex(index)}
                    addToast={addToast}
                    error={error}
                    count={form.goods.length}
                    setShowGoods={setShowGoods}
                    readOnly={readOnly}
                  />
                );
              })}
              {!readOnly &&
                <button className="button secondary" onClick={addGoods}>
                  Add additional goods
                </button>}
            </div>
          </div>

        )}

      </div>
    </motion.div>
  );
}
