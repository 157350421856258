import React, { useRef, useState, useEffect } from "react";
import { SelectInput, plusIcon, TextInput, EmailInput, PasswordInput } from "../../Components";
import AssetVideo from "../Campaigns/Modals/Assets/AssetVideo";
import { motion } from "framer-motion";
import {
  accountService,
} from '../../Services';
export default function UsersEdit({ item, addToast, toastList, roleList, form, onChange, setInput, updateForm }) {
  const fileRef = useRef(null);
  const refEditUser = useRef(null);
  const refPassword = useRef(null);
  const [assetFile, setAssetFile] = useState("");
  const [assetFileDetail, setAssetFileDetail] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorPass, setErrorPass] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
    updateForm({
      'firstName': item.firstName, 'lastName': item.lastName,
      'email': item.email, 'role': item.roles, 'password': '', 'confirmPassword': ''
    })
  }, []);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterCalled();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [form]);
  const enterCalled = () => {
    let editUser = document.activeElement.parentElement.closest('.formUsers__edit');
    let pass = document.activeElement.parentElement.closest('.form__password');
    if (editUser) {
      refEditUser.current.click();
    }
    if (pass) {
      refPassword.current.click();
    }
  }
  const saveChanges = (data) => {
    if (data === "user") {
      if (
        form.firstName !== "" &&
        form.lastName !== "" &&
        form.firstName !== null &&
        form.lastName !== null &&
        form.email !== "" &&
        form.email !== null &&
        form.role.length !== 0
      ) {
        form.request_type = 'user_profile';
        setError(false);
        updateUser();
      } else {
        setError(true);
        addToast({
          error: true,
          msg: "Enter all fields to save details.",
          successText: "Data saved successfully.",
          date: new Date(),
        });
      }
    } else if (data === "pass") {
      if (
        form.password !== "" && form.password !== null &&
        form.confirmPassword !== "" && form.confirmPassword !== null
      ) {
        var passwordFormat = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (form.password.match(passwordFormat)) {
          form.request_type = 'password';
          if (form.password === form.confirmPassword) {
            setErrorPass(false);
            updateUser();
          } else {
            addToast({
              error: true,
              msg: "Password and Confirm Password should be same",
              successText: "Data saved successfully.",
              date: new Date(),
            });
          }
        } else {
          addToast({
            error: true,
            msg: "Password should have atleast one uppercase, one lowercase letter, one number and one special character",
            successText: '',
            date: new Date()
          });
        }
      } else {
        setErrorPass(true);
        addToast({
          error: true,
          msg: "Enter Password and Confirm Password",
          successText: "Data saved successfully.",
          date: new Date(),
        });
      }
    }
  };
  const updateUser = async () => {
    try {
      let fieldsToUpdate = {};
      let roleToPass = [];
      roleList.map(item => {
        let selRole = form.role.filter(formRole => formRole.id === item.id);
        if (selRole) {
          if (selRole.length > 0) {
            roleToPass.push({ 'id': selRole[0].id, 'name': selRole[0].name, 'checked': true });
          } else {
            roleToPass.push({ 'id': item.id, 'name': item.name, 'checked': false });
          }
        }
      });
      if (form.request_type === "password") {
        fieldsToUpdate.password = form.password;
        fieldsToUpdate.confirmPassword = form.password;
        fieldsToUpdate.request_type = "password";
      } else {
        fieldsToUpdate.firstName = form.firstName;
        fieldsToUpdate.lastName = form.lastName;
        fieldsToUpdate.email = form.email;
        fieldsToUpdate.request_type = "user_profile";
        fieldsToUpdate.role = roleToPass;
      }


      let res = await accountService.update(item.id, fieldsToUpdate);
      if (form.request_type === 'password') {
        updateForm({ ['password']: '', ['confirmPassword']: '' });
      }
      addToast({
        error: false,
        msg: res.message.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        id: `${Date.now()}-${toastList.length}`
      });
    } catch (err) {
      addToast({
        error: true,
        msg: err.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  return (
    <>
      <div className="users">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 * 2 }}
          exit={{ opacity: 0, y: 30 }}
          className="form"
        >
          <div className="form__title">

          </div>
          <button
            ref={refEditUser}
            className="button secondary"
            onClick={() => saveChanges("user")}
          >
            Save changes
          </button>
          {isLoaded &&
            <div className="formUsers__edit">
              <div className="formUsers__edit-inner">
                <div className="formUsers__edit-row">
                  <TextInput
                    value={form.firstName}
                    type="text"
                    onChange={setInput("firstName")}
                    label="First name"
                    error={error}
                    errorText="Please enter First name"
                  />
                  <TextInput
                    value={form.lastName}
                    type="text"
                    onChange={setInput("lastName")}
                    label="Last name"
                    error={error}
                    errorText="Please enter Last name"
                  />
                </div>
                <div className="formUsers__edit-row">
                  <EmailInput
                    value={form.email}
                    type="email"
                    onChange={setInput("email")}
                    label="Email"
                    error={error}
                    errorText="Please enter Email"
                  />
                  <div className="input__outer ">
                    <label>Role</label>
                    <div className="roleCheckBox">
                      {roleList.map((itemRole, index) => {
                        return (
                          <>
                            <input
                              value={itemRole.id}
                              type='checkbox'
                              checked={form.role.filter(item => item.id == itemRole.id).length > 0}
                              key={itemRole.id}
                              onChange={onChange}
                            /> {itemRole.name == 'advertiser' ? 'Brand' : (itemRole.name).charAt(0).toUpperCase() + itemRole.name.slice(1)}
                          </>)
                      })}

                    </div>
                  </div>
                </div>
              </div>

              {/*<div className="assetsAdd__form-file">
              <h6 className="semi">Upload file/logo</h6>
              <div className="assetsAdd__form-file-inner">
                {assetFile && (
                  <div className="assetsAdd__form-preview">
                    {assetFileDetail.type === "image/jpeg" && (
                      <div className="assets__media">
                        <img src={process.env.PUBLIC_URL + assetFile} alt="" />
                      </div>
                    )}
                    {assetFileDetail.type === "application/pdf" && (
                      <div className="assets__media">
                        <img
                          src={process.env.PUBLIC_URL + "/images/pdf.png"}
                          alt=""
                        />
                      </div>
                    )}
                    {assetFileDetail.type === "video/mp4" && (
                      <AssetVideo asset={assetFile} />
                    )}

                    <h6 className="sm">{assetFileDetail.name}</h6>
                  </div>
                )}
                <div className="assetsAdd__form-upload">
                  <div className="assetsAdd__form-upload-icon">{plusIcon}</div>
                  <h5 className="reg">Upload media</h5>
                  <p className="sm">
                    .PDF or .JPG only with a maximum file size of 10MB
                  </p>
                  <div className="assetsAdd__form-upload-btns">
                    <button className="button primary">
                      Upload
                      <input
                        title=" "
                        type="file"
                        accept="application/pdf , image/jpeg, video/mp4"
                        ref={fileRef}
                        onChange={previewImage}
                      />
                    </button>
                  </div>
                </div>
              </div>
                    </div>*/}
            </div>
          }
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 * 4 }}
          exit={{ opacity: 0, y: 30 }}
          className="form pass"
        >
          <div className="form__title">
            <h3 className="big">Reset password</h3>
          </div>
          <button
            ref={refPassword}
            className="button secondary"
            onClick={() => {
              saveChanges("pass");
            }}
          >
            Save changes
          </button>

          <div className="form__password">
            {/*<div className="form__password-row">
             <PasswordInput
                value={form.currentPassword}
                type="password"
                onChange={setInput("currentPassword")}
                label="Current password"
                error={errorPass}
                errorText="Please enter Current password"
              />
          </div>*/}
            <div className="form__password-row">
              <PasswordInput
                value={form.password ? form.password : ''}
                type="password"
                onChange={setInput("password")}
                label="New password"
                error={errorPass}
                errorText={form.password === "" ? "Please enter New password" : "please enter 8 digit password"}
              />
              <PasswordInput
                value={form.confirmPassword ? form.confirmPassword : ''}
                type="password"
                onChange={setInput("confirmPassword")}
                label="Confirm new password"
                error={errorPass}
                errorText={form.password === "" ? "Please enter confirm new password" : "please enter 8 digit confirm password"}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}
