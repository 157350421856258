import React, { useState, useEffect } from 'react';
import {
  Profile, Toast
} from '../../Components';
import { influencerService } from '../../Services';
import { link } from '../../Components';

export default function ReferFriend(props) {
  const [referurl, setReferurl] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [toastList, setToastList] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await influencerService.getReferralUrl();
        setReferurl(res['data']);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, []);
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const copyBtn = (data) => {
    navigator.clipboard.writeText(data);
    addToast({
      error: false,
      errorText: "Error.",
      msg: "Link copied to clipboard.",
    });
  };
  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title'>
            <h2>Refer a Friend</h2>
            <Profile
              user={props.user}
              setModal={props.setModal}
              profiles={props.profiles}
              NotifModul={props.NotifModul}
              setNotifModul={props.setModalNotification}
            />
          </div>
          <div className='myprofile'>
            <div className='form'>
              <p className="sm referfriend">
                <p>Welcome to the TAMA Influencer Rewards Program!</p>
                <p>Are you ready to take your influencer journey to the next level? We've got something special just for you.</p>
                <h3>Refer Friends, Earn Rewards!</h3>
                <h6>Here's How It Works:</h6>
                <ol>
                  <li><strong>Share Your Link:</strong> Invite your influencer friends to join TAMA with your unique referral link.</li>
                  <li><strong>Grow Together:</strong> As your friends become active on TAMA, you both earn fantastic rewards.</li>
                  <li><strong>Enjoy the Perks: </strong>Get exclusive campaign opportunities, early access to features, and more!</li>
                </ol>
                <h6>Join the TAMA Community</h6>
                <p>Discover the power of community and collaboration. Together, we rise higher!</p>
                <h6>Become a TAMA Influencer Ambassador</h6>
                <p>Ready to get started? Use the unique link below to refer your friends and unlock incredible rewards!</p>


                <table className="copyReferelUrlTable">
                  <tbody>
                    <tr>
                      <td className="copyReferelUrlTd referBox">
                        <input type="text" value={referurl} readOnly />
                      </td>
                      <td className="copyReferelUrlTd">

                        <button class="copyReferelUrlButton" onClick={() => copyBtn(referurl)}>Copy</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p>Questions? We're here to help. Reach out anytime.</p>
              </p>
            </div>
          </div>
        </div>
      </div>
      {toastList && (
        <Toast type='refer' list={toastList} setList={setToastList} />
      )}
    </>
  );
}