import React from "react";
import { utils } from '../../Helpers/utils';
export default function TamaCoinItem(props) {
  return (
    <tr>
      <td>
        <p>{props.created_at}</p>
      </td>
      <td>{props.activity}</td>      
      <td>{props.coin}</td>
    </tr>
  );
}
