import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { TextInput } from "../../../Components";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { EditorInput } from "../../../Components";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Details({
  setInput,
  form,
  updateForm,
  error,
  tabData,
}) {






  const handlePress = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="admnotif__form"
    >
      <div
        style={{ width: "100%" }}
        className={
          "admnotif__formDetail " +
          (tabData?.status === "active" ? "disabled" : "")
        }
      >
        <div className="admnotif__formDetail-title">
          <h3 className="big">Details {form.id ? '[' + form.id + ']' : ''}</h3>
        </div>
        <TextInput
          value={form.activity}
          type="text"
          onChange={setInput("activity")}
          label="Activity"
          error={error}
          errorText="Please enter activity"
        />
        <div className="input__outer">

          <TextInput
            value={form.notes}
            type="text"
            onChange={setInput("notes")}
            label="Notes"
            error={error}
            errorText="Please enter notes"
          />
        </div>
        <div style={{ width: "20%" }}>
          <TextInput
            value={form.coin}
            type="number"
            onChange={setInput("coin")}
            label="Coin"
            onKeyDown={handlePress}
            error={error}
            errorText="Please enter coin"
          />
        </div>
      </div>

    </motion.div>
  );
}
