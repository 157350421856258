import React, { useState } from "react";
import Countries from "./components/Countries";
import { Kpi, Industries } from "../../Components";
const genderList = [
  { id: "1", value: "Men focused" },
  { id: "2", value: "Women focused" },
];
export default function RecommendForm({ error, form, updateForm, countries, categoriesData, categories, facebookProducts, instaProducts, tikTokProducts, youtubeProducts, addToast,
  toastList }) {

  return (
    <div className="recomForm">
      <h3>Overview</h3>
      <Industries
        addToast={addToast}
        toastList={toastList}
        form={form}
        updateForm={updateForm}
        categoriesList={categoriesData}
        categories={categories} />
      <div className="recomForm__deliver">
        <div className="recomForm__deliver-col">
          <div className="recomProduct">
            <div>
              <img
                src={process.env.PUBLIC_URL + '/images/facebook.png'}
                alt='facebook'
              />
            </div>
            <div> <h6> Products</h6></div>
          </div>

          <div className="recomForm__deliver-items">
            {facebookProducts.map((item, index) => {
              return (
                <FacebookItem
                  itemData={item}
                  key={index}
                  updateForm={updateForm}
                  form={form}
                />
              );
            })}
          </div>
        </div>
        <div className="recomForm__deliver-col">
          <div className="recomProduct">
            <div><img
              src={process.env.PUBLIC_URL + '/images/instagram.png'}
              alt='instagram'
            />
            </div>
            <div> <h6> Products</h6></div>
          </div>
          <div className="recomForm__deliver-items">
            {instaProducts.map((item, index) => {
              return (
                <InstagramItem
                  itemData={item}
                  key={index}
                  updateForm={updateForm}
                  form={form}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="recomForm__deliver">
        <div className="recomForm__deliver-col">
          <div className="recomProduct">
            <div><img
              src={process.env.PUBLIC_URL + '/images/tiktok.png'}
              alt='tikTok'
            />
            </div>
            <div> <h6> Products</h6></div>
          </div>
          <div className="recomForm__deliver-items">
            {tikTokProducts.map((item, index) => {
              return (
                <TikTokItem
                  itemData={item}
                  key={index}
                  updateForm={updateForm}
                  form={form}
                />
              );
            })}
          </div>
        </div>
        <div className="recomForm__deliver-col">
          <div className="recomProduct">
            <div><img
              src={process.env.PUBLIC_URL + '/images/youtube.png'}
              alt='tikTok'
            />
            </div>
            <div> <h6> Products</h6></div>
          </div>
          <div className="recomForm__deliver-items">
            {youtubeProducts.map((item, index) => {
              return (
                <YoutubeItem
                  itemData={item}
                  key={index}
                  updateForm={updateForm}
                  form={form}
                />
              );
            })}
          </div>
        </div>
      </div>
      <h3>Key metric goals</h3>
      <Kpi reach={form.reach} interaction={form.interaction} updateForm={updateForm} error={error} />

      <div className="recomForm__demo">
        <h3>Demographic</h3>
        <p className="sm demo">
          This will help our platform select influencers with a follower base
          that most closely aligns to these demographics.
        </p>
        <div className="recomForm__demo-inner">
          <div className="recomForm__demo-gender">
            <h6>Target Audience</h6>
            <div className="recomForm__demo-gender-items">
              {genderList.map((item, index) => {
                return (
                  <GenderItem
                    itemData={item}
                    key={index}
                    updateForm={updateForm}
                    form={form}
                  />
                );
              })}
            </div>
          </div>
          <div className="recomForm__demo-age">
            <h6>Age</h6>
            <div className="recomForm__demo-age-items">
              <div className="checkbox">
                <input
                  checked={form.age18_24}
                  onChange={(e) => {
                    updateForm({ age18_24: e.target.checked });
                  }}
                  type="checkbox"
                />
                <label htmlFor=""></label>
                <p className="sm">18-24</p>
              </div>
              <div className="checkbox">
                <input
                  checked={form.age25_34}
                  onChange={(e) => {
                    updateForm({ age25_34: e.target.checked });
                  }}
                  type="checkbox"
                />
                <label htmlFor=""></label>
                <p className="sm">25-34</p>
              </div>
              <div className="checkbox">
                <input
                  checked={form.age35_44}
                  onChange={(e) => {
                    updateForm({ age35_44: e.target.checked });
                  }}
                  type="checkbox"
                />
                <label htmlFor=""></label>
                <p className="sm">35-44</p>
              </div>
              <div className="checkbox">
                <input
                  checked={form.age45_54}
                  onChange={(e) => {
                    updateForm({ age45_54: e.target.checked });
                  }}
                  type="checkbox"
                />
                <label htmlFor=""></label>
                <p className="sm">45-54</p>
              </div>
              <div className="checkbox">
                <input
                  checked={form.age55_64}
                  onChange={(e) => {
                    updateForm({ age55_64: e.target.checked });
                  }}
                  type="checkbox"
                />
                <label htmlFor=""></label>
                <p className="sm">55-64</p>
              </div>
              <div className="checkbox">
                <input
                  checked={form.age65_plus}
                  onChange={(e) => {
                    updateForm({ age65_plus: e.target.checked });
                  }}
                  type="checkbox"
                />
                <label htmlFor=""></label>
                <p className="sm">65+</p>
              </div>
              <div className="checkbox">
                <input
                  checked={form.legalDringkingAge}
                  onChange={(e) => {
                    updateForm({ legalDringkingAge: e.target.checked });
                  }}
                  type="checkbox"
                />
                <label htmlFor=""></label>
                <p className="sm">Legal drinking age</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<Countries form={form} updateForm={updateForm}
        countries={countries} allCountry={[...countries]} />*/}
    </div >
  );
}
const FacebookItem = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.facebookDel];
      arr.push(props.itemData);
      props.updateForm({ facebookDel: arr });
    } else {
      props.updateForm({
        facebookDel: props.form.facebookDel.filter(
          (item) => item.id !== props.itemData.id
        ),
      });
    }
  };
  return (
    <div className="checkbox">
      <input checked={checked} onChange={onChange} type="checkbox" />
      <label htmlFor=""></label>
      <p className="sm">{props.itemData.name.replace("Facebook", "FB")}</p>
    </div>
  );
};
const InstagramItem = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.instagramDel];
      arr.push(props.itemData);
      props.updateForm({ instagramDel: arr });
    } else {
      props.updateForm({
        instagramDel: props.form.instagramDel.filter(
          (item) => item.id !== props.itemData.id
        ),
      });
    }
  };
  return (
    <div className="checkbox">
      <input checked={checked} onChange={onChange} type="checkbox" />
      <label htmlFor=""></label>
      <p className="sm">{props.itemData.name.replace("Instagram", "IG")}</p>
    </div>
  );
};
const TikTokItem = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.tikTokDel];
      arr.push(props.itemData);
      props.updateForm({ tikTokDel: arr });
    } else {
      props.updateForm({
        tikTokDel: props.form.tikTokDel.filter(
          (item) => item.id !== props.itemData.id
        ),
      });
    }
  };
  return (
    <div className="checkbox">
      <input checked={checked} onChange={onChange} type="checkbox" />
      <label htmlFor=""></label>
      <p className="sm">{props.itemData.name.replace("TikTok", "TT")}</p>
    </div>
  );
};
const YoutubeItem = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.youtubeDel];
      arr.push(props.itemData);
      props.updateForm({ youtubeDel: arr });
    } else {
      props.updateForm({
        youtubeDel: props.form.youtubeDel.filter(
          (item) => item.id !== props.itemData.id
        ),
      });
    }
  };
  return (
    <div className="checkbox">
      <input checked={checked} onChange={onChange} type="checkbox" />
      <label htmlFor=""></label>
      <p className="sm">{props.itemData.name.replace("Youtube", "YT")}</p>
    </div>
  );
};
const GenderItem = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.gender];
      arr.push(props.itemData);
      props.updateForm({ gender: arr });
    } else {
      props.updateForm({
        gender: props.form.gender.filter(
          (item) => item.id !== props.itemData.id
        ),
      });
    }
  };
  return (
    <div className="checkbox">
      <input checked={checked} onChange={onChange} type="checkbox" />
      <label htmlFor=""></label>
      <p className="sm">{props.itemData.value}</p>
    </div>
  );
};
