import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  Header,
  Sidebar,
  ModalSwitch
} from '../Components';
import { accountService, campaignService } from '../Services';
import { PrivateRoute } from '../Helpers';
import { CustomUrl, ProductGuidelines, AdminCampaigns, Users, Notifications, Cron, AdminHome, PostVerification, TamaCoin } from './index';


export default function Admin({ user, setChangePage, changePage, path, allNotifications,
  setModalNotification,
  setModalNotificationData,
  updateNotificationStatus,
  modalNotification, locationData, handleAnchorClick, setSaveCampaign, setShowConfirmModal, setShowConfirmAction, saveCampaign }) {
  const [NotifModul, setNotifModul] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modal, setModal] = useState(null);
  const [menu, setMenu] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [newNavigation, setNewNavigation] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    if (user) {
      const roleId =
        user['data']['roles'].length > 0
          ? user['data']['roles'].filter(
            item => item['id'] == user['data']['active_role']
          )[0]['id']
          : '';
      if (roleId === 1) {
        //navigate('/influencer/dashboard');
      } else if (roleId === 3) {
        //navigate('/brand/dashboard');
      }
    }
  }, []);

  useEffect(() => {
    if (modal) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [modal]);
  const deleteNotif = id => {
    setNotifModul(NotifModul.filter(item => item.id !== id));
    if (modal === 'notifDelete') {
      setModal('notifications');
    } else if (modal === 'notifDeleteDash') {
      setModal(null);
    }
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove('active');
  }, [location]);
  const close = e => {
    if (e.target === e.currentTarget) setMenu(false);
  };

  useEffect(() => {
    getProfiles();
    getActiveOpportunityCount();
  }, []);

  async function getProfiles() {
    try {
      const res = await accountService.getProfiles({});
      if (res && res['data']) {
        setProfiles(res['data']['profiles']);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getActiveOpportunityCount() {
    try {
      const res = await campaignService.getCount();
      setNewNavigation({ 'campaigns_count': res.campaign_count });
    } catch (err) {
      addToast({ error: true, date: new Date(), msg: err });
    }
  }
  const hideHeaderSidebar =
    path.indexOf('product_guidelines') > -1 ? true : false;

  return (
    <>
      {!hideHeaderSidebar && (
        <>
          <Header
            setModal={setModal}
            menu={menu}
            setMenu={setMenu}
            user={user}
            profiles={profiles}
          />
          <Sidebar menu={menu} handleAnchorClick={handleAnchorClick} close={close} user={user} type={'admin'} newNavigation={newNavigation} />
        </>
      )}
      <main>
        <Routes>
          <Route
            exact
            path='/dashboard'
            element={
              <PrivateRoute>
                <AdminHome
                  user={user}
                  setModal={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotification={setModalNotification}
                  locationData={locationData}
                />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin-custom-url'
            element={
              <PrivateRoute>
                <CustomUrl
                  user={user}
                  setModal={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotification={setModalNotification}
                  locationData={locationData}
                />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/product_guidelines'
            element={
              <PrivateRoute>
                <ProductGuidelines user={user} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/campaigns'
            element={
              <PrivateRoute>
                <AdminCampaigns
                  user={user}
                  setModal={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotification={setModalNotification}
                  locationData={locationData}
                  saveCampaign={saveCampaign}
                  setSaveCampaign={setSaveCampaign}
                  setShowConfirmModal={setShowConfirmModal}
                  setShowConfirmAction={setShowConfirmAction}
                />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/postverification'
            element={
              <PrivateRoute>
                <PostVerification
                  user={user}
                  setModal={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotification={setModalNotification}
                  locationData={locationData}
                />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path='/notifications'
            element={
              <PrivateRoute>
                <Notifications
                  user={user}
                  setModal={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotification={setModalNotification}
                  locationData={locationData}
                />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin-users'
            element={
              <PrivateRoute>
                <Users
                  user={user}
                  setModal={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotification={setModalNotification}
                  locationData={locationData}
                />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/cron'
            element={
              <PrivateRoute>
                <Cron
                  user={user}
                  setModal={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotification={setModalNotification}
                  locationData={locationData}
                />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/tamacoin'
            element={
              <PrivateRoute>
                <TamaCoin
                  user={user}
                  setModal={setModal}
                  profiles={profiles}
                  NotifModul={allNotifications}
                  setModalNotification={setModalNotification}
                  locationData={locationData}
                />
              </PrivateRoute>
            }
          />

        </Routes>
      </main>
      <AnimatePresence>

        {modal === 'switch' && (
          <ModalSwitch
            changePage={changePage}
            setChangePage={setChangePage}
            profiles={profiles}
            close={() => setModal(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
}