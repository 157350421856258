import React, { useState, useEffect } from "react";
import { Modal, TextInput, TextArea } from '../../../../Components';
import {
  influencerService
} from '../../../../Services';


export default function ModalChangeReq(props) {

  const [data, setData] = useState(props);
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [toastList, setToastList] = useState([]);

  // const addToast = (data) => {
  //   const newToastList = [];
  //   newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
  //   setToastList(newToastList);
  // };
  const onChange = (e, key) => {
    const val = e.target.value;
    const newData = Object.assign({}, data);
    newData[key] = val;
    setData(newData);
  };
  const onConfirm = async () => {
    try {
      const param =
        { status: "changerequest", feedback: data.feedback };

      await influencerService.upadateRevisionDetails(data.data.id, param);
      props.close();
      props.addToast({ error: false, date: new Date() });
      let filterStr = 'campaign_id=' + props.campaignId;
      let pageSize = 10;
      const res = await influencerService.getRevisionWithStatus({
        page: 1,
        pageSize,

      }, '', filterStr
      );
      if (res.meta && res.meta.pagination) {
        // props.setPaginationData(res.meta.pagination);
      }
      props.setInfEngList(res.data);
      props.setLoadList(true);
      props.setModal(null);
    } catch (err) {
      props.close();
      props.addToast({
        error: true,
        date: new Date(),
        msg: 'eepe'
      });
      props.close();
    }
  };
  return (
    <Modal addClass='modalRateConnect' close={props.close}>
      <div className='modal__rate'>
        <h6 className='mb'>
          Send change request with feedback
        </h6>
        <div className='modal__rate-link'>
          <TextArea
            label=''
            value={data.feedback}
            row={4}
            onChange={e => onChange(e, 'feedback')}
          />
        </div>
        <div className='modal__rate-btns'>
          <button className='button secondary' onClick={props.close}>
            Cancel
          </button>
          <button
            className='button primary'
            onClick={() => onConfirm()}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}
