import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { InstCardModul } from './Card/InstCardModul';
import { FacebookCardModul } from './Card/FacebookCardModul';
import { Profile, Toast } from '../../Components';
import CardRates from './Card/CardRates';
import ModalRates from './ModalRates';
import { AnimatePresence } from 'framer-motion';
import ModalConnect from './ModalConnect';
import ModalAuth from './ModalAuth';
import ModalConfirm from './ModalConfirm';
import { influencerService } from '../../Services';

export default function Rates(props) {
  const [fcbkModul, setFcbkModul] = useState([]);
  const [instModul, setInstModul] = useState([]);
  const [tiktokModul, setTiktokModul] = useState([]);
  const [youtubeModul, setyoutubeModul] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [modal, setModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [profileType, setprofileType] = useState(null);
  const [newAccounts, setNewAccounts] = useState([]);
  const [authorizeType, setAuthorizeType] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [deleteSocialType, setDeleteSocialType] = useState('');
  const [fbLink, setFbLink] = useState('');
  const [instaLink, setInstaLink] = useState('');
  const [tiktokLink, setTiktokLink] = useState('');
  const [googleLink, setGoogleLink] = useState('');
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const socialId = searchParams.get('isSocial')
    ? searchParams.get('isSocial')
    : null;

  const socialErr = searchParams.get('error')
    ? searchParams.get('error')
    : 0;
  const isNew = socialId != null ? true : false;

  const deleteConfirm = (id, type) => {
    setDeleteId(id)
    setDeleteSocialType(type)
    setModal("confirm");
  };
  const deleteFacebookCard = id => {
    setFcbkModul(fcbkModul.filter(item => item.id !== id));
    removeAccount(id);
    setModal(null);
  };
  const deleteCard = () => {
    if (deleteSocialType === 'facebook') {
      deleteFacebookCard(deleteId)
    }
    if (deleteSocialType === 'instagram') {
      deleteInstagramCard(deleteId)
    }
    if (deleteSocialType === 'tiktok') {
      deleteTiktokCard(deleteId)
    }
    if (deleteSocialType === 'google') {
      deleteGoogleCard(deleteId)
    }
  };
  const deleteTiktokCard = id => {
    setTiktokModul(tiktokModul.filter(item => item.id !== id));
    removeAccount(id);
    setModal(null);
  };

  const deleteGoogleCard = id => {
    setyoutubeModul(youtubeModul.filter(item => item.id !== id));
    removeAccount(id);
    setModal(null);
  };

  const deleteInstagramCard = id => {
    setInstModul(instModul.filter(item => item.id !== id));
    removeAccount(id);
    setModal(null);
  };
  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    if (modal) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [modal]);

  const fetchAccounts = async () => {
    try {
      const res = await influencerService.getSocialAccounts();
      setFbLink(res['facebookConnectLink']);
      setInstaLink(res['instaConnectLink']);
      setTiktokLink(res['tiktokConnectLink'])
      setGoogleLink(res['youtubeConnectLink'])
      if (res['platform'] && res['platform'].length > 0) {
        const fbAccounts = res['platform'].filter(
          item => item['socialType'] === 'Facebook'
        );
        const instaAccounts = res['platform'].filter(
          item => item['socialType'] === 'Instagram'
        );
        const tiktokAccounts = res['platform'].filter(
          item => item['socialType'] === 'TikTok'
        );
        const youtubeAccounts = res['platform'].filter(
          item => item['socialType'] === 'Youtube'
        );
        setInstModul(prepareData(instaAccounts[0]));
        setFcbkModul(prepareData(fbAccounts[0]));
        setTiktokModul(prepareData(tiktokAccounts[0]));
        setyoutubeModul(prepareData(youtubeAccounts[0]));
      }
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        date: new Date()
      });
    }
  };

  const prepareData = data => {
    if (data['handles'] && data['handles'].length === 0) {
      return [];
    }
    const res = data['handles'].map(item => {
      return {
        id: item['id'] ? item['id'] : 0,
        profileImage: item['photo'] ? item['photo'] : '',
        profileName: item['handle'] ? item['handle'] : '',
        posts: item['posts'] ? item['posts'] : 0,
        followers: item['followers'] ? item['followers'] : 0,
        following: item['following'] ? item['following'] : 0,
        profileDesc: item['bio'] ? item['bio'] : '',
        link: item['link'] ? item['link'] : '',
        rates: getProducts(item['products'])
      };
    });
    return res;
  };

  const getProducts = data => {
    const res = data.map(item => {
      return {
        id: item['id'] ? item['id'] : 0,
        rateName: item['product'] ? item['product'] : '',
        priceText: item['product_description']
          ? item['product_description']
          : '',
        price: item['rate'] ? item['rate'] : 0,
        desc: item['description'] ? item['description'] : '',
        link: item['link'] ? item['link'] : ''
      };
    });

    return res;
  };

  const fetchNewAccounts = async () => {
    try {
      const res = await influencerService.getSocialAccountsToConnect(socialId);
      setNewAccounts(res);
      setModal('connect');
    } catch (err) {
      addToast({
        error: true,
        errorText: err,
        successText: '',
        date: new Date()
      });
    }
  };

  useEffect(() => {
    fetchAccounts();
    if (isNew && socialErr == 0) {
      console.log("fffff///");
      fetchNewAccounts();
    }
    if (socialErr == 1) {
      addToast({
        error: true,
        errorText: 'Error.',
        successText: 'Account is already linked with another user.',
        msg: "Account is already linked with another user.",
        date: new Date()
      });
    }
  }, []);

  const removeAccount = async id => {
    try {
      const res = await influencerService.removeSocialAccount(id);
      addToast({
        error: false,
        errorText: 'Error.',
        successText: 'Account removed successfully.',
        msg: res['message'],
        date: new Date()
      });
    } catch (err) {
      addToast({
        error: true,
        errorText: err,
        successText: '',
        date: new Date()
      });
    }
  };

  const saveProduct = async data => {
    const payload = {
      description: data['desc'],
      product_id: data['id'],
      influencer_id: data['handleId'],
      link: data['link'],
      rate: data['price'],
      status: 1
    };
    try {
      const res = await influencerService.saveSocialAccountProduct(payload);
      props.setIsBasicStepsDone(!props.isBasicStepsDone);
      addToast({
        error: false,
        errorText: 'Error.',
        successText: res,
        msg: res,
        date: new Date()
      });
    } catch (err) {
      addToast({
        error: true,
        errorText: err,
        successText: '',
        date: new Date()
      });
    }
  };

  const updateHandler = data => {

    if (data['type'] === 'Instagram') {
      let newInsta = instModul.map(item => {
        if (item['id'] == data['handleId']) {
          let rates = item['rates'].map(el => {
            if (el['id'] == data['id']) {
              return {
                ...el,
                price: data['price'],
                link: data['link'],
                desc: data['desc']
              };
            } else {
              return el;
            }
          });
          return { ...item, rates };
        } else {
          return item;
        }
      });
      setInstModul(newInsta);
      saveProduct(data);
    }
    if (data['type'] === 'Facebook') {
      let newFb = fcbkModul.map(item => {
        if (item['id'] == data['handleId']) {
          let rates = item['rates'].map(el => {
            if (el['id'] == data['id']) {
              return {
                ...el,
                price: data['price'],
                link: data['link'],
                desc: data['desc']
              };
            } else {
              return el;
            }
          });
          return { ...item, rates };
        } else {
          return item;
        }
      });
      setFcbkModul(newFb);
      saveProduct(data);
    }

    if (data['type'] === 'TikTok') {
      let newtiktok = tiktokModul.map(item => {
        if (item['id'] == data['handleId']) {
          let rates = item['rates'].map(el => {
            if (el['id'] == data['id']) {
              return {
                ...el,
                price: data['price'],
                link: data['link'],
                desc: data['desc']
              };
            } else {
              return el;
            }
          });
          return { ...item, rates };
        } else {
          return item;
        }
      });
      setTiktokModul(newtiktok);
      saveProduct(data);
    }

    if (data['type'] === 'Youtube') {
      let newgoogle = youtubeModul.map(item => {
        if (item['id'] == data['handleId']) {
          let rates = item['rates'].map(el => {
            if (el['id'] == data['id']) {
              return {
                ...el,
                price: data['price'],
                link: data['link'],
                desc: data['desc']
              };
            } else {
              return el;
            }
          });
          return { ...item, rates };
        } else {
          return item;
        }
      });
      setyoutubeModul(newgoogle);
      saveProduct(data);
    }
  };

  const authorize = (modal, type) => {
    setAuthorizeType(type);
    const link = type === 'instagram' ? instaLink : type === 'facebook' ? fbLink : type === 'tiktok' ? tiktokLink : googleLink;
    window.location.href = link;
  };

  const onAuthorize = () => {

  };

  const onSave = async payload => {
    // API request to save selected handles.
    try {
      const res = await influencerService.saveSocialAccounts(payload);
      setModal(null);
      addToast({
        error: false,
        errorText: 'Error.',
        successText: 'Account linked successfully.',
        msg: res,
        date: new Date()
      });
      // navigate('/influencer/social_accounts');
      fetchAccounts();
    } catch (err) {
      addToast({
        error: true,
        errorText: err,
        successText: '',
        date: new Date()
      });
    }
  };

  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title rates'>
            <h2>Rate & Media Kit</h2>
            <Profile
              user={props.user}
              setModal={props.setModal}
              profiles={props.profiles}
              NotifModul={props.NotifModul}
              setNotifModul={props.setModalNotification}
            />
          </div>
          <div className='rate'>
            <div className='rate__inner'>
              <div className='rate__col'>
                <div className='rate__account'>
                  <div className='rate__account-title'>
                    <div className='rate__account-image'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/facebook.png'}
                        alt='facebook'
                      />
                    </div>
                    <h3 className='big'>Facebook</h3>
                  </div>

                  <button
                    className='button secondary'
                    onClick={() => authorize('auth', 'facebook')}
                  >
                    {'Link account'}
                  </button>
                </div>
                {fcbkModul.length > 0 && (
                  <div className='rates__cards'>
                    {fcbkModul.map((item, index) => {
                      return (
                        <CardRates
                          type='rates'
                          typeName='Facebook'
                          setModal={setModal}
                          setModalData={setModalData}
                          index={index}
                          key={index}
                          setprofileType={setprofileType}
                          deleteCard={() => deleteConfirm(item.id, 'facebook')}
                          {...item}
                        />
                      );
                    })}
                  </div>
                )}
              </div>



              <div className='rate__col'>
                <div className='rate__account'>
                  <div className='rate__account-title'>
                    <div className='rate__account-image'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/instagram.png'}
                        alt='instagram'
                      />
                    </div>
                    <h3 className='big'>Instagram</h3>
                  </div>

                  <button
                    className='button secondary'
                    onClick={() => authorize('auth', 'instagram')}
                  >
                    {'Link account'}
                  </button>
                </div>
                {instModul.length > 0 && (
                  <div className='rates__cards'>
                    {instModul.map((item, index) => {
                      return (
                        <CardRates
                          type='rates'
                          typeName='Instagram'
                          setModal={setModal}
                          setModalData={setModalData}
                          index={index}
                          key={index}
                          setprofileType={setprofileType}
                          deleteCard={() => deleteConfirm(item.id, 'instagram')}
                          {...item}
                        />
                      );
                    })}
                  </div>
                )}
              </div>






              <div className='rate__col'>
                <div className='rate__account'>
                  <div className='rate__account-title'>
                    <div className='rate__account-image'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/tiktok.png'}
                        alt='tikTok'
                      />
                    </div>
                    <h3 className='big'>TikTok</h3>
                  </div>

                  <button
                    className='button secondary'
                    onClick={() => authorize('auth', 'tiktok')}
                  >
                    {'Link account'}
                  </button>
                </div>
                {tiktokModul.length > 0 && (
                  <div className='rates__cards'>
                    {tiktokModul.map((item, index) => {
                      return (
                        <CardRates
                          type='rates'
                          typeName='TikTok'
                          setModal={setModal}
                          setModalData={setModalData}
                          index={index}
                          key={index}
                          setprofileType={setprofileType}
                          deleteCard={() => deleteConfirm(item.id, 'tiktok')}
                          {...item}
                        />
                      );
                    })}
                  </div>
                )}
              </div>

              <div className='rate__col'>
                <div className='rate__account'>
                  <div className='rate__account-title'>
                    <div className='rate__account-image'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/youtube.png'}
                        alt='youtube'
                      />
                    </div>
                    <h3 className='big'>Youtube</h3>
                  </div>

                  <button
                    className='button secondary'
                    onClick={() => authorize('auth', 'google')}
                  >
                    {'Link account'}
                  </button>
                </div>
                {youtubeModul.length > 0 && (
                  <div className='rates__cards'>
                    {youtubeModul.map((item, index) => {
                      return (
                        <CardRates
                          type='rates'
                          typeName='Youtube'
                          setModal={setModal}
                          setModalData={setModalData}
                          index={index}
                          key={index}
                          setprofileType={setprofileType}
                          deleteCard={() => deleteConfirm(item.id, 'google')}
                          {...item}
                        />
                      );
                    })}
                  </div>
                )}
              </div>


            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {modal === 'rate' && (
          <ModalRates
            addToast={addToast}
            type={profileType}
            modalData={modalData}
            close={() => setModal(null)}
            updateHandler={updateHandler}

          />
        )}
        {modal === 'connect' && (
          <ModalConnect
            close={() => setModal(null)}
            accounts={newAccounts}
            onSave={onSave}
          />
        )}
        {modal === 'auth' && (
          <ModalAuth
            setModal={setModal}
            close={() => setModal(null)}
            onAuthorize={onAuthorize}
          />
        )}
        {modal === 'confirm' && (
          <ModalConfirm
            setModal={setModal}
            close={() => setModal(null)}
            onConfirm={deleteCard}
          />
        )}
      </AnimatePresence>
      {toastList && (
        <Toast type='rate' list={toastList} setList={setToastList} />
      )}
    </>
  );
}
