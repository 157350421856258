import React from 'react';
import { motion } from 'framer-motion';
import { Card, trashIcon } from '../../../Components';

export default function CardRates(props) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 * (props.index + 1) }}
      exit={{ opacity: 0, y: 30 }}
      className={'card rates'}
    >
      <div className='card__trash' onClick={props.deleteCard}>
        {trashIcon}
      </div>
      <div className='card__title'>
        <div className='card__title-image'>
          {props.typeName === 'Facebook' && (
            <img
              src={process.env.PUBLIC_URL + '/images/facebook.png'}
              alt='facebook'
            />
          )}
          {props.typeName === 'Instagram' && (
            <img
              src={process.env.PUBLIC_URL + '/images/instagram.png'}
              alt='instagram'
            />
          )}
          {props.typeName === 'TikTok' && (
            <img
              src={process.env.PUBLIC_URL + '/images/tiktok.png'}
              alt='tiktok'
            />
          )}
          {props.typeName === 'Youtube' && (
            <img
              src={process.env.PUBLIC_URL + '/images/youtube.png'}
              alt='tiktok'
            />
          )}
        </div>
        <h3 className='big'>{props.typeName}</h3>
      </div>
      <div className='card__inner'>
        <div className='card__inner-title'>
          <div className='card__inner-title-image'>
            <img
              src={
                props.profileImage && props.profileImage !== ''
                  ? props.profileImage
                  : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
              }
              alt={props.id}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
              }}
            />
          </div>
          <div className='card__inner-title-content'>
            <h4>{props.profileName}</h4>
            <a
              target="_blank"
              href={
                props.link == '' ? '/#/influencer/social_accounts' : props.link
              }
              className='card__inner-title-content-link'
            >
              View profile
            </a>
          </div>
        </div>
        <div className='card__inner-info'>
          <div className='card__inner-info-item'>
            <h5>{props.posts}</h5>
            <p className='sm'>Posts</p>
          </div>
          <div className='card__inner-info-item'>
            <h5>{props.followers}</h5>
            <p className='sm'>Followers</p>
          </div>
          {props.typeName !== 'Facebook' &&
            <div className='card__inner-info-item'>
              <h5>{props.following}</h5>
              <p className='sm'>Following</p>
            </div>
          }
        </div>
        <div className='card__inner-desc'>
          <p>
            {props.profileDesc !== ''
              ? props.profileDesc.toString().substring(0, 86) + '... '
              : ''}
          </p>
        </div>
        <div className='card__rates'>
          <h3>Rates</h3>
          <div className='card__rates-inner'>
            {props.rates.map((item, index) => {
              return (
                <div className='card__rates-item' key={index}>
                  <h6>{item.rateName}</h6>
                  <div className='card__rates-item-info'>
                    <p>${item.price === '' ? '---' : item.price}</p>
                    <button
                      className='button'
                      onClick={() => {
                        props.setModalData({
                          ...item,
                          handleId: props.id,
                          type: props.typeName
                        });
                        props.setprofileType(props.typeName);
                        props.setModal('rate');
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
