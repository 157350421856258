export const AssetsModul = [
  {
    id: "1",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Short title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to",
    uploadDate: "12-30-2021",
    timesUsed: "12",
    campaignsUsing: [],
  },
  {
    id: "2",
    asset: "/images/video/world_scan.mp4",
    assetName: "world_scan.mp4",
    assetType: "video",
    title: "Longer test campaign title that goes two...",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to",
    uploadDate: "12-30-2021",
    timesUsed: "1",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
      {
        id: "4",
        name: "Dat brand promo",
      },
      {
        id: "5",
        name: "Dat brand promo",
      },
      {
        id: "6",
        name: "Dat brand promo",
      },
      {
        id: "7",
        name: "Dat brand promo",
      },
      ,
      {
        id: "8",
        name: "Dat brand promo",
      },
    ],
  },
  {
    id: "3",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    timesUsed: "0",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
    ],
  },
  {
    id: "4",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    timesUsed: "0",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
      {
        id: "4",
        name: "Dat brand promo",
      },
      {
        id: "5",
        name: "Dat brand promo",
      },
      {
        id: "6",
        name: "Dat brand promo",
      },
      {
        id: "7",
        name: "Dat brand promo",
      },
      ,
      {
        id: "8",
        name: "Dat brand promo",
      },
    ],
  },
  {
    id: "5",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    timesUsed: "0",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
      {
        id: "4",
        name: "Dat brand promo",
      },
      {
        id: "5",
        name: "Dat brand promo",
      },
      {
        id: "6",
        name: "Dat brand promo",
      },
      {
        id: "7",
        name: "Dat brand promo",
      },
      ,
      {
        id: "8",
        name: "Dat brand promo",
      },
    ],
  },
  {
    id: "6",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    timesUsed: "12",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
      {
        id: "4",
        name: "Dat brand promo",
      },
      {
        id: "5",
        name: "Dat brand promo",
      },
      {
        id: "6",
        name: "Dat brand promo",
      },
      {
        id: "7",
        name: "Dat brand promo",
      },
      ,
      {
        id: "8",
        name: "Dat brand promo",
      },
    ],
  },
  {
    id: "7",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    timesUsed: "1",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
      {
        id: "4",
        name: "Dat brand promo",
      },
      {
        id: "5",
        name: "Dat brand promo",
      },
      {
        id: "6",
        name: "Dat brand promo",
      },
      {
        id: "7",
        name: "Dat brand promo",
      },
      ,
      {
        id: "8",
        name: "Dat brand promo",
      },
    ],
  },
  {
    id: "8",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    timesUsed: "0",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
      {
        id: "4",
        name: "Dat brand promo",
      },
      {
        id: "5",
        name: "Dat brand promo",
      },
      {
        id: "6",
        name: "Dat brand promo",
      },
      {
        id: "7",
        name: "Dat brand promo",
      },
      ,
      {
        id: "8",
        name: "Dat brand promo",
      },
    ],
  },
  {
    id: "9",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    timesUsed: "0",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
      {
        id: "4",
        name: "Dat brand promo",
      },
      {
        id: "5",
        name: "Dat brand promo",
      },
      {
        id: "6",
        name: "Dat brand promo",
      },
      {
        id: "7",
        name: "Dat brand promo",
      },
      ,
      {
        id: "8",
        name: "Dat brand promo",
      },
    ],
  },
  {
    id: "10",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    timesUsed: "0",
    campaignsUsing: [
      {
        id: "1",
        name: "Dat brand promo",
      },
      {
        id: "2",
        name: "Dat brand promo",
      },
      {
        id: "3",
        name: "Dat brand promo",
      },
      {
        id: "4",
        name: "Dat brand promo",
      },
      {
        id: "5",
        name: "Dat brand promo",
      },
      {
        id: "6",
        name: "Dat brand promo",
      },
      {
        id: "7",
        name: "Dat brand promo",
      },
      ,
      {
        id: "8",
        name: "Dat brand promo",
      },
    ],
  },
];
export const DelAssetsModul = [
  {
    id: "1",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Short title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
    notif: true,
  },
  {
    id: "2",
    asset: "/images/video/world_scan.mp4",
    assetName: "world_scan.mp4",
    assetType: "video",
    title: "Longer test campaign title that goes two...",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to Logo of our supporting brand that we are promoting to",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
  {
    id: "3",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
  {
    id: "4",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
  {
    id: "5",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
  {
    id: "6",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
  {
    id: "7",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
  {
    id: "8",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
  {
    id: "9",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
  {
    id: "10",
    asset: "/images/placeholder.jpg",
    assetName: "placeholder.jpg",
    assetType: "image",
    title: "Longer test campaign title",
    titleModal: "Title",
    desc: "Logo of our supporting brand that we are promoting to ...",
    uploadDate: "12-30-2021",
    deletedOn: "12-30-2021",
  },
];
