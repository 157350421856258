import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { arrowRight, externalLink, externalLinkBlack } from './SVG';
import { Link } from 'react-router-dom'

import { influencerService, accountService } from '../../Services';
export default function Sidebar({ close, menu, user, type, newNavigation, isBasicStepsDone, impersonationBar, handleAnchorClick }) {

  const location = useLocation(); // React Hook
  const [path, setPath] = useState(location.pathname);
  const [isPaymentSet, setIsPaymentSet] = useState(false);
  const [isRateSet, setIsRateSet] = useState(false);
  const [isSocialAccountLinked, setIsSocialAccountLinked] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [toastList, setToastList] = useState([]);
  useEffect(() => {
    setPath(location.pathname)

  }, [location.pathname]);

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  useEffect(() => {
    const checkStatus = async () => {
      try {
        const res = await influencerService.getProfileStatus();
        res.profilestatus.payee == 1 ? setIsPaymentSet(true) : setIsPaymentSet(false);
        res.profilestatus.profile == 1 ? setIsProfileComplete(true) : setIsProfileComplete(false);
        res.profilestatus.linkaccount == 1 ? setIsSocialAccountLinked(true) : setIsSocialAccountLinked(false);
        res.profilestatus.rate == 1 ? setIsRateSet(true) : setIsRateSet(false);
      } catch (err) {
        addToast({
          error: true,
          errorText: err,
          successText: '',
          date: new Date()
        });
      }
    }
    checkStatus();

  }, [isBasicStepsDone]);

  return (
    <div
      className={
        (type === 'brand' ? 'sidebar brand ' : 'sidebar ') +
        (menu ? 'active' : '') +
        (impersonationBar ? ' impersonationDivMargin' : '')
      }
      onClick={close}
    >
      <div className='sidebar__inner'>
        <NavLink to='/' className='sidebar__inner-logo'>
          <img
            src={
              process.env.PUBLIC_URL +
              (type === 'brand'
                ? '/images/logo-white-text.png'
                : '/images/logo.png')
            }
            alt='logo'
          />
        </NavLink>
        <div className='sidebar__inner-content'>
          <div className='nav'>
            {type === 'brand' ? (
              <div className='nav__inner'>
                <NavLink to='/brand/dashboard' onClick={handleAnchorClick} className='nav__inner-brand'>
                  Dashboard
                </NavLink>
                <NavLink
                  to='/brand/recommendations'
                  className='nav__inner-brand'
                  onClick={handleAnchorClick}
                >
                  Recommendations
                </NavLink>
                {/* <NavLink to='/brand/insights' className='nav__inner-brand'>
                  Insights
            </NavLink>*/}
                <NavLink to='/brand/campaigns' className='nav__inner-brand'>
                  Campaigns {newNavigation.campaigns_count !== 0 && <span className='pill active'>Active</span>}
                </NavLink>
                <NavLink to='/brand/assets' onClick={handleAnchorClick} className='nav__inner-brand'>
                  Assets
                </NavLink>
                <NavLink to='/brand/billing' onClick={handleAnchorClick} className='nav__inner-brand'>
                  Billing
                </NavLink>
              </div >
            ) : type === 'influencer' ? (
              <div className='nav__inner'>
                <NavLink to='/influencer/dashboard' className='nav__inner-link'>
                  Dashboard
                </NavLink>
                <NavLink
                  to='/influencer/social_accounts'
                  className='nav__inner-link'
                >
                  Rates & Media Kits
                </NavLink>
                <NavLink
                  to='/influencer/opportunities'
                  className='nav__inner-link'
                >
                  Opportunities  {newNavigation.campaigns_count !== 0 && <span className='pill new'>New</span>}
                </NavLink>
                <NavLink
                  to='/influencer/collaborations'
                  className='nav__inner-link'
                >
                  Collaborations  {newNavigation.collaboration_count !== 0 && <span className='pill active'>Active</span>}
                </NavLink>
                <NavLink to='/influencer/payment' className='nav__inner-link'>
                  Payment History
                </NavLink>
                <NavLink to='/influencer/refer_friend' className='nav__inner-link'>
                  Refer a Friend
                </NavLink>
                <NavLink to='/influencer/tamacoin' className='nav__inner-link'>
                  TAMA Coins
                </NavLink>
                <NavLink to='https://blog.tamayou.com/' className='nav__inner-link' target="_blank">
                  TAMA Blog <span className="externalLinkBlog">{externalLinkBlack}</span>
                </NavLink>

              </div>
            ) : (
              <div className='nav__inner'>
                <NavLink to='/admin/dashboard' onClick={handleAnchorClick} className='nav__inner-admin'>
                  Home
                </NavLink>
                <NavLink to='/admin/profile' onClick={handleAnchorClick} className='nav__inner-admin'>
                  Profile
                </NavLink>
                <NavLink to='/admin/content' onClick={handleAnchorClick} className='nav__inner-admin'>
                  Content
                </NavLink>
                <NavLink to='/admin/campaigns' onClick={handleAnchorClick} className='nav__inner-admin'>
                  Campaigns {newNavigation.campaigns_count !== 0 && <span className='pill active'>Active</span>}
                </NavLink>
                <NavLink to='/admin/postverification' onClick={handleAnchorClick} className='nav__inner-admin'>
                  Post Verification
                </NavLink>
                <NavLink to='/admin/notifications' onClick={handleAnchorClick} className='nav__inner-admin'>
                  Notifications
                </NavLink>
                <NavLink to='/admin/admin-users' onClick={handleAnchorClick} className='nav__inner-admin'>
                  Users
                </NavLink>
                <NavLink
                  to='/admin/admin-custom-url'
                  className='nav__inner-admin'
                  onClick={handleAnchorClick}
                >
                  SignUp Url
                </NavLink>
                <NavLink to='/admin/tamacoin' className='nav__inner-admin' onClick={handleAnchorClick}>
                  TamaCoins
                </NavLink>
                <NavLink to='/admin/cron' className='nav__inner-admin' onClick={handleAnchorClick}>
                  Cron
                </NavLink>
              </div>
            )
            }
          </div >
          <div className='sidebar__info'>
            <div

              className={
                (' sidebar__info-box  pulse2 sidebar__info-box') +
                ((type === 'influencer' &&
                  (!isSocialAccountLinked || !isRateSet || !isProfileComplete || !isPaymentSet)) ? ' pulse2 ' : ' ') +

                (type === 'brand' ? ' pulsebrandBrand ' : type === 'admin' ? ' pulsebrandAdmin' : '')
              }>
              {(path != '/brand/campaigns') ?
                <>
                  <div className='sidebar__info-box-inner'>
                    <h5>Don’t miss out!</h5>
                    <p className='sm'>
                      {(type === 'brand') ? <>Be sure to complete your profile .</> : (type === 'admin') ? <>Be sure to complete your profile so you don’t miss out on any opportunities.</>
                        : <>
                          <ol className="sideBarBox">
                            <li className='sideBarBoxLink'><Link to="/influencer/social_accounts">Link social account
                              {!isSocialAccountLinked ? arrowRight : <input
                                checked='checked'
                                type='checkbox'
                                readOnly='readonly'
                              />}</Link></li>
                            <li className='sideBarBoxLink'><Link to="/influencer/social_accounts">Set your rates
                              {!isRateSet ? arrowRight : <input
                                checked='checked'
                                type='checkbox'
                                readOnly='readonly'
                              />}</Link></li>
                            <li className='sideBarBoxLink'><Link to="/influencer/profile">Complete your profile
                              {!isProfileComplete ? arrowRight : <input
                                checked='checked'
                                type='checkbox'
                                readOnly='readonly'
                              />}


                            </Link></li>
                            <li className='sideBarBoxLink'><Link to="/influencer/payment">Get paid setup
                              {!isPaymentSet ? arrowRight : <input
                                checked='checked'
                                type='checkbox'
                                readOnly='readonly'
                              />}</Link></li>
                          </ol></>}
                    </p>
                  </div>
                  {(type === 'brand' || type === 'admin') &&
                    <button className='button uniq'>
                      <Link className="button uniq" to={type === 'brand'
                        ? "/brand/profile"
                        : type === 'influencer' ? '/influencer/profile' : '/admin/profile'} >

                        Complete Now {arrowRight}</Link>
                    </button>
                  }
                </> : <>
                  <div className='sidebar__info-box-inner'>
                    <h5><img src={
                      process.env.PUBLIC_URL + '/images/light-bulb.png'
                    }></img> Tips for Quick Campaign Creation</h5>
                    <p className='sm'>
                      <ol>
                        <li>Make sure ALL fields are completed before you hit publish.</li>
                        <li>Ensure that each hashtag is separated by commas. They are then added by clicking on the “+” Sign.</li>
                        <li>If you are unsure about a question, add all the information you do have, save as draft and contact us.</li>
                      </ol>
                    </p>
                  </div>
                </>}

            </div>
            <div className='sidebar__info-copy'>
              <div>
                <a className="sidebar__info-copy-link" target="_blank" href="https://tamayou.com/tama-privacy-policy/">Privacy</a>
                <a className="sidebar__info-copy-link" target="_blank" href="https://tamayou.com/terms/" style={{ paddingLeft: '20px' }} >Terms</a>
                <a className="sidebar__info-copy-link" target="_blank" href="https://tamayou.com/" style={{ paddingLeft: '20px' }} >Corp Site
                  <span className="externalLink"> {(type === 'brand') ? externalLink : externalLinkBlack} </span>  </a>

              </div>
              <p className='sm'>Copyright {new Date().getFullYear()} © TamaYou</p>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
}
