import { fetchWrapper } from '../Helpers';

export const uploadService = {
  getFiles,
  upload
};

function upload(file, onUploadProgress, isAsset = false) {
  let formData = new FormData();

  formData.append('file_name', file);
  formData.append('visibility', 'public');
  formData.append('is_thumbnail', 1);
  return fetchWrapper.postFile(
    isAsset ? '/file/uploadAssets' : '/file/uploads',
    formData,
    onUploadProgress
  );
}

function getFiles() {
  return fetchWrapper.get('/files');
}
