import React, { useEffect, useState } from "react";
import {
  Modal,
  arrowRight,
  chevronBottom,
  trashIcon,
} from "../../Components";
import AssetVideo from "./AssetVideo";
export default function ModalAssetDetail(props) {
  const [showMore, setShowMore] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [modul, setModul] = useState(props.campaignsUsing.slice(0, 3));
  const [tabModal, setTabModal] = useState("main");
  useEffect(() => {
    if (viewAll) {
      setModul(props.campaignsUsing);
    } else {
      setModul(props.campaignsUsing.slice(0, 3));
    }
  }, [viewAll]);
  return (
    <Modal addClass="modalAsset" close={props.close}>
      <div className="modal__asset">
        <div className="modal__asset-inner">
          {tabModal === "main" && (
            <div className="modal__assetDetail">
              <button
                className="button tertiary big grey"
                onClick={() => setTabModal("delete")}
              >
                {trashIcon} Delete
              </button>
              <div
                className="modal__assetDetail-media"
                onClick={props.mediaClick}
              >
                <div className="modal__assetDetail-media-outer">
                  <div className="modal__assetDetail-media-inner">
                    {props.media_type_id === 2 && (
                      <img src={process.env.PUBLIC_URL + props.thumbnail} alt={props.title} />
                    )}
                    {props.media_type_id === 1 && (
                      <video src={process.env.PUBLIC_URL + props.mediafile}></video>
                    )}
                  </div>
                </div>

                <div className="modal__assetDetail-media-content">
                  <div className="modal__assetDetail-media-content-item">
                    <h6>File type</h6>
                    <p>{props.file_type && props.file_type.toUpperCase()}</p>
                  </div>
                  <div className="modal__assetDetail-media-content-item">
                    <h6>File Size</h6>
                    <p>{props.file_size && (props.file_size / 1048576).toFixed(2)} MB</p>
                  </div>
                  {props.assetType === "video" && (
                    <div className="modal__assetDetail-media-content-item">
                      <h6>Resolution:</h6>
                      <p>150x150</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal__assetDetail-content">
                <h5>Title</h5>
                <h3>{props.title}</h3>
                <div className="modal__assetDetail-content-desc">
                  <h5>Description</h5>
                  <p>
                    {showMore
                      ? props.description
                      : props.description.toString().substring(0, 305) + "..."}
                    {props.description && props.description.length > 305 && (
                      <button
                        type="button"
                        className="button tertiary"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </button>
                    )}
                  </p>
                </div>
                <div className="modal__assetDetail-content-date">
                  <h6>Upload date:</h6>
                  <p>{props.created_at}</p>
                </div>
                <div className="modal__assetDetail-campaign">
                  <h5 className={props.campaigns.length <= 0 ? "mb" : ""}>
                    Campaigns using this asset:{" "}
                    <span>{props.campaignsUsing.length <= 0 && "None"}</span>
                  </h5>
                  {props.campaignsUsing.length > 0 && (
                    <>
                      <div className="modal__assetDetail-campaign-items">
                        {modul.map((item, index) => {
                          return (
                            <div
                              className="modal__assetDetail-campaign-item"
                              key={index}
                            >
                              <h6>{item.title}</h6>
                              <button className="button tertiary big right">
                                View campaign details {arrowRight}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <div className="modal__assetDetail-campaign-view">
                        <button
                          className={
                            "button tertiary big right " +
                            (viewAll ? "active" : "")
                          }
                          onClick={() => setViewAll(!viewAll)}
                        >
                          {viewAll ? "View less" : "View all"}
                          {chevronBottom}
                        </button>
                        {!viewAll && (
                          <p className="sm">
                            And {props.campaignsUsing.length} others
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  <button
                    className="button secondary"
                    onClick={props.editBtnClick}
                  >
                    Edit asset
                  </button>
                </div>
              </div>
            </div>
          )}
          {tabModal === "delete" && (
            <div className="modal__assetDetail-delete">
              <h4>Are you sure you want to delete this asset?</h4>
              <div className="modal__assetDetail-delete-btns">
                <button
                  className="button secondary"
                  onClick={() => setTabModal("main")}
                >
                  Cancel
                </button>
                <button className="button primary" onClick={props.deleteAsset}>
                  Delete asset
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
