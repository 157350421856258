import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { accountService, alertService } from './Services';
import { utils } from './Helpers/utils';
import { useSearchParams } from 'react-router-dom';
function SocialCallback({ match }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [hash, query] = window.location.href.split('#')[1].split('?');
  const params = Object.fromEntries(new URLSearchParams(query));
  const token = params['token'];
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("ir...." + searchParams.get('referalPath'));
  const referPageUrl = searchParams.get('referalPath');
  useEffect(() => {
    accountService
      .loginViaToken(token)
      .then((res) => {
        let arrPath = [];
        console.log(referPageUrl);
        if (referPageUrl !== null && referPageUrl !== undefined) {
          arrPath = referPageUrl.split('/');
        }
        console.log(arrPath);
        console.log(res);
        if (res['data']['roles'][0] && res['data']['roles'][0]['id'] === 1 && res['data']['active_role'] === 1) {
          //redirect to landing page.
          let urlToredirect = '';
        
          if (arrPath.length > 0 ) {
           if(arrPath[1] === 'influencer'){
            urlToredirect = (referPageUrl + '?sociallogin=true');
           }else{
            urlToredirect = utils.redirectUrl(res.profilestatus)[0] + '?sociallogin=true';
           }
            
          } else {
            console.log("out");
            urlToredirect = utils.redirectUrl(res.profilestatus)[0] + '?sociallogin=true';
          }
          console.log(urlToredirect);
          navigate(urlToredirect);
          //navigate('/influencer/dashboard?sociallogin=true');
        } else if (
          res['data']['role'] &&
          res['data']['role'] === 'advertiser' &&
          res['data']['active_role'] === 3
        ) {
          if (arrPath.length > 0 && arrPath[1] === 'brand') {
            navigate(referPageUrl + '?sociallogin=true');
          } else {
            navigate('/brand/dashboard?sociallogin=true');
          }
          //navigate('/brand/dashboard?sociallogin=true');
        } else if (
          res['data']['role'] &&
          res['data']['role'] === 'admin' &&
          res['data']['active_role'] === 2
        ) {
          if (arrPath.length > 0 && arrPath[1] === 'admin') {
            navigate(referPageUrl + '?sociallogin=true');
          } else {
            navigate('/admin/dashboard?sociallogin=true');
          }
          // navigate('/admin/dashboard?sociallogin=true');
        } else {
          navigate('/account/login');
        }

      })
      .catch(error => {
        alertService.error(error);
      });
  }, [token]);

  return null;
}

export { SocialCallback };
