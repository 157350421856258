import React from "react";
import { utils } from '../../Helpers/utils';
export default function NotifMainItem(props) {
  return (
    <tr onClick={props.onClick}>      
      <td>
        {props.id}
      </td>
      <td>
        <p>{props.title}</p>
      </td>
      <td>
        <p>{props.text
                  ? utils.convertJsontoString(props.text)
                  : ''}</p>
      </td>
      {props.tab==='main' &&
      <td className="bold gray">{props.assigned} Users</td>}
      <td>{props.date}</td>
      <td>
        <div className={"admnotif__table-status " + props.status}>
          {props.status}
        </div>
      </td>
    </tr>
  );
}
