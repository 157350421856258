import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal';
import { accountService } from '../../../Services';

export default function ModalSwitch(props) {
  const navigate = useNavigate();

  const clickHandler = (type, id) => {
    // props.setChangePage(type);
    if (type === 'advertiser') {
      switchProfile(id, 'brand');
    }
    if (type === 'influencer') {
      switchProfile(id, 'influencer');
    }
    if (type === 'admin') {
      switchProfile(id, 'admin');
    }
  };

  async function switchProfile(id, type) {
    try {
      const res = await accountService.switchProfile(
        {
          role_id: id
        },
        () => {
          window.location.href = `/#/${type}/dashboard`;
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal addClass='modalFunds' close={props.close}>
      <div className='modal__swap'>
        <h6>Select the account you would like to switch to.</h6>
        <div className='modal__swap-profiles'>
          {props.profiles.map((item, index) => {
            return (
              <div key={index} className='modal__swap-profile'>
                <div className='modal__swap-profile-content'>
                  <div className='modal__swap-profile-content-image'>
                    <img
                      style={{ width: '48px', height: '48px' }}
                      src={
                        item.image && item.image !== ''
                          ? item.image
                          : `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = `${process.env.PUBLIC_URL}/images/placeholder/avatar.png`;
                      }}
                      alt='avatar'
                    />
                  </div>
                  <div className='modal__swap-profile-content-info'>
                    <p className='sm'>{item.title}</p>
                    <p className='sm'>{item.subTitle}</p>
                  </div>
                </div>
                {item.active ? (
                  <button className='button secondary' onClick={props.close}>
                    Switch
                  </button>
                ) : (
                  <button
                    className='button primary'
                    onClick={() => clickHandler(item.type, item.id)}
                  >
                    Switch
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
