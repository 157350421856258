import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { TextInput } from "../../../Components";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorInput } from "../../../Components";
export default function DetailsSystem({
  setInput,
  form,
  updateForm,
  error,
  type,
}) {
  const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const NotifRef1 = useRef(null);
  const NotifRef2 = useRef(null);
  const [monthText, setMonthText] = useState(month[new Date().getMonth()]);
  const [editorState, setEditorState] = useState(
    form.text !== ""
      ? EditorState.createWithContent(convertFromRaw(form.text))
      : undefined
  );

  const updateText = (value) => {
    updateForm({ text: value });
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    updateText(convertToRaw(editorState.getCurrentContent()));
  };

  useEffect(() => {
    if (NotifRef1.current && form.text !== "")
      NotifRef1.current.innerHTML = draftToHtml(form.text);
    if (NotifRef2.current && form.text !== "")
      NotifRef2.current.innerHTML = draftToHtml(form.text);
  }, [form.text]);
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0, y: 30 }}
      className="admnotif__form"
    >
      <div className="admnotif__formDetail">
        <div className="admnotif__formDetail-title">
          <h3 className="big">Details</h3>
        </div>
        <TextInput
          value={form.title}
          type="text"
          onChange={setInput("title")}
          label="Title"
          error={error}
          errorText="Please enter title"
        />
        <div className="input__outer">
          <label>Description (Max length 1500 Characters)</label>
          <div
            className={
              "input " +
              (error && form?.text?.blocks[0]?.text === "" ? "error" : "")
            }
          >
            <EditorInput
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                options: ["inline", "list"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
            />
          </div>
          {error && form?.text?.blocks[0]?.text === "" && (
            <div className="input__error">Please enter text</div>
          )}
        </div>
      </div>
      <div
        className={
          "admnotif__formPreview sys " + (type === "payment" ? "paym" : "")
        }
      >
        <div className="admnotif__formPreview-title">
          <h3 className="big">Preview</h3>
        </div>
        <div className="admnotif__formPreview-inner">
          <div className={"notificationItem mod"}>
            <div className="notificationItem__image">
              {type === "opportunity" && (
                <img
                  src={process.env.PUBLIC_URL + "/images/notification/3.svg"}
                />
              )}
              {type === "payment" && (
                <img
                  src={process.env.PUBLIC_URL + "/images/notification/4.svg"}
                />
              )}
            </div>
            <div
              className={
                "notificationItem__content " + (type === "payment" ? "pb" : "")
              }
            >
              <div className="notificationItem__content-title">
                <h6>{form.title}</h6>
                <p className="sm">{monthText}.  {new Date().getDate()}, {new Date().getFullYear()}</p>
              </div>
              <div className="ellipsis" ref={NotifRef1}></div>
              {type === "payment" && (
                <div className="notificationItem__content-btns">
                  <button className="button primary">View payment</button>
                  <button className="button secondary">Delete</button>
                </div>
              )}
            </div>
          </div>
          <div className="modal__notification-item">
            <div className="modal__notification-item-image">
              {type === "opportunity" && (
                <img
                  src={process.env.PUBLIC_URL + "/images/notification/3.svg"}
                />
              )}
              {type === "payment" && (
                <img
                  src={process.env.PUBLIC_URL + "/images/notification/4.svg"}
                />
              )}
            </div>
            <h3>[Brand Name]</h3>
            <div className="ellipsis" ref={NotifRef2}></div>
            {type === "payment" && (
              <button className="button primary">View payment</button>
            )}
            {type === "opportunity" && (
              <button className="button primary">View details</button>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
