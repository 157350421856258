import React, { useState, useEffect } from "react";
import { SearchInput, SelectInput, Pagination } from "../../Components";
import {
  arrowTop,
  download,
  plusIcon,
  searchIcon,
  arrowRight
} from "../../Components/";
import { engagementModul } from "./CampaignsModul";
import EngagementTableItem from "./EngagementTableItem";
import MoneyBlock from "./MoneyBlock";
import GoodsBlock from "./GoodsBlock";
import InsigntBlock from "./InsigntBlock";

import {
  engagementService
} from '../../Services';
import { utils } from "../../Helpers/utils";
const selectDescList = [
  { id: "1", value: "All campaigns" },
  { id: "2", value: "Title" },
  { id: "3", value: "Description" },
  { id: "4", value: "Start date" },
  { id: "5", value: "End date" },
  { id: "6", value: "Budget" },
];
export default function CampaignsDetail({ setTab, tabData, setEngagementId, setEngagementOverview }) {
  const [selectDesc, setselectDesc] = useState(selectDescList[0].value);
  const changeMediumDesc = (item) => {
    setselectDesc(item.value);
  };
  const [toastList, setToastList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({});
  const [isloaded, setIsLoaded] = React.useState(false);
  const [engagementData, setEngagementData] = useState([]);
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const onPaginate = page => {
    setPage(page);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await engagementService.getByCampaignId({
          page: page,
          pageSize,
        }, tabData.id);
        if (
          res.meta &&
          res.meta.pagination
        ) {
          setPaginationData(res.meta.pagination);
        }
        // setRowCount((res.data.length));
        setEngagementData(res.data)
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchData();
  }, [page]);
  const downloadCsv = async () => {
    try {
      const response = await engagementService.getByCampaignId({ page: page, pageSize: pageSize, fileType: 'csv' }, tabData.id);
      utils.downloadFile(response, "adminEngagement_" + page + ".csv");

    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  return (
    <div className="engagement">
      {tabData.campaigns_type_id !== 3 ?
        <div className="engagement__row">
          <div className="engagement__col withReportButton">
            <h3 className="big">Description
              <button
                className="reportButton"
                onClick={() => setTab("viewCampaign")}
              >
                View Campaign
              </button></h3>
            <div className="engagement__col-desc">
              <p className="hideToolbar">
                {tabData.description ?
                  <span>
                    {utils.draftJsFormatingForDetails({ 'data': tabData.description, 'view': 'fullview' })}
                  </span> : 'NA'}
              </p>
            </div>
          </div>
          {tabData.campaigns_type_id === 1 ?
            <div className="engagement__col">
              <MoneyBlock tabData={tabData} title="Details" />
            </div>
            :
            <div className="engagement__col withReportButton">
              <GoodsBlock tabData={tabData} title="Details"> </GoodsBlock>
            </div>
          }
          <div className="engagement__col withReportButton">
            <InsigntBlock tabData={tabData} setTab={setTab}></InsigntBlock>
          </div>
        </div> :
        <>
          <div className="engagement__row">
            <div className="engagement__col withReportButton completeBlock">
              <h3 className="big">Description
                <button
                  className="reportButton"
                  onClick={() => setTab("viewCampaign")}
                >
                  View Campaign
                </button></h3>
              <div className="engagement__col-desc">
                <p className="hideToolbar">
                  {tabData.description ?
                    <span>
                      {utils.draftJsFormatingForDetails({ 'data': tabData.description, 'view': 'fullview' })}
                    </span> : 'NA'}
                </p>
              </div>
            </div>
          </div>
          <div className="engagement__row">
            <div className="engagement__col">
              <MoneyBlock tabData={tabData} title="Money" />
            </div>
            <div className="engagement__col withReportButton">
              <GoodsBlock tabData={tabData} title="Goods"> </GoodsBlock>
            </div>
            <div className="engagement__col withReportButton">
              <InsigntBlock tabData={tabData} setTab={setTab}></InsigntBlock>
            </div>
          </div>
        </>
      }
      <div className="engagement__table-title">
        <div className="engagement__table-title-info">
          <h4>Engagements</h4>
          {tabData.status === 'active' &&
            <button
              className="button tertiary big"
              onClick={() => setTab("create")}
            >
              Add engagement {plusIcon}
            </button>}
        </div>
        <div className="engagement__table-title-inner">
          {/*<SelectInput
            list={selectDescList}
            selected={selectDescList[0]}
            onChange={changeMediumDesc}
          />
  <SearchInput type="text" icon={searchIcon} placeholder="Search" />*/}
        </div>
      </div>
      <>
        <div className="engagement__table">
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Title</th>
                <th>
                  <div className="engagement__table-filter">
                    Start date {arrowTop}
                  </div>
                </th>
                <th>End date</th>
                <th>Budget</th>
                <th>Views</th>
                <th>Reach</th>
                <th>Likes</th>
                <th>Shares</th>
                <th>Engmnt (%)</th>
                <th>Status</th>
                <th>Revision</th>
              </tr>
            </thead>
            <tbody>
              {engagementData.map((item, index) => {
                return (
                  <EngagementTableItem
                    onClick={() => {
                      console.log(item);
                      if (item.status === "draft") { setEngagementId(item.id); setTab("edit"); }
                      if (item.status === "active" || item.status === "complete") { setEngagementOverview(item); setEngagementId(item.id); setTab("engInsights") }

                    }}
                    key={index}
                    {...item}
                    setTab={setTab}
                    setEngagementId={setEngagementId}

                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </>
      <div className="engagement__table-footer">
        <button className="button tertiary download" onClick={downloadCsv}>
          Download CSV {download}
        </button>
        <Pagination
          perPage={paginationData['per_page']}
          total={paginationData['total']}
          currentPage={paginationData['current_page']}
          totalPages={paginationData['total_pages']}
          onPaginate={onPaginate}
        />
      </div>
    </div >
  );
}
