import React, { useState } from "react";
import {SearchInput} from "../../../Components";
import {SelectInput} from "../../../Components";
import { download, searchIcon } from "../../../Components";
import {Pagination} from "../../../Components";
import PrevSentItem from "./PrevSentItem";
import { PrevSentModul } from "../PrevSentModul";

const selectDescList = [
  { id: "1", value: "Name" },
  { id: "2", value: "Handle" },
  { id: "3", value: "Email" },
  { id: "4", value: "Role" },
  { id: "5", value: "Date sent" },
  { id: "5", value: "Status" },
];
export default function PrevSent({prevSentUser}) {
  const [selectDesc, setselectDesc] = useState(selectDescList[0].value);
  const [allPrevSentUser, setAllPrevSentUser] = useState(prevSentUser ? prevSentUser.length >0 ? prevSentUser :[] :[]);
  const changeMediumDesc = (item) => {
    setselectDesc(item.value);
  };
  return (
    <div className="prevsent">
      <div className="prevsent__table-title">
        <h3 className="big">Previously sent to</h3>
        <div className="prevsent__table-title-inner">
          <SelectInput
            list={selectDescList}
            selected={selectDescList[0]}
            onChange={changeMediumDesc}
          />
          <SearchInput type="text" icon={searchIcon} placeholder="Search" />
        </div>
      </div>

      <div className="prevsent__table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Handle</th>
              <th>Email</th>
              <th>Role</th>
              <th>Date sent</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {allPrevSentUser.map((item, index) => {
              return <PrevSentItem key={index} {...item} />;
            })}
          </tbody>
        </table>
      </div>

      <div className="prevsent__table-footer">
        <button className="button tertiary download">
          Download CSV {download}
        </button>
        <Pagination viewText="Viewing 1-10 of 15" />
      </div>
    </div>
  );
}
