import React from 'react';
import { Modal } from '../../Components';

export default function ModalConfirm(props) {
  return (
    <Modal addClass='modalRateConnect' close={props.close}>
      <div className='modal__rate'>
        <h6 className='mb'>
          Are you sure you want to unlink account?
        </h6>
        <div className='modal__rate-btns'>
          <button className='button secondary' onClick={props.close}>
            Cancel
          </button>
          <button
            className='button primary'
            onClick={() => props.onConfirm()}
          >
            Unlink
          </button>
        </div>
      </div>
    </Modal>
  );
}
