import React, { useState, useEffect } from "react";
import { Toast } from "../../Components";
import { Profile } from "../../Components";
import NotifMain from "./NotifMain";
import NotifSystem from "./NotifSystem";
import NotifAdd from "./NotifAdd/NotifAdd";
import NotifAddSystem from "./NotifAdd/NotifAddSystem";
import { arrowLeft } from "../../Components";
import NotifEdit from "./NotifAdd/NotifEdit";
import NotifEditSystem from "./NotifAdd/NotifEditSystem";
import {
  notificationService
} from '../../Services';
import { utils } from "../../Helpers/utils";
export default function Notif({ setAuth, setModal, NotifModul, account, profiles, setModalNotification, locationData }) {
  const [tab, setTab] = useState("main");
  const [isloaded, setIsLoaded] = React.useState(false);
  const [isUserLoaded, setIsUserLoaded] = React.useState(false);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({});
  const [allusers, setAllUsers] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [systemNotificationData, setSystemNotificationData] = useState([]);
  const [tabData, setTabData] = useState({});
  const [selectDesc, setselectDesc] = useState("title");
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState(false);
  const changeMediumDesc = (item) => {
    setselectDesc(item.id)
    setPage(1);
    setSearchText("");
    setSearchList(!searchList);
  };
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        let typeVal = tab === 'main' ? 'manual' : 'system';
        let filter = 'filter[type]=' + typeVal;
        if (searchText !== '') {
          if (filter != "") {
            filter += '&filter[' + selectDesc + ']=' + searchText;
          } else {
            filter = 'filter[' + selectDesc + ']=' + searchText;
          }
        }
        const res = await notificationService.getAll({
          page: page,
          pageSize: pageSize,
          filter
        });
        if (res.meta && res.meta.pagination) {
          setPaginationData(res.meta.pagination);
        }
        setNotificationData(res.data ? res.data : []);
        setSystemNotificationData(res.data ? res.data : []);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    async function fetchUsers() {
      setIsUserLoaded(false);
      try {
        let id = '';
        if (tab === "add") {
          id = "";
        } else {
          if (tabData.status === "active") {
            id = tabData.id;
          }
        }
        const res = await notificationService.getAllUsers(id);
        setAllUsers(res);
        setIsUserLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    if (tab !== "add" && tab !== "edit")
      fetchData();
    if (tab === "add" || tab === "edit")
      fetchUsers();
  }, [tab, page, searchList]);
  const downloadCsv = async () => {
    try {
      let typeVal = tab === 'main' ? 'manual' : 'system';
      let filter = 'filter[type]=' + typeVal;
      if (searchText !== '') {
        if (filter != "") {
          filter += '&filter[' + selectDesc + ']=' + searchText;
        } else {
          filter = 'filter[' + selectDesc + ']=' + searchText;
        }
      }
      const response = await notificationService.getAll({ page: page, pageSize: pageSize, filter: filter, fileType: 'csv' });
      utils.downloadFile(response, "adminNotification_" + page + ".csv");

    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const onPaginate = page => {
    setPage(page);
  };
  async function deleteNotification() {
    try {
      const res = await notificationService.delete(tabData.id);
      addToast({
        error: false,
        msg: "Notification deleted",
        id: `${Date.now()}-${toastList.length}`
      });
      setTab("main");
      setModal(null)
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const setSearchTextOnChange = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchText(event.target.value);
  };
  useEffect(() => {
    setTab("main");
    setselectDesc("title")
    setPage(1);
    setSearchText("");
  }, [locationData.key]);
  return (
    <>
      {isloaded &&
        <div className="main">
          <div className="main__inner">
            <div className="main__inner-title">
              {tab === "main" && <h2>Notifications</h2>}
              {tab === "system" && <h2>System Notifications</h2>}
              {(tab === "add") && (
                <div className="main__inner-title-col">
                  <div className="main__inner-title-row">
                    <h2>New Notification</h2>
                    <div className={"main__inner-title-status draft"}>Draft</div>
                  </div>
                  <button
                    className="button tertiary big"
                    onClick={() => setTab("main")}
                  >
                    {arrowLeft} Back
                  </button>
                </div>
              )}
              {(tab === "edit") && (
                <div className="main__inner-title-col">
                  <div className="main__inner-title-row">
                    <h2>New notification</h2>
                    <div className={"main__inner-title-status " + tabData.status}>
                      {tabData.status}
                    </div>
                  </div>
                  <button
                    className="button tertiary big"
                    onClick={() => setTab("main")}
                  >
                    {arrowLeft} Back
                  </button>
                </div>
              )}
              {tab === "systemAdd" && (
                <div className="main__inner-title-col">
                  <div className="main__inner-title-row">
                    <h2>System Notifications</h2>
                    <div className={"main__inner-title-status active"}>
                      active
                    </div>
                  </div>
                  <button
                    className="button tertiary big"
                    onClick={() => setTab("system")}
                  >
                    {arrowLeft} Back
                  </button>
                </div>
              )}
              {(tab === "systemEditOppr" || tab === "systemEditPay") && (
                <div className="main__inner-title-col">
                  <div className="main__inner-title-row">
                    <h2>System Notifications</h2>
                    <div className={"main__inner-title-status active"}>
                      active
                    </div>
                  </div>
                  <button
                    className="button tertiary big"
                    onClick={() => setTab("system")}
                  >
                    {arrowLeft} Back
                  </button>
                </div>
              )}

              <Profile
                account={account}
                setAuth={setAuth}
                setModal={setModal}
                NotifModul={NotifModul}
                profiles={profiles}
                setNotifModul={setModalNotification}
              />
            </div>

            {tab === "main" && (
              <NotifMain
                downloadCsv={downloadCsv}
                onPaginate={onPaginate}
                paginationData={paginationData}
                setTab={setTab}
                setTabData={setTabData}
                tabData={notificationData}
                setSearchTextOnChange={setSearchTextOnChange}
                changeMediumDesc={changeMediumDesc}
                search={search}
                searchText={searchText} />
            )}
            {tab === "system" && (
              <NotifSystem
                onPaginate={onPaginate}
                paginationData={paginationData}
                setTab={setTab}
                setTabData={setTabData}
                tabData={systemNotificationData}
                downloadCsv={downloadCsv}
                setSearchTextOnChange={setSearchTextOnChange}
                changeMediumDesc={changeMediumDesc}
                search={search}
                searchText={searchText}
              />
            )}
            {(tab === "add" && isUserLoaded === true) && <NotifAdd deleteNotification={deleteNotification} setTab={setTab} addToast={addToast} allusers={allusers} />}
            {(tab === "edit" && tabData.text !== "" && isUserLoaded === true) && (
              <NotifEdit deleteNotification={deleteNotification} setTab={setTab} tabData={tabData} addToast={addToast} allusers={allusers} />
            )}
            {(tab === "systemAdd") && (
              <NotifAddSystem setTab={setTab} addToast={addToast} />
            )}
            {tab === "systemEditOppr" && (
              <NotifEditSystem
                type="opportunity"
                setTab={setTab}
                addToast={addToast}
                tabData={tabData}
              />
            )}
            {tab === "systemEditPay" && (
              <NotifEditSystem
                type="payment"
                setTab={setTab}
                addToast={addToast}
                tabData={tabData}
              />
            )}
          </div>
        </div>
      }
      {toastList && (
        <Toast type="rate" list={toastList} setList={setToastList} />
      )}
    </>
  );
}
