import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '../../Components';
import { Pagination } from '../../Components';
import { Profile } from '../../Components';
import { arrowRight, arrowTop, download, Toast, arrowBottom } from '../../Components';
import { YearToDate } from '../../Components';
import DashboardItem from './DashboardItem';
import { NotificationItem } from '../../Components';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { campaignService } from '../../Services';
import { utils } from '../../Helpers';
const ytdList = [
  { id: '0', value: 'Year to date' },
  { id: '1', value: 'Past 1 month' },
  { id: '6', value: 'Past 6 months' },
  { id: '12', value: 'Past 12 months' }
];

export default function BrandDashboard({
  NotifModul,
  setNotifModul,
  deleteNotif,
  user,
  profiles,
  setModalSwitch,
  updateNotificationStatus,
  setModalNotification,
  setModalNotificationData,
  accountCountNotification
}) {
  // eslint-disable-next-line
  const [dateRange, setDateRange] = useState(0);
  const [dashboardSummary, setDashboardSummary] = useState({});
  const [dashboardCampaign, setDashboardCampaign] = useState({});
  const [isloaded, setIsLoaded] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('desc');
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modal, setModal] = useState(null);
  const influencerName = user ? (user.data ? user.data.firstName : null) : null;
  const width = useMediaQuery();
  const navigate = useNavigate();
  const pageSize = 5;

  useEffect(() => {
    async function fetchBrandDashboardData() {
      try {
        const res = await campaignService.getAll({
          page: page,
          pageSize: 5,
          range: dateRange,
          sortBy: sortOrder
        });
        setDashboardSummary(res.summary)
        setDashboardCampaign(res.data);
        if (res.meta && res.meta.pagination) {
          setPaginationData(res.meta.pagination);
        }
        setIsCampaignLoaded(true);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }
    fetchBrandDashboardData();
  }, [page, dateRange, sortOrder]);

  const downloadCsv = async () => {
    try {
      const response = await campaignService.getAll({
        page: page,
        pageSize: 5,
        range: dateRange,
        fileType: 'csv',
        sortBy: sortOrder
      });
      utils.downloadFile(response, "brandDashboard_page_" + page + ".csv");
    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }

  const onPaginate = page => {
    setPage(page);
  };

  const sortList = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const addToast = data => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };


  const maxPlaceholder = width <= 1180 ? 2 : 3;
  const NotifMax = width <= 1180 && NotifModul.length > 2 ? 2 : 3;
  const NotifMaxPlaceholder = width <= 1180 ? 2 : 3;
  const showNotifPlacholder = NotifMaxPlaceholder - NotifModul.length;

  const changeMedium = item => {
    setDateRange(item.id);
    setPage(1);
    setSortOrder('desc');
  };
  return (
    <>
      <div className='main'>
        <div className='main__inner'>
          <div className='main__inner-title'>
            <h2>Hey {influencerName}!</h2>
            <Profile
              user={user}
              setModal={setModalSwitch}
              NotifModul={NotifModul}
              profiles={profiles}
              setNotifModul={setModalNotification}
            />
          </div>
          {isloaded && (
            <div className='dashboard'>
              <div className='dashboard__inner'>
                <div className='dashboard__row'>
                  <div className='dashboard__col blue'>
                    <div className='dashboard__col-title'>
                      <h5>Active campaigns</h5>
                      <button
                        onClick={() => navigate('/brand/campaigns')}
                        className='button tertiary'
                      >
                        View all {arrowRight}
                      </button>
                    </div>
                    <div className='dashboard__col-content'>
                      <div className='dashboard__col-content-item'>
                        <h2>
                          {dashboardSummary
                            ? dashboardSummary.totalactivecampaign
                            : '0'}
                        </h2>
                        <p className='sm'>Total</p>
                      </div>
                      <div className='dashboard__col-content-item'>
                        <h2>
                          {dashboardSummary
                            ? dashboardSummary.totalrevenueofactivecampaign
                            : '$0'}
                        </h2>
                        <p className='sm'>Total revenue</p>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard__col green'>
                    <div className='dashboard__col-title'>
                      <h5>Billing</h5>
                      <button
                        onClick={() => navigate('/brand/billing')}
                        className='button tertiary'
                      >
                        View all {arrowRight}
                      </button>
                    </div>
                    <div className='dashboard__col-content'>
                      <div className='dashboard__col-content-item'>

                      </div>
                      <div className='dashboard__col-content-item'>
                        <h2>
                          {dashboardSummary
                            ? dashboardSummary.availablebalance
                            : '$0'}
                        </h2>
                        <p className='sm'>Available balance</p>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard__col yellow'>
                    <div className='dashboard__col-title'>
                      <h5>TAMA coins</h5>
                      <button className='button tertiary'>
                        View all {arrowRight}
                      </button>
                    </div>
                    <div className='dashboard__col-content'>
                      <div className='dashboard__col-content-item'>
                        <h2>{dashboardSummary ? '0' : '0'}</h2>
                        <p className='sm'>Total</p>
                      </div>
                      <div className='dashboard__col-content-item'>
                        <h2>0</h2>
                        <p className='sm'>Achievements</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='dashboard__table-outer'>
                  <div className='dashboard__table-title'>
                    <h4>Campaigns</h4>
                    <YearToDate
                      list={ytdList}
                      selected={ytdList[0]}
                      onChange={changeMedium}
                    />
                  </div>
                  <div className='dashboard__table'>
                    <table>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th >Date</th>
                          <th>Description</th>
                          <th>Budget</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(isCampaignLoaded && dashboardCampaign.length > 0) ? (
                          <>
                            {dashboardCampaign.map((item, index) => {
                              return <DashboardItem key={index} {...item} />;
                            })}
                          </>
                        ) : (
                            <tr>
                              <td colSpan='4' style={{ textAlign: 'center', fontWeight: 'normal' }}>
                                No records
                            </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                  {dashboardCampaign.length > 0 && (
                    <div className='dashboard__table-footer'>
                      <button className='button tertiary download' onClick={downloadCsv}>
                        Download CSV {download}
                      </button>
                      <Pagination
                        perPage={paginationData['per_page']}
                        total={paginationData['total']}
                        currentPage={paginationData['current_page']}
                        totalPages={paginationData['total_pages']}
                        onPaginate={onPaginate}
                      />
                    </div>
                  )}
                </div>
                <div className='notification__outer'>
                  <div className='notification__title'>
                    <h4>Notifications</h4>
                    {NotifModul.length > 0 && (
                      <button
                        className='button tertiary'
                        onClick={() => setModalNotification('notifications')}
                      >
                        View all {arrowRight}
                      </button>
                    )}
                  </div>
                  <div className='notification'>
                    {[...NotifModul].splice(0, NotifMax).map((item, index) => {
                      return (
                        <NotificationItem
                          NotifModul={NotifModul}
                          setNotifModul={setModalNotification}
                          deleteNotif={deleteNotif}
                          updateNotificationStatus={updateNotificationStatus}
                          setModalNotification={setModalNotification}
                          setModalNotificationData={setModalNotificationData}
                          setModalData={() => {
                            setModalNotificationData(item);
                            setModalNotification('notifDetailDash');
                          }}
                          key={index}
                          {...item}
                          accountCount={accountCountNotification}
                        />
                      );
                    })}
                    {showNotifPlacholder > 0 && (
                      <div
                        className={
                          'notification__placeholder ' +
                          (showNotifPlacholder === (width <= 1180 ? '' : 2)
                            ? 'half '
                            : '') +
                          (showNotifPlacholder === (width <= 1180 ? 2 : 3)
                            ? 'full '
                            : '')
                        }
                      >
                        <div className='notification__placeholder-image'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/images/placeholder/notificationPlaceholder.png'
                            }
                            alt='notificationPlaceholder.png'
                          />
                        </div>
                        <h6>
                          {showNotifPlacholder === (width <= 1180 ? 2 : 3)
                            ? 'You don’t have any notifications yet.'
                            : 'That’s all the notifications for now!!'}
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div >
      {toastList && <Toast list={toastList} setList={setToastList} />
      }
    </>
  );
}