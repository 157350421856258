import React, { useState, useEffect } from "react";
import { Toast } from "../../Components";
import { Profile } from "../../Components";
import TamaCoinMain from "./TamaCoinMain";

import TamaCoinEdit from "./TamaCoinAdd/TamaCoinEdit";
import TamaCoinAdd from "./TamaCoinAdd/TamaCoinAdd";
import { arrowLeft } from "../../Components";

import {
  notificationService, tamacoinService
} from '../../Services';
import { utils } from "../../Helpers/utils";
export default function TamaCoin({ setAuth, setModal, NotifModul, account, profiles, setModalNotification, locationData }) {
  const [tab, setTab] = useState("main");
  const [isloaded, setIsLoaded] = React.useState(false);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState({});

  const [activityData, setActivityData] = useState([]);
  const [tabData, setTabData] = useState({});
  const [selectDesc, setselectDesc] = useState("coin");
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState(false);
  const changeMediumDesc = (item) => {
    setselectDesc(item.id)
    setPage(1);
    setSearchText("");
    setSearchList(!searchList);
  };
  const addToast = (data) => {
    const newToastList = [...toastList];
    newToastList.push({ ...data, id: `${Date.now()}-${toastList.length}` });
    setToastList(newToastList);
  };
  const search = () => {
    setSearchList(!searchList);
    setPage(1);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        let typeVal = tab === 'main' ? 'manual' : 'system';
        // let filter = 'filter[type]=' + typeVal;
        let filter = "";
        console.log(searchText);
        console.log(selectDesc)
        if (searchText !== '') {
          if (filter != "") {
            filter += '&filter[' + selectDesc + ']=' + searchText;
          } else {
            filter = 'filter[' + selectDesc + ']=' + searchText;
          }
        }
        const res = await tamacoinService.getAll({
          page: page,
          pageSize: pageSize,
          filter
        });
        if (res.meta && res.meta.pagination) {
          setPaginationData(res.meta.pagination);
        }
        setActivityData(res.data ? res.data : []);
        setIsLoaded(true);
      } catch (err) {
        addToast({
          error: true,
          msg: err,
          id: `${Date.now()}-${toastList.length}`
        });
      }
    }

    if (tab !== "add" && tab !== "edit")
      fetchData();

  }, [tab, page, searchList]);
  const downloadCsv = async () => {
    try {

      let filter = "";
      if (searchText !== '') {
        if (filter != "") {
          filter += '&filter[' + selectDesc + ']=' + searchText;
        } else {
          filter = 'filter[' + selectDesc + ']=' + searchText;
        }
      }
      const response = await tamacoinService.getAll({ page: page, pageSize: pageSize, filter: filter, fileType: 'csv' });
      utils.downloadFile(response, "adminNotification_" + page + ".csv");

    } catch (err) {
      addToast({
        error: true,
        msg: err,
        id: `${Date.now()}-${toastList.length}`
      });
    }
  }
  const onPaginate = page => {
    setPage(page);
  };

  const setSearchTextOnChange = event => {
    if (event.target.value === '') {
      setSearchList(!searchList);
    }
    setSearchText(event.target.value);
  };
  useEffect(() => {
    setTab("main");
    setselectDesc("coin")
    setPage(1);
    setSearchText("");
  }, [locationData.key]);
  return (
    <>
      {isloaded &&
        <div className="main">
          <div className="main__inner">
            <div className="main__inner-title">
              <h2>Tama Coin</h2>




              <Profile
                account={account}
                setAuth={setAuth}
                setModal={setModal}
                NotifModul={NotifModul}
                profiles={profiles}
                setNotifModul={setModalNotification}
              />
            </div>

            {tab === "main" && (
              <TamaCoinMain
                downloadCsv={downloadCsv}
                onPaginate={onPaginate}
                paginationData={paginationData}
                setTab={setTab}
                setTabData={setTabData}
                tabData={activityData}
                setSearchTextOnChange={setSearchTextOnChange}
                changeMediumDesc={changeMediumDesc}
                search={search}
                searchText={searchText} />
            )}

            {(tab === "add") && <TamaCoinAdd tab={tab} setTab={setTab} addToast={addToast} />}


            {(tab === "edit" && tabData.activity !== "") && (
              <TamaCoinEdit setTab={setTab} tab={tab} tabData={tabData} addToast={addToast} />
            )}

          </div>
        </div>
      }
      {toastList && (
        <Toast type="rate" list={toastList} setList={setToastList} />
      )}
    </>
  );
}
