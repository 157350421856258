import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { utils } from '../../../Helpers/utils';
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { attributesToProps } from "html-react-parser";
import Parser from 'html-react-parser';
export default function EditorInput({
  label,
  onChange,
  value,
  placeholder,
  error,
  errorText,
  editorState,
  onEditorStateChange,
  readOnly
}) {
  let maxLength = 1500;
  const [errorLength, setErrorLength] = useState();
  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > maxLength - 1) {
      setErrorLength('You can type max ' + maxLength + ' characters')
      return 'handled';
    } else {

      setErrorLength("");
    }
  }
  useEffect(() => {
    setErrorLength('');
  }, []);
  const handleRemoveText = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();
    if (currentContentLength - selectedTextLength > maxLength - 1) {
      setErrorLength('You can type max ' + maxLength + ' characters');
    } else {
      setErrorLength("");
    }
  }

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();
    if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
      setErrorLength('You can paste max ' + maxLength + ' characters')
      return 'handled';
    } else {
      setErrorLength("");
    }
  };
  let valueObj = value ? value !== undefined ? (value[0]) ? value[0].text : value : '' : '';
  return (
    <div className="input__outer">
      {label && <label>{Parser(label)}</label>}
      <div className={"input " + (((error && value === "") || (error && valueObj === "") || (errorLength != '')) ? 'error' : '')}>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          onChange={onChange}
          placeholder={placeholder}
          handleBeforeInput={handleBeforeInput}
          handleKeyCommand={handleRemoveText}
          handlePastedText={handlePastedText}
          readOnly={readOnly}
          toolbar={{
            options: ["inline", "list"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
        />
      </div>
      {((error && value === "") || (error && valueObj === "") || (errorLength !== "")) && <div className="input__error">{errorLength != '' ? errorLength : errorText}</div>}
    </div >
  );
}
